import React, { useEffect, useState } from "react";

// @mui material components
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Graphql
import { useMutation } from "@apollo/client";

// Queries
import { ADD_USER_EMPLOYEE } from "apollo/queries/employees/AddUserEmployee";

// Alert
import toast from "react-hot-toast";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Types

interface Props {
  handleClose: () => void;
  open: boolean;
  employee: any;
  reFetch: () => void;
}

function DialogAddUser({ handleClose, open, employee, reFetch }: Props) {
  // Mutations
  const [addUserEmployee, dataAddUserEmployee] = useMutation(ADD_USER_EMPLOYEE);

  const validationSchema = Yup.object({
    name: Yup.string()
      .oneOf([String(employee?.firstName), null], "El nombre debe coincidir")
      .required("El nombre es requerido"),
    email: Yup.string().email("El correo es invalido").trim().required("El correo es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await addUserEmployee({
          variables: {
            idEmployeeCompany: employee?.idEmployeeCompany,
            userData: {
              name: values.name,
              email: values.email,
            },
          },
        });
        if (res.data?.createUserEmployee?.isSuccess) {
          toast.success("El usuario se a creado correctamente");
          reFetch();
          resetForm({
            values: {
              email: "",
              name: "",
            },
          });
          handleClose();
          formik.setTouched({
            name: false,
            email: false,
          });
        } else {
          toast.error(`Hubo un error al crear el usuario ${res.data?.createUserEmployee?.message?.detail}`);
        }
      } catch (error) {
        toast.error(`Hubo un error al crear el usuario ${error}`);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        formik.setTouched({
          name: false,
          email: false,
        });
      }}
      sx={{ px: 2 }}
    >
      <DialogTitle sx={{ color: "#5AA9E6", pb: 0 }}>{"Crear Usuario"}</DialogTitle>
      <DialogTitle sx={{ color: "#00547A", pt: 1 }}>{`${employee?.firstName} ${employee?.secondName} ${employee?.firstLastName} ${employee?.secondLastName}`}</DialogTitle>
      <DialogContent>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Le crearas el{" "}
          <MDTypography variant="button" sx={{ color: "#5AA9E6" }} fontWeight="bold" fontSize="1rem">
            USUARIO{" "}
          </MDTypography>
          al empleado{" "}
          <MDTypography variant="button" sx={{ color: "#5AA9E6" }} fontWeight="bold" fontSize="1rem">
            {employee?.firstName}{" "}
          </MDTypography>
          para confirmar esta operacion por favor digita el primer nombre en el campo de abajo y el correo con el que ingresara a la aplicacion:
        </MDTypography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre"
              name="name"
              variant="standard"
              value={formik.values.name}
              onBlur={() => formik.setFieldTouched("name", true)}
              error={Boolean(formik.errors.name) && formik.touched.name}
              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Correo Electronico"
              name="email"
              variant="standard"
              value={formik.values.email}
              onBlur={() => formik.setFieldTouched("email", true)}
              error={Boolean(formik.errors.email) && formik.touched.email}
              helperText={!formik.touched.email || (formik.touched.email && !formik.errors.email) ? " " : formik.errors.email}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
            formik.setTouched({
              name: false,
              email: false,
            });
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={dataAddUserEmployee?.loading || Boolean(formik.errors.name) || Boolean(formik.errors.email) || formik.values.name === "" || formik.values.email === ""}
          variant="gradient"
          type="submit"
          color="info"
        >
          {dataAddUserEmployee?.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Guardar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogAddUser;
