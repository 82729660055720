// @ts-nocheck
import React, { useState } from "react";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

import { DropdownOptions } from "types/environment";
import { IvaDocument } from "types/iva-document";

const options = [DropdownOptions.SELECT_ENV, DropdownOptions.AUTOMATIC_TEST_MH];

export default function StateDropdown({ state, handleSelect, id, row }: { state?: string; handleSelect?: any; id?: number; row?: IvaDocument }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const stateIndex = options.indexOf(state);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setOpen(false);
    handleSelect(id, options[index], row);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "Pruebas Internas":
        return <Icon>circle</Icon>;
      case "Pruebas con el Ministerio de Hacienda":
        return <Icon color="warning">circle</Icon>;
      case "Emisiones Reales":
        return <Icon color="success">circle</Icon>;
      default:
        return <Icon>circle</Icon>;
    }
  };

  return (
    <React.Fragment>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>
      <Popper
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <NotificationItem key={option} icon={getIcon(option)} title={option} disabled={index === stateIndex} selected={index === stateIndex} onClick={(event) => handleMenuItemClick(event, index)} />
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </React.Fragment>
  );
}
