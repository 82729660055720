// @mui material components
import { FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel } from "@mui/material";

// Assets
import DocuementIcon from "layouts/assets/icons/DocumentIcon";

// Context
import { useFormikContext } from "formik";

// Utils
import { handleChangeFormat } from "utils/handleChangeFormat";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const VentaCuentasTerceros = () => {
  const { values, setFieldValue, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  return (
    <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
      <Grid item xs={12} lg={6} sx={{ position: "relative" }}>
        <FormControl fullWidth variant="standard" error={Boolean(errors?.ventasCuentasTerceros?.nit)}>
          <InputLabel>Numero de Identificacion Tributaria</InputLabel>
          <Input
            name="ventasCuentasTerceros.nit"
            endAdornment={
              <InputAdornment position="start">
                <DocuementIcon />
              </InputAdornment>
            }
            onChange={(e) => handleChangeFormat(e, "NIT", setFieldValue as any, "ventasCuentasTerceros.nit")}
            value={values.ventasCuentasTerceros?.nit}
          />
          <FormHelperText>{!errors?.ventasCuentasTerceros?.nit ? " " : errors?.ventasCuentasTerceros?.nit}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6} sx={{ position: "relative" }}>
        <FormControl fullWidth variant="standard" error={Boolean(errors?.ventasCuentasTerceros?.name)}>
          <InputLabel>Nombre</InputLabel>
          <Input name="ventasCuentasTerceros.name" onChange={(e) => setFieldValue("ventasCuentasTerceros.name", e.target.value)} value={values.ventasCuentasTerceros?.name} />
          <FormHelperText>{!errors?.ventasCuentasTerceros?.name ? " " : errors?.ventasCuentasTerceros?.name}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default VentaCuentasTerceros;
