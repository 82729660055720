import gql from "graphql-tag";

export const ADD_ARTICLE_FROM_STORAGE_TO_PRICE_LIST = gql`
  mutation ADD_ARTICLE_FROM_STORAGE_TO_PRICE_LIST($idStorage: Float!, $idPriceList: Float!) {
    addArticlesFromStorageToPriceList(idStorage: $idStorage, idPriceList: $idPriceList) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
