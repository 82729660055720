// @mui material components
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Types
import { FormikProps } from "formik";
import { NameAndId } from "../DetallesLDP";
import { SerializationKeys } from "types/apollo";
import { ValuesLDP } from "types/ValuesLDP";

// Graphql
import { useMutation } from "@apollo/client";

// Queries
import { DELETE_SALE_POINT_FROM_PRICE_LIST } from "apollo/mutations/salePoints/deleteSalePointFromPriceList";

interface Props {
  open: {
    openAdd: boolean;
    openDelete: boolean;
  };
  handleClose: (value: boolean) => void;
  formik: FormikProps<ValuesLDP>;
  nameDelete: NameAndId;
  location: any;
  handleSucces: any;
  handleFail: any;
}

function DialogDeletePtosDeVtasLDP({ open, handleClose, formik, nameDelete, location, handleSucces, handleFail }: Props) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Graphql
  const [deleteSalePointFromPriceList, dataDeleteSalePointFromPriceList] = useMutation(DELETE_SALE_POINT_FROM_PRICE_LIST, { context });

  const handleDeletePtosDeVtasLDP = () => {
    if (nameDelete?.id !== null || nameDelete?.name !== "" || nameDelete !== null) {
      deleteSalePointFromPriceList({
        variables: {
          idSalePoint: Number(nameDelete?.id),
          idPriceList: Number(location.state.id),
        },
      })
        .then((data) => {
          if (data.data.deleteSalePointFromPriceList.isSuccess) {
            handleSucces();
          } else {
            handleFail(data);
          }
        })
        .catch((e) => {
          handleFail(e);
        });
    }
  };
  return (
    <Dialog
      open={open.openDelete}
      keepMounted
      onClose={() => {
        handleClose(false);
        formik.setFieldValue("nameSalePointDelete", "");
        formik.setFieldTouched("nameSalePointDelete", false);
      }}
    >
      <DialogTitle
        children={
          <Typography color="error" variant="h4" fontWeight="bold">
            Desvincular Punto de Ventas
          </Typography>
        }
      />
      <DialogContent>
        <MDBox>
          <MDTypography color="info" variant="h6" fontWeight="bold">
            {`¿Estas seguro que quieres desvincular este punto de ventas de la lista de precios: ${location.state.namePriceList}?`}
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
            Confirma que quieres{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`Desvincular`}
            </MDTypography>{" "}
            de la lista de precios este punto de venta llamado:{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`"${nameDelete?.name}".`}{" "}
            </MDTypography>
            Escribiendo su nombre en el siguiente campo de texto:
          </MDTypography>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.nameSalePointDelete) && formik.touched.nameSalePointDelete}
              onBlur={() => formik.setFieldTouched("nameSalePointDelete", true)}
              label="Nombre del Punto de Venta"
              name="nameSalePointDelete"
              helperText={!formik.touched.nameSalePointDelete || (formik.touched.nameSalePointDelete && !formik.errors.nameSalePointDelete) ? "Escribe el nombre exacto del punto de venta a eliminar" : formik.errors.nameSalePointDelete}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.nameSalePointDelete}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose(false);
            formik.setFieldValue("nameSalePointDelete", "");
            formik.setFieldTouched("nameSalePointDelete", false);
          }}
          variant="outlined"
          color="info"
        >
          cancelar
        </MDButton>
        <MDButton
          disabled={dataDeleteSalePointFromPriceList.loading || Boolean(formik.errors.nameSalePointDelete) || formik.values.nameSalePointDelete === ""}
          onClick={() => {
            handleDeletePtosDeVtasLDP();
          }}
          startIcon={dataDeleteSalePointFromPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
          variant="contained"
          type="submit"
          color="error"
        >
          {dataDeleteSalePointFromPriceList.loading ? "Eliminando..." : "Eliminar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDeletePtosDeVtasLDP;
