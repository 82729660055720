import gql from "graphql-tag";

export const RE_SEND_SALE = gql`
  mutation RE_SEND_SALE($generationCode: String!, $idSaleorigin: Float!, $data: GeneralSaleDocumentDti!) {
    resendSale(generationCode: $generationCode, idSaleorigin: $idSaleorigin, data: $data) {
      isSuccess
      data {
        mhObservations
        generationCodeDte
        status
        clientCompany {
          name
          id
        }
      }
      message {
        code
        detail
        message
      }
    }
  }
`;
