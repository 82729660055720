import gql from "graphql-tag";

export const ADD_STORAGE_ARTICLE = gql`
  mutation ADD_STORAGE_ARTICLE($data: StorageArticleDti!) {
    addStorageArticle(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
