import gql from "graphql-tag";

export const DELETE_ARTICLE = gql`
  mutation DELETE_ARTICLE($idArticle: Float!) {
    deleteArticle(idArticle: $idArticle) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
