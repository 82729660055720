import { useEffect, useMemo } from "react";

// React dropzone
import { FileWithPath, useDropzone } from "react-dropzone";

// Alert
import { toast } from "react-hot-toast";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  justifyContent: "center",
  padding: "20px",
  borderWidth: "0.0625rem",
  borderRadius: "0.375rem",
  borderColor: "#00547A",
  borderStyle: "solid",
  backgroundColor: "transparent",
  color: "#00547A",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "150px",
};

const focusedStyle = {
  color: "#00547A",
  boxShadow: "0rem 0rem 0rem 0.2rem rgba(0, 84, 122, 0.5)",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface Props {
  handleDrop: any;
}
function FileDropZone({ handleDrop }: Props) {
  const onDrop = (acceptedFiles: FileWithPath[]) => {
    acceptedFiles && acceptedFiles.length > 0 && handleDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles, fileRejections } = useDropzone({
    accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] },
    onDrop,
    maxFiles: 3,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (fileRejections.length > 3) {
      toast.error("Muchos archivos😲😲, la cantidad maxima es 3");
    }
  }, [fileRejections]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <section className="container">
        <div {...getRootProps({ style: { ...style } })}>
          <input {...getInputProps({})} />
          <p>Arrastra los archivos para subirlos</p>
        </div>
        <h4>Files:</h4>
        <MDBox sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>{<ul>{files}</ul>}</MDBox>
      </section>
    </>
  );
}
export default FileDropZone;
