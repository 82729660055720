import gql from "graphql-tag";

export const DELETE_USER_ENTITY = gql`
  mutation DELETE_USER_ENTITY($idUserEntity: Float!) {
    deleteUserEntity(idUserEntity: $idUserEntity) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
