// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// React and GraphQl
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

//Components
import TipoBodegas from "../components/TipoBodega";
import GeneralesBodega from "../components/GeneralesBodega";

// Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

//Types
import { Bodegas } from "types/bodegas";

// Data
const steps = ["Tipo", "Generales"];

const validate = (values: Bodegas) => {
  const errors: any = {};

  if (!values.finalidad) {
    errors.finalidad = "La finalidad de la bodega es requerida";
  }
  //General
  if (!values.name) {
    errors.name = "El nombre de la bodega es requerido";
  } else if (values.name.length < 4) {
    errors.name = "El nombre de la bodega debe tener al menos 4 caracteres ";
  }
  if (!values.costCenter?.label) {
    errors.costCenter = "La oficina es requerida";
  }
  return errors;
};

const ADD_STORAGE = gql`
  mutation ADD_STORAGE($storageData: StoragesDti!) {
    addStorage(storageData: $storageData) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;

function CrearBodega() {
  //Querys and Mutations
  const [addStorage, { loading }] = useMutation(ADD_STORAGE);

  // States
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      finalidad: "",
      isActive: false,
      name: "",
      costCenter: {
        id: 0,
        label: "",
      },
    },
    validate,
    onSubmit: async (values) => {
      addStorage({
        variables: {
          storageData: {
            idCostCenter: values.costCenter.id,
            name: values.name,
            isActive: true,
            allowSale: values.finalidad === "Para la Venta" ? true : false,
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addStorage;
          if (isSuccess) {
            toast.success("Bodega creada correctamente");
            navigate("/configuracion/empresa", { state: 2 });
          } else {
            toast.error(`Hubo un error al crear la bodega ${message.message}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al crear la bodega ${e}`);
        });
    },
  });

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <TipoBodegas formik={formik} />;
      case 1:
        return <GeneralesBodega formik={formik} />;
    }
  }
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 1) return prev + 1;
      return prev;
    });
  };
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev == 1) return prev - 1;
      return prev;
    });
  };
  const buttonNext = (step: any) => {
    if (step === 0) {
      return (
        <MDButton
          disabled={!Boolean(formik.values.finalidad)}
          onClick={() => {
            handleNext();
          }}
          variant="gradient"
          color="info"
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          disabled={Boolean(formik.errors.costCenter) || Boolean(formik.errors.name) || loading}
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={580} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/configuracion/empresa")} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default CrearBodega;
