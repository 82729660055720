// Material Dashboard 2 PRO React TS examples components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// Custom components
import CustomTimeLineItem from "./CustomTimelineItem";

// Graphql
import { useQuery } from "@apollo/client";

//Query
import { GET_REINTENTOS } from "apollo/queries/getReintentosByGenerationCode";
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";

//Types
import { SerializationKeys } from "types/apollo";

const Reintentos = () => {
  // Context
  const { document } = useGeneralDocumentContext();

  // Data
  const lengthHistory = document?.history.length;

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Graphql
  const { data, loading, error } = useQuery(GET_REINTENTOS, {
    variables: {
      id: document?.idSale,
      generationCodeDte: document?.generationCode,
    },
    context,
  });

  return (
    <>
      {loading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" height={{ xs: "200px", lg: "400px" }}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <Grid container justifyContent="center" gap={5}>
          <Grid container justifyContent="center" gap={5}>
            <Card sx={{ padding: 3, width: "100%" }}>
              <MDTypography variant="h3" fontWeight="bold" color="info">
                Reitentos
              </MDTypography>

              <MDBox mt={2}>
                {data.getReintentosByGenerationCode.data.map((item: any) => (
                  <CustomTimeLineItem
                    lastItem={lengthHistory === 1 ? true : false}
                    mb={lengthHistory === 1 ? 0 : 3}
                    color="error"
                    icon="clear"
                    title={`Reitento 2`}
                    dateTime={item?.date ?? "No hay fecha"}
                    reintentos={
                      <>
                        <MDTypography variant="subtitle1" color="secondary" fontWeight="bold">
                          Cliente:{" "}
                          <MDTypography variant="button" color="secondary" fontWeight="regular" fontSize="1.25rem">
                            {`${item?.clientCompany?.name}`}
                          </MDTypography>
                        </MDTypography>
                        <MDTypography variant="subtitle1" color="secondary" fontWeight="bold">
                          Rechazado por:{" "}
                          <MDTypography variant="button" color="secondary" fontWeight="regular" fontSize="1.25rem">
                            {`${item?.mhObservations}`}
                          </MDTypography>
                        </MDTypography>
                      </>
                    }
                  />
                ))}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Reintentos;
