import gql from "graphql-tag";

export const DELETE_DOCUMENT = gql`
  mutation DELETE_DOCUMET($idDocument: Float!) {
    deleteDocument(idDocument: $idDocument) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
