// @mui material components
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";

// Custom components
import Contado from "./Contado";

// Types
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { OperationVariables, QueryResult } from "@apollo/client";
import { SaleInformation } from "types/saleInformation";

interface Props {
  handleClose: (value: ActiveValues) => void;
  freeSale?: boolean;
  handleOpen: (value: ActiveValues) => void;
  CorregirDTE?: boolean;
  open: boolean;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  dataGetGeneralDocuments: QueryResult<any, OperationVariables>;
}

const PaymentMethods = ({ dataGetGeneralDocuments, setSaleInformation, open, handleClose, freeSale, handleOpen, CorregirDTE }: Props) => {
  return (
    <>
      <Modal open={open} onClose={() => handleClose(ActiveValues.paymentMethod)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Card
          sx={{
            overflowY: "auto",
            padding: 3,
            maxWidth: { xs: "100%", xl: "30%" },
            maxHeight: "90vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Contado dataGetGeneralDocuments={dataGetGeneralDocuments} setSaleInformation={setSaleInformation} handleClose={handleClose} freeSale={freeSale} CorregirDTE={CorregirDTE} handleOpen={handleOpen} />
        </Card>
      </Modal>
    </>
  );
};

export default PaymentMethods;
