import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//react-router-dom
import { useNavigate } from "react-router-dom";

//Graphql
import { gql, useMutation, useQuery } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";

//Alerts
import { toast } from "react-hot-toast";

// Types
import { PAGINATION } from "constants/pagination";

const GET_STORAGES = gql`
  query {
    getStorages {
      isSuccess
      data {
        id
        name
        isActive
        allowSale
        costCenter {
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

const DELETE_STORAGE = gql`
  mutation DELETE_STORAGE($idStorage: Float!) {
    deleteStorage(idStorage: $idStorage) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;

interface Row {
  codigo: number;
  nombreBodega: string;
  centroDeCosto: string;
  finalidad: string;
  menu: JSX.Element;
}

const columns = [
  { Header: "Codigo", accessor: "codigo" },
  { Header: "Nombre Bodega", accessor: "nombreBodega" },
  { Header: "Centro de Costo", accessor: "centroDeCosto" },
  { Header: "Finalidad", accessor: "finalidad" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];

const options = ["Ver Detalles", "Editar", "Archivar"];

function Bodegas() {
  const { data, loading, error, refetch } = useQuery(GET_STORAGES, { variables: { pagination: PAGINATION } });
  const [deleteStorage, dataDeleteStorage] = useMutation(DELETE_STORAGE);
  const [rowsData, setRowsData] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const navigate = useNavigate();

  //Effects
  useEffect(() => {
    if (data) {
      setRowsData(data.getStorages.data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      navigate(`/configuracion/empresa/bodegas/editar/${selectedMenuOption.id}`);
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteStorage({
        variables: {
          idStorage: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteStorage.isSuccess) {
          refetch({
            filters: {},
          });
          toast.success("Se archivo la bodega correctamente");
        }
      });
    }
  }, [selectedMenuOption]);

  const rows: Row[] = useMemo(() => {
    return rowsData.map((item, index) => ({
      codigo: index + 1,
      nombreBodega: item?.name,
      centroDeCosto: item.costCenter.name,
      finalidad: item?.allowSale ? "Venta" : "Almacenaje",
      menu: <MenuDropdown onSelectOption={setSelectedMenuOption} id={item?.id} options={options} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Bodegas{" "}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"En este apartado nos referimos a los lugares donde guardas tus inventarios adentro de tus oficinas Recuerda esta gerarquia: Empresa > Oficinas > Bodegas"}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteStorage.loading ? { columns: columns, rows: [] } : table}
        loading={loading || dataDeleteStorage.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => navigate("/configuracion/empresa/bodegas/crear")} color="info">
            Crear Nueva Bodega
          </MDButton>
        </Grid>
      </DataTable>
    </>
  );
}
export default Bodegas;
