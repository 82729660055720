import React, { createContext, useState, useContext, useEffect } from "react";

// Types
import { Company } from "types/company";
import { UserDocumentDTO } from "types/documents";

// Hooks
import { useLocalStorage } from "hooks/useLocalStorage";

export interface Props {
  children: React.ReactNode;
}

interface InfoEmployeeDocuments {
  idMainEntity: number;
  idEntity: number;
  documents: UserDocumentDTO[] | [];
}

export interface CompanyProviderProps {
  company: Company;
  setCompany: (company: Company) => void;
  infoEmployeeDocuments: InfoEmployeeDocuments;
  setInfoEmployeeDocuments: React.Dispatch<React.SetStateAction<InfoEmployeeDocuments>>;
}

const CompanyContext = createContext<CompanyProviderProps>({
  company: null,
  setCompany: () => {},
  infoEmployeeDocuments: null,
  setInfoEmployeeDocuments: () => {},
});

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

export const CompanyContextProvider = ({ children }: Props) => {
  const [persistedCompany, setPersistedCompany] = useLocalStorage("company", null);
  const [persistedInfoEmployeeDocuments, setPersistedInfoEmployeeDocuments] = useLocalStorage("infoEmployeeDocuments", null);
  const [company, setCompany] = useState<Company>(persistedCompany);
  const [infoEmployeeDocuments, setInfoEmployeeDocuments] = useState<InfoEmployeeDocuments>(persistedInfoEmployeeDocuments);

  useEffect(() => {
    setPersistedCompany(company);
  }, [company]);

  useEffect(() => {
    setPersistedInfoEmployeeDocuments(infoEmployeeDocuments);
  }, [infoEmployeeDocuments]);

  const value = {
    company,
    setCompany,
    infoEmployeeDocuments,
    setInfoEmployeeDocuments,
  };

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};
