// Constants
import { RETENTION_PERCEPTION } from "constants/finantial";

// Types
import { Company } from "types/company";
import { ContribuyenteTypes } from "types/contribuyenteTypes";
import { ClientCompany } from "types/documents";

export const calculatePerception = (totalGravados: number, company: Company, clientCompany: ClientCompany) => {
  if (company.contribuyenteType === ContribuyenteTypes.GRANDE && clientCompany.contribuyenteType !== ContribuyenteTypes.GRANDE) {
    return totalGravados * RETENTION_PERCEPTION;
  }
  return 0;
};
