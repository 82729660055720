import gql from "graphql-tag";

export const UPDATE_GROUP = gql`
  mutation UPDATE_GROUP($idGroup: Float!, $groupData: GroupDti!) {
    updateGroup(idGroup: $idGroup, groupData: $groupData) {
      isSuccess
      message {
        code
        message
        detail
      }
      data {
        name
        id
        createdAt
        updatedAt
        company {
          id
        }
      }
    }
  }
`;
