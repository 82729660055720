import gql from "graphql-tag";

export const GET_CLIENTS_BY_PRICE_LIST = gql`
  query GET_ARTICLES_BY_PRICE_LIST($filters: ClientsByPriceListDtiQueryFilter!) {
    getClientsByPriceList(filters: $filters) {
      isSuccess
      data {
        id
        name
        emailUser
        emailNotifications
        phone
        idClientCompany
        tradename
        address
        idClientPriceList
        documents {
          documentNumber
          type
        }
      }
      message {
        detail
        code
        message
      }
    }
  }
`;
