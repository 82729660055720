import gql from "graphql-tag";

export const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($idDocument: Float!, $documentNumber: String!) {
    updateDocument(idDocument: $idDocument, documentNumber: $documentNumber) {
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;
