import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

// Types
import { ChooseEnvironment } from "../..";
type Props = {
  active: ChooseEnvironment;
  setActive: React.Dispatch<React.SetStateAction<ChooseEnvironment>>;
};

const PrimerosPasos = ({ active, setActive }: Props) => {
  const theme = useTheme();

  return (
    // <MDBox mb={2} ml={2} textAlign="left">
    //   <MDBox mb={1}>
    //     <MDTypography color="info" variant="h5" fontWeight="bold">
    //       Primeros pasos
    //     </MDTypography>
    //   </MDBox>

    //   <MDBox mb={1} color="info">
    //     {/* <MDTypography color="info" variant="body1" fontWeight="normal"> */}
    //     <p>
    //       Para iniciar el proceso es necesario que ingreses al portal del Ministerio de Hacienda. Puedes acceder haciendo clic {""}
    //       <a href="/">
    //         <MDTypography variant="string" color="error" fontWeight="medium">
    //           aqui {""}
    //         </MDTypography>
    //       </a>
    //       pero antes te informamos que necesitaras tener a la mano tu numero de NIT y la contraseña que te permite presentar tus declaraciones mensuales.
    //     </p>
    //     <br />
    //     <p>Si aun no la tienes puedes inscribirnos al correo electrónico ayuda.dtes@hazconta.com y con gusto te asistiremos.</p>
    //     <br />
    //     <p>Te dejamos un enlace donde tendrás el paso a paso de como generar tus credenciales dando clic aquí. Este proceso puede llegar a tardar entre 20 a 40 minutos. Cuando estes listo puedes darle en continuar</p>
    //     {/* </MDTypography> */}
    //   </MDBox>
    // </MDBox>
    <MDBox mb={2} ml={2} textAlign="left">
      <MDBox mb={1}>
        <MDTypography color="info" variant="h4" fontWeight="bold" textAlign="center">
          Entornos de Trabajo con el Ministerio de Hacienda
        </MDTypography>
      </MDBox>

      <MDBox mb={1} color="info">
        {/* <MDTypography color="info" variant="body1" fontWeight="normal"> */}
        <p>Por favor selecciona el entorno de trabajo que quieras configurar:</p>
        <li>
          <MDTypography variant="string" color="info" fontWeight="bold">
            Pruebas con el Ministerio de Hacienda: {""}
          </MDTypography>
          Utiliza esta opción cuando este reincorporándose por primera vez a la factura electrónica, y aun no este autorizado a emitir documentos tributarios electrónicos reales.
        </li>
        <li>
          <MDTypography variant="string" color="success" fontWeight="bold">
            Emiciones Reales: {""}
          </MDTypography>
          Utiliza esta opción cuando ya tengas la autorización del Ministerio de Hacienda, para emitir documentos reales.
        </li>
        <br />
        Si quieres saber más del tema haz click {""}
        <a href="/">
          <MDTypography variant="string" color="error" fontWeight="medium">
            aquí
          </MDTypography>
        </a>
      </MDBox>

      <MDBox display="flex" justifyContent="space-evenly" my={6}>
        <MDBox
          display="flex"
          flexDirection="column"
          width={{ lg: "30%" }}
          onClick={() => setActive(ChooseEnvironment.PRUEBAS)}
          sx={{
            transitionDuration: "100ms",
            "&:hover": {
              "button, h4, span, div": {
                color: theme.palette.info.main,
                borderColor: theme.palette.info.main,
              },
            },
          }}
        >
          <MDBox display="flex" justifyContent="center" mb={2}>
            <MDBox
              display="inline-flex"
              fontSize="120px"
              sx={{
                cursor: "pointer",
                color: active === ChooseEnvironment.PRUEBAS ? theme.palette.info.main : "#5AA9E6",
                border: "2px solid",
                borderRadius: theme.borders.borderRadius,
                padding: 5,
              }}
            >
              <Icon sx={{ display: "block" }}>engineering_icon</Icon>
            </MDBox>
          </MDBox>
          <MDTypography sx={{ color: active === ChooseEnvironment.PRUEBAS ? theme.palette.info.main : "#5AA9E6" }} variant="h4" textAlign="center">
            Pruebas con el Ministerio de Hacienda
          </MDTypography>
        </MDBox>

        <MDBox
          display="flex"
          flexDirection="column"
          width={{ lg: "30%" }}
          onClick={() => setActive(ChooseEnvironment.EMISIONES)}
          sx={{
            transitionDuration: "100ms",
            "&:hover": {
              "button, h4, span, div": {
                color: theme.palette.info.main,
                borderColor: theme.palette.info.main,
              },
            },
          }}
        >
          <MDBox display="flex" justifyContent="center" mb={2}>
            <MDBox
              display="inline-flex"
              fontSize="120px"
              sx={{
                cursor: "pointer",
                color: active === ChooseEnvironment.EMISIONES ? theme.palette.info.main : "#5AA9E6",
                border: "2px solid",
                borderRadius: theme.borders.borderRadius,
                padding: 5,
              }}
            >
              <Icon>cloud_sync</Icon>
            </MDBox>
          </MDBox>
          <MDTypography sx={{ color: active === ChooseEnvironment.EMISIONES ? theme.palette.info.main : "#5AA9E6" }} variant="h4" textAlign="center">
            Emiciones Reales con el Ministerio de Hacienda
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default PrimerosPasos;
