import React, { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//@mui material components
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

//Formik
import { FormikProps } from "formik";

//Graphql
import { useQuery } from "@apollo/client";

//Alert
import { toast } from "react-hot-toast";

// Queries
import { GET_TAGS } from "apollo/queries/tags/getTags";

// Types
import { LabelandId } from "types/labelAndId";
import { SerializationKeys } from "types/apollo";
import { ValuesArticulos } from "types/Articulos";
import { TagsArr } from "types/caracteristicas";
import { PAGINATION } from "constants/pagination";

interface Props {
  formik: FormikProps<ValuesArticulos>;
  arrOfTAgs: TagsArr[];
  setArrOfTAgs: React.Dispatch<React.SetStateAction<TagsArr[]>>;
  setItemDeleteTags?: React.Dispatch<any>;
  itemDeleteTags?: any[];
  dataArticleTags?: any;
}

const columns = [
  { Header: "Codigo", accessor: "codigo", width: "20px" },
  { Header: "Caracteristica", accessor: "tag" },
  { Header: "Valor", accessor: "tagValue" },
  { Header: "Borrar", accessor: "borrar", hiddeSort: true, align: "center" },
];

function Caracteristica({ formik, arrOfTAgs, setArrOfTAgs, setItemDeleteTags, itemDeleteTags, dataArticleTags }: Props) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Querys
  const { data, loading, error } = useQuery(GET_TAGS, { context, variables: { tagsFilters: { pagination: PAGINATION } } });

  // States
  const [tags, setTags] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [idRemove, setIdRemove] = useState("");

  // Effects
  useEffect(() => {
    if (data) {
      setTags(
        data.getTags?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [data]);
  useEffect(() => {
    if (formik.values.tags?.label === "" || formik.values.tags === null) {
      formik.setFieldValue("tagValue", "");
    }
  }, [formik.values.tags]);
  useEffect(() => {
    if (idRemove !== "") {
      setArrOfTAgs(arrOfTAgs.filter((item) => item.codigo !== idRemove));
      setIdRemove("");
      if (setItemDeleteTags !== undefined) {
        let exist;
        if (itemDeleteTags.length !== 0) {
          exist = itemDeleteTags.find((item) => item == dataArticleTags.find((item: any) => item?.id == idRemove));
        }
        if (!exist) {
          if (dataArticleTags.find((item: any) => item?.id == idRemove)) {
            setItemDeleteTags((prev: any) => [...prev, dataArticleTags.find((item: any) => item?.id == idRemove)]);
          }
        }
      }
    }
  }, [idRemove]);

  const rows: TagsArr[] = arrOfTAgs.map((item) => ({
    codigo: item?.codigo,
    tag: item?.tag,
    tagValue: item?.tagValue,
    borrar: (
      <ClearIcon
        sx={{ cursor: "pointer" }}
        fontSize="large"
        color="error"
        id={`${item?.codigo}`}
        onClick={(e) => {
          setIdRemove(e.currentTarget.id);
        }}
      />
    ),
  }));

  const table = {
    columns: columns || [],
    rows: rows || [],
  };
  function clearForm() {
    formik.resetForm({
      values: {
        allowNegativeKardex: formik.values.allowNegativeKardex,
        barCode: formik.values.barCode,
        code: formik.values.code,
        description: formik.values.description,
        measurementUnit: formik.values.measurementUnit,
        name: formik.values.name,
        operationType: formik.values.operationType,
        type: formik.values.type,
        defaultPrice: formik.values.defaultPrice,
        priceList: formik.values.priceList,
        price: formik.values.price,
        groups: formik.values.groups,
        tags: { label: "", id: 0 },
        tagValue: "",
        storage: formik.values.storage,
        costsCenters: formik.values.costsCenters,
        max: formik.values.max,
        min: formik.values.min,
        stock: formik.values.stock,
        photos: formik.values.photos,
      },
    });
  }

  const handleAdd: any = () => {
    const exist = arrOfTAgs.find((item) => item.codigo === String(formik.values?.tags?.id));
    if (!exist) {
      if (formik.values.tags?.label !== "" && formik.values.tagValue !== "" && formik.values.tagValue !== null) {
        setArrOfTAgs([
          ...arrOfTAgs,
          {
            codigo: String(formik.values?.tags?.id) ?? "No hay codigo",
            tag: formik.values?.tags?.label ?? "No hay caracteristica",
            tagValue: formik.values.tagValue,
            borrar: (
              <ClearIcon
                sx={{ cursor: "pointer" }}
                fontSize="large"
                color="error"
                id={`${formik.values?.tags?.id}`}
                onClick={(e) => {
                  setIdRemove(e.currentTarget.id);
                }}
              />
            ),
          },
        ]);
        clearForm();
      } else {
        toast.error("Rellene el formulario correctamente");
        formik.setFieldTouched("tags", true);
        formik.setFieldTouched("tagValue", true);
      }
    } else {
      toast.error("No puedes agregar una misma caracteristica dos veces");
      clearForm();
    }
  };

  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Caracteristicas
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Esta seccion es opcional.
        </MDTypography>
      </MDBox>
      <MDBox mt={2} sx={{ border: "1px solid #d2d6da", p: "5px", borderRadius: "0.375rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="tags"
              freeSolo
              options={tags}
              loading={loading}
              value={formik.values.tags}
              onChange={(e, value) => formik.setFieldValue("tags", value)}
              getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
              onBlur={() => formik.setFieldTouched("tags", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Caracteristicas"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={Boolean(formik.errors.tags) && Boolean(formik.touched.tags)}
                  helperText={!formik.touched.tags || (formik.touched.tags && !formik.errors.tags) ? " " : `${formik.errors.tags}`}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.tagValue) && formik.touched.tagValue}
              onBlur={() => formik.setFieldTouched("tagValue", true)}
              label="Valor"
              name="tagValue"
              helperText={!formik.touched.tagValue || (formik.touched.tagValue && !formik.errors.tagValue) ? " " : formik.errors.tagValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => formik.setFieldValue("tagValue", e.target.value)}
              variant="standard"
              value={formik.values.tagValue}
            />
          </Grid>
          <Grid item xs={12}>
            <MDButton
              fullWidth
              variant="outlined"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => {
                handleAdd();
              }}
            >
              AGREGAR CARACTERISTICA
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <DataTable table={table} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }} />
      </MDBox>
    </>
  );
}
export default Caracteristica;
