import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import { Card, CircularProgress, Grid } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Graph
import { useLazyQuery, useMutation } from "@apollo/client";

// Custom components
import Caracteristica from "../../crear/crearArticulos/components/Caracteristica";
import Precio from "../../crear/crearArticulos/components/Precio";
import Bodega from "../../crear/crearArticulos/components/Bodega";
import StepperCat from "components/Global/StepperCat";
import GeneralesArticulos from "../../crear/crearArticulos/components/GeneralesArticulos";

// Formik
import { useFormik } from "formik";
import { validationSchema } from "../../crear/crearArticulos";

// Alert
import { toast } from "react-hot-toast";

// Types
import { SerializationKeys } from "types/apollo";
import { OperationType } from "types/environment";
import { BodegasArr } from "types/bodegas";
import { TagsArr } from "types/caracteristicas";
import { ArticleTypes, ValuesArticulos } from "types/Articulos";
import { PriceListArr } from "types/arrListaDePrecio";

// Id
import { v4 as uuidv4 } from "uuid";

// Axios
import axios from "axios";

// Context
import { useAuthContext } from "context/AuthContext";

// Queries and Mutations
import { GET_ARTICLE } from "apollo/queries/articles/getArticle";
import { UPDATE_ARTICLE } from "apollo/mutations/articles/updateArticle";
import { ADD_ARTICLE_TAGS } from "apollo/mutations/tags/addArticleTags";
import { DELETE_ARTICLE_TAGS } from "apollo/mutations/tags/deleteArticleTags";
import { ADD_STORAGE_ARTICLE } from "apollo/mutations/storage/addStorageArticle";
import { DELETE_STORAGE_ARTICLE } from "apollo/mutations/storage/deleteStorageArticle";
import { DELETE_PHOTO_FROM_ARTICLE } from "apollo/mutations/articles/deletePhotoFromArticle";
import { ADD_ARTICLE_TO_PRICE_LIST } from "apollo/mutations/priceList/addArticleToPriceList";
import { DELETE_ARTICLE_FROM_PRICE_LIST } from "apollo/mutations/priceList/deleteArticleFromPriceList";
import { ADD_ARTICLE_GROUPS } from "apollo/mutations/articles/addArticlesGroups";
import { DELETE_ARTICLE_GROUP } from "apollo/mutations/articles/deleteArticleGroup";

const steps = [
  {
    label: "Generales",
  },
  {
    label: "Bodega",
  },
  {
    label: "Caracteristica",
  },
  {
    label: "Precio",
  },
];

function EditarArticulos() {
  // React-Router-Dom
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useAuthContext();

  //States
  const [activeStep, SetActiveStep] = useState(0);
  const [arrOfBodegas, setArrOfBodegas] = useState<BodegasArr[]>([]);
  const [arrOfTAgs, setArrOfTAgs] = useState<TagsArr[]>([]);
  const [arrOfPricesList, setArrOfPricesList] = useState<PriceListArr[]>([]);
  const [idDelArticulo, setIdDelArticulo] = useState<string>();

  const [itemDeleteBod, setItemDeleteBod] = useState<any[]>([]);
  const [itemDeleteTags, setItemDeleteTags] = useState<any[]>([]);
  const [itemDeletePrice, setItemDeletePrice] = useState<any[]>([]);
  const [itemDeletePhoto, setItemDeletePhoto] = useState<any[]>([]);

  const [itemAddPhoto, setItemAddPhoto] = useState<any[]>([]);

  /* useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []); */

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries and Mutations
  const [getArticle, { data, loading, refetch }] = useLazyQuery(GET_ARTICLE, { context });
  const [updateArticle, dataUpdateArticle] = useMutation(UPDATE_ARTICLE, { context });
  const [addStorageArticle, dataAddStorageArticle] = useMutation(ADD_STORAGE_ARTICLE, { context });
  const [deleteStorageArticle, dataDeleteStorageArticle] = useMutation(DELETE_STORAGE_ARTICLE, { context });
  const [addArticlesTags, dataAddArticlesTags] = useMutation(ADD_ARTICLE_TAGS, { context });
  const [addArticlesGroups, dataAddArticlesGroups] = useMutation(ADD_ARTICLE_GROUPS, { context });
  const [deleteArticlesGroups, dataDeleteArticlesGroups] = useMutation(DELETE_ARTICLE_GROUP, { context });
  const [deleteArticleTags, dataDeleteArticleTags] = useMutation(DELETE_ARTICLE_TAGS, { context });
  const [addArticleToPriceList, dataAddArticleToPriceList] = useMutation(ADD_ARTICLE_TO_PRICE_LIST, { context });
  const [deleteArticlePrice, dataDeleteArticlePrice] = useMutation(DELETE_ARTICLE_FROM_PRICE_LIST, { context });
  const [deletePhotoFromArticle] = useMutation(DELETE_PHOTO_FROM_ARTICLE, { context });

  const formik = useFormik<ValuesArticulos>({
    initialValues: {
      allowNegativeKardex: false,
      defaultPrice: false,
      barCode: "",
      code: "",
      description: "",
      measurementUnit: { label: "", id: 0 },
      name: "",
      operationType: "",
      type: "",
      priceList: { label: "", id: 0 },
      price: "",
      groups: [],
      tags: { label: "", id: 0 },
      tagValue: "",
      storage: { label: "", id: 0 },
      costsCenters: { label: "", id: 0 },
      max: "",
      min: "",
      stock: "",
      photos: [],
    },
    validationSchema: activeStep === 0 ? null : validationSchema,
    onSubmit: async (values) => {
      updateArticle({
        variables: {
          articleId: Number(idDelArticulo),
          articleData: {
            allowNegativeKardex: false,
            barCode: values?.barCode ?? null,
            code: values?.code ?? null,
            description: values?.description,
            idMeasurementUnit: values?.measurementUnit?.id,
            name: values?.name,
            operation_type: getOperationTypeOnSubmit(values?.operationType),
            type: getTypeOnSubmit(values?.type),
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.updateArticle;
          if (isSuccess) {
            toast.success("Articulo actualizado correctamente");
            navigate("/inventarios/articulos", { state: 0 });
          } else {
            toast.error(`Hubo un error al actualizado el articulo ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al actualizado el articulo ${e}`);
        });
    },
  });

  const getTypeOnSubmit = (value: string) => {
    switch (value) {
      case ArticleTypes.BIENES:
        return ArticleTypes.BIENES.toUpperCase();
      case ArticleTypes.SERVICIOS:
        return ArticleTypes.SERVICIOS.toUpperCase();
      case ArticleTypes.AMBOS:
        return ArticleTypes.AMBOS.toUpperCase();
      case ArticleTypes.OTRO:
        return ArticleTypes.OTRO.toUpperCase();
      default:
        value;
    }
  };
  const getOperationTypeOnSubmit = (value: string) => {
    switch (value) {
      case OperationType.EXENTAS:
      case OperationType.EXENTAS.toLowerCase():
        return OperationType.EXENTAS.toUpperCase();
      case OperationType.GRAVADAS:
      case OperationType.GRAVADAS.toLowerCase():
        return OperationType.GRAVADAS.toUpperCase();
      case OperationType.No_Sujetas:
      case OperationType.NO_SUJETAS.toLowerCase():
        return OperationType.NO_SUJETAS.toUpperCase().replace(" ", "_");
      default:
        return value;
    }
  };
  const handleSubmitStorage = async () => {
    if (itemDeleteBod.length !== 0) {
      itemDeleteBod.map((item) => {
        deleteStorageArticle({
          variables: {
            idStorageArticle: item.id,
          },
        })
          .then((dataDeleteStorageArticle) => {
            if (dataDeleteStorageArticle.data.deleteStorageArticle.isSuccess) {
              toast.success("Bodega eliminada correctamente");
              setItemDeleteBod([]);
              refetch({
                idArticle: idDelArticulo,
              });
            } else {
              toast.error(`Hubo un error al eliminar la bodega ${dataDeleteStorageArticle.data.deleteStorageArticle?.message?.detail}`);
            }
          })
          .catch((error) => {
            toast.error(`Hubo un error al eliminar la bodega ${error}`);
          });
      });
    }
    if (arrOfBodegas.length !== 0) {
      arrOfBodegas.map((item, index) => {
        addStorageArticle({
          variables: {
            data: {
              idArticle: Number(idDelArticulo),
              idStorage: Number(item.codigo),
              min: Number(item.min),
              max: Number(item.max),
              skul: item.stock,
            },
          },
        }).then((dataAddStorageArticle) => {
          if (dataAddStorageArticle.data.addStorageArticle.isSuccess) {
            toast.success("Bodega agregada correctamente");
          } else {
            toast.error(`Hubo un error al agregar la bodega ${dataAddStorageArticle.data.addStorageArticle?.message?.detail}`);
          }
        });
      });
    }
  };
  const handleSubmitTags = async () => {
    if (itemDeleteTags.length !== 0) {
      itemDeleteTags.map((item) => {
        deleteArticleTags({
          variables: {
            idArticleTag: item.id,
          },
        })
          .then((dataDeleteArticlesTags) => {
            if (dataDeleteArticlesTags.data.deleteArticlesTags.isSuccess) {
              toast.success("Caracteristica eliminada correctamente");
              setItemDeleteTags([]);
              refetch({
                idArticle: idDelArticulo,
              });
            } else {
              toast.error(`Hubo un error al eliminar la caracteristica ${dataDeleteArticlesTags.data.deleteArticlesTags?.message?.detail}`);
            }
          })
          .catch((error) => {
            toast.error(`Hubo un error al eliminar la caracteristica ${error}`);
          });
      });
    }
    if (arrOfTAgs.length !== 0) {
      const arrTags: { idTag: number; value: string }[] = [];

      arrOfTAgs.map((item) => {
        arrTags.push({ idTag: Number(item?.codigo), value: item?.tagValue });
      });

      arrTags.length > 0
        ? addArticlesTags({ variables: { data: { idArticle: Number(idDelArticulo), tags: arrTags } } })
            .then((dataAddArticleTags) => {
              if (dataAddArticleTags.data.addArticlesTags.isSuccess) {
                toast.success("Caracteristica agregada correctamente");
                refetch({
                  idArticle: idDelArticulo,
                });
              } else {
                toast.error(`Hubo un error al agregar la caracteristica ${dataAddArticleTags.data.addArticlesTags?.message?.detail}`);
              }
            })
            .catch((error) => toast.error(`Hubo un error al agregar la caracteristica ${error}`))
        : null;
    }
  };
  const handleSubmitPrice = async () => {
    if (itemDeletePrice.length !== 0) {
      itemDeletePrice.map((item) => {
        deleteArticlePrice({
          variables: {
            idArticlePriceList: item.id,
          },
        })
          .then((dataDeleteArticlePrice) => {
            if (dataDeleteArticlePrice.data.deleteArticleFromPriceList.isSuccess) {
              toast.success("Precio eliminado correctamente");
              setItemDeletePrice([]);
              refetch({
                idArticle: idDelArticulo,
              });
            } else {
              toast.error(`Hubo un error al eliminar la bodega ${dataDeleteArticlePrice.data.deleteArticleFromPriceList?.message?.detail}`);
            }
          })
          .catch((error) => toast.error(`Hubo un error al eliminar la bodega ${error}`));
      });
    }
    if (arrOfPricesList.length !== 0) {
      const arrPrice: { idPriceList: number; price: number; isDefault: boolean }[] = [];

      arrOfPricesList.map((item) => {
        arrPrice.push({ idPriceList: Number(item?.codigo), price: Number(item?.price), isDefault: item?.default === "Si" ? true : false });
      });

      arrPrice.length > 0
        ? addArticleToPriceList({ variables: { data: arrPrice, idArticle: Number(idDelArticulo) } })
            .then((dataAddArticlePrice) => {
              if (dataAddArticlePrice.data.addArticleToPriceList.isSuccess) {
                toast.success("Precio agregado correctamente");
                refetch({
                  idArticle: idDelArticulo,
                });
              } else {
                toast.error(`Hubo un error al agregar el precio ${dataAddArticlePrice.data.addArticleToPriceList?.message?.detail}`);
              }
            })
            .catch((error) => toast.error(`Hubo un error al agregar el precio ${error}`))
        : null;
    }
  };
  const handleSubmitPhotos = async () => {
    if (itemDeletePhoto.length !== 0) {
      itemDeletePhoto.map((item) => {
        deletePhotoFromArticle({
          variables: {
            idPhoto: item.id,
          },
        })
          .then((dataDeletePhotoFromArticle) => {
            if (dataDeletePhotoFromArticle.data.deletePhotoFromArticle) {
              toast.success("Foto Eliminada correctamente");
              setItemDeletePhoto([]);
              refetch({
                idArticle: idDelArticulo,
              });
            } else {
              toast.error(`Hubo un error al elminar la foto ${dataDeletePhotoFromArticle.data.deletePhotoFromArticle?.message?.detail}`);
            }
          })
          .catch((error) => toast.error(`Hubo un error al elminar la foto ${error}`));
      });
    }
    if (itemAddPhoto.length !== 0) {
      const formData = new FormData();
      itemAddPhoto.map((file, index) => {
        if (index === 0) {
          return formData.append("main", file);
        }
        return formData.append("file", file);
      });
      axios
        .post(`${process.env.REACT_APP_API_URL}/articulos/${idDelArticulo}/photos`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          toast.success("Foto agregada correctamente");
          refetch({
            idArticle: idDelArticulo,
          });
        });
    }
  };
  const handleSubmitGroups = async () => {
    const itemsRemoved = data.getArticle.data?.groups
      .map((itemData: any) => {
        if (!formik.values.groups.find((itemFormik) => itemFormik.id == itemData.group.id)) {
          return itemData;
        }
      })
      .filter((item: any) => item !== undefined);

    if (itemsRemoved.length > 0) {
      itemsRemoved.map((item: any) => {
        deleteArticlesGroups({ variables: { idArticleGroup: item.idArticleGroup } })
          .then((dataDeleteArticlesGroups) => {
            if (dataDeleteArticlesGroups.data.deleteArticleGroup.isSuccess) {
              toast.success("Grupo eliminado correctamente");
            } else {
              toast.error(`Hubo un error al eliminar el grupo ${dataDeleteArticlesGroups.data.deleteArticleGroup?.message?.detail}`);
            }
          })
          .catch((error) => toast.error(`Hubo un error al eliminar el grupo ${error}`));
      });
    }

    const itemAdd = formik.values.groups
      .map((itemFormik: any) => {
        if (!data.getArticle.data?.groups.find((itemData: any) => itemData.group.id == itemFormik.id)) {
          return itemFormik;
        }
      })
      .filter((item: any) => item !== undefined)
      .map((item: any) => item.id);

    if (itemAdd.length > 0) {
      addArticlesGroups({ variables: { data: { idArticle: idDelArticulo, idsGroups: itemAdd } } })
        .then((dataAddArticlesGroups) => {
          if (dataAddArticlesGroups.data.addArticlesGroups.isSuccess) {
            toast.success("Grupo Agregado correctamente");
          } else {
            toast.error(`Hubo un error al agregar el grupo ${dataAddArticlesGroups.data.addArticlesGroups?.message?.detail}`);
          }
        })
        .catch((error) => toast.error(`Hubo un error al agregar el grupo ${error}`));
    }
  };
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <GeneralesArticulos formik={formik} setItemDeletePhoto={setItemDeletePhoto} setItemAddPhoto={setItemAddPhoto} />;
      case 1:
        return <Bodega formik={formik} arrOfBodegas={arrOfBodegas} setArrOfBodegas={setArrOfBodegas} itemDeleteBod={itemDeleteBod} dataArticleStorages={data?.getArticle?.data?.storages} setItemDeleteBod={setItemDeleteBod} />;
      case 2:
        return <Caracteristica formik={formik} arrOfTAgs={arrOfTAgs} setArrOfTAgs={setArrOfTAgs} itemDeleteTags={itemDeleteTags} dataArticleTags={data?.getArticle?.data?.tags} setItemDeleteTags={setItemDeleteTags} />;
      case 3:
        return (
          <Precio formik={formik} arrOfPricesList={arrOfPricesList} setArrOfPricesList={setArrOfPricesList} dataArticleSalesPrices={data?.getArticle?.data?.salesPrices} itemDeletePrice={itemDeletePrice} setItemDeletePrice={setItemDeletePrice} />
        );
    }
  }
  const getGuardarLabel = (step: number) => {
    switch (step) {
      case 0:
        return "Guardar Generales";
      case 1:
        return "Guardar Bodegas";
      case 2:
        return "Guardar Caracteristicas";
      case 3:
        return "Guardar Precios";
    }
  };
  //Effects
  useEffect(() => {
    if (location.state) {
      setIdDelArticulo(location.state as string);
    }
    if (idDelArticulo) {
      getArticle({
        variables: {
          idArticle: Number(idDelArticulo),
        },
      });
    }
  }, [location.state, idDelArticulo]);
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        barCode: data.getArticle.data?.barCode,
        code: data.getArticle.data?.code,
        description: data.getArticle.data?.description,
        measurementUnit:
          data.getArticle.data?.measurement === null
            ? { id: 0, label: "" }
            : {
                id: data.getArticle.data?.measurement?.id,
                label: data.getArticle.data?.measurement?.name,
              },
        name: data.getArticle.data?.name,
        operationType:
          data.getArticle.data?.operation_type == "no_sujetas" || data.getArticle.data?.operation_type == "No_sujetas" ? "No Sujetas" : data.getArticle.data?.operation_type.charAt(0).toUpperCase() + data.getArticle.data?.operation_type.slice(1),
        type: data.getArticle.data?.type,
        photos: data.getArticle.data?.photos.map((file: any) => ({
          id: file?.id,
          preview: file?.urlPhoto,
          idImg: uuidv4(),
        })),
        groups: data.getArticle.data?.groups.map((item: any) => ({
          id: item.group.id,
          label: item.group.name,
        })),
      }));
      if (data.getArticle.data?.storages.length !== 0) {
        setArrOfBodegas(
          data.getArticle.data?.storages.map((item: any) => ({
            codigo: String(item.storage?.id) ?? "No hay codigo",
            bodegas: item.storage?.name ?? "No hay bodegas",
            costsCenters: item.storage?.costCenter?.name ?? "No hay Centro de Costo",
          }))
        );
      }
      if (data.getArticle.data?.tags.length !== 0) {
        setArrOfTAgs(
          data.getArticle.data?.tags.map((item: any) => ({
            codigo: String(item?.id),
            tag: item?.name,
            tagValue: item?.value,
          }))
        );
      }
      if (data.getArticle.data?.salesPrices.length !== 0) {
        setArrOfPricesList(
          data.getArticle.data?.salesPrices.map((item: any) => ({
            codigo: item?.priceList?.id === 0 ? "No hay codigo" : String(item?.priceList?.id),
            priceList: item.priceList?.name ?? "No hay lista de precio",
            price: String(item.salePrice) ?? "No hay precio",
            default: item.isDefault ? "Si" : "No",
          }))
        );
      }
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <MDBox justifyContent={{ xs: "center", sm: "flex-start" }} display="flex" width="100%">
            <StepperCat steps={steps} color="info" state={[activeStep, SetActiveStep]} />
          </MDBox>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card sx={{ padding: 3 }}>{getStepContent(activeStep)}</Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/inventarios/articulos", { state: 0 })} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={
                      dataUpdateArticle.loading ||
                      dataAddStorageArticle.loading ||
                      dataDeleteStorageArticle.loading ||
                      dataAddArticlesTags.loading ||
                      dataDeleteArticleTags.loading ||
                      dataAddArticleToPriceList.loading ||
                      dataDeleteArticlePrice.loading ||
                      dataAddArticlesGroups.loading ||
                      dataDeleteArticlesGroups.loading
                    }
                    onClick={() => {
                      if (activeStep == 0) {
                        formik.handleSubmit();
                        handleSubmitPhotos();
                        handleSubmitGroups();
                      } else if (activeStep == 1) {
                        handleSubmitStorage();
                      } else if (activeStep == 2) {
                        handleSubmitTags();
                      } else if (activeStep == 3) {
                        handleSubmitPrice();
                      }
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdateArticle.loading ||
                    dataAddStorageArticle.loading ||
                    dataDeleteStorageArticle.loading ||
                    dataAddArticlesTags.loading ||
                    dataDeleteArticleTags.loading ||
                    dataAddArticleToPriceList.loading ||
                    dataDeleteArticlePrice.loading ||
                    dataAddArticlesGroups.loading ||
                    dataDeleteArticlesGroups.loading
                      ? "Guardando..."
                      : getGuardarLabel(activeStep)}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
export default EditarArticulos;
