import React from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Icon from "@mui/material/Icon";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

// GrahpQL
import { useMutation } from "@apollo/client";
import { SEND_DTE_BY_EMAIL } from "apollo/mutations/dtes/sendDteByEmail";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Libs
import { toast } from "react-hot-toast";

// Types
import { JsonDte } from "types/documents";
import { SerializationKeys } from "types/apollo";
import { DataTableDTEObject } from "types/dataTableDteObject";

const optionsRechazados = ["Ver Detalles", "Descargar PDF", "Descargar JSON", "Corregir DTE"];

const DtesRechazadosDropdown = ({ state, selectedItem, setDteInformation, handleOpenDelete }: any) => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Graphql
  const [sendDteByEmail] = useMutation(SEND_DTE_BY_EMAIL, { context });

  const { setInitialValues, setGlobal, global } = useAddSellContext();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const stateIndex = optionsRechazados.indexOf(state);

  const document = selectedItem;
  const parsedJson: JsonDte = JSON.parse(document?.jsonDte as any);

  const sendEmail = () => {
    sendDteByEmail({ variables: { data: { idSale: document?.id, emails: [document?.clientCompany?.emailNotifications] } } })
      .then(({ data }) => {
        data.sendDte.isSuccess && toast.success("Email enviado correctamente");
        !data.sendDte.isSuccess && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      })
      .catch((err) => {
        err && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      });
  };

  const handleMenuItemClick = async (option: string, index: number) => {
    /*
     * View individual DTE was built using localStorage because there was no
     *  getGeneralDocumentById query at the moment
     */
    localStorage.setItem("documentView", JSON.stringify(selectedItem));
    index === 0 && navigate(`/ventas/detalle/${selectedItem?.id}`);

    if (option === "Duplicar") {
      const products = document.detailDocuments.map((product: any) => {
        return {
          id: product.id,
          quantity: product.detailQuantity,
          unitPrice: product.priceDetail,
          totalPrice: product.priceDetail * product.detailQuantity,
          operation_type: product.ivaOperationType,
          barCode: product.article.barCode,
          name: product.article.name,
          description: product.article.description,
          code: product.article.code,
          type: product.article.type,
        } as DataTableDTEObject;
      });

      setGlobal({
        ...global,
        conditionPayment: null,
        idClient: document?.idClientCompany,
        iva: document?.ivaAmount,
        products: products,
        paymentMethods: [],
        paymentMethodsFull: [],
        perception: null,
        retention: null,
        subTotal: null,
        totalExentos: null,
        totalGravados: null,
        totalNoSujetos: null,
        totalToPay: null,
        IvaDocumentType: document?.ivaDocument,
      });

      await setInitialValues({
        id: document.id,
        cashier: document.cashier,
        costCenter: document?.costCenter,
        idClientCompany: document?.idClientCompany,
        ivaAmount: document?.ivaAmount,
        IvaDocumentType: document?.ivaDocument,
        subtotal: document?.subtotal,
        totalToPay: document?.totalToPay,
        status: document?.status,
        salePoint: document?.salePoint,
        retentionAmount: document?.retentionAmount,
        perceptionAmount: document?.perceptionAmount,
        operationType: document?.operationType,
        idClien: document?.client,
        clientCompany: document?.clientCompany,
        date: document?.date,
        documentReceiver: document?.documentReceiver,
        dteControlNumber: document?.dteControlNumber,
        environment: document?.environment,
        generationCodeDte: document?.generationCodeDte,
        products: products,
        operation_type: null,
        productCodes: null,
        salePrice: null,
        detailDocument: {
          quantity: 1,
          salePrice: null,
        },
        quantity: 1,
      });
      navigate("/ventas/nuevoDTE");
    }
    if (option === "Imprimir") {
      let byteCharacters = atob(document.pdfBase64);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    if (option === "Descargar PDF") {
      var base64PDF = document?.pdfBase64;
      var link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();
    }
    if (option === "Descargar JSON") {
      const jsonDte: JsonDte = document?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = window.document.createElement("a");

      link.href = url;
      link.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link.click();
    }
    if (option === "Descargar") {
      const jsonDte: JsonDte = document?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link2 = window.document.createElement("a");

      link2.href = url;
      link2.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link2.click();

      var base64PDF = document?.pdfBase64;
      var link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();
    }
    if (option === "Remover") {
      const pdfWindow = window.open(document.pdf);
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
    if (option === "Enviar") {
      sendEmail();
    }
    if (option === "Corregir DTE") {
      /* const products = document.detailDocuments.map((product: any) => {
        return {
          id: product.id,
          quantity: product.detailQuantity,
          unitPrice: product.priceDetail,
          totalPrice: product.priceDetail * product.detailQuantity,
          operation_type: product.ivaOperationType,
          barCode: product.article.barCode,
          name: product.article.name,
          description: product.article.description,
          code: product.article.code,
          type: product.article.type,
        } as DataTableDTEObject;
      });

      setGlobal({
        ...global,
        conditionPayment: null,
        idClient: document?.idClientCompany,
        iva: document?.ivaAmount,
        products: products,
        paymentMethods: [],
        paymentMethodsFull: [],
        retention: 0,
        perception: 0,
        totalGravados: 0,
        totalExentos: 0,
        totalNoSujetos: 0,
        totalToPay: 0,
        subTotal: 0,
        IvaDocumentType: document?.ivaDocument,
      });

      await setInitialValues({
        id: document.id,
        conditionPayment: { label: "Contado", value: PaymentConditions.CONTADO },
        cashier: document.cashier,
        costCenter: document?.costCenter,
        idClientCompany: document?.idClientCompany,
        ivaAmount: document?.ivaAmount,
        IvaDocumentType: document?.ivaDocument,
        subtotal: document?.subtotal,
        totalToPay: document?.totalToPay,
        status: document?.status,
        salePoint: document?.salePoint,
        retentionAmount: document?.retentionAmount,
        perceptionAmount: document?.perceptionAmount,
        operationType: document?.operationType,
        idClien: document?.client,
        clientCompany: document?.clientCompany,
        date: document?.date,
        documentReceiver: document?.documentReceiver,
        dteControlNumber: document?.dteControlNumber,
        environment: document?.environment,
        generationCodeDte: document?.generationCodeDte,
        products: products,
        operation_type: null,
        quantity: 1,
      }); */
      navigate(`/ventas/corregirDTE/${selectedItem.generationCodeDte}`, { state: selectedItem });
    }
    if (option === "Anular") {
      const getNumControl = (string: string) => {
        const parts = string.split("-");
        const lastPart = parts[parts.length - 1];
        const number = parseInt(lastPart, 10);

        return number;
      };
      setDteInformation({
        idDte: selectedItem?.id,
        nroDTE: getNumControl(selectedItem?.dteControlNumber),
        clientName: {
          label: selectedItem?.client ?? "Cliente Anonimo",
          id: selectedItem?.idClientCompany ?? 0,
        },
        date: selectedItem.date,
        ivaDocumentName: selectedItem.ivaDocument.extended_name_document,
        amount: selectedItem.totalToPay,
        idEmployee: selectedItem.cashier.idEmployeeCompany,
      });
      handleOpenDelete();
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "Imprimir":
        return <Icon color="info">print</Icon>;
      case "Descargar PDF":
        return <Icon color="info">document_scanner_icon</Icon>;
      case "Descargar JSON":
        return <Icon color="info">code_icon</Icon>;
      case "Descargar":
        return <CloudDownloadOutlinedIcon color="info" />;
      case "Ver Detalles":
        return <Icon color="info">plagiarism</Icon>;
      case "Enviar":
        return <Icon color="info">forward_to_inbox_icon</Icon>;
      case "Duplicar":
        return <Icon color="info">copy_all_icon</Icon>;
      case "Agregar Pago":
        return <Icon color="info">price_check_icon</Icon>;
      case "Anular":
        return <Icon color="error">close</Icon>;
      case "Corregir DTE":
        return <Icon color="error">construction</Icon>;
      default:
        return <Icon>circle</Icon>;
    }
  };

  return (
    <>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>
      <Popper
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {optionsRechazados.map((option, index) => (
                      <NotificationItem
                        key={option}
                        icon={getIcon(option)}
                        title={option}
                        customColor={option === "Corregir DTE" ? "error" : "info"}
                        disabled={index === stateIndex}
                        selected={index === stateIndex}
                        onClick={(event) => handleMenuItemClick(option, index)}
                      />
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </>
  );
};

export default DtesRechazadosDropdown;
