import gql from "graphql-tag";

export const GENERATE_AUTO_DTES = gql`
  mutation GenerateAutoDtes($idIvadocument: Float!, $idEmployee: Float!, $idArticle: Float!, $idPriceList: Float!, $articlePrice: Float!, $quantity: Float!) {
    generateAutoDtes(idIvadocument: $idIvadocument, idEmployee: $idEmployee, idArticle: $idArticle, idPriceList: $idPriceList, articlePrice: $articlePrice, quantity: $quantity) {
      isSuccess
      message {
        detail
        code
        message
      }
    }
  }
`;
