import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormikProps } from "formik";
import { Link } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";

interface Props {
  formik: FormikProps<any>;
}
function Credenciales({ formik }: Props) {
  const [showPassword, setShowPassword] = useState({
    pass: false,
    confirm: false,
  });
  const handleClickShowPassword = (value: boolean) =>
    setShowPassword((show) => {
      if (value) {
        return {
          ...show,
          pass: !show.pass,
        };
      } else {
        return {
          ...show,
          confirm: !show.confirm,
        };
      }
    });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    formik.setFieldTouched("password", false);
    formik.setFieldTouched("confirmPassword", false);
    formik.setFieldTouched("securityCode", false);
  }, []);
  return (
    <>
      <MDBox my={2.5} display="flex" justifyContent="center">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Cambio de Contraseña
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="subtitle2" color="text">
          ¿Ya tienes una cuenta?{" "}
          <MDTypography component={Link} to="/authentication/sign-in" variant="button" color="secondary" fontWeight="medium" textGradient>
            Ingresa aquí
          </MDTypography>
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12}>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem", mb: 1.5 }}>
            Recuerda que el codigo de seguridad fue enviado por correo
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDBox mb={2} position="relative">
            <TextField
              type={showPassword.pass ? "text" : "password"}
              name="password"
              label="Contraseña"
              value={formik.values.password}
              fullWidth
              error={Boolean(formik.errors.password) && Boolean(formik.touched.password)}
              onBlur={() => {
                formik.setFieldTouched("password", true);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              helperText={!formik.touched.password || (formik.touched.password && !formik.errors.password) ? " " : String(formik.errors.password)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            />
            <MDBox display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: -6, right: 0, width: "10%", height: "100%" }}>
              <IconButton onClick={() => handleClickShowPassword(true)} onMouseDown={handleMouseDownPassword}>
                {showPassword.pass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </MDBox>
          </MDBox>
          <MDBox mb={2} position="relative">
            <TextField
              type={showPassword.confirm ? "text" : "password"}
              name="confirmPassword"
              label="Confirmar Contraseña"
              value={formik.values.confirmPassword}
              fullWidth
              error={Boolean(formik.errors.confirmPassword) && Boolean(formik.touched.confirmPassword)}
              onBlur={() => {
                formik.setFieldTouched("confirmPassword", true);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              helperText={!formik.touched.confirmPassword || (formik.touched.confirmPassword && !formik.errors.confirmPassword) ? " " : String(formik.errors.confirmPassword)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            />
            <MDBox display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: -6, right: 0, width: "10%", height: "100%" }}>
              <IconButton onClick={() => handleClickShowPassword(false)} onMouseDown={handleMouseDownPassword}>
                {showPassword.confirm ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </MDBox>
          </MDBox>
          <TextField
            fullWidth
            label="Codigo de Seguridad"
            error={Boolean(formik.errors.securityCode) && Boolean(formik.touched.securityCode)}
            value={formik.values.securityCode ?? ""}
            onBlur={() => {
              formik.setFieldTouched("password", true);
            }}
            helperText={!formik.touched.securityCode || (formik.touched.securityCode && !formik.errors.securityCode) ? " " : String(formik.errors.securityCode)}
            onChange={formik.handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PinOutlinedIcon />
                </InputAdornment>
              ),
            }}
            name="securityCode"
          />
        </Grid>
      </Grid>
    </>
  );
}
export default Credenciales;
