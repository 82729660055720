import { useState, useEffect, memo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Libs
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFPreviewer = memo(function PDFPreviewer() {
  const { document } = useGeneralDocumentContext();

  const params = useParams();

  const pdf = `${process.env.REACT_APP_API_URL}/sales/dte/pdf/fex/${params.id}`;
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }
    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);
    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <Grid item xs={12} width="100%" maxWidth="100%" bgcolor="white">
      <Document file={document.urlPdf || pdf}>
        <Page pageNumber={1} renderTextLayer={false} />
      </Document>
    </Grid>
  );
});

export default PDFPreviewer;
