import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//@mui material components
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Card } from "@mui/material";

//Components
import Oficinas from "./components/Oficinas";
import EditarEmpresa from "./components/EditarEmpresa";
import Bodegas from "./components/Bodegas";
import PuntosDeVenta from "./components/PuntosDeVenta";
import { useLocation } from "react-router-dom";

function Empresa(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setActiveStep(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [activeStep]);

  const steps = [
    {
      label: "Empresa",
      icon: <ApartmentIcon />,
    },
    {
      label: "Oficinas",
      icon: <HomeWorkIcon />,
      component: <Oficinas />,
    },
    {
      label: "Bodegas",
      icon: <WarehouseIcon />,
      component: <Bodegas />,
    },
    {
      label: "Ptos de Vtas",
      icon: <PointOfSaleIcon />,
      component: <PuntosDeVenta />,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <MDBox color="black" bgColor="#F8F9FA" borderRadius="md" shadow="md" opacity={1} p={{ xs: 1, sm: 2 }} mb={2} display="inline-flex" gap={2}>
          {steps.map((item, index) => (
            <MDButton
              key={index}
              color={`${activeStep === index ? "info" : "light"}`}
              onClick={() => {
                setActiveStep(index);
              }}
              sx={{
                p: "0.85rem",
                "&:focus:not(:hover)": {
                  backgroundColor: `${activeStep === index ? "#00547A" : "#f0f2f5"}`,
                },
              }}
              startIcon={item.icon}
            >
              {item.label}
            </MDButton>
          ))}
        </MDBox>
        {activeStep > 0 ? <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card> : <EditarEmpresa />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Empresa;
