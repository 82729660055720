// @ts-nocheck
import React from "react";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

// Types
import { DropdownOptions } from "types/environment";

export default function EventsDropDown({ handleSubmit, option }: { handleSubmit?: any; option?: DropdownOptions }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "Pruebas Internas":
        return <Icon>circle</Icon>;
      case "Pruebas con el Ministerio de Hacienda":
        return <Icon color="warning">circle</Icon>;
      case "Emisiones Reales":
        return <Icon color="success">circle</Icon>;
      default:
        return <Icon>circle</Icon>;
    }
  };

  return (
    <React.Fragment>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>
      <Popper
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <NotificationItem icon={getIcon(option)} title={option} onClick={() => handleSubmit(option)} />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </React.Fragment>
  );
}
