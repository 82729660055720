import React, { useState } from "react";

// @mui material components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";

// Types
import { useAddSellContext } from "context/AddSellContext";

interface Props {
  handleClose: () => void;
  open: boolean;
}

const DialogEditSeguroFex = ({ handleClose, open }: Props) => {
  // Context
  const {
    global: { seguroFex },
    setGlobal,
  } = useAddSellContext();

  // State
  const [seguro, setSeguro] = useState<any>("");
  const [error, setError] = useState("");
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setSeguro("");
      }}
    >
      <DialogTitle>{"¿Desea editar el seguro de la venta?"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              inputProps={{ inputMode: "decimal" }}
              fullWidth
              label="Seguro de la venta"
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const regex = /^[\d.]*$/;
                if (regex.test(e.target.value)) {
                  setSeguro(e.target.value);
                }
              }}
              error={error !== ""}
              helperText={error}
              variant="standard"
              value={seguro}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
            setSeguro("");
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          onClick={() => {
            if (!isNaN(Number(seguro))) {
              setGlobal((prev) => ({ ...prev, seguroFex: seguro }));
              setError("");
              handleClose();
              setSeguro("");
            } else if (!isNaN(Number(seguroFex))) {
              handleClose();
              setSeguro("");
            } else {
              setError("El valor debe ser un numero");
            }
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          Guardar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEditSeguroFex;
