import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//Graphql
import { useMutation, useQuery } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";
import CaracteristicasDialog from "./CaracteristicasDialog";

//Alerts
import { toast } from "react-hot-toast";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Queries
import { GET_TAGS } from "apollo/queries/tags/getTags";
import { DELETE_TAG } from "apollo/mutations/tags/deleteTag";
import { ADD_TAG } from "apollo/mutations/tags/addTag";
import { UPDATE_TAG } from "apollo/mutations/tags/updateTags";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

interface Row {
  nombre: string;
  menu: JSX.Element;
}

const validationSchema = Yup.object({
  tag: Yup.string().required("El nombre de la caracteristica debe tener al menos 1 caracter"),
});

const columns = [
  { Header: "Nombre", accessor: "nombre", width: "90%" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center", width: "10%" },
];

const options = ["Editar", "Archivar"];

function Caracteristicas() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  //Querys and mutations
  const { data, loading, refetch } = useQuery(GET_TAGS, { context, variables: { tagsFilters: { pagination: PAGINATION } } });
  const [deleteTag, dataDeleteTag] = useMutation(DELETE_TAG, { context });
  const [addTag, dataAddTag] = useMutation(ADD_TAG, { context });
  const [updateTag, dataUpdateTag] = useMutation(UPDATE_TAG, { context });

  //Formik
  const formik = useFormik({
    initialValues: {
      tag: "",
    },
    validationSchema,
    onSubmit: (values) => {
      addTag({
        variables: {
          data: {
            name: values.tag,
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addTag;
          if (isSuccess) {
            refetch();
            formik.setFieldValue("tag", "");
            formik.setFieldTouched("tag", false);
            toast.success("Caracteristica creada correctamente");
            handleClose();
          } else {
            toast.error(`Hubo un error al crear la caracteristica ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al crear la caracteristica ${e}`);
        });
    },
  });

  // States
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [open, setOpen] = useState<{ state: boolean; type?: number }>({ state: false, type: null });

  const handleClickOpen = () => {
    setOpen({ state: true, type: 0 });
  };
  function handleClose() {
    setOpen({ state: false, type: null });
    formik.setFieldValue("tag", "");
    formik.setFieldTouched("tag", false);
    setSelectedMenuOption({
      option: "",
      id: "",
    });
  }
  const handleSubmitUpdateTag = () => {
    updateTag({
      variables: {
        idTag: Number(selectedMenuOption.id),
        data: {
          name: formik.values.tag,
        },
      },
    }).then((data: any) => {
      if (data.data.updateTag.isSuccess) {
        refetch();
        toast.success("Caracteristica actualizada correctamente");
        handleClose();
      }
    });
  };

  //Effects
  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      setOpen({ state: true, type: 1 });
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteTag({
        variables: {
          idTag: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteTag.isSuccess) {
          refetch();
          toast.success("Se archivo la caracteristica correctamente");
        }
      });
    }
  }, [selectedMenuOption]);
  useEffect(() => {
    if (data) {
      setRowsData(data.getTags.data);
    }
  }, [data]);

  const rows: Row[] = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item?.name,
      menu: <MenuDropdown onSelectOption={setSelectedMenuOption} id={item?.id} options={options} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows,
  };

  const loadingsDialog = {
    loadingAdd: dataAddTag.loading,
    loadingUpdate: dataUpdateTag.loading,
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Caracteristicas{" "}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui podras Buscar, editar y archivar todos las caracteristicas creados"}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteTag.loading ? { columns: columns, rows: [] } : table}
        loading={loading || dataDeleteTag.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => handleClickOpen()} color="info">
            Crear Nueva Caracteristica
          </MDButton>
        </Grid>
      </DataTable>
      <CaracteristicasDialog open={open} handleSubmitUpdateTag={handleSubmitUpdateTag} handleClose={handleClose} formik={formik} loading={loadingsDialog} />
    </>
  );
}
export default Caracteristicas;
