import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";

//react-router-dom
import { useNavigate } from "react-router-dom";

// Custom Components
import MenuDropdown from "../components/MenuDropdown";

//Alert
import { toast } from "react-hot-toast";

// GraphQl
import { gql, useMutation, useQuery } from "@apollo/client";

//Types
import { Rows } from "..";
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

// Queries and Mutations
const GET_CLIENTS = gql`
  query {
    getClients(clientFilters: {}) {
      isSuccess
      data {
        name
        id
        idClientCompany
        emailNotifications
        phone
        documents {
          id
          documentNumber
          type
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const DELETE_CLIENT_COMPANY = gql`
  mutation DELETE_CLIENT_COMPANY($idClientCompany: Float!) {
    deleteClientFromCompany(idClientCompany: $idClientCompany) {
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;

const columns = [
  { Header: "Nombre", accessor: "nombre" },
  { Header: "Email", accessor: "emailNotifications" },
  { Header: "Telefono", accessor: "telefono" },
  { Header: "NIT", accessor: "NIT" },
  { Header: "NRC/DUI", accessor: "nrc_dui" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];
const options = ["Ver Detalles", "Editar", "Enviar Link del Portal", "Archivar"];

function Detalles() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Contacts };

  // Queries and mutations
  const { data, loading, error, refetch } = useQuery(GET_CLIENTS, { context, variables: { pagination: PAGINATION } });
  const [deleteClientCompany, dataDeleteClientCompany] = useMutation(DELETE_CLIENT_COMPANY, { context });

  // States
  const [rowsData, setRowsData] = useState<Rows[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });

  // React-router
  const navigate = useNavigate();

  //Effects
  useEffect(() => {
    if (data) {
      setRowsData(data?.getClients.data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      navigate(`/contactos/clientes/editar/${selectedMenuOption.id}`);
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteClientCompany({
        variables: {
          idClientCompany: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteClientFromCompany.isSuccess) {
          refetch({
            clientFilters: {},
          });
          toast.success("Se archivo el cliente correctamente");
        }
      });
    }
  }, [selectedMenuOption]);

  //Data
  const rows = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item.name,
      emailNotifications: item?.emailNotifications ?? "No hay email",
      telefono: item?.phone ?? "No hay Telefono",
      NIT: item.documents.find((elem) => elem.type === "NIT")?.documentNumber ?? "No hay NIT",
      nrc_dui: item.documents.find((elem) => elem.type === "DUI" || elem.type === "NRC")?.documentNumber ?? "No hay NRC/DUI",
      menu: <MenuDropdown options={options} id={String(item.idClientCompany)} onSelectOption={setSelectedMenuOption} />,
    }));
  }, [rowsData]);
  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Clientes
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Aqui podras, ver, editar o eliminar todos los clientes que tienes registrados.
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteClientCompany.loading ? { columns: columns, rows: [] } : table}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
        loading={loading || dataDeleteClientCompany.loading}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => navigate("/contactos/clientes/crear")} color="info">
            Crear Nuevo Cliente
          </MDButton>
        </Grid>
      </DataTable>
    </>
  );
}
export default Detalles;
