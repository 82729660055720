/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, ReactNode, SyntheticEvent } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarDesktopMenu, navbarMobileMenu } from "views/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator } from "context";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Custom components
import Credenciales from "./Credenciales";
import DTES from "./dtes";

// Types
import { ChooseEnvironment } from "../..";

// Declaring props types for BaseLayout
interface Props {
  stickyNavbar?: boolean;
  enviroment?: ChooseEnvironment;
  setActiveEnv: React.Dispatch<React.SetStateAction<ChooseEnvironment>>;
  children?: ReactNode;
}

function Empresa({ stickyNavbar, enviroment, setActiveEnv }: Props): JSX.Element {
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState<number>(0);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event: SyntheticEvent, newValue: number) => setTabValue(newValue);
  const handleSwtich = () => {
    setActiveEnv(enviroment === ChooseEnvironment.EMISIONES ? ChooseEnvironment.PRUEBAS : ChooseEnvironment.EMISIONES);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={stickyNavbar ? 3 : 4} minHeight="100vh">
        <Grid container>
          <AppBar position="static" color="inherit" sx={(theme) => navbar(theme, { transparentNavbar, darkMode })}>
            <Toolbar sx={navbarContainer}>
              <MDBox color="inherit" width="100%" mb={{ xs: 1, md: 0 }} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="DTES"
                    color="primary"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        document_scanner
                      </Icon>
                    }
                  />
                  <Tab
                    label="Credenciales"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        vpn_key_outlined
                      </Icon>
                    }
                  />
                </Tabs>
                {tabValue === 1 && (
                  <MDBox>
                    <FormGroup>
                      <FormControlLabel control={<Switch onChange={handleSwtich} />} label={enviroment === ChooseEnvironment.EMISIONES ? "Producción" : "Pruebas"} />
                    </FormGroup>
                  </MDBox>
                )}
              </MDBox>
            </Toolbar>
          </AppBar>
        </Grid>
        {tabValue === 0 && <DTES />}
        {tabValue === 1 && <Credenciales enviroment={enviroment} setActiveEnv={setActiveEnv} />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Declaring default props for BaseLayout
Empresa.defaultProps = {
  stickyNavbar: false,
};

export default Empresa;
