import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import React from "react";
import MDButton from "components/MDButton";
import { FormikProps } from "formik";

interface EmailFormikValues {
  emails: any[];
  email: string;
}

interface Props {
  formik: FormikProps<any>;
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  handleSubmit?: () => void;
}
function DialogEmail({ formik, open, handleClose, loading, handleSubmit }: Props) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{"¿A que correos deseas enviar?"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.email) && Boolean(formik.touched.email)}
              onBlur={() => formik.setFieldTouched("email", true)}
              value={formik.values.email}
              label="Correo electronico"
              name="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                formik.setFieldValue("email", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <GroupAddOutlinedIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="medium"
                    onClick={() => {
                      if (formik.values.email !== "") {
                        formik.setFieldValue("emails", [...formik.values.emails, formik.values.email]);
                        formik.setFieldValue("email", "");
                      }
                    }}
                  />
                ),
              }}
              // helperText={!formik.touched.email || (formik.touched.email && !formik.errors.email) ? " " : formik.errors.email}
              helperText={formik.touched.email && typeof formik.errors.email === "string" ? formik.errors.email : " "}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              forcePopupIcon={false}
              options={[]}
              multiple
              open={false}
              value={formik.values.emails}
              onChange={(e, value, reason) => {
                formik.setFieldValue("emails", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formik.errors.emails) && Boolean(formik.touched.emails)}
                  helperText={!Boolean(formik.touched.emails) || (formik.touched.emails && !formik.errors.emails) ? " " : "Los emails son requeridos"}
                  label="Correos electronicos a enviar"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          disabled={loading}
          onClick={() => {
            handleSubmit ? handleSubmit() : formik.handleSubmit();
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogEmail;
