// Context
import { useAddSellContext } from "context/AddSellContext";

// Libs
import toast from "react-hot-toast";

// Types
import { DataTableDTEObject } from "types/dataTableDteObject";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { FormikState } from "formik";

export const useHandleSubmitFormikVentas = () => {
  // Context
  const { initialValues, setGlobal } = useAddSellContext();

  const handleSubmitFormikVentas = (values: AddSaleGeneralDocumentFilterValues, resetForm: (nextState?: Partial<FormikState<AddSaleGeneralDocumentFilterValues>>) => void) => {
    setGlobal((global) => {
      const newDocument: DataTableDTEObject = {
        ...values.productCodes,
        id: values.productCodes?.id ?? Math.random(),
        productDiscount: values.productDiscount,
        name: values?.productCodes?.name,
        type: values?.type ?? values?.productCodes?.type,
        productCodes: values?.productCodes,
        quantity: values?.detailDocument?.quantity,
        description: values?.detailDocument?.description ?? "",
        unitPrice: values.detailDocument.salePrice,
        totalPrice: (values?.detailDocument?.salePrice - Number(values.productDiscount ?? "")) * values?.detailDocument?.quantity,
        operation_type: values?.operation_type.value,
        idPriceList: values.productCodes.salesPrices && Number(values?.productCodes?.salesPrices.find((item) => item?.id === values?.salePrice?.id)?.priceList?.id),
        idSalePrice: values.productCodes.salesPrices ? Number(values?.productCodes?.salesPrices.find((item) => item?.id === values?.salePrice?.id)?.id) : null,
      };
      return {
        ...global,
        operation_type: values?.operation_type,
        date: values?.date,
        IvaDocumentType: values?.IvaDocumentType,
        notifyTo: values?.notifyTo,
        conditionPayment: typeof values?.conditionPayment === "object" ? values?.conditionPayment?.value.toUpperCase() : values?.conditionPayment.toUpperCase(),
        clientCompany: values.clientCompany,
        extension: values?.extension,
        products: [...global.products, newDocument],
      };
    });
    toast.success("Venta agregada correctamente a la lista");
    // ? Reset Form
    resetForm({
      values: {
        ...initialValues,
        date: values.date,
        IvaDocumentType: values.IvaDocumentType,
        clientCompany: values.clientCompany,
        conditionPayment: values.conditionPayment,
        type: values.type,
        notifyTo: values.notifyTo,
        incoterms: values.incoterms,
        recintoFiscal: values.recintoFiscal,
        regimenExportacion: values.regimenExportacion,
        ventasCuentasTerceros: {
          name: values.ventasCuentasTerceros?.name,
          nit: values.ventasCuentasTerceros?.nit,
        },
        otherAssociatedDocuments: values.otherAssociatedDocuments,
      },
    });
  };

  return { handleSubmitFormikVentas };
};
