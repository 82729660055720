import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";

// Types
import { FormikProps } from "formik";

interface Props {
  open: { state: boolean; type?: number };
  handleClose: () => void;
  formik: FormikProps<{ group: string }>;
  loading: {
    loadingAdd: boolean;
    loadingUpdate: boolean;
  };
  handleSubmitUpdateGroup: () => void;
}
function GroupsDialog({ open, handleClose, formik, loading, handleSubmitUpdateGroup }: Props) {
  const titles = ["¿Desea agregar un nuevo grupo?", "¿Desea editar este grupo?"];
  return (
    <Dialog
      open={open.state}
      keepMounted
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{titles[open.type]}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.group) && formik.touched.group}
              onBlur={() => formik.setFieldTouched("group", true)}
              label="Nombre del Grupo"
              name="group"
              helperText={!formik.touched.group || (formik.touched.group && !formik.errors.group) ? " " : formik.errors.group}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.group}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          disabled={loading.loadingAdd || loading.loadingUpdate || formik.values.group === ""}
          onClick={() => {
            if (open.type === 0) {
              formik.handleSubmit();
            } else if (open.type === 1) {
              handleSubmitUpdateGroup();
            }
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading.loadingAdd || loading.loadingUpdate ? "Enviando" : "Enviar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default GroupsDialog;
