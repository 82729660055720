import gql from "graphql-tag";

export const GET_EMPLOYEE = gql`
  query GET_EMPLOYEE($idEmployee: Float!) {
    getEmployee(idEmployee: $idEmployee) {
      isSuccess
      data {
        id
        idEmployeeCompany
        idUserEntity
        firstName
        secondName
        firstLastName
        secondLastName
        afpType
        emailUser
        emailNotifications
        phone
        hasUser
        address
        documents {
          id
          documentNumber
          code
          type
        }
        region {
          id
          name
          code {
            id
            code
          }
        }
        genre
        checkInDate
        checkOutDate
        isSupport
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
