import gql from "graphql-tag";

export const UPDATE_ARTICLE = gql`
  mutation UPDATE_ARTICLE($articleId: Float!, $articleData: ArticleDti!) {
    updateArticle(articleId: $articleId, articleData: $articleData) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
