// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Types
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const PreviewSection = () => {
  const { jsonDte, document } = useGeneralDocumentContext();

  return (
    <Grid container justifyContent="center" gap={5} width={{ xs: "100%" }} height="100%">
      <Card sx={{ padding: 3, width: "100%" }}>
        <Grid container flexDirection="column" gap={3} fontSize="12px">
          <MDBox>
            <MDBox border={"solid 2px #F0F2F5"} borderRadius="xl" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" gap={2} p={2}>
              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    Código de generación
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.identificacion?.codigoGeneracion}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Fecha de Generación
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {jsonDte?.identificacion?.fecEmi + " " + jsonDte?.identificacion?.horEmi}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    Número de Control
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.identificacion?.numeroControl}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Módelo de Facturación
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {capitalizeFirstLetter(document?.modelType)}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    Sello de Recepción
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.selloRecibido}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Tipo de Transmisión
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {capitalizeFirstLetter(document?.transmissionType)}
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
          </MDBox>

          <MDBox>
            <MDBox border={"solid 2px #F0F2F5"} borderRadius="xl" component="li" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" gap={2} p={2}>
              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    Nombre del Cliente:
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.receptor?.nombre}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Actividad Económica
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {jsonDte?.receptor?.descActividad}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    NIT:
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.receptor?.nit}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Dirección
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {jsonDte?.receptor?.direccion?.complemento}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid container display="flex" justifyContent="space-between">
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold">
                    NRC
                  </MDTypography>
                  <MDTypography variant="inherit" color="info">
                    {jsonDte?.receptor?.nrc}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="right">
                    Correo Electrónico
                  </MDTypography>
                  <MDTypography variant="inherit" color="info" textAlign="right">
                    {jsonDte?.receptor?.correo}
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
          </MDBox>

          {jsonDte?.cuerpoDocumento?.map((doc, index) => {
            return (
              <MDBox key={index}>
                <MDBox border={"solid 2px #F0F2F5"} borderRadius="xl" component="li" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" gap={2} p={2}>
                  <Grid container display="flex" justifyContent="space-between" gap={2} wrap="nowrap">
                    <MDBox>
                      <MDTypography variant="inherit" color="info" fontWeight="bold">
                        Cantidad
                      </MDTypography>
                      <MDTypography variant="inherit" color="info">
                        {doc?.cantidad}
                      </MDTypography>
                    </MDBox>
                    <MDBox maxWidth="20%">
                      <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="center">
                        Descripción
                      </MDTypography>
                      <MDTypography variant="inherit" color="info" textAlign="center">
                        {doc?.descripcion}
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="center">
                        Precio Unitario
                      </MDTypography>
                      <MDTypography variant="inherit" color="info" textAlign="center">
                        {formatNumberToMoney(doc?.precioUni)}
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="center">
                        Venta No Sujeta
                      </MDTypography>
                      <MDTypography variant="inherit" color="info" textAlign="center">
                        {formatNumberToMoney(doc?.ventaNoSuj)}
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="center">
                        Venta Exenta
                      </MDTypography>
                      <MDTypography variant="inherit" color="info" textAlign="center">
                        {formatNumberToMoney(doc?.ventaExenta)}
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="inherit" color="info" fontWeight="bold" textAlign="center">
                        Venta Gravada
                      </MDTypography>
                      <MDTypography variant="inherit" color="info" textAlign="center">
                        {formatNumberToMoney(doc?.ventaGravada)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            );
          })}

          <MDBox display="flex" flexDirection="column" gap={2} width="50%" justifyContent="flex-end" alignSelf="flex-end">
            <table>
              <tbody>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Total Descuentos:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalDescu)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Ventas Gravadas:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalGravada)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Ventas Exentas
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalExenta)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Ventas No Sujetas
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalNoSuj)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Sub Total:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.subTotal)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Impuesto al Valor Agregado (IVA 13%)
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalIva)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Impuesto Sobre la Renta (ISR 10%)
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.reteRenta)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      IVA Percibido:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.ivaPerci1)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      IVA Retenido:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.ivaRete1)}
                    </MDTypography>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      Total a Pagar:
                    </MDTypography>
                  </td>
                  <td>
                    <MDTypography variant="inherit" color="info" textAlign="right">
                      {formatNumberToMoney(jsonDte?.resumen?.totalPagar)}
                    </MDTypography>
                  </td>
                </tr>
              </tbody>
            </table>
          </MDBox>
        </Grid>
      </Card>
    </Grid>
  );
};

export default PreviewSection;
