import gql from "graphql-tag";

export const GET_SALE_POINTS_BY_PRICE_LIST = gql`
  query GET_SALE_POINTS_BY_PRICE_LIST($filters: SalePointByPriceListDtiQueryFilter!) {
    getSalePointsByPriceList(filters: $filters) {
      data {
        id
        name
        mhCode
        storages {
          id
          name
        }
        salePoints {
          id
          name
          serie
          mark
          model
          code
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
