// Graphql
import { useMutation } from "@apollo/client";

// Mutations
import { ADD_DOCUMENTS } from "apollo/queries/documents/addDocuments";

// Context
import { useCompanyContext } from "context/CompanyContext";

import { DocumentState } from "layouts/ventas/nuevoDTE/components/dailogs/DialogRegisterDocument";
import toast from "react-hot-toast";
import { SerializationKeys } from "types/apollo";
import { DocumentType } from "types/documentType";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";

interface Props {
  handleClose: (value: ActiveValues) => void;
}

export const useAddDocumentSubmit = ({ handleClose }: Props) => {
  // Context
  const { infoEmployeeDocuments, setInfoEmployeeDocuments } = useCompanyContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Mutations
  const [addDocuments, dataAddDocuments] = useMutation(ADD_DOCUMENTS, {
    context,
  });

  const handleSubmitRegisterDocument = async (documentState: DocumentState) => {
    const validateType = (type: string) => {
      switch (type) {
        case DocumentType.DUI:
          return documentState?.dui;
        case DocumentType.NIT:
          return documentState?.nit;
        case DocumentType.PASSPORT.toUpperCase():
          return documentState?.pasaporte;
      }
    };
    await addDocuments({
      variables: {
        data: {
          idUser: infoEmployeeDocuments?.idMainEntity,
          userType: "EMPLOYEE",
          documents: [
            {
              documentNumber: validateType(documentState?.type),
              type: documentState?.type,
            },
          ],
        },
      },
    })
      .then((resAddDocuments) => {
        if (resAddDocuments.data.addDocuments.isSuccess) {
          toast.success("El usuario se actualizo correctamente");
          handleClose(ActiveValues.registerDocument);
        } else {
          toast.error(`Hubo un error al agregar el documento. ${resAddDocuments.data.addDocuments?.message?.detail}`);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(`Hubo un error al agregar el documento. Intente nuevamente${e}`);
      });
  };

  return { handleSubmitRegisterDocument, dataAddDocuments };
};
