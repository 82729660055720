//@mui material components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";

// Types
import { FormikProps } from "formik";

interface Props {
  loading: {
    loadingAdd: boolean;
    loadingUpdate: boolean;
  };
  open: { state: boolean; type?: number };
  handleClose: () => void;
  formik: FormikProps<{ tag: string }>;
  handleSubmitUpdateTag: () => void;
}
function CaracteristicasDialog({ open, handleClose, formik, loading, handleSubmitUpdateTag }: Props) {
  const titles = ["¿Desea agregar una nueva caracteristica?", "¿Desea editar esta caracteristica?"];
  return (
    <Dialog
      open={open.state}
      keepMounted
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{titles[open.type]}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.tag) && formik.touched.tag}
              onBlur={() => formik.setFieldTouched("tag", true)}
              label="Nombre de la Caracteristica"
              name="tag"
              helperText={!formik.touched.tag || (formik.touched.tag && !formik.errors.tag) ? " " : formik.errors.tag}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.tag}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          disabled={loading.loadingAdd || loading.loadingUpdate || formik.values.tag === ""}
          onClick={() => {
            if (open.type === 0) {
              formik.handleSubmit();
            } else if (open.type === 1) {
              handleSubmitUpdateTag();
            }
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading.loadingAdd || loading.loadingUpdate ? "Enviando" : "Enviar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CaracteristicasDialog;
