import gql from "graphql-tag";

export const GET_DTE_RECHAZADOS_ALL = gql`
  query GET_DTE_RECHAZADOS_ALL($filters: DteRachazadosFiltersDti!) {
    getDteRechazados(filters: $filters) {
      isSuccess
      data {
        multa
        dtes {
          generationCodeDte
          dteControlNumber
          paymentCondition
          client
          documentReceiver
          jsonDte
          mhObservations
          history {
            email_destinatarios
            statusReception
            fecha
            eventType
          }
          paymentMethods {
            paymentMethod {
              id
              name
              code
              hasReferenceNumber
            }
            type
            finalAmount
          }
          clientCompany {
            id
            name
            contribuyenteType
            phone
            address
            plazoType
            tradename
            plazoType
            legalRepresentation
            address
            lineOfBusiness {
              name
            }
            emailNotifications
            retention
            perception
            isActiveCredit
            creditLimit
            excelse
            creditPeriod
            userDocuments {
              id
              documentNumber
              type
              code
            }
          }
          detailDocuments {
            id
            article {
              barCode
              code
              description
              id
              name
              operation_type
              type
            }
            detailQuantity
            priceDetail
            description
            ivaOperationType
            createdAt
            updatedAt
            unitPrice
            priceList {
              id
              name
            }
          }
          date
          createdAt
          environment
          idClientCompany
          id
          pdfBase64
          selloRecibido
          status
          pdf
          receptionDate
          costCenter {
            id
            name
          }
          salePoint {
            id
            code
            mhCode
            storage {
              id
              name
              allowSale
              isActive
              createdAt
              updatedAt
            }
            model
            serie
            name
            resolution
            mark
            date
            createdAt
            updatedAt
          }
          receiverName
          cashier {
            idEmployeeCompany
            firstName
            secondName
            firstLastName
            secondLastName
            marriedLastName
            image
            createdAt
            updatedAt
          }
          observations
          perceptionAmount
          retentionAmount
          ivaAmount
          totalToPay
          subtotal
          correlative {
            id
            doc_number
            serial_number
            doc_reference
            createdAt
            updatedAt
          }
          ivaDocument {
            name_document
            id
            isActive
            description
            extended_name_document
          }
          operationType
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
