import gql from "graphql-tag";

export const ADD_PRICE_LISTS = gql`
  mutation ADD_PRICE_LISTS($data: PriceListDti!) {
    addPriceList(data: $data) {
      isSuccess
      data {
        id
        name
        description
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
