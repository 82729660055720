// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// @mui material components
import { Autocomplete, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, SvgIcon, TextField } from "@mui/material";

// Libs
import { format } from "fecha";

// Types
import { EmployeesComponentsProps } from "types/employeesComponentsProps";
import { AfpTypes } from "types/afpTypes";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

export const afpTypes = [AfpTypes.CONFIA, AfpTypes.CRECER, AfpTypes.INPEP, AfpTypes.UPISSS];

function PatronalesEmployees({ formik }: EmployeesComponentsProps) {
  /* 
    !! Falta el handle changes del ISS y el NUP
    */
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Datos Patronales
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} mb={4}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} lg={4}>
                <MDDatePicker
                  value={formik.values.checkInDate}
                  onChange={(date: string) => {
                    formik.setFieldValue("checkInDate", format(new Date(date), "YYYY-MM-DD"));
                  }}
                  options={{ maxDate: format(new Date(), "YYYY-MM-DD") }}
                  input={{
                    name: "checkInDate",
                    id: "checkInDate",
                    variant: "standard",
                    label: "Fecha de ingreso",
                    sx: {
                      input: { paddingY: "1.04rem" },
                      width: "100%",
                    },
                  }}
                >
                  <span onBlur={() => formik.setFieldTouched("checkInDate", true)} />
                </MDDatePicker>
                <CustomFieldError touched={formik.touched.checkInDate ? true : false} errorName={formik.errors.checkInDate && (formik.errors.checkInDate as string)} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <MDDatePicker
                  value={formik.values.checkOutDate}
                  onChange={(date: string) => {
                    formik.setFieldValue("checkOutDate", format(new Date(date), "YYYY-MM-DD"));
                  }}
                  input={{
                    name: "checkOutDate",
                    id: "checkOutDate",
                    variant: "standard",
                    label: "Fecha de salida",
                    sx: {
                      input: { paddingY: "1.04rem" },
                      width: "100%",
                    },
                  }}
                />
                <CustomFieldError touched={formik.touched.checkOutDate ? true : false} errorName={formik.errors.checkOutDate && (formik.errors.checkOutDate as string)} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={3}>
                <Autocomplete
                  freeSolo
                  openOnFocus
                  value={formik.values.afpType}
                  onChange={(e, value) => formik.setFieldValue("afpType", value)}
                  onBlur={() => formik.setFieldTouched("afpType", true)}
                  options={afpTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de AFP"
                      error={Boolean(formik.errors.afpType) && Boolean(formik.touched.afpType)}
                      helperText={!formik.touched.afpType || (formik.touched.afpType && !formik.errors.afpType) ? " " : `${formik.errors.afpType}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" fullWidth variant="standard" error={Boolean(formik.errors.nup) && formik.touched.nup}>
                  <InputLabel>Numero unico patronal</InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="start">
                        <SvgIcon viewBox="0 0 16 12">
                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                        </SvgIcon>
                      </InputAdornment>
                    }
                    name="nup"
                    onBlur={() => formik.setFieldTouched("nup", true)}
                    onChange={formik.handleChange}
                    value={formik.values.nup}
                  />
                  <FormHelperText>{!formik.touched.nup || (formik.touched.nup && !formik.errors.nup) ? " " : formik.errors.nup}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl size="medium" fullWidth variant="standard" error={Boolean(formik.errors.isss) && formik.touched.isss}>
                  <InputLabel>Numero ISSS</InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="start">
                        <SvgIcon viewBox="0 0 16 12">
                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                        </SvgIcon>
                      </InputAdornment>
                    }
                    name="isss"
                    onBlur={() => formik.setFieldTouched("isss", true)}
                    onChange={formik.handleChange}
                    value={formik.values.isss}
                  />
                  <FormHelperText>{!formik.touched.isss || (formik.touched.isss && !formik.errors.isss) ? " " : formik.errors.isss}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default PatronalesEmployees;
