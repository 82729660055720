import React, { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// @mui material components
import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";

// Types

const DialogDeleteEmployee = ({ deletedItem, deleteEmployeeHandle, handleClose, open }: { deletedItem: any; deleteEmployeeHandle: () => void; handleClose: () => void; open: boolean }) => {
  const [confirmationString, setConfirmationString] = useState("");

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Card
          sx={{
            padding: 3,
            maxWidth: "33%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <MDTypography variant="h4" color="error" mb={1}>
            Empleado a Archivar
          </MDTypography>
          <MDTypography color="info" fontWeight="bold" mb={1}>
            {deletedItem?.firstName}
          </MDTypography>
          <MDTypography color="info" mb={1}>
            El empleado a <span style={{ color: "#f44335", fontWeight: "bold" }}>ARCHIVAR</span> ya no te aparecera en ninguna pantalla confirma si en realidad lo quieres archivar digitando el primer nombre del empleado{" "}
            <span style={{ color: "#f44335", fontWeight: "bold" }}>{deletedItem?.firstName}</span> para confirmar esta operacion
          </MDTypography>

          <MDBox container my={2}>
            <MDInput label="Nombre del empleado" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationString(e.target.value)} sx={{ width: "100%", input: { padding: "1rem" } }} />
          </MDBox>

          <MDBox display="flex" justifyContent="space-between">
            <MDButton
              variant="outlined"
              color="info"
              onClick={() => {
                handleClose();
              }}
            >
              Cancelar
            </MDButton>
            <MDButton
              color="error"
              disabled={confirmationString !== deletedItem?.firstName ? true : false}
              onClick={() => {
                deleteEmployeeHandle();
                setConfirmationString(null);
              }}
            >
              Eliminar
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
};

export default DialogDeleteEmployee;
