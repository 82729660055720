import gql from "graphql-tag";

export const FIX_FACTURA_EXPORTACION = gql`
  mutation FIX_FACTURA_EXPORTACION($input: EditFacturaExportacionInputDti!) {
    fixFacturaExportacion(input: $input) {
      selloRecibido
      respuestaHacienda {
        completeResponse {
          estado
          descripcionMsg
        }
      }
    }
  }
`;
