import gql from "graphql-tag";

export const GET_MEASUREMENTS = gql`
  query GET_MEASUREMENTS($measurementsFilters: QueryFilterDti!) {
    getMeasurements(measurementsFilters: { pagination: 100 }) {
      isSuccess
      data {
        id
        name
        symbol
        code
        value
        company {
          id
          name
        }
      }
      message {
        code
        message
      }
    }
  }
`;
