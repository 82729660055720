import { useEffect, useState } from "react";

// Views
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Dialog } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ProductsList page components
import CustomIdCell from "layouts/ventas/facturacion/components/IdCell";

// Utils
import { parseDate } from "utils/parseDate";
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Custom Components
import SelectedItemsMenu from "layouts/ventas/facturacion/components/SelectedItemsMenu";
import DtesRechazadosDropdown from "./DtesRechazadosDropdown";
import Step1 from "layouts/ventas/components/StepContent/Step1";
import Step2 from "layouts/ventas/components/StepContent/Step2";
import Step3 from "layouts/ventas/components/StepContent/Step3";

// Queries
import { INVALIDATE_GENERAL_DOCUMENT } from "apollo/mutations/dtes/invalidateGeneralDocument";
import { GET_COMPANIES_BY_USER } from "apollo/queries/companies/getCompaniesByUser";
import { INVALIDATE_DTE_DOCUMENT } from "apollo/mutations/dtes/invalidateDTEDocument";

// Graphql
import { useMutation, useLazyQuery } from "@apollo/client";

// Data
import { sellsDataTableDataRechazados } from "../data/dataTableRechazadosData";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Constants
import { PAGINATION } from "constants/pagination";

// Context
import { useCompanyContext } from "context/CompanyContext";

// Libs
import toast from "react-hot-toast";

// Types
import { GeneralDocument } from "types/documents";
import { DTE_INFO } from "layouts/ventas/facturacion/components/SellsTable";
import { InvalidTypes, RazonDeInvalidacion } from "types/InvalidDtesType";
import { SerializationKeys } from "types/apollo";
import { IvaDocumentTypes } from "types/iva-document";

export interface RequestorInfo {
  name: string;
  docNumber: string;
  docType: string;
}

interface Props {
  data: GeneralDocument[];
  getData: any;
  refetch?: any;
  loadingData?: boolean;
  type?: string;
}

export interface FormikValues {
  nroDTE: string;
  razonDeInvalidacion: string;
  client: {
    label: string;
    id: number;
  };
  nroDTEReemplazo: {
    label: string;
    generationCodeDte: string;
  };
  description: string;
  email?: string;
  emails?: string[];
}

const DTEsRechazadosTable = ({ data, getData, loadingData, type }: Props) => {
  // States
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dteInformation, setDteInformation] = useState<DTE_INFO>();
  const [requestorInfo, setRequestorInfo] = useState<RequestorInfo>();

  //Context
  const { company } = useCompanyContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  //Graphql
  const [invalidateGeneralDocument, dataInvalidateGeneralDocument] = useMutation(INVALIDATE_GENERAL_DOCUMENT, {
    context,
  });
  const [invalidateDTEDocument, dataInvalidateDTEDocument] = useMutation(INVALIDATE_DTE_DOCUMENT, { context });
  const [getCompanies, result] = useLazyQuery(GET_COMPANIES_BY_USER, { context });

  const validationSchema = Yup.object({
    razonDeInvalidacion: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
    description: Yup.string().required("La descripcion es requerida").min(5, "La descripcion debe tener al menos 5 caracteres"),
    nroDTE: Yup.string().oneOf([String(dteInformation?.nroDTE), null], "El numero de DTE debe coincidir"),
    client: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
      .required("El cliente es requerido")
      .nullable(),
    nroDTEReemplazo: Yup.object({
      generationCodeDte: Yup.string(),
      label: Yup.string(),
    })
      .required("El cliente es requerido")
      .nullable(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      nroDTE: "",
      razonDeInvalidacion: "",
      client: {
        label: "",
        id: 0,
      },
      nroDTEReemplazo: {
        label: "",
        generationCodeDte: "",
      },
      description: "",
    },
    onSubmit: async (values) => {
      if (dteInformation.ivaDocumentName === IvaDocumentTypes.EXTENDED_FEX) {
        invalidateDTEDocument({
          variables: {
            input: {
              codigoGeneracionAInvalidar: dteInformation.generationCode,
              codigoGeneracionAReemplazar: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
              motivoInvalidacion: values.description,
              tipoInvalidacion: getInvalidationType(values.razonDeInvalidacion),
            },
          },
        })
          .then(({ data }) => {
            if (data.invalidateDTEDocument?.isSuccess) {
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm();
              setActiveStep(0);
              setOpenDelete(false);
              getData({
                variables: {
                  filters: { priority: "PENDIENTES" },
                },
              });
              toast.success("DTE invalidado correctamente");
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.invalidateDTEDocument.message.detail}`);
              setOpenDelete(false);
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm();
              setActiveStep(0);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);
            setSelectedItems([]);
            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      } else {
        invalidateGeneralDocument({
          variables: {
            idGeneralDocument: dteInformation.nroDTE,
            idEmployee: dteInformation.idEmployeeCompany,
            data: {
              invalidationType: getInvalidationType(values.razonDeInvalidacion),
              invalidationMotive: values.description,
              dteGenerationCodeReplace: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
              requestorName: requestorInfo?.name,
              requestorDocNumber: requestorInfo?.docNumber,
              requestorDocType: requestorInfo?.docType,
            },
          },
        })
          .then((data) => {
            if (data.data.invalidateGeneralDocument.isSuccess) {
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm();
              setActiveStep(0);
              setOpenDelete(false);
              getData({
                variables: {
                  filters: { priority: "PENDIENTES" },
                },
              });
              toast.success("DTE invalidado correctamente");
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.data.invalidateGeneralDocument.message.detail}`);
              setDteInformation(null);
              formik.resetForm();
              setSelectedItems([]);
              setActiveStep(0);
              setOpenDelete(false);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);
            setSelectedItems([]);
            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      }
    },
    validationSchema,
  });

  // Functions
  const getNumControl = (string: string) => {
    const parts = string.split("-");
    const lastPart = parts[parts.length - 1];
    const number = parseInt(lastPart, 10);

    return number;
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete: any = async () => {
    await formik.resetForm();
    setActiveStep(0);
    setDteInformation(null);
    setOpenDelete(false);
  };
  const stepContent = (value: number) => {
    switch (value) {
      case 0:
        return <Step1 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} />;
      case 1:
        return <Step2 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} formik={formik} dteInformation={dteInformation} />;
      case 2:
        return <Step3 formik={formik} handleCloseDelete={handleCloseDelete} dteInformation={dteInformation} dataInvalidateGeneralDocument={dataInvalidateGeneralDocument} dataInvalidateDTEDocument={dataInvalidateDTEDocument} />;
    }
  };
  function getInvalidationType(value: string) {
    switch (value) {
      case RazonDeInvalidacion.ERROR_EN_LA_INFORMACION_DEL_DTE:
        return InvalidTypes.INFORMACION_INCORRECTA;
      case RazonDeInvalidacion.YA_NO_SE_VA_A_LLEVAR_A_CABO_LA_OPERACION:
        return InvalidTypes.RESCINDIR_OPERACION;
      case RazonDeInvalidacion.OTRO:
        return InvalidTypes.OTRO;
      default:
        return value;
    }
  }

  const rows = rowsData.map((item: any) => {
    if (item) {
      return {
        select: item?.id && <CustomIdCell checked={selectedItems.includes(item) ? true : false} idDoc={item.id} selectedItem={item} setChecked={setSelectedItems} />,
        num_control: item?.dteControlNumber && item?.ivaDocument && item?.ivaDocument?.name_document && `#${getNumControl(item?.dteControlNumber)} - ${item?.ivaDocument?.name_document}`,
        date: item?.createdAt && parseDate(item.createdAt),
        client: item?.client && item.client,
        lugar: item?.costCenter && item?.salePoint && `${item?.costCenter?.name} - ${item?.salePoint?.name}`,
        amount: item?.totalToPay && formatNumberToMoney(item?.totalToPay),
        actions: <DtesRechazadosDropdown selectedItem={item} setDteInformation={setDteInformation} handleOpenDelete={handleOpenDelete} />,
      };
    }
  });

  const table = {
    columns: sellsDataTableDataRechazados.columns,
    rows: rows || [],
  };

  //Effects
  useEffect(() => {
    //Companies by user
    getCompanies({ variables: { filters: { pagination: PAGINATION } } });
  }, []);
  useEffect(() => {
    if (data) {
      setRowsData(data);
    }
  }, [data]);
  useEffect(() => {
    if (result) {
      const companieFilter = result.data?.getCompaniesByUser.data.filter((item: any) => item.company.name === company.name)[0];
      setRequestorInfo({
        name: companieFilter?.company.name,
        docNumber: companieFilter?.documents[0]?.documentNumber,
        docType: companieFilter?.documents[0]?.type,
      });
    }
  }, [result]);
  useEffect(() => {
    if (dteInformation?.clientName?.label !== "" && dteInformation?.clientName?.id !== 0) {
      formik.setFieldValue("client", {
        label: dteInformation?.clientName?.label,
        id: dteInformation?.clientName?.id,
      });
    } else {
      formik.setFieldValue("client", null);
    }
  }, [dteInformation]);

  return (
    <>
      <SelectedItemsMenu selectedItems={selectedItems} handleOpenDelete={handleOpenDelete} setDteInformation={setDteInformation} setSelectedItems={setSelectedItems} />

      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Administracion de Dtes Rechazados
            </MDTypography>
            <MDTypography color="secondary" variant="subtitle2">
              {type === "Pendientes"
                ? "Los Dtes Rechazados a continuación son Dtes pendientes por corregir y todavia no son multas por el Ministerio de Hacienda."
                : "Los Dtes Rechazados a continuación son Dtes multados y ya son multas por el Ministerio de Hacienda."}
            </MDTypography>
          </MDBox>

          <Grid container gap={5} wrap="nowrap" direction={{ xs: "column", lg: "column" }}>
            <Grid item lg={12}>
              <MDBox mb={5}>
                <DataTable loading={loadingData} table={table} canSearch pagination={{ variant: "contained", color: "info" }} />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {dteInformation && (
        <Dialog
          open={openDelete}
          keepMounted
          onClose={() => {
            handleCloseDelete();
            formik.setFieldValue("nroDTE", "");
            formik.setFieldTouched("nroDTE", false);
          }}
        >
          {stepContent(activeStep)}
        </Dialog>
      )}
    </>
  );
};

export default DTEsRechazadosTable;
