import gql from "graphql-tag";

export const GET_GROUPS = gql`
  query GET_GROUPS($groupsFilters: QueryFilterDti!) {
    getGroups(groupsFilters: $groupsFilters) {
      isSuccess
      data {
        id
        name
        createdAt
        updatedAt
        company {
          id
          name
          address
        }
      }
      message {
        code
        message
      }
    }
  }
`;
