import React, { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import { Autocomplete, CircularProgress, Grid, Stack, Switch, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

// Formik
import { FormikProps } from "formik";

// Types
import { LabelandId } from "types/labelAndId";
import { SerializationKeys } from "types/apollo";
import { ValuesArticulos } from "types/Articulos";
import { BodegasArr } from "types/bodegas";

// Graphql
import { useLazyQuery, useQuery } from "@apollo/client";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//Alert
import { toast } from "react-hot-toast";

// Queries
import { GET_STORAGES } from "apollo/queries/storage/getStorage";
import { GET_COSTS_CENTERS } from "apollo/queries/costsCenters/getCostsCenters";

// Types
import { PAGINATION } from "constants/pagination";

interface Props {
  formik: FormikProps<ValuesArticulos>;
  arrOfBodegas: BodegasArr[];
  setArrOfBodegas: React.Dispatch<React.SetStateAction<BodegasArr[]>>;
  setItemDeleteBod?: React.Dispatch<any>;
  itemDeleteBod?: any[];
  dataArticleStorages?: any;
}

const columns = [
  { Header: "Codigo", accessor: "codigo", width: "20px" },
  { Header: "Bodegas", accessor: "bodegas" },
  { Header: "Centro de Costos", accessor: "costsCenters" },
  { Header: "Borrar", accessor: "borrar", hiddeSort: true, align: "center" },
];

function Bodega({ formik, arrOfBodegas, setArrOfBodegas, setItemDeleteBod, itemDeleteBod, dataArticleStorages }: Props) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Contacts };

  // Querys
  const [getStorages, { data, loading, error }] = useLazyQuery(GET_STORAGES, { context });
  const dataGetCostsCenters = useQuery(GET_COSTS_CENTERS, { context, variables: { filters: { pagination: PAGINATION } } });

  // States
  const [storages, setStorages] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [costsCenters, setCostsCenters] = useState<LabelandId[]>([]);
  const [idRemove, setIdRemove] = useState("");

  // Effects
  useEffect(() => {
    if (dataGetCostsCenters?.data?.getCostsCenters?.data) {
      setCostsCenters(
        dataGetCostsCenters?.data?.getCostsCenters?.data?.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetCostsCenters?.data?.getCostsCenters?.data]);
  useEffect(() => {
    if (data) {
      setStorages(
        data?.getStorages?.data?.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [data]);
  useEffect(() => {
    if (formik.values.costsCenters) {
      getStorages({ variables: { filter: { idCostCenter: formik.values.costsCenters.id, pagination: PAGINATION } } });
    }
    if (formik.values.costsCenters?.label === "" || formik.values.costsCenters === null) {
      setStorages([{ label: "No Options", id: 0 }]);
      formik.setFieldValue("storage", { label: "", id: 0 });
    }
  }, [formik.values.costsCenters]);
  useEffect(() => {
    if (idRemove !== "") {
      setArrOfBodegas(arrOfBodegas.filter((item) => item.codigo !== idRemove));
      setIdRemove("");
      if (setItemDeleteBod !== undefined) {
        let exist;
        if (itemDeleteBod.length !== 0) {
          exist = itemDeleteBod.find((item) => item == dataArticleStorages.find((item: any) => item.storage?.id == idRemove));
        }
        if (!exist) {
          if (dataArticleStorages.find((item: any) => item.storage?.id == idRemove)) {
            setItemDeleteBod((prev: any) => [...prev, dataArticleStorages.find((item: any) => item.storage?.id == idRemove)]);
          }
        }
      }
    }
  }, [idRemove]);

  const rows: BodegasArr[] = arrOfBodegas.map((item) => {
    return {
      codigo: item?.codigo ?? "No hay codigo",
      bodegas: item?.bodegas ?? "No hay bodegas",
      costsCenters: item?.costsCenters ?? "No hay centro de costos" /* 
      min: item?.min ?? "No hay minimo",
      max: item?.max ?? "No hay maximo",
      stock: item?.stock ?? "No hay stock", */,
      borrar: (
        <ClearIcon
          sx={{ cursor: "pointer" }}
          fontSize="large"
          color="error"
          id={`${item?.codigo}`}
          onClick={(e) => {
            setIdRemove(e.currentTarget.id);
          }}
        />
      ),
    };
  });
  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  function clearForm() {
    formik.resetForm({
      values: {
        allowNegativeKardex: false,
        barCode: formik.values.barCode,
        code: formik.values.code,
        description: formik.values.description,
        measurementUnit: formik.values.measurementUnit,
        name: formik.values.name,
        operationType: formik.values.operationType,
        type: formik.values.type,
        priceList: formik.values.priceList,
        defaultPrice: formik.values.defaultPrice,
        price: formik.values.price,
        groups: formik.values.groups,
        tags: formik.values.tags,
        tagValue: formik.values.tagValue,
        storage: { label: "", id: 0 },
        costsCenters: { label: "", id: 0 },
        max: "",
        min: "",
        stock: "",
        photos: formik.values.photos,
      },
    });
  }
  const handleAdd: any = () => {
    const exist = arrOfBodegas.find((item) => item.codigo === String(formik.values?.storage?.id));
    if (!exist) {
      if (formik.values.costsCenters?.label !== "" && formik.values.costsCenters !== null && formik.values.storage?.label !== "" && formik.values.storage !== null) {
        setArrOfBodegas([
          ...arrOfBodegas,
          {
            codigo: String(formik.values?.storage?.id) ?? "No hay codigo",
            bodegas: formik.values?.storage?.label ?? "No hay bodegas",
            costsCenters: formik.values?.costsCenters?.label ?? "No hay centro de costos",
            min: formik.values?.min,
            max: formik.values?.max,
            stock: formik.values?.stock,
            borrar: (
              <ClearIcon
                sx={{ cursor: "pointer" }}
                fontSize="large"
                color="error"
                id={`${formik.values?.storage?.id}`}
                onClick={(e) => {
                  setIdRemove(e.currentTarget.id);
                }}
              />
            ),
          },
        ]);
        clearForm();
      } else {
        toast.error("Rellene el formulario correctamente");
        formik.setFieldTouched("storage", true);
        formik.setFieldTouched("costsCenters", true);
      }
    } else {
      toast.error("No puedes agregar un articulo dos veces a la misma bodega");
      clearForm();
    }
  };

  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Agregarlo a una Bodega
        </MDTypography>
      </MDBox>
      <MDBox mt={2} sx={{ border: "1px solid #d2d6da", p: "5px", borderRadius: "0.375rem" }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="costsCenters"
                  freeSolo
                  options={costsCenters}
                  value={formik.values.costsCenters}
                  loading={dataGetCostsCenters.loading}
                  onChange={(e, value) => formik.setFieldValue("costsCenters", value)}
                  getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                  onBlur={() => formik.setFieldTouched("costsCenters", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centro de Costos"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {dataGetCostsCenters.loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={formik.values.costsCenters === null || formik.values.costsCenters?.label === "" ? Boolean(formik.touched.costsCenters) : null}
                      helperText={(formik.touched.costsCenters && formik.values.costsCenters === null) || (formik.touched.costsCenters && formik.values.costsCenters?.label === "") ? "La Bodega es requerida" : null}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="storage"
                  freeSolo
                  options={storages}
                  value={formik.values.storage}
                  loading={loading}
                  onChange={(e, value) => formik.setFieldValue("storage", value)}
                  getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                  onBlur={() => formik.setFieldTouched("storage", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bodegas"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={formik.values.storage === null || formik.values.storage?.label === "" ? Boolean(formik.touched.storage) : null}
                      helperText={(formik.touched.storage && formik.values.storage === null) || (formik.touched.storage && formik.values.storage?.label === "") ? "La Bodega es requerida" : null}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack mt={{ xs: 0, md: 2.5 }} direction="row" alignItems="center">
                  <MDTypography color={`${formik.values.allowNegativeKardex ? "info" : "secondary"}`} sx={{ fontSize: "0.8rem" }}>
                    ¿Se permite valores negativos?
                  </MDTypography>
                  <Switch checked={formik.values.allowNegativeKardex} onChange={formik.handleChange} name="allowNegativeKardex" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  onBlur={() => formik.setFieldTouched("min", true)}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label="Existencia Minima"
                  name="min"
                  // error={formik.values.min === "" && Boolean(formik.touched.min) ? true : false}
                  helperText={!formik.touched.min || (formik.touched.min && !formik.errors.min) ? " " : formik.errors.min}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      formik.handleChange(e);
                    }
                  }}
                  variant="standard"
                  value={formik.values.min}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  onBlur={() => formik.setFieldTouched("max", true)}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label="Existencia Maxima"
                  name="max"
                  // error={formik.values.max === "" && Boolean(formik.touched.max) ? true : false}
                  helperText={!formik.touched.max || (formik.touched.max && !formik.errors.max) ? " " : formik.errors.max}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      formik.handleChange(e);
                    }
                  }}
                  variant="standard"
                  value={formik.values.max}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  error={Boolean(formik.errors.stock) && formik.touched.stock}
                  onBlur={() => formik.setFieldTouched("stock", true)}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label="Cantidad de Articulos"
                  name="stock"
                  helperText={!formik.touched.stock || (formik.touched.stock && !formik.errors.stock) ? " " : formik.errors.stock}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      formik.handleChange(e);
                    }
                  }}
                  variant="standard"
                  value={formik.values.stock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDButton
              fullWidth
              variant="outlined"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => {
                handleAdd();
              }}
            >
              AGREGAR BODEGAS
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <DataTable table={table} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }} />
      </MDBox>
    </>
  );
}
export default Bodega;
