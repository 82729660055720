import gql from "graphql-tag";

export const INVALIDATE_DTE_DOCUMENT = gql`
  mutation INVALIDATE_DTE_DOCUMENT($input: InvalidGeneralDTEDti!) {
    invalidateDTEDocument(input: $input) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
