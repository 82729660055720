export enum DocumentType {
  NRC = "NRC",
  DUI = "DUI",
  NIT = "NIT",
  ISSS = "ISSS",
  AFP = "AFP",
  NUP = "NUP",
  PASSPORT = "Pasaporte",
  CARNET_RESIDENTE = "CARNET_RESIDENTE",
}
