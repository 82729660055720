import React, { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// GraphQL
import { useLazyQuery } from "@apollo/client";

// Queries
import { GET_COSTS_CENTERS } from "apollo/queries/getCostsCenters";

// Constants
import { YEARS_COUNT } from "constants/yearsCount";
import { MONTHS_ARRAY } from "constants/monthsArray";

// Utils
import { getPastYearsArray } from "utils/getPastYearsArray";
import { getDateByMonth } from "utils/getDateByMonth";
import { getLastDayOfTheMonth } from "utils/getLastDayOfTheMonth";
import { getEndDateByMonth } from "utils/getEndDateByMonth";
import { getEnvironmentSubmit } from "utils/getEnvironmentSubmit";
// Libs
import { format } from "fecha";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useCompanyContext } from "context/CompanyContext";

// Types
import { CostCenter } from "types/sells";
import { SerializationKeys } from "types/apollo";
import { Environment } from "types/environment";
import { PAGINATION } from "constants/pagination";

const options = [Environment.EmisionesReales, Environment.PruebasMH, Environment.PruebasInternas];
const isOptionEqualToValue = (option: string, value: string) => option === value;

const TableFilters = ({ getGeneralDocuments }: { getGeneralDocuments: React.Dispatch<React.SetStateAction<any>> }) => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Queries
  const [getCostsCenters, { data: costsCenters }] = useLazyQuery(GET_COSTS_CENTERS, {
    context,
  });

  // Context
  const { sellsConfig, setSellsConfig, resetSellsConfig } = useSellsConfigContext();
  const { company } = useCompanyContext();

  // States
  const [year, setYear] = useState<string>();
  const [month, setMonth] = useState<string>();
  const [environment, setEnviroment] = useState<string | null>();

  // Constants
  const currentYear = new Date().getFullYear();
  const selectedMonth = sellsConfig?.emisionStartDate ? new Date(sellsConfig?.emisionStartDate) : new Date();
  selectedMonth.setHours(selectedMonth.getHours() + 6);

  // Fuctions

  // Effects
  useEffect(() => {
    getCostsCenters({ variables: { pagination: PAGINATION } });
    setSellsConfig({
      ...sellsConfig,
      emisionStartDate: getDateByMonth(),
      emisionEndDate: getEndDateByMonth(),
    });
  }, []);

  useEffect(() => {
    sellsConfig?.costCenter &&
      sellsConfig?.selectedSalePoint &&
      getGeneralDocuments({
        variables: {
          filters: {
            idCostCenter: sellsConfig.costCenter.id,
            idSalePoint: sellsConfig.selectedSalePoint.id,
            emisionStartDate: sellsConfig.emisionStartDate,
            emisionEndDate: sellsConfig.emisionEndDate,
            pagination: 1000000,
            environment: environment ? getEnvironmentSubmit(environment) : null,
          },
        },
      });
  }, [sellsConfig, environment]);

  useEffect(() => {
    if (year && month) {
      setSellsConfig({
        ...sellsConfig,
        emisionStartDate: getDateByMonth(MONTHS_ARRAY.indexOf(month), parseInt(year)),
        emisionEndDate: format(new Date(parseInt(year), MONTHS_ARRAY.indexOf(month), getLastDayOfTheMonth(MONTHS_ARRAY.indexOf(month) + 1)), "YYYY-MM-DD"),
      });
    } else if (year) {
      setSellsConfig({
        ...sellsConfig,
        emisionStartDate: getDateByMonth(new Date().getMonth(), parseInt(year)),
        emisionEndDate: format(new Date(parseInt(year), new Date().getMonth(), getLastDayOfTheMonth(new Date().getMonth() + 1)), "YYYY-MM-DD"),
      });
    } else if (month) {
      setSellsConfig({
        ...sellsConfig,
        emisionStartDate: getDateByMonth(MONTHS_ARRAY.indexOf(month)),
        emisionEndDate: format(new Date(currentYear, MONTHS_ARRAY.indexOf(month), getLastDayOfTheMonth(MONTHS_ARRAY.indexOf(month) + 1)), "YYYY-MM-DD"),
      });
    }
  }, [month, year]);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDTypography color="info" variant={"h3"} verticalAlign="center" fontSize={{ xs: "1.4rem", lg: "1.875rem" }} sx={{ textAlign: "center" }}>
            {company?.name}
          </MDTypography>

          <Grid container gap={2} my={5} justifyContent="center" wrap="nowrap" flexDirection={{ xs: "column", lg: "row" }}>
            <Grid item xs={12} lg={4}>
              <Autocomplete
                id="SellsRoom"
                noOptionsText="No hay salas de venta"
                disablePortal
                fullWidth
                renderInput={(params) => <TextField name="SellsRoom" {...params} label="Sala de ventas" />}
                value={sellsConfig?.costCenter?.name || null}
                onChange={async (_, newValue, reason) => {
                  if (reason === "clear") {
                    resetSellsConfig();
                    return;
                  }

                  const costCenter = costsCenters.getCostsCenters.data.find((center: CostCenter) => center.name === newValue);

                  setSellsConfig({
                    ...sellsConfig,
                    costCenter,
                    salePoints: costCenter.salePoints,
                    selectedSalePoint: null,
                  });
                }}
                options={costsCenters?.getCostsCenters?.data?.map((item: CostCenter) => item.name) || []}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Autocomplete
                id="SellsPoint"
                noOptionsText="No hay puntos de venta"
                disablePortal
                fullWidth
                renderInput={(params) => <TextField {...params} label="Puntos de Ventas" />}
                disabled={sellsConfig?.costCenter ? false : true}
                value={sellsConfig?.selectedSalePoint?.name || null}
                onChange={async (_, newValue) => {
                  const salePoint = sellsConfig?.salePoints?.find((center: any) => center?.name === newValue);
                  setSellsConfig({ ...sellsConfig, selectedSalePoint: salePoint });
                }}
                options={(sellsConfig?.salePoints?.length > 0 && sellsConfig?.salePoints?.map((item) => item.name)) || []}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Autocomplete
                id="Enviroment"
                disablePortal
                fullWidth
                renderInput={(params) => <TextField {...params} label="Entorno" />}
                value={environment}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={async (_, newValue, reason) => {
                  if (reason === "clear") {
                    setEnviroment("");
                    return;
                  }
                  setEnviroment(newValue);
                }}
                options={options}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Autocomplete
                id="month"
                disablePortal
                fullWidth
                renderInput={(params) => <TextField {...params} label="Mes" />}
                disabled={sellsConfig?.salePoints && sellsConfig?.salePoints?.length > 0 ? false : true}
                value={sellsConfig?.emisionStartDate ? (month !== "Enero" ? MONTHS_ARRAY[new Date(sellsConfig.emisionStartDate).getMonth() + 1] : MONTHS_ARRAY[0]) : null}
                onChange={(event, newValue, intention) => {
                  if (intention === "clear") {
                    setMonth(undefined);
                    setYear(undefined);
                    setSellsConfig({
                      ...sellsConfig,
                      emisionStartDate: null,
                    });
                  } else {
                    setMonth(newValue);
                  }
                }}
                options={MONTHS_ARRAY}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Autocomplete
                id="year"
                fullWidth
                disablePortal
                disabled={sellsConfig && sellsConfig.salePoints ? false : true}
                value={sellsConfig?.emisionStartDate ? (month !== "Enero" ? new Date(sellsConfig?.emisionStartDate)?.getFullYear()?.toString() : (new Date(sellsConfig?.emisionStartDate)?.getFullYear() + 1)?.toString()) : null}
                onChange={(event, newValue, intention) => {
                  if (intention === "clear") {
                    setYear(undefined);
                    setSellsConfig({
                      ...sellsConfig,
                      emisionStartDate: null,
                    });
                  } else {
                    setYear(newValue);
                  }
                }}
                options={getPastYearsArray(YEARS_COUNT).map((year: number) => year.toString()) || []}
                renderInput={(params) => <TextField {...params} label="Año" />}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default TableFilters;
