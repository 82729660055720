export function validarDUI(dui: string | null) {
  let validador = 0;
  let valorstr = 0;
  let multiplicadorstr = 9;
  let acumuladorstr = 0;
  let posicionstr = 1;
  let residuostr = 0;
  let numeroDui = dui?.replace(/-/g, "").replace(/ /g, "");

  const message = "D.U.I. invalido";
  if (isNumeric(numeroDui)) {
    if (dui.length < 10) {
      return "No esta completo";
    } else {
      validador = parseInt(numeroDui.substring(8, 9), 10);
      for (let i = 0; i < 8; i++) {
        valorstr = parseInt(numeroDui.substring(posicionstr - 1, posicionstr), 10);
        acumuladorstr += multiplicadorstr * valorstr;
        multiplicadorstr--;
        posicionstr++;
      }
      residuostr = 10 - (acumuladorstr % 10);
      if (residuostr === validador) {
        return; // DUI válido
      } else {
        return message;
      }
    }
  } else {
    return message;
  }
}

function isNumeric(str: any) {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(parseFloat(str));
}
