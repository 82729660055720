// Material Dashboard 2 PRO React TS component
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Autocomplete, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, SvgIcon, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//Formik
import { FormikProps } from "formik";

// Types
import { ClientORCompanyForm } from "types/clientsForm";
import { legalRepresetantion } from "types/legalRepresentation";

// Data
import { actividadComercial } from "utils/getActividadPrincipal";

interface GeneralProps {
  formik: FormikProps<ClientORCompanyForm>;
}

function General({ formik }: GeneralProps) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangeFormat = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      formik.setFieldValue("nit", string);
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      formik.setFieldValue("dui", value);
    }
    if (e.target.name === "NRC") {
      e.currentTarget.maxLength = 8;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      formik.setFieldValue("nrc", value);
    }
  };

  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Generales
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
          Esta informacion es requerida por el Ministerio de Hacienda para que puedas ocupar los Documentos Tributarios Electronicos.
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={1}>
          {formik.values.legalRepresentation === legalRepresetantion.JURIDICA.toUpperCase() && formik.values.clientStatus === "extranjero" ? (
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 3 }}>
                <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                  <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nit) && formik.touched.nit}>
                    <InputLabel>Numero de Identificacion Tributaria</InputLabel>
                    <Input
                      name="NIT"
                      endAdornment={
                        <InputAdornment position="start">
                          <SvgIcon viewBox="0 0 16 12">
                            <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                          </SvgIcon>
                        </InputAdornment>
                      }
                      onBlur={() => formik.setFieldTouched("nit", true)}
                      onChange={handleChangeFormat}
                      value={formik.values.nit}
                    />
                    <FormHelperText>{!formik.touched.nit || (formik.touched.nit && !formik.errors.nit) ? " " : formik.errors.nit}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                  <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nrc) && formik.touched.nrc}>
                    <InputLabel>Numero de Registro Contribuyente</InputLabel>
                    <Input
                      endAdornment={
                        <InputAdornment position="start">
                          <SvgIcon viewBox="0 0 16 12">
                            <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                          </SvgIcon>
                        </InputAdornment>
                      }
                      onChange={handleChangeFormat}
                      value={formik.values.nrc}
                      onBlur={() => formik.setFieldTouched("nrc", true)}
                      name="NRC"
                    />
                    <FormHelperText>{!formik.touched.nrc || (formik.touched.nrc && !formik.errors.nrc) ? " " : formik.errors.nrc}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              {/* Verifica si es cliente nacional o extranjero */}
              {formik.values.clientStatus === "nacional" ? (
                <>
                  {/* Verifica si el cliente no es Juridico */}
                  {formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? (
                    <Grid item xs={12} md={4}>
                      <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.dui) && formik.touched.dui}>
                        <InputLabel>Documento Unico de Identidad</InputLabel>
                        <Input
                          endAdornment={
                            <InputAdornment position="start">
                              <SvgIcon viewBox="0 0 16 12">
                                <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                              </SvgIcon>
                            </InputAdornment>
                          }
                          name="DUI"
                          onBlur={() => formik.setFieldTouched("dui", true)}
                          onChange={handleChangeFormat}
                          value={formik.values.dui}
                        />
                        <FormHelperText>{!formik.touched.dui || (formik.touched.dui && !formik.errors.dui) ? " " : formik.errors.dui}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                    <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nit) && formik.touched.nit}>
                      <InputLabel>Numero de Identificacion Tributaria</InputLabel>
                      <Input
                        name="NIT"
                        endAdornment={
                          <InputAdornment position="start">
                            <SvgIcon viewBox="0 0 16 12">
                              <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                            </SvgIcon>
                          </InputAdornment>
                        }
                        onBlur={() => formik.setFieldTouched("nit", true)}
                        onChange={handleChangeFormat}
                        value={formik.values.nit}
                      />
                      <FormHelperText>{!formik.touched.nit || (formik.touched.nit && !formik.errors.nit) ? " " : formik.errors.nit}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (
                    <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                      <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nrc) && formik.touched.nrc}>
                        <InputLabel>Numero de Registro Contribuyente</InputLabel>
                        <Input
                          endAdornment={
                            <InputAdornment position="start">
                              <SvgIcon viewBox="0 0 16 12">
                                <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                              </SvgIcon>
                            </InputAdornment>
                          }
                          onChange={handleChangeFormat}
                          value={formik.values.nrc}
                          onBlur={() => formik.setFieldTouched("nrc", true)}
                          name="NRC"
                        />
                        <FormHelperText>{!formik.touched.nrc || (formik.touched.nrc && !formik.errors.nrc) ? " " : formik.errors.nrc}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4}>
                      <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.pasaporte) && formik.touched.pasaporte}>
                        <InputLabel>Pasaporte</InputLabel>
                        <Input
                          endAdornment={
                            <InputAdornment position="start">
                              <SvgIcon viewBox="0 0 16 12">
                                <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                              </SvgIcon>
                            </InputAdornment>
                          }
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                              formik.handleChange(e);
                            }
                          }}
                          value={formik.values.pasaporte}
                          onBlur={() => formik.setFieldTouched("pasaporte", true)}
                          name="pasaporte"
                        />
                        <FormHelperText>{!formik.touched.pasaporte || (formik.touched.pasaporte && !formik.errors.pasaporte) ? " " : formik.errors.pasaporte}</FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="standard" error={Boolean(formik.errors.carnet_residente) && formik.touched.carnet_residente}>
                      <InputLabel>Carnet de Residente</InputLabel>
                      <Input
                        endAdornment={
                          <InputAdornment position="start">
                            <SvgIcon viewBox="0 0 16 12">
                              <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                            </SvgIcon>
                          </InputAdornment>
                        }
                        onChange={formik.handleChange}
                        value={formik.values.carnet_residente}
                        onBlur={() => formik.setFieldTouched("carnet_residente", true)}
                        name="carnet_residente"
                      />
                      <FormHelperText>{!formik.touched.carnet_residente || (formik.touched.carnet_residente && !formik.errors.carnet_residente) ? " " : formik.errors.carnet_residente}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="standard" error={Boolean(formik.errors.pasaporte) && formik.touched.pasaporte}>
                      <InputLabel>Pasaporte</InputLabel>
                      <Input
                        endAdornment={
                          <InputAdornment position="start">
                            <SvgIcon viewBox="0 0 16 12">
                              <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                            </SvgIcon>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            formik.handleChange(e);
                          }
                        }}
                        value={formik.values.pasaporte}
                        onBlur={() => formik.setFieldTouched("pasaporte", true)}
                        name="pasaporte"
                      />
                      <FormHelperText>{!formik.touched.pasaporte || (formik.touched.pasaporte && !formik.errors.pasaporte) ? " " : formik.errors.pasaporte}</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.nombre) && formik.touched.nombre}
              onBlur={() => formik.setFieldTouched("nombre", true)}
              label="Nombre"
              name="nombre"
              helperText={!formik.touched.nombre || (formik.touched.nombre && !formik.errors.nombre) ? " " : formik.errors.nombre}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.nombre}
              /* InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon viewBox="0 0 16 12">
                      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }} */
            />
          </Grid>
          {formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre Comercial"
                name="nombreComercial"
                variant="standard"
                value={formik.values.nombreComercial}
                onBlur={() => formik.setFieldTouched("nombreComercial", true)}
                error={Boolean(formik.errors.nombreComercial)}
                helperText={!formik.touched.nombreComercial || (formik.touched.nombreComercial && !formik.errors.nombreComercial) ? " " : formik.errors.nombreComercial}
                onChange={formik.handleChange}
              />
            </Grid>
          ) : null}
          {formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (
            <Grid item xs={12}>
              <Autocomplete
                options={actividadComercial}
                onChange={(e, value) => formik.setFieldValue("actividadPrincipal", value)}
                freeSolo
                onBlur={() => formik.setFieldTouched("actividadPrincipal", true)}
                value={formik.values.actividadPrincipal?.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Actividad Principal"
                    variant="standard"
                    error={Boolean(formik.errors.actividadPrincipal) && Boolean(formik.touched.actividadPrincipal)}
                    helperText={formik.touched.actividadPrincipal && typeof formik.errors.actividadPrincipal === "string" ? formik.errors.actividadPrincipal : " "}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Telefono"
                  name="telefono"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={Boolean(formik.errors.telefono) && formik.touched.telefono}
                  value={formik.values.telefono ?? ""}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)) && e.target.value.length < 21) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched("telefono", true)}
                  helperText={!formik.touched.telefono || (formik.touched.telefono && !formik.errors.telefono) ? " " : formik.errors.telefono}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  error={Boolean(formik.errors.email) && formik.touched.email}
                  label="Correo Electronico Principal"
                  name="email"
                  value={formik.values.email ?? ""}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("email", true)}
                  helperText={!formik.touched.email || (formik.touched.email && !formik.errors.email) ? " " : formik.errors.email}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default General;
