import gql from "graphql-tag";

export const GENERATE_CONTIGENCY_EVENTS = gql`
  mutation {
    generateContingencyEvents {
      isSuccess
      message {
        detail
        code
        message
      }
    }
  }
`;
