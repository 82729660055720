// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Card } from "@mui/material";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";

// GraphQL
import { useQuery } from "@apollo/client";

// Queries
import { GET_COSTS_CENTERS } from "apollo/queries/getCostsCenters";

// Types
import { CostCenter } from "types/sells";
import { SerializationKeys } from "types/apollo";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { PAGINATION } from "constants/pagination";

interface Props {
  open: boolean;
  handleClose: (value: ActiveValues) => void;
}

const InventoryConfigSelector = ({ handleClose, open }: Props) => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Graphql
  const { data: costsCenters } = useQuery(GET_COSTS_CENTERS, {
    context,
    variables: { pagination: PAGINATION },
  });

  const { sellsConfig, setSellsConfig, resetSellsConfig } = useSellsConfigContext();

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose(ActiveValues.InventaryConfig);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            padding: 3,
            maxWidth: { xs: "100%", xl: "20%" },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <MDTypography variant="h4" color="info" mb={1}>
            ¿Desde dónde facturas?
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular" color="info" mb={4}>
            Por favor selecciona los parametros para asi hacer las descargas de los inventarios.
          </MDTypography>
          <Grid item width={{ xs: "100%" }} mb={2}>
            <Autocomplete
              id="SellsRoom"
              noOptionsText="No hay salas de venta"
              disablePortal
              fullWidth
              renderInput={(params: any) => <TextField {...params} name="SellsRoom" data-testid="autocomplete-SellsRoom" label="Sala de ventas" />}
              onChange={async (event: any, newValue: any, reason: any) => {
                if (reason === "clear") {
                  resetSellsConfig();
                  return;
                }
                const costCenter = costsCenters?.getCostsCenters?.data.find((center: CostCenter) => center.name === newValue);
                setSellsConfig({
                  ...sellsConfig,
                  costCenter,
                  salePoints: costCenter.salePoints,
                });
              }}
              options={costsCenters?.getCostsCenters?.data.map((item: CostCenter) => item.name) || []}
            />
          </Grid>
          <Grid item width={{ xs: "100%" }} mb={2}>
            <Autocomplete
              id="SellsPoint"
              noOptionsText="No hay puntos de venta"
              disablePortal
              fullWidth
              renderInput={(params: any) => <TextField {...params} name="SellsPoint" data-testid="autocomplete-SellsPoint" label="Puntos de Ventas" />}
              onChange={async (event: any, newValue: any) => {
                const salePoint = sellsConfig.salePoints.find((center: any) => center.name === newValue);
                setSellsConfig({ ...sellsConfig, selectedSalePoint: salePoint });
              }}
              options={(sellsConfig && sellsConfig.salePoints && sellsConfig.salePoints.length > 0 && sellsConfig?.salePoints.map((item) => item.name)) || []}
            />
          </Grid>
        </Card>
      </Modal>
    </>
  );
};

export default InventoryConfigSelector;
