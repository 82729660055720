import React from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

// Layouts
import { DTE_INFO } from "layouts/ventas/facturacion/components/SellsTable";

// Libs
import { useNavigate } from "react-router-dom";

// Context
import { useGetGeneralDocumentContext } from "context/GetGeneralDocumentContext";

// Types
import { JsonDte } from "types/documents";

const options = ["Imprimir", /* "Descargar", */ "Descargar PDF", "Descargar JSON", "Enviar", "Duplicar", "Anular"];

interface Props {
  handleOpenDelete?: () => void;
  setDteInformation?: React.Dispatch<React.SetStateAction<DTE_INFO>>;
  handleClickOpenEmail: (asd: any) => void;
}
const PreviewDropdown = ({ handleOpenDelete, setDteInformation, handleClickOpenEmail }: Props) => {
  // Context
  const { generalDocument, jsonDte: parsedJson } = useGetGeneralDocumentContext();

  // States
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const stateIndex = options.indexOf("Ver Detalles");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "Imprimir":
        return <Icon color="info">print</Icon>;
      case "Descargar":
        return <CloudDownloadOutlinedIcon color="info" />;
      case "Descargar PDF":
        return <Icon color="info">document_scanner_icon</Icon>;
      case "Descargar JSON":
        return <Icon color="info">code_icon</Icon>;
      case "Enviar":
        return <Icon color="info">forward_to_inbox_icon</Icon>;
      case "Duplicar":
        return <Icon color="info">copy_all_icon</Icon>;
      case "Agregar Pago":
        return <Icon color="info">price_check_icon</Icon>;
      case "Anular":
        return <Icon color="error">close</Icon>;
      default:
        return <Icon>circle</Icon>;
    }
  };
  const handleMenuItemClick = async (event?: React.MouseEvent<HTMLLIElement, MouseEvent>, index?: number) => {
    if (options[index] === "Duplicar") {
      navigate("/ventas/nuevoDTE", {
        state: {
          generationCode: generalDocument?.generationCodeDte,
        },
      });
    }
    if (options[index] === "Imprimir") {
      let byteCharacters = atob(generalDocument?.pdfBase64);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    if (options[index] === "Descargar") {
      const base64PDF = generalDocument?.pdfBase64;
      const link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();

      const jsonDte: JsonDte = generalDocument?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link2 = window.document.createElement("a");

      link2.href = url;
      link2.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link2.click();
    }
    if (options[index] === "Descargar PDF") {
      const base64PDF = generalDocument?.pdfBase64;
      const link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();
    }
    if (options[index] === "Descargar JSON") {
      const jsonDte: JsonDte = generalDocument?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = window.document.createElement("a");

      link.href = url;
      link.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link.click();
    }
    if (options[index] === "Remover") {
      const pdfWindow = window.open(generalDocument.pdf);
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
    if (options[index] === "Enviar") {
      handleClickOpenEmail(generalDocument);
    }
    if (options[index] === "Anular") {
      const getNumControl = (string: string) => {
        const parts = String(string)?.split("-");
        const lastPart = parts[parts.length - 1];
        const number = parseInt(lastPart, 10);

        return number;
      };
      setDteInformation({
        idDte: generalDocument?.id,
        nroDTE: getNumControl(generalDocument?.dteControlNumber),
        generationCode: generalDocument?.generationCodeDte,
        clientName: {
          label: generalDocument?.client ?? "Cliente Anonimo",
          id: generalDocument?.clientCompany.idClientCompany ?? 0,
        },
        date: generalDocument?.date,
        ivaDocumentName: generalDocument?.ivaDocument?.extended_name_document,
        amount: generalDocument?.totalToPay,
        idEmployeeCompany: generalDocument?.cashier?.idEmployeeCompany,
      });

      handleOpenDelete();
    }
    setOpen(false);
  };

  return (
    <>
      <MDBox id="split-button-menu" mb={2} display="flex" width={{ xs: "50%", lg: "25%" }} justifyContent={{ xs: "flex-end", lg: "flex-end" }} alignItems="center" order={{ xs: 2, lg: 3 }}>
        <Icon ref={anchorRef} fontSize="large" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
          more_vert
        </Icon>
        <Popper
          sx={{
            zIndex: 1,
          }}
          defaultChecked={false}
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-end"
        >
          {({ TransitionProps }) => {
            return (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "right top",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <NotificationItem
                          key={option}
                          icon={getIcon(option)}
                          title={option}
                          customColor={option === "Anular" ? "error" : "info"}
                          disabled={index === stateIndex}
                          selected={index === stateIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        />
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </Popper>
      </MDBox>
    </>
  );
};

export default PreviewDropdown;
