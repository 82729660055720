import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// react-router-dom components
import { Link } from "react-router-dom";

export default function HaveAcount() {
  return (
    <>
      <MDBox mt={3} textAlign="center">
        <MDTypography variant="button" color="text">
          ¿Ya tienes una cuenta?{" "}
          <MDTypography component={Link} to="/authentication/sign-up/cover" variant="button" color="secondary" fontWeight="medium" textGradient>
            Ingresa aquí
          </MDTypography>
        </MDTypography>
      </MDBox>
    </>
  );
}
