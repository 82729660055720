import { useEffect, useState } from "react";

// GraphQL
import { useQuery } from "@apollo/client";

// @mui material components
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS Base Styles
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS components
import TextField from "components/MDInput";
import MDTypography from "components/MDTypography";

// Constants
import { PAGINATION } from "constants/pagination";

// Context
import { useAuthContext } from "context/AuthContext";
import { useCompanyContext } from "context/CompanyContext";
import { useSellsConfigContext } from "context/SellsConfigContext";

// Layouts
import SinglePageLayout from "layouts/authentication/components/SinglePageLayout";

// Queries
import { GET_COMPANIES_BY_USER } from "apollo/queries/companies/getCompaniesByUser";

// Alert
import toast from "react-hot-toast";

// React-router-dom
import { Navigate } from "react-router-dom";

// Types
import { Company } from "types/company";

const Comenzar = () => {
  const { data, loading } = useQuery(GET_COMPANIES_BY_USER, { variables: { filters: { pagination: PAGINATION } } });

  // Context
  const { onLogout } = useAuthContext();
  const { company, setCompany, setInfoEmployeeDocuments } = useCompanyContext();
  const { resetSellsConfig } = useSellsConfigContext();

  // States
  const [allow, setAllow] = useState<boolean>(false);
  const [companies, setCompanies] = useState([{ label: "No hay compañias", id: 0 }]);

  useEffect(() => {
    const companyAutocomplete = data?.getCompaniesByUser?.data.map(({ company }: { company: Company }) => {
      return { label: company.name, id: company.id };
    });

    setCompanies(companyAutocomplete);
  }, [data]);

  useEffect(() => {
    setCompany(null);
  }, []);

  return (
    <SinglePageLayout>
      <MDBox mb={3}>
        <MDTypography variant="h4" align="center" sx={{}}>
          ¿Con qué empresa comenzamos?
        </MDTypography>
      </MDBox>

      <MDBox mb={{ xs: 5 }} bgColor="#f8f9fa" shadow="md" p={2} borderRadius="lg">
        <Grid container justifyContent={"space-between"} alignItems="center" gap={{ xs: 2, lg: 3 }} wrap="nowrap" direction={{ xs: "column", xl: "row" }}>
          <Grid item alignContent="center" lg={6}>
            <MDTypography variant="body2" paragraph mb={0} color="info">
              <b>Selecciona</b> la empresa con la que trabajaras, y asi desbloquearas todas las opciones
            </MDTypography>
          </Grid>

          <Grid item lg={6} width="100%">
            <Grid container justifyContent="center" alignContent="center">
              <Autocomplete
                fullWidth
                autoComplete
                openOnFocus
                noOptionsText="No hay empresas"
                loading={loading}
                loadingText="Cargando empresas..."
                getOptionLabel={(option) => option.label}
                onChange={(_, value) => {
                  const newCompany = data?.getCompaniesByUser?.data.find(({ company }: { company: Company }) => company?.id === value?.id);

                  if (newCompany) {
                    setCompany(newCompany.company);
                    setInfoEmployeeDocuments({
                      documents: newCompany?.documents,
                      idMainEntity: newCompany?.idMainEntity,
                      idEntity: newCompany?.idEntity,
                    });
                    resetSellsConfig();
                  }
                }}
                options={companies || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="setCompany"
                    data-testid="autocomplete-options"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item lg={3}>
            <Grid container item justifyContent="flex-end" wrap="nowrap" gap={1}>
              <Grid
                id="selectLogicalButton"
                onClick={() => {
                  if (company) {
                    setAllow(true);
                    return null;
                  } else {
                    toast.error("Por favor, selecciona una empresa");
                  }
                }}
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                width={100}
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.7, transition: "all 0.3s ease", transform: "scale(1.1)" },
                }}
              >
                <CheckCircleOutlineIcon cursor="pointer" fontSize="medium" />
                <MDTypography variant="body2" paragraph mb={0} color="info">
                  Seleccionar
                </MDTypography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                width={100}
                onClick={onLogout}
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.7, transition: "all 0.3s ease", transform: "scale(1.1)" },
                }}
              >
                <LogoutIcon cursor="pointer" color="error" />
                <MDTypography variant="body2" paragraph mb={0} color="error">
                  Salir
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {company && allow && <Navigate to="/ventas" />}
    </SinglePageLayout>
  );
};

export default Comenzar;
