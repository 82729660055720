import gql from "graphql-tag";

export const UPDATE_MH_USER_PASSWORD = gql`
  mutation UpadteMhUserpassword($password: String!, $environment: MH_BILLING_ENVIORONMENTS!) {
    upadteMhUserpassword(password: $password, environment: $environment) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
