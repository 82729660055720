import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//react-router-dom
import { useNavigate } from "react-router-dom";

//Graphql
import { gql, useMutation, useQuery } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";

//Alerts
import { toast } from "react-hot-toast";

// Types
import { PAGINATION } from "constants/pagination";

const GET_SALE_POINTS = gql`
  query GET_SALE_POINTS($filters: SalePointrQueryFilterDti!) {
    getSalePoints(filters: $filters) {
      isSuccess
      data {
        name
        date
        code
        model
        storage {
          id
          name
        }
        costCenter {
          id
          name
          phone
          address
        }
        id
      }
      message {
        code
        detail
        message
      }
    }
  }
`;

const DELETE_SALE_POINT = gql`
  mutation DELETE_SALE_POINT($idSalePoint: Float!) {
    deleteSalePoint(idSalePoint: $idSalePoint) {
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;

const columns = [
  { Header: "Codigo", accessor: "codigo" },
  { Header: "Nombre", accessor: "nombre" },
  { Header: "Depende", accessor: "depende" },
  { Header: "Equipo", accessor: "equipo" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];

const options = ["Editar", "Archivar"];

function PuntosDeVenta() {
  //Queries and Mutations
  const { data, loading, error, refetch } = useQuery(GET_SALE_POINTS, {
    variables: {
      filters: {
        pagination: PAGINATION,
      },
    },
  });
  const [deleteSalePoint, dataDeleteSalePoint] = useMutation(DELETE_SALE_POINT);

  //States
  const [rowsData, setRowsData] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const navigate = useNavigate();

  //Effects
  useEffect(() => {
    if (data) {
      setRowsData(data.getSalePoints.data);
    }
  }, [data]);
  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      navigate(`/configuracion/empresa/PtosDeVtas/editar/${selectedMenuOption.id}`);
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteSalePoint({
        variables: {
          idSalePoint: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteSalePoint.isSuccess) {
          refetch({
            filters: {},
          });
          toast.success("Se archivo el punto de venta correctamente");
        }
      });
    }
  }, [selectedMenuOption]);

  const rows: any = useMemo(() => {
    return rowsData.map((item) => ({
      codigo: item?.code,
      nombre: item?.name,
      depende: item?.costCenter?.name ?? item?.storage?.name,
      equipo: item?.model,
      menu: <MenuDropdown onSelectOption={setSelectedMenuOption} id={item?.id} options={options} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Puntos de Ventas{" "}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui es donde asignas los equipos que podran facturar, pero recuerda que solo estaran habilitadas las bodegas que designes para la venta."}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteSalePoint.loading ? { columns: columns, rows: [] } : table}
        loading={loading || dataDeleteSalePoint.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => navigate("/configuracion/empresa/PtosDeVtas/crear")} color="info">
            Crear Nuevo Punto de Ventas
          </MDButton>
        </Grid>
      </DataTable>
    </>
  );
}
export default PuntosDeVenta;
