import gql from "graphql-tag";

export const DELETE_PHOTO_FROM_ARTICLE = gql`
  mutation DELETE_PHOTO_FROM_ARTICLE($idPhoto: Float!) {
    deletePhotoFromArticle(idPhoto: $idPhoto) {
      isSuccess
      message {
        message
        code
        detail
      }
    }
  }
`;
