import { format } from "fecha";

export const parseDate = (date: string) => {
  const [year, month, dayAndTime] = date.split("-");
  const [day, time] = dayAndTime.split(" ");
  const [hour, minute] = time.split(":");
  const parsedDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute));

  return format(parsedDate, "DD MMM, H:mm A");
};
