import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//react-router-dom
import { useNavigate } from "react-router-dom";

//Graphql
import { useMutation, useQuery } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";

//Alerts
import { toast } from "react-hot-toast";

// Queries
import { GET_ARTICLES } from "apollo/queries/articles/getArticles";
import { DELETE_ARTICLE } from "apollo/mutations/articles/deleteArticle";

// Types
import { SerializationKeys } from "types/apollo";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

interface Row {
  codigo: number;
  nombre: string;
  caracteristica: string;
  cantidad: string;
  precio: string;
  menu: JSX.Element;
}

const columns = [
  { Header: "Cod.Interno/Barra", accessor: "codigo" },
  { Header: "Nombre", accessor: "nombre" },
  { Header: "Caracteristica", accessor: "caracteristica" },
  { Header: "Cantidad", accessor: "cantidad" },
  { Header: "Precio Venta", accessor: "precio" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];

const options = ["Editar", "Archivar"];

function ArticulosTable() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  //Querys and mutations
  const { data, loading, refetch, error } = useQuery(GET_ARTICLES, {
    variables: {
      articlesFilters: {},
    },
    context,
  });
  const [deleteArticle, dataDeleteArticle] = useMutation(DELETE_ARTICLE, { context });

  // States
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });

  const navigate = useNavigate();

  //Effects
  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      navigate(`/inventarios/articulos/editar`, { state: selectedMenuOption.id });
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteArticle({
        variables: {
          idArticle: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteArticle.isSuccess) {
          refetch({
            articlesFilters: {},
          });
          toast.success("Se archivo el articulo correctamente");
        }
      });
    }
  }, [selectedMenuOption]);
  useEffect(() => {
    if (data) {
      setRowsData(data.getArticles.data);
    }
  }, [data]);

  // Data
  const rows: Row[] = useMemo(() => {
    return rowsData.map((item) => ({
      codigo: item?.code ?? "No hay codigo",
      nombre: item?.name ?? "No hay nombre",
      caracteristica: item?.tags[0]?.name ?? "No hay caracteristica",
      cantidad: item?.storages[0]?.skul ?? "Fuera de stock",
      precio: formatNumberToMoney(item?.salesPrices[0]?.salePrice) ?? "No tiene precio",
      menu: <MenuDropdown onSelectOption={setSelectedMenuOption} id={item?.id} options={options} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Articulos
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui podras Buscar, editar y archivar todos los articulos creados"}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Todos los precios mostrados no incluyen IVA"}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteArticle.loading ? { columns: columns, rows: [] } : table}
        loading={loading || dataDeleteArticle.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => navigate("/inventarios/articulos/crear")} color="info">
            Crear Nuevo Articulo
          </MDButton>
        </Grid>
      </DataTable>
    </>
  );
}
export default ArticulosTable;
