import React, { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//@mui material components
import { Autocomplete, CircularProgress, Grid, Stack, Switch, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

// Formik
import { FormikProps } from "formik";

// Graphql
import { useQuery } from "@apollo/client";

// Queries
import { GET_PRICE_LISTS } from "apollo/queries/priceList/getPriceLists";

// Alert
import { toast } from "react-hot-toast";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Types
import { LabelandId } from "types/labelAndId";
import { SerializationKeys } from "types/apollo";
import { ValuesArticulos } from "types/Articulos";
import { PriceListArr } from "types/arrListaDePrecio";
import { PAGINATION } from "constants/pagination";

interface Props {
  formik: FormikProps<ValuesArticulos>;
  arrOfPricesList: PriceListArr[];
  setArrOfPricesList: React.Dispatch<React.SetStateAction<PriceListArr[]>>;
  setItemDeletePrice?: React.Dispatch<any>;
  itemDeletePrice?: any[];
  dataArticleSalesPrices?: any;
}

const columns = [
  { Header: "Codigo", accessor: "codigo", width: "20px" },
  { Header: "Lista de Precios", accessor: "priceList" },
  { Header: "Precio", accessor: "price" },
  { Header: "Default", accessor: "default" },
  { Header: "Borrar", accessor: "borrar", hiddeSort: true, align: "center" },
];

function Precio({ formik, arrOfPricesList, setArrOfPricesList, setItemDeletePrice, itemDeletePrice, dataArticleSalesPrices }: Props) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Query
  const { data, loading, error } = useQuery(GET_PRICE_LISTS, { context, variables: { filters: { pagination: PAGINATION } } });

  const [priceLists, setPriceLists] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [idRemove, setIdRemove] = useState("");

  //Effects
  useEffect(() => {
    if (data) {
      setPriceLists(
        data.getPriceLists?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [data]);
  useEffect(() => {
    if (formik.values.priceList?.label === "" || formik.values.priceList === null) {
      formik.setFieldValue("price", "");
    }
  }, [formik.values.priceList]);
  useEffect(() => {
    if (idRemove !== "") {
      setArrOfPricesList(arrOfPricesList.filter((item) => item.codigo !== idRemove));
      setIdRemove("");
      if (setItemDeletePrice !== undefined) {
        let exist;
        if (itemDeletePrice.length !== 0) {
          exist = itemDeletePrice.find((item) => item == dataArticleSalesPrices.find((item: any) => item?.priceList?.id == idRemove));
        }
        if (!exist) {
          if (dataArticleSalesPrices.find((item: any) => item.priceList?.id == idRemove)) {
            setItemDeletePrice((prev: any) => [...prev, dataArticleSalesPrices.find((item: any) => item.priceList?.id == idRemove)]);
          }
        }
      }
    }
  }, [idRemove]);

  const rows: PriceListArr[] = arrOfPricesList.map((item) => ({
    codigo: item?.codigo ?? "No hay codigo",
    priceList: item.priceList ?? "No hay lista de precio",
    price: formatNumberToMoney(Number(item?.price)) ?? "No hay precio",
    default: item.default,
    borrar: (
      <ClearIcon
        sx={{ cursor: "pointer" }}
        fontSize="large"
        color="error"
        id={`${item?.codigo}`}
        onClick={(e) => {
          setIdRemove(e.currentTarget.id);
        }}
      />
    ),
  }));

  function clearForm() {
    formik.resetForm({
      values: {
        allowNegativeKardex: formik.values.allowNegativeKardex,
        barCode: formik.values.barCode,
        code: formik.values.code,
        description: formik.values.description,
        measurementUnit: formik.values.measurementUnit,
        name: formik.values.name,
        operationType: formik.values.operationType,
        type: formik.values.type,
        priceList: { label: "", id: 0 },
        defaultPrice: false,
        price: "",
        groups: formik.values.groups,
        tags: formik.values.tags,
        tagValue: formik.values.tagValue,
        storage: formik.values.storage,
        costsCenters: formik.values.costsCenters,
        max: formik.values.max,
        min: formik.values.min,
        stock: formik.values.stock,
        photos: formik.values.photos,
      },
    });
  }

  const handleAdd: any = () => {
    const exist = arrOfPricesList.find((item) => item.codigo === String(formik.values?.priceList?.id));
    if (!exist) {
      if (formik.values.priceList?.label !== "" && formik.values.priceList !== null && formik.values.price !== "" && formik.values.price !== null) {
        setArrOfPricesList([
          ...arrOfPricesList,
          {
            codigo: String(formik.values?.priceList?.id) ?? "No hay codigo",
            priceList: formik.values?.priceList?.label ?? "No hay lista de precio",
            price: formik.values?.price ?? "No hay precio",
            default: formik.values?.defaultPrice ? "Si" : "No",
            borrar: (
              <ClearIcon
                sx={{ cursor: "pointer" }}
                fontSize="large"
                color="error"
                id={`${formik.values?.priceList?.id}`}
                onClick={(e) => {
                  setIdRemove(e.currentTarget.id);
                }}
              />
            ),
          },
        ]);
        clearForm();
      } else {
        toast.error("Rellene el formulario correctamente");
        formik.setFieldTouched("priceList", true);
        formik.setFieldTouched("price", true);
      }
    } else {
      toast.error("No puedes agregar dos precios a la misma lista de precios");
      clearForm();
    }
  };

  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Precio de Ventas
        </MDTypography>
      </MDBox>
      <MDBox mt={2} sx={{ border: "1px solid #d2d6da", p: "5px", borderRadius: "0.375rem" }}>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              id="priceList"
              freeSolo
              options={priceLists ?? [{ label: "No Options", id: 0 }]}
              value={formik.values.priceList}
              loading={loading}
              onChange={(e, value) => formik.setFieldValue("priceList", value)}
              getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
              onBlur={() => formik.setFieldTouched("priceList", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lista de precios"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={Boolean(formik.errors.priceList) && Boolean(formik.touched.priceList)}
                  helperText={!formik.touched.priceList || (formik.touched.priceList && !formik.errors.priceList) ? " " : `${formik.errors.priceList}`}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={Boolean(formik.errors.price) && formik.touched.price}
              onBlur={() => formik.setFieldTouched("price", true)}
              label="Valor"
              name="price"
              helperText={!formik.touched.price || (formik.touched.price && !formik.errors.price) ? " " : formik.errors.price}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  formik.handleChange(e);
                }
              }}
              variant="standard"
              fullWidth
              value={formik.values.price}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack mt={{ xs: 0, md: 2.5 }} direction="row" alignItems="center">
              <MDTypography color={`${formik.values.defaultPrice ? "info" : "secondary"}`} sx={{ fontSize: "0.8rem" }}>
                ¿Es el precio por defecto?
              </MDTypography>
              <Switch checked={formik.values.defaultPrice} onChange={formik.handleChange} name="defaultPrice" />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <MDButton
              fullWidth
              variant="outlined"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => {
                handleAdd();
              }}
            >
              AGREGAR PRECIO
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <DataTable table={table} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }} />
      </MDBox>
    </>
  );
}
export default Precio;
