export enum InvalidTypes {
  INFORMACION_INCORRECTA = "INFORMACION_INCORRECTA",
  RESCINDIR_OPERACION = "RESCINDIR_OPERACION",
  OTRO = "OTRO",
}

export enum RazonDeInvalidacion {
  ERROR_EN_LA_INFORMACION_DEL_DTE = "ERROR EN LA INFORMACION DEL DTE",
  YA_NO_SE_VA_A_LLEVAR_A_CABO_LA_OPERACION = "YA NO SE VA A LLEVAR A CABO LA OPERACION",
  OTRO = "OTRO",
}
