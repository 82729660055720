import { useState, useEffect, SyntheticEvent } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Dialog, Skeleton, Typography } from "@mui/material";

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Libs
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import toast from "react-hot-toast";

// Custom Components
import PDFPreviewer from "./PDFPreviewer";
import Resume from "./Resume";
import PreviewDropdown from "./PreviewDropdown";
import Step1 from "layouts/ventas/components/StepContent/Step1";
import Step2 from "layouts/ventas/components/StepContent/Step2";
import Step3 from "layouts/ventas/components/StepContent/Step3";
import DialogEmail from "layouts/ventas/components/DialogEmail";

//Form
import { useFormik } from "formik";
import * as Yup from "yup";

//Context
import { useCompanyContext } from "context/CompanyContext";

// Constants
import { PAGINATION } from "constants/pagination";
import { InvalidTypes, RazonDeInvalidacion } from "types/InvalidDtesType";

//Graphql
import { useLazyQuery, useMutation } from "@apollo/client";

// Queries
import { INVALIDATE_GENERAL_DOCUMENT } from "apollo/mutations/dtes/invalidateGeneralDocument";
import { GET_COMPANIES_BY_USER } from "apollo/queries/companies/getCompaniesByUser";
import { SEND_DTE_BY_EMAIL } from "apollo/mutations/dtes/sendDteByEmail";
import { INVALIDATE_DTE_DOCUMENT } from "apollo/mutations/dtes/invalidateDTEDocument";

// Context
import { useGetGeneralDocumentContext } from "context/GetGeneralDocumentContext";

// Types
import { DTE_INFO } from "layouts/ventas/facturacion/components/SellsTable";
import { FormikValues, RequestorInfo } from "layouts/ventas/verDtesRechazados/components/DtesRechazadosTable";
import { SerializationKeys } from "types/apollo";
import { IvaDocumentTypes } from "types/iva-document";

const PreviewSection = () => {
  // Context
  const { generalDocument, loading: loadingDocument, error: errorDocument } = useGetGeneralDocumentContext();
  const { company } = useCompanyContext();

  // States
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [dteInformation, setDteInformation] = useState<DTE_INFO>();
  const [requestorInfo, setRequestorInfo] = useState<RequestorInfo>();
  const [openDelete, setOpenDelete] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [idDteEmail, setIdDteEmail] = useState<number>();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Queries and mutations
  const [getCompanies, result] = useLazyQuery(GET_COMPANIES_BY_USER, { context });
  const [invalidateGeneralDocument, dataInvalidateGeneralDocument] = useMutation(INVALIDATE_GENERAL_DOCUMENT, {
    context,
  });
  const [invalidateDTEDocument, dataInvalidateDTEDocument] = useMutation(INVALIDATE_DTE_DOCUMENT, { context });
  const [sendDteByEmail, { loading }] = useMutation(SEND_DTE_BY_EMAIL, { context });

  const validationSchema = Yup.object({
    razonDeInvalidacion: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
    description: Yup.string().required("La descripcion es requerida").min(5, "La descripcion debe tener al menos 5 caracteres"),
    nroDTE: Yup.string().oneOf([String(dteInformation?.nroDTE), null], "El numero de DTE debe coincidir"),
    client: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    }).nullable(),
    nroDTEReemplazo: Yup.object({
      generationCodeDte: Yup.string(),
      label: Yup.string(),
    })
      .required("El Dte de reemplazo es requerido")
      .nullable(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      nroDTE: "",
      razonDeInvalidacion: "",
      client: {
        label: "",
        id: 0,
      },
      nroDTEReemplazo: {
        label: "",
        generationCodeDte: "",
      },
      description: "",
      email: "",
      emails: [],
    },
    onSubmit: async (values) => {
      if (dteInformation.ivaDocumentName === IvaDocumentTypes.EXTENDED_FEX) {
        invalidateDTEDocument({
          variables: {
            input: {
              codigoGeneracionAInvalidar: dteInformation.generationCode,
              codigoGeneracionAReemplazar: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
              motivoInvalidacion: values.description,
              tipoInvalidacion: getInvalidationType(values.razonDeInvalidacion),
            },
          },
        })
          .then(({ data }) => {
            if (data.invalidateDTEDocument?.isSuccess) {
              toast.success("DTE invalidado correctamente");
              setOpenDelete(false);

              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.invalidateDTEDocument.message.detail}`);
              setOpenDelete(false);

              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);

            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      } else {
        invalidateGeneralDocument({
          variables: {
            input: {
              idGeneralDocument: dteInformation.idDte,
              idEmployeeCompany: dteInformation.idEmployeeCompany,
              data: {
                invalidationType: getInvalidationType(values.razonDeInvalidacion),
                invalidationMotive: values.description,
                dteGenerationCodeReplace: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
                requestorName: requestorInfo?.name,
                requestorDocNumber: requestorInfo?.docNumber,
                requestorDocType: requestorInfo?.docType,
              },
            },
          },
        })
          .then((data) => {
            if (data.data.invalidateGeneralDocument.isSuccess) {
              toast.success("DTE invalidado correctamente");
              setOpenDelete(false);

              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.data.invalidateGeneralDocument.message.detail}`);
              setOpenDelete(false);

              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);

            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      }
    },
    validationSchema,
  });

  const handleSetTabValue = (event: SyntheticEvent, newValue: number) => setTabValue(newValue);

  function getInvalidationType(value: string) {
    switch (value) {
      case RazonDeInvalidacion.ERROR_EN_LA_INFORMACION_DEL_DTE:
        return InvalidTypes.INFORMACION_INCORRECTA;
      case RazonDeInvalidacion.YA_NO_SE_VA_A_LLEVAR_A_CABO_LA_OPERACION:
        return InvalidTypes.RESCINDIR_OPERACION;
      case RazonDeInvalidacion.OTRO:
        return InvalidTypes.OTRO;
      default:
        return value;
    }
  }
  function handleClickOpenEmail(selectedItem: any) {
    formik.setFieldValue("email", selectedItem?.clientCompany?.emailNotifications);
    setIdDteEmail(selectedItem.idSale);
    setOpen(true);
  }
  function handleCloseEmail() {
    setOpen(false);
    formik.setFieldValue("email", "");
    formik.setFieldTouched("email", false);
    formik.setFieldValue("emails", "");
    formik.setFieldTouched("emails", false);
    setIdDteEmail(null);
  }
  const sendEmail = () => {
    sendDteByEmail({ variables: { data: { idSale: idDteEmail, emails: formik.values.emails } } })
      .then(({ data }) => {
        if (data.sendDte.isSuccess) {
          toast.success("Email enviado correctamente");
          handleCloseEmail();
        }
        !data.sendDte.isSuccess && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      })
      .catch((err) => {
        err && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      });
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete: any = async () => {
    await formik.resetForm();
    setActiveStep(0);
    setDteInformation(null);
    setOpenDelete(false);
  };
  const stepContent = (value: number) => {
    switch (value) {
      case 0:
        return <Step1 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} />;
      case 1:
        return <Step2 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} formik={formik} dteInformation={dteInformation} />;
      case 2:
        return <Step3 formik={formik} handleCloseDelete={handleCloseDelete} dteInformation={dteInformation} dataInvalidateGeneralDocument={dataInvalidateGeneralDocument} dataInvalidateDTEDocument={dataInvalidateDTEDocument} />;
    }
  };

  //useEffect
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  useEffect(() => {
    if (result) {
      const companieFilter = result.data?.getCompaniesByUser.data.filter((item: any) => item.company.name === company.name)[0];
      setRequestorInfo({
        name: companieFilter?.company.name,
        docNumber: companieFilter?.documents[0]?.documentNumber,
        docType: companieFilter?.documents[0]?.type,
      });
    }
  }, [result]);
  useEffect(() => {
    //Companies by user
    getCompanies({ variables: { filters: { pagination: PAGINATION } } });

    return () => {
      localStorage.removeItem("documentView");
    };
  }, []);
  useEffect(() => {
    if (dteInformation?.clientName?.label !== "" && dteInformation?.clientName?.id !== 0) {
      formik.setFieldValue("client", {
        label: dteInformation?.clientName?.label,
        id: dteInformation?.clientName?.id,
      });
    } else {
      formik.setFieldValue("client", null);
    }
  }, [dteInformation]);

  return (
    <>
      {!loadingDocument && !errorDocument && (
        <Grid container width={{ xs: "100%", lg: "60%" }} justifyContent="center">
          <Grid container mb={2} flexDirection={{ xs: "row" }} justifyContent="space-between">
            <MDBox width={{ xs: "50%", sm: "25%", md: "50%", lg: "25%" }}>
              <MDTypography variant="h6" color="info">
                {generalDocument?.ivaDocument?.extended_name_document}
              </MDTypography>
            </MDBox>
            <Grid container flexDirection="column" justifyContent="center" alignContent="center" gap={5} width={{ xs: "100%", lg: "25%" }} wrap="nowrap" order={{ xs: 3, lg: 2 }}>
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Resumen" sx={{ paddingX: "30px" }} />
                <Tab label="PDF" color="primary" sx={{ paddingX: "30px" }} />
              </Tabs>
            </Grid>
            <PreviewDropdown handleOpenDelete={handleOpenDelete} setDteInformation={setDteInformation} handleClickOpenEmail={handleClickOpenEmail} />
          </Grid>
          <Grid minHeight="100vh" height="100%">
            {tabValue === 0 && <Resume />}
            {tabValue === 1 && <PDFPreviewer />}
          </Grid>
        </Grid>
      )}

      {loadingDocument && (
        <Grid container width={{ xs: "100%", lg: "60%" }} alignItems="center" gap={4} flexDirection="column">
          <Skeleton variant="rounded" width="50%" height="7vh" />
          <Skeleton variant="rounded" width="75%" height="75vh" />
        </Grid>
      )}

      {errorDocument && (
        <Grid container width={{ xs: "100%", lg: "60%" }} justifyContent="center" alignItems="center" gap={4} flexDirection="column">
          <Typography variant="h5" color="error">
            Hubo un error al cargar el documento
          </Typography>
        </Grid>
      )}

      {dteInformation?.nroDTE && (
        <Dialog
          open={openDelete}
          onClose={() => {
            handleCloseDelete();
            formik.setFieldValue("nroDTE", "");
            formik.setFieldTouched("nroDTE", false);
          }}
        >
          {stepContent(activeStep)}
        </Dialog>
      )}
      <DialogEmail formik={formik} handleClose={handleCloseEmail} loading={loading} open={open} handleSubmit={sendEmail} />
    </>
  );
};

export default PreviewSection;
