import gql from "graphql-tag";

export const DELETE_ARTICLE_GROUP = gql`
  mutation DELETE_ARTICLE_GROUP($idArticleGroup: Float!) {
    deleteArticleGroup(idArticleGroup: $idArticleGroup) {
      isSuccess
      message {
        message
        code
        detail
      }
    }
  }
`;
