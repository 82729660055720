import gql from "graphql-tag";

export const DELETE_GROUP = gql`
  mutation DELETE_GROUP($idGroup: Float!) {
    deleteGroup(idGroup: $idGroup) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
