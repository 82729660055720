import gql from "graphql-tag";

export const UPDATE_COMPANY = gql`
  mutation UPDATE_COMPANY($companyData: CompanyDti!) {
    updateCompany(companyData: $companyData) {
      isSuccess
      data {
        name
        id
        contribuyenteType
        legalRepresentation
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
