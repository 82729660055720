// @mui material components
import { Autocomplete, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Types
import { LabelandId } from "types/labelAndId";
import { FormikProps } from "formik";
import { ValuesLDP } from "types/ValuesLDP";

interface Props {
  open: {
    openAdd: boolean;
    openDelete: boolean;
  };
  handleClose: (value: boolean) => void;
  formik: FormikProps<ValuesLDP>;
  costCenters: LabelandId[];
  salePoints: LabelandId[];
  loading: boolean;
}
function DialogAddPtosDeVtasLDP({ open, handleClose, formik, costCenters, salePoints, loading }: Props) {
  return (
    <Dialog
      open={open.openAdd}
      keepMounted
      onClose={() => {
        handleClose(true);
        formik.setFieldValue("costCenter", {
          id: 0,
          label: "",
        });
        formik.setFieldTouched("costCenter", false);
        formik.setFieldValue("salePoint", {
          id: 0,
          label: "",
        });
        formik.setFieldTouched("salePoint", false);
      }}
    >
      <DialogTitle>{"¿Desea agregar un punto de venta a esta lista de precios?"}</DialogTitle>
      <MDTypography sx={{ px: 2 }} variant="subtitle2" fontWeight="regular" color="secondary">
        Selecciona el punto de ventas que se agregara a la lista de precios
      </MDTypography>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="costCenter"
              freeSolo
              options={costCenters}
              value={formik.values.costCenter}
              getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
              onChange={(e, value) => formik.setFieldValue("costCenter", value)}
              onBlur={() => formik.setFieldTouched("costCenter", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Centro de Costos"
                  error={Boolean(formik.errors.costCenter) && Boolean(formik.touched.costCenter)}
                  helperText={!formik.touched.costCenter || (formik.touched.costCenter && !formik.errors.costCenter) ? " " : `${formik.errors.costCenter}`}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="salePoint"
              freeSolo
              options={salePoints}
              value={formik.values.salePoint}
              getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
              onChange={(e, value) => formik.setFieldValue("salePoint", value)}
              onBlur={() => formik.setFieldTouched("salePoint", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Punto de Venta"
                  error={Boolean(formik.errors.salePoint) && Boolean(formik.touched.salePoint)}
                  helperText={!formik.touched.salePoint || (formik.touched.salePoint && !formik.errors.salePoint) ? " " : `${formik.errors.salePoint}`}
                  variant="standard"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose(true);
            formik.setFieldValue("costCenter", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("costCenter", false);
            formik.setFieldValue("salePoint", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("salePoint", false);
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          disabled={loading}
          onClick={() => {
            formik.handleSubmit();
          }}
          startIcon={loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogAddPtosDeVtasLDP;
