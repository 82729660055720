// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Libs
import { format } from "fecha";

// Custom components
import SendEmails from "./SendEmails";

// Context
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";

const Description = () => {
  const { jsonDte } = useGeneralDocumentContext();

  return (
    <>
      <Grid container justifyContent="center" gap={5}>
        <Grid container justifyContent="center" gap={5}>
          <Card sx={{ padding: 3, width: "100%" }}>
            <MDTypography variant="h3" color="info" mb={2}>
              Generales
            </MDTypography>
            <Grid container flexDirection="column" gap={2}>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Total
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  {jsonDte?.resumen && formatNumberToMoney(jsonDte.resumen.totalPagar)}
                </MDTypography>
              </Grid>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Numero de Documento
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  {jsonDte?.identificacion?.numeroControl}
                </MDTypography>
              </Grid>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Cliente
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  {jsonDte?.receptor?.nombre}
                </MDTypography>
              </Grid>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Fecha de Creacion
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  {jsonDte?.identificacion && format(new Date(jsonDte?.identificacion?.fecEmi + "T" + jsonDte?.identificacion?.horEmi), "DD/MM/YYYY  hh:mm:ss A")}
                </MDTypography>
              </Grid>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Fecha de Vencimiento
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  ----
                </MDTypography>
              </Grid>
              <Grid container justifyContent="space-between">
                <MDTypography variant="body1" color="text">
                  Bodega
                </MDTypography>
                <MDTypography variant="body1" color="info">
                  ----
                </MDTypography>
              </Grid>
            </Grid>
          </Card>

          <SendEmails />
        </Grid>
      </Grid>
    </>
  );
};

export default Description;
