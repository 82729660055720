import { useState, ChangeEvent, useEffect } from "react";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import CustomDropZone from "components/Global/CustomDropZone";

// Context
import { useAuthContext } from "context/AuthContext";
import { useCompanyContext } from "context/CompanyContext";

// Forms
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

// GraphQL
import { gql, useMutation } from "@apollo/client";

// Alert
import toast from "react-hot-toast";

// Mutations
import { CHANGE_PASSWORD_MH_CERTIFICATE } from "apollo/mutations/configuration/changePasswordMhCertificate";
// Types
import { ChooseEnvironment } from "../..";

import { UPDATE_MH_USER_PASSWORD } from "apollo/mutations/configuration/updateMhUserPassword";

interface Values {
  certificate?: File;
  "api-password": string;
  "api-password-confirm": string;
  "certificate-password": string;
  "certificate-password-confirm": string;
  environment: string;
}

const Credenciales = ({ enviroment }: { enviroment: ChooseEnvironment; setActiveEnv?: React.Dispatch<React.SetStateAction<ChooseEnvironment>> }) => {
  // States
  const [hasUploadedCertificate, setHasUploadedCertificate] = useState<boolean>(false);

  // Context
  const { token } = useAuthContext();
  const { company } = useCompanyContext();

  // Queries
  const [ChangePasswordMhCertificate] = useMutation(CHANGE_PASSWORD_MH_CERTIFICATE);
  const [UpadteMhUserpassword] = useMutation(UPDATE_MH_USER_PASSWORD);

  const formik = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      "api-password": "",
      "api-password-confirm": "",
      "certificate-password": "",
      "certificate-password-confirm": "",
      environment: enviroment,
    },

    onSubmit: async (values) => {
      await ChangePasswordMhCertificate({
        variables: { newPassword: values["certificate-password"], environment: enviroment },
      })
        .then((res) => {
          if (res.data.changePasswordMhCertificate !== undefined) {
            res.data.changePasswordMhCertificate.isSuccess && toast.success("La contraseña del certificado se actualizó correctamente");
            !res.data.changePasswordMhCertificate.isSuccess && toast.error("Hubo un error al actualizar la contraseña del certificado \n " + res.data.changePasswordMhCertificate.message.message);
          } else {
            toast.error("Hubo un error al actualizar la contraseña del certificado. La respuesta es undefined.");
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.log(err);
          toast.error(`Error al actualizar la contraseña del certificado`);
        });
      await UpadteMhUserpassword({ variables: { password: values["api-password"], environment: enviroment } })
        .then((res) => {
          res.data.upadteMhUserpassword.isSuccess && toast.success("La contraseña de la API se actualizó correctamente");
          !res.data.upadteMhUserpassword.isSuccess && toast.error("Hubo un error al actualizar la contraseña de la API \n " + res.data.upadteMhUserpassword.message.message);
        })
        .catch((err) => {
          toast.error(`Error al actualizar la contraseña de la API`);
        });
    },
    validationSchema: Yup.object({
      "api-password": Yup.string().required("La contraseña es un campo requerido"),
      "api-password-confirm": Yup.string()
        .required("La confirmación de la contraseña es un campo requerido")
        .oneOf([Yup.ref("api-password"), null], "Las contraseñas no son iguales"),
      "certificate-password": Yup.string().required("La contraseña del certificado es un campo requerido"),
      "certificate-password-confirm": Yup.string()
        .required("La confirmación de la contraseña es un campo requerido")
        .oneOf([Yup.ref("certificate-password"), null], "Las contraseñas no son iguales"),
    }),
  });

  useEffect(() => {
    formik.resetForm();
  }, [enviroment]);
  return (
    <>
      <MDBox mt={5} mb={9}>
        <Grid container direction="column" gap={5}>
          <Grid container justifyContent="center" gap={4}>
            <Grid item xs={12} lg={10}>
              <Card>
                <MDBox p={4}>
                  <MDBox mb={5}>
                    <MDTypography color="info" variant="h4" fontWeight="bold" mb={2}>
                      Configuración del certificado
                    </MDTypography>
                  </MDBox>
                  <Grid container gap={5} wrap="nowrap" direction={{ xs: "column", lg: "row" }}>
                    <Grid item lg={6}>
                      <MDBox mb={5}>
                        <MDTypography
                          mb={2}
                          color="info"
                          variant="h5"
                          fontWeight="bold"
                          // mb={2}
                          align="center"
                        >
                          Certificado
                        </MDTypography>
                        <p>
                          Puedes ver un video para saber como obtener el certificado desde el portal del Ministerio de Hacienda. Para saber como {""}
                          <a href="/">
                            <MDTypography variant="string" color="error" fontWeight="medium">
                              haz click aqui
                            </MDTypography>
                          </a>
                        </p>
                      </MDBox>
                      {enviroment === ChooseEnvironment.EMISIONES ? (
                        <CustomDropZone
                          height={200}
                          env={enviroment}
                          handleDrop={async (file) => {
                            const formData = new FormData();
                            formData.append("file", file[0]);

                            axios
                              .post(`${process.env.REACT_APP_API_URL}/certificate/company/${company.id}/env/prod`, formData, {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                  Authorization: `Bearer ${token}`,
                                },
                              })
                              .then((res) => {
                                setHasUploadedCertificate(true);
                                res.data.isSuccess && toast.success(res.data.message ? res.data.message : "Certificado cargado con éxito");
                                !res.data.isSuccess && toast.error(res.data.detail ? res.data.detail : "Hubo un error al cargar el certificado");
                              })
                              .catch((err) => {
                                setHasUploadedCertificate(false);
                                toast.error(
                                  `
                              ${err.response.data.message.message ? err.response.data.message.detail : "Error al cargar el archivo"}`.trim()
                                );
                              });
                          }}
                        />
                      ) : (
                        <CustomDropZone
                          height={200}
                          env={enviroment}
                          handleDrop={async (file) => {
                            const formData = new FormData();
                            formData.append("file", file[0]);
                            axios
                              .post(`${process.env.REACT_APP_API_URL}/certificate/company/${company.id}/env/test`, formData, {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                  Authorization: `Bearer ${token}`,
                                },
                              })
                              .then((res) => {
                                setHasUploadedCertificate(true);
                                res.data.isSuccess && toast.success(res.data.message ? res.data.message : "Certificado cargado con éxito");
                                !res.data.isSuccess && toast.error(res.data.detail ? res.data.detail : "Hubo un error al cargar el certificado");
                              })
                              .catch((err) => {
                                setHasUploadedCertificate(false);
                                toast.error(
                                  `
                              ${err.response?.data?.message?.message ? err?.response?.data?.message?.detail : "Error al cargar el archivo"}`.trim()
                                );
                              });
                          }}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sx={{
                        opacity: !hasUploadedCertificate ? "40%" : "100%",
                        pointerEvents: !hasUploadedCertificate ? "none" : "auto",
                      }}
                    >
                      <MDBox mb={5}>
                        <MDTypography
                          mb={2}
                          color="info"
                          variant="h5"
                          fontWeight="bold"
                          // s
                          align="center"
                        >
                          Contraseña del certificado
                        </MDTypography>
                        <p>
                          Necesitamos la contraseña privada que agregastes en el portal del Ministerio de Hacienda al momento de generar el certificado. Para saber como obtenerla
                          <a href="/">
                            <MDTypography variant="string" color="error" fontWeight="medium">
                              {""} haz click aqui
                            </MDTypography>
                          </a>
                        </p>
                      </MDBox>

                      <Grid item mb={4}>
                        <MDInput
                          name="certificate-password"
                          label="Contraseña privada del certificado"
                          value={formik.values["certificate-password"]}
                          type="password"
                          fullWidth
                          autoComplete="off"
                          disabled={!hasUploadedCertificate}
                          onBlur={() => {
                            formik.setFieldTouched("certificate-password", true);
                          }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(e.target.name, e.target.value);
                          }}
                        />
                        <CustomFieldError name="certificate-password" errorName={formik.errors["certificate-password"]} touched={formik.touched["certificate-password"]} />
                      </Grid>

                      <Grid item>
                        <MDInput
                          name="certificate-password-confirm"
                          label="Confirma la contraseña privada del certificado"
                          value={formik.values["certificate-password-confirm"]}
                          type="password"
                          autoComplete="off"
                          fullWidth
                          disabled={!hasUploadedCertificate}
                          onBlur={() => {
                            formik.setFieldTouched("certificate-password-confirm", true);
                          }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(e.target.name, e.target.value);
                          }}
                        />
                        <CustomFieldError name="certificate-password-confirm" errorName={formik.errors["certificate-password-confirm"]} touched={formik.touched["certificate-password-confirm"]} />
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" gap={4}>
            <Grid item xs={12} lg={10}>
              <Card>
                <MDBox p={4}>
                  <MDBox mb={5}>
                    <MDTypography color="info" variant="h4" fontWeight="bold" mb={2}>
                      Credenciales de la API
                    </MDTypography>
                    <p>
                      Puedes ver {""}
                      <a href="/">
                        <MDTypography variant="string" color="error" fontWeight="medium">
                          aqui
                        </MDTypography>
                      </a>
                      {""} un video donde te explicamos como cambiar las contraseñas en el Portal del Ministerio de Hacienda.
                      <br />
                      Una vez realizada esta operación digítala en campo de texto abajo para que nosotros podamos comprobar la conexión con dicha entidad de gobierno.
                    </p>
                  </MDBox>
                  <Grid
                    container
                    justifyContent="center"
                    gap={4}
                    sx={{
                      opacity: !hasUploadedCertificate ? "40%" : "100%",
                      pointerEvents: !hasUploadedCertificate ? "none" : "auto",
                    }}
                  >
                    <Grid item mb={2} xs={12} lg={4}>
                      <MDInput
                        name="api-password"
                        label="Contraseña de la API"
                        value={formik.values["api-password"]}
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        disabled={!hasUploadedCertificate}
                        onBlur={() => {
                          formik.setFieldTouched("api-password", true);
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                      <CustomFieldError name="api-password" errorName={formik.errors["api-password"]} touched={formik.touched["api-password"]} />
                    </Grid>
                    <Grid item mb={2} xs={12} lg={4}>
                      <MDInput
                        name="api-password-confirm"
                        label="Confirma contraseña de la API"
                        value={formik.values["api-password-confirm"]}
                        type="password"
                        autoComplete="off"
                        fullWidth
                        disabled={!hasUploadedCertificate}
                        onBlur={() => {
                          formik.setFieldTouched("api-password-confirm", true);
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                      <CustomFieldError name="api-password-confirm" errorName={formik.errors["api-password-confirm"]} touched={formik.touched["api-password-confirm"]} />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" gap={4}>
            <Grid item xs={12} lg={10}>
              <Grid container wrap="nowrap" my={5} gap={4}>
                <MDButton
                  onClick={() => {
                    setHasUploadedCertificate(false);

                    formik.resetForm();
                  }}
                  variant="outlined"
                  color="info"
                  fullWidth
                >
                  Cancelar
                </MDButton>
                <MDButton variant="contained" color="info" fullWidth disabled={Object.values(formik.errors).length > 0 ? true : false} onClick={() => formik.handleSubmit()}>
                  Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox
        px={2}
        py={1}
        sx={(theme) => {
          return { position: "fixed", bottom: 0, right: 0, margin: 2, backgroundColor: enviroment === ChooseEnvironment.EMISIONES ? theme.palette.success.main : theme.palette.warning.main, borderRadius: theme.borders.borderRadius };
        }}
      >
        <MDTypography variant="button" color="white" fontWeight="medium" sx={{ display: "block" }}>
          Ambiente actual: {enviroment === ChooseEnvironment.EMISIONES ? "Producción" : "Pruebas"}
        </MDTypography>
      </MDBox>
    </>
  );
};

export default Credenciales;
