export const getTime = () => {
  const hours = new Date().getHours();
  const minute = new Date().getMinutes();
  const seconds = new Date().getSeconds();
  const hoursParsed = String(hours).length === 1 ? "0" + String(hours).slice(0) : hours;
  const minuteParsed = String(minute).length === 1 ? "0" + String(minute).slice(0) : minute;
  const secondsParsed = String(seconds).length === 1 ? "0" + String(seconds).slice(0) : seconds;

  return `${hoursParsed}:${minuteParsed}:${secondsParsed}`;
};
