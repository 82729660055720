import { useEffect, useState } from "react";

// @mui material component
import { Autocomplete, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Graphql
import { useLazyQuery } from "@apollo/client";

// Queries
import { GET_CLIENTS } from "apollo/queries/clients/getClients";
import { GET_GENERAL_DOCUMENTS } from "apollo/queries/dtes/getGeneralDocuments";

//Types
import { FormikValues } from "layouts/ventas/verDtesRechazados/components/DtesRechazadosTable";
import { DTE_INFO } from "../../facturacion/components/SellsTable";
import { FormikProps } from "formik";
import { PAGINATION } from "constants/pagination";
import { RazonDeInvalidacion } from "types/InvalidDtesType";

// Utils
import { getNumControl } from "utils/getNumControl";

interface Props {
  dteInformation: DTE_INFO;
  formik: FormikProps<FormikValues>;
  dataInvalidateGeneralDocument: any;
  dataInvalidateDTEDocument: any;
  handleCloseDelete: any;
}
function Step3({ dteInformation, formik, dataInvalidateGeneralDocument, handleCloseDelete, dataInvalidateDTEDocument }: Props) {
  const [DTEs, setDTEs] = useState([]);

  const [getClients, { data: dataClients, loading, error, refetch }] = useLazyQuery(GET_CLIENTS);
  const [getGeneralDocuments, dataGetGeneralDocuments] = useLazyQuery(GET_GENERAL_DOCUMENTS);

  useEffect(() => {
    setDTEs([]);
    if (formik.values.client?.id && formik.values.client?.id !== 0) {
      if (formik.values.razonDeInvalidacion !== RazonDeInvalidacion.YA_NO_SE_VA_A_LLEVAR_A_CABO_LA_OPERACION && formik.values.razonDeInvalidacion !== "") {
        getGeneralDocuments({
          variables: {
            filters: {
              pagination: PAGINATION,
              idClient: formik.values.client?.id,
              status: "APROBADO",
            },
          },
        }).then((data) => {
          if (data.data.getGeneralDocuments.isSuccess) {
            const DTEsFilter = data.data.getGeneralDocuments.data
              .filter((item: any) => item.id !== dteInformation.idDte)
              .filter((item: any) => item.ivaDocument.extended_name_document === dteInformation.ivaDocumentName)
              .map((item: any) => ({ label: getNumControl(item?.dteControlNumber), generationCodeDte: item.generationCodeDte }));
            setDTEs(DTEsFilter);
          }
        });
      }
    }
  }, [formik.values.client?.id, formik.values.razonDeInvalidacion]);

  useEffect(() => {
    getClients({
      variables: {
        clientFilters: {
          pagination: PAGINATION,
        },
      },
    });
  }, []);
  return (
    <>
      <DialogTitle
        children={
          <Typography color="info" variant="h4" fontWeight="bold">
            Detalles de la anulacion
          </Typography>
        }
      />
      <DialogContent>
        <MDBox>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
            Confirma que quieres anular el{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`${dteInformation.ivaDocumentName} N° ${dteInformation.nroDTE}`}
            </MDTypography>{" "}
            del cliente llamado{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`"${dteInformation.clientName.label}".`}{" "}
            </MDTypography>
            de fecha {dteInformation.date} por un monto de ${dteInformation.amount}. Debido a que:
          </MDTypography>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={["ERROR EN LA INFORMACION DEL DTE", "YA NO SE VA A LLEVAR A CABO LA OPERACION", "OTRO"]}
              value={formik.values.razonDeInvalidacion}
              onChange={(e, value) => formik.setFieldValue("razonDeInvalidacion", value)}
              onBlur={() => formik.setFieldTouched("razonDeInvalidacion", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="¿Por que anulas el documento?"
                  error={Boolean(formik.errors.razonDeInvalidacion) && Boolean(formik.touched.razonDeInvalidacion)}
                  helperText={!formik.touched.razonDeInvalidacion || (formik.touched.razonDeInvalidacion && !formik.errors.razonDeInvalidacion) ? " " : `${formik.errors.razonDeInvalidacion}`}
                  variant="standard"
                />
              )}
            />
          </Grid>
          {formik.values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || formik.values.razonDeInvalidacion === "OTRO" ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={
                    loading
                      ? [{ label: "Cargando...", id: 0 }]
                      : dataClients?.getClients?.isSuccess
                      ? dataClients?.getClients?.data.map((item: any) => ({
                          label: item.name,
                          id: item.id,
                        }))
                      : [{ label: "No hay Clientes", id: 0 }]
                  }
                  getOptionDisabled={(option) => option.label === "Cargando..." || option.label === "No hay Clientes" || option.id === 0}
                  loading={loading}
                  value={formik.values.client}
                  onChange={(e, value) => formik.setFieldValue("client", value)}
                  onBlur={() => formik.setFieldTouched("client", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nombre del Cliente"
                      error={Boolean(formik.errors.client) && Boolean(formik.touched.client)}
                      helperText={!formik.touched.client || (formik.touched.client && !formik.errors.client) ? " " : `${formik.errors.client}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={
                    dataGetGeneralDocuments?.data === undefined
                      ? ["Escoja un cliente primero"]
                      : dataGetGeneralDocuments?.loading
                      ? ["Cargando..."]
                      : DTEs.length === 0 && formik.values.client?.id !== 0
                      ? ["No hay registros"]
                      : dataGetGeneralDocuments?.data?.getGeneralDocuments?.isSuccess
                      ? DTEs
                      : ["Error al cargar los registros"]
                  }
                  loading={dataGetGeneralDocuments?.loading}
                  getOptionDisabled={(option) => option === "Cargando..." || option === "No hay registros" || option === "Escoja un cliente primero" || option === "Error al cargar los registros"}
                  value={formik.values.nroDTEReemplazo}
                  onChange={(e, value) => formik.setFieldValue("nroDTEReemplazo", value)}
                  onBlur={() => formik.setFieldTouched("nroDTEReemplazo", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="¿Numero del DTE de reemplazo"
                      error={Boolean(formik.errors.nroDTEReemplazo) && Boolean(formik.touched.nroDTEReemplazo)}
                      helperText={!formik.touched.nroDTEReemplazo || (formik.touched.nroDTEReemplazo && !formik.errors.nroDTEReemplazo) ? " " : `${formik.errors.nroDTEReemplazo}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripcion"
              multiline
              maxRows={4}
              name="description"
              error={Boolean(formik.errors.description) && formik.touched.description}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched("description", true)}
              helperText={!formik.touched.description || (formik.touched.description && !formik.errors.description) ? " " : formik.errors.description}
              variant="standard"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleCloseDelete();
          }}
          variant="outlined"
          color="info"
        >
          Cancelar
        </MDButton>
        <MDButton
          disabled={
            dataInvalidateGeneralDocument?.loading ||
            dataInvalidateDTEDocument?.loading ||
            Boolean(formik.errors.razonDeInvalidacion) ||
            formik.values.razonDeInvalidacion === "" ||
            Boolean(formik.errors.description) ||
            formik.values.description === "" ||
            formik.values.razonDeInvalidacion !== "YA NO SE VA A LLEVAR A CABO LA OPERACION"
              ? Boolean(formik.errors.nroDTEReemplazo) || formik.values.nroDTEReemplazo?.label === "" || Boolean(formik.errors.client) || formik.values.client?.label === ""
              : false
          }
          onClick={() => {
            formik.handleSubmit();
          }}
          startIcon={dataInvalidateGeneralDocument?.loading || dataInvalidateDTEDocument?.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
          variant="contained"
          type="submit"
          color="error"
        >
          {dataInvalidateGeneralDocument?.loading || dataInvalidateDTEDocument?.loading ? "Anulando..." : "Anular"}
        </MDButton>
      </DialogActions>
    </>
  );
}

export default Step3;
