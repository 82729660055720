import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

//Graphql
import { useQuery } from "@apollo/client";

//Custom components
import DropZoneImg from "./DropZoneImg";

//Alerts
import { toast } from "react-hot-toast";

// Queries
import { GET_MEASUREMENTS } from "apollo/queries/measurements/getMeasurements";
import { GET_GROUPS } from "apollo/queries/getGroups";

// Types
import { LabelandId } from "types/labelAndId";
import { FileWithPath } from "react-dropzone";
import { FormikProps } from "formik";
import { SerializationKeys } from "types/apollo";
import { ArticleTypes, ValuesArticulos } from "types/Articulos";
import { PAGINATION } from "constants/pagination";

interface Props {
  formik: FormikProps<ValuesArticulos>;
  setItemDeletePhoto?: React.Dispatch<React.SetStateAction<any[]>>;
  setItemAddPhoto?: React.Dispatch<React.SetStateAction<any[]>>;
}

function GeneralesArticulos({ formik, setItemDeletePhoto, setItemAddPhoto }: Props) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries
  const dataMeasurementUnit = useQuery(GET_MEASUREMENTS, { context, variables: { measurementsFilters: { pagination: PAGINATION } } });
  const dataGroups = useQuery(GET_GROUPS, { context, variables: { groupsFilters: { pagination: PAGINATION } } });

  //States
  const [measurementUnit, setMeasurementUnit] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [groups, setGroups] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);

  //Effects
  useEffect(() => {
    if (dataMeasurementUnit?.data) {
      setMeasurementUnit(
        dataMeasurementUnit.data.getMeasurements?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataMeasurementUnit.data]);

  useEffect(() => {
    if (dataGroups?.data) {
      setGroups(
        dataGroups.data.getGroups?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGroups.data]);

  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Datos Generales
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={formik.values.type === ArticleTypes.SERVICIOS ? 3 : 1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.name) && formik.touched.name}
              onBlur={() => formik.setFieldTouched("name", true)}
              label={`${formik.values.type === ArticleTypes.SERVICIOS ? "Nombre del Servicio" : "Nombre del Articulo"}`}
              name="name"
              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="operationType"
                  freeSolo
                  options={["Exentas", "Gravadas", "No Sujetas"]}
                  value={formik.values.operationType}
                  onChange={(e, value) => formik.setFieldValue("operationType", value)}
                  onBlur={() => formik.setFieldTouched("operationType", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Preferencia de Facturacion"
                      error={Boolean(formik.errors.operationType) && Boolean(formik.touched.operationType)}
                      helperText={!formik.touched.operationType || (formik.touched.operationType && !formik.errors.operationType) ? " " : `${formik.errors.operationType}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="type"
                  freeSolo
                  options={["Bienes", "Servicios", "Ambos", "Otro"]}
                  value={formik.values.type}
                  onChange={(e, value) => formik.setFieldValue("type", value)}
                  onBlur={() => formik.setFieldTouched("type", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de Articulo"
                      error={Boolean(formik.errors.type) && Boolean(formik.touched.type)}
                      helperText={!formik.touched.type || (formik.touched.type && !formik.errors.type) ? " " : `${formik.errors.type}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {formik.values.type !== ArticleTypes.SERVICIOS && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    error={Boolean(formik.errors.code) && formik.touched.code}
                    onBlur={() => formik.setFieldTouched("code", true)}
                    label="Codigo Interno"
                    name="code"
                    helperText={!formik.touched.code || (formik.touched.code && !formik.errors.code) ? " " : `${formik.errors.code}`}
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    error={Boolean(formik.errors.barCode) && formik.touched.barCode}
                    onBlur={() => formik.setFieldTouched("barCode", true)}
                    label="Codigo de Barras"
                    name="barCode"
                    helperText={!formik.touched.barCode || (formik.touched.barCode && !formik.errors.barCode) ? " " : `${formik.errors.barCode}`}
                    value={formik.values.barCode}
                    onChange={formik.handleChange}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {formik.values.type !== ArticleTypes.SERVICIOS && (
                <Grid item xs={12} md={formik.values.type !== ArticleTypes.SERVICIOS ? 6 : 12}>
                  <Autocomplete
                    id="measurementUnit"
                    freeSolo
                    options={measurementUnit ?? [{ label: "No Options", id: 0 }]}
                    value={formik.values.measurementUnit}
                    getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                    onChange={(e, value) => formik.setFieldValue("measurementUnit", value)}
                    onBlur={() => formik.setFieldTouched("measurementUnit", true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unidad de Medida"
                        error={Boolean(formik.errors.measurementUnit) && Boolean(formik.touched.measurementUnit)}
                        helperText={!formik.touched.measurementUnit || (formik.touched.measurementUnit && !formik.errors.measurementUnit) ? " " : `${formik.errors.measurementUnit}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={formik.values.type !== ArticleTypes.SERVICIOS ? 6 : 12}>
                <Autocomplete
                  id="groups"
                  multiple
                  freeSolo
                  options={groups}
                  value={formik.values.groups}
                  getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                  onChange={(e, values) => {
                    if (values.length < formik.values?.groups.length) {
                      return formik.setFieldValue("groups", values);
                    }
                    values.map((value) => {
                      if (typeof value === "object") {
                        if (!formik.values?.groups.find((item) => item.id == value.id)) {
                          return formik.setFieldValue("groups", values);
                        }
                      }
                    });
                  }}
                  onBlur={() => formik.setFieldTouched("groups", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grupos"
                      error={Boolean(formik.errors.groups) && Boolean(formik.touched.groups)}
                      helperText={!formik.touched.groups || (formik.touched.groups && !formik.errors.groups) ? " " : `${formik.errors.groups}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={`${formik.values.type === ArticleTypes.SERVICIOS ? "Descripcion del Servicio" : "Descripcion del Articulo"}`}
              multiline
              maxRows={4}
              name="description"
              error={Boolean(formik.errors.description) && formik.touched.description}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched("description", true)}
              helperText={!formik.touched.description || (formik.touched.description && !formik.errors.description) ? " " : formik.errors.description}
              variant="standard"
            />
          </Grid>
          {formik.values.type !== ArticleTypes.SERVICIOS && (
            <Grid item xs={12}>
              <DropZoneImg
                handleDrop={(files: FileWithPath[]) => {
                  const formikPhotos = formik.values.photos;
                  if (files.length > 0 && formikPhotos.length === 3) {
                    toast.error("Cantidad maxima de archivos, inteta eliminar archivos");
                    return;
                  }
                  if ((formikPhotos.length === 2 && files.length === 1) || (formikPhotos.length === 1 && files.length <= 2) || (formikPhotos.length === 0 && files.length < 4)) {
                    setItemAddPhoto(files);
                    formik.setFieldValue("photos", [...formikPhotos, ...files]);
                    return;
                  } else {
                    toast.error("Muchos archivos, intenta con menos archivos");
                    return;
                  }
                }}
                formik={formik}
                setItemDeletePhoto={setItemDeletePhoto}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </>
  );
}
export default GeneralesArticulos;
