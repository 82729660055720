// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// Services
import { tagsService } from "services/tags-service";
import { groupsService } from "services/groups-service";
import { measurementService } from "services/measurement-service";
import { articleService } from "services/article-service";

// Custom components
import ImportarArticle from "./Imports";

function GetImportComponent({ step, companyId }: { step: number; companyId: number }): JSX.Element {
  const titles = ["Importar Articulos", "Importar Caracteristicas", "Importar Grupos", "Importar Unidades de Medida"];

  const descriptions = [
    "En esta parte podras importar los articulos que quieras, pero debes de cumplir el formato que puedes descargar en el siguiente boton:",
    "En esta parte podras importar las caracteristicas que quieras, pero debes de cumplir el formato que puedes descargar en el siguiente boton:",
    "En esta parte podras importar los grupos que quieras, pero debes de cumplir el formato que puedes descargar en el siguiente boton:",
    "En esta parte podras importar las unidades de medida que quieras, pero debes de cumplir el formato que puedes descargar en el siguiente boton:",
  ];

  const getService = (step: number): any => {
    const services = [articleService, tagsService, groupsService, measurementService];
    return services[step];
  };
  return (
    <ImportarArticle companyId={companyId} service={getService(step)}>
      <>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          {titles[step]}
        </MDTypography>

        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {descriptions[step]}
        </MDTypography>
      </>
    </ImportarArticle>
  );
}

export default GetImportComponent;
