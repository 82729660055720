//@mui material components
import { ButtonBase } from "@mui/material";

// Theme
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom component
import IconExport from "./components/IconExport";

const buttonStyles = {
  button: {
    borderRadius: "8px",
    color: colors.info.main,
    padding: 1,
    borderColor: colors.info.main,
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
  },
};

interface Props {
  children: JSX.Element;
  service?: () => {};
  name: string;
}

function ExportArticle({ children, service, name }: Props) {
  return (
    <>
      <MDBox>
        {children}
        <MDBox display="flex" flexDirection="column" alignContent="center" my={2}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonBase sx={{ ...buttonStyles.button, flexDirection: "column" }}>
              <MDTypography variant="h6" color="info">
                Descargar
              </MDTypography>
              <IconExport name={name} />
            </ButtonBase>
          </div>
        </MDBox>
      </MDBox>
    </>
  );
}
export default ExportArticle;
