// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { TextField } from "@mui/material";

//Libs
import { useFormikContext } from "formik";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const Textarea = () => {
  const { setFieldValue } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  return (
    <Grid id="test" container>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex" flexDirection="column" borderRadius="lg" gap={3} p={5}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Observaciones
            </MDTypography>
            <MDTypography color="secondary" variant="subtitle2">
              Incluye observaciones adicionales o notas importantes relacionadas con este DTE
            </MDTypography>
          </MDBox>
          <MDBox width="100%">
            <TextField
              onChange={(e) => {
                setFieldValue("extension.observations", e.target.value);
              }}
              placeholder=""
              sx={{ width: "100%" }}
              multiline
              rows={2}
              maxRows={4}
            />
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
};

export default Textarea;
