import gql from "graphql-tag";

export const GET_DTE_BY_ID = gql`
  query GetDteFile($id: Float!) {
    getDteFiles(idDte: $id) {
      isSuccess
      data {
        idSale
        generationCode
        urlPdf
        jsonDte
        transmissionType
        modelType
        history {
          email_destinatarios
          statusReception
          fecha
          eventType
        }
        clientCompany {
          id
          name
          contribuyenteType
          phone
          address
          plazoType
          tradename
          plazoType
          legalRepresentation
          address
          lineOfBusiness {
            name
          }
          emailNotifications
          retention
          perception
          isActiveCredit
          creditLimit
          excelse
          creditPeriod
          userDocuments {
            id
            documentNumber
            type
            code
          }
        }
      }
      message {
        code
        detail
      }
    }
  }
`;
