import { useEffect } from "react";

// @mui material components
import { ButtonBase, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, SvgIcon } from "@mui/material";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Formik
import { FormikProps } from "formik";

//Types
import { ClientORCompanyForm } from "types/clientsForm";

interface Props {
  formik: FormikProps<ClientORCompanyForm>;
}

function Tipo({ formik }: Props) {
  return (
    <>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mt={2.5} mb={{ xs: 3, md: 6 }}>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Estructura Legal
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Selecciona la clasificacion legal que tu cliente posee:
        </MDTypography>
      </MDBox>
      <MDBox display="flex">
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.legalRepresentation === "PERSONA_NATURAL_NO_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.legalRepresentation === "PERSONA_NATURAL_NO_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="PERSONA_NATURAL_NO_IVA"
                onClick={(e) => {
                  formik.setFieldValue("legalRepresentation", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 90 72"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M22.5002 18C17.5361 18 13.5002 13.9641 13.5002 9C13.5002 4.03594 17.5361 0 22.5002 0C27.4643 0 31.5002 4.03594 31.5002 9C31.5002 13.9641 27.4643 18 22.5002 18ZM12.3752 67.5V56.25H9.87208C8.33927 56.25 7.25646 54.7453 7.73458 53.2828L12.108 40.1484L8.08614 45.4641C6.58146 47.4469 3.75489 47.8266 1.78614 46.3359C-0.182606 44.8453 -0.576356 42.0188 0.914269 40.0359L9.19708 29.1094C12.3471 24.9469 17.283 22.5 22.5002 22.5C27.7174 22.5 32.6533 24.9469 35.8033 29.1094L44.0861 40.0359C45.5908 42.0188 45.1971 44.8453 43.2143 46.3359C41.2315 47.8266 38.4049 47.4469 36.9143 45.4641L32.8924 40.1484L37.2658 53.2828C37.758 54.7453 36.6611 56.25 35.1283 56.25H32.6252V67.5C32.6252 69.9891 30.6143 72 28.1252 72C25.6361 72 23.6252 69.9891 23.6252 67.5V56.25H21.3752V67.5C21.3752 69.9891 19.3643 72 16.8752 72C14.3861 72 12.3752 69.9891 12.3752 67.5ZM67.5002 18C62.5361 18 58.5002 13.9641 58.5002 9C58.5002 4.03594 62.5361 0 67.5002 0C72.4643 0 76.5002 4.03594 76.5002 9C76.5002 13.9641 72.4643 18 67.5002 18ZM66.3752 54V67.5C66.3752 69.9891 64.3643 72 61.8752 72C59.3861 72 57.3752 69.9891 57.3752 67.5V42.2578L55.5611 45.1406C54.2393 47.25 51.4549 47.8688 49.3596 46.5469C47.2643 45.225 46.6315 42.4406 47.9533 40.3453L55.2236 28.8C57.6986 24.8766 62.0158 22.4859 66.6424 22.4859H68.3721C73.0127 22.4859 77.3299 24.8625 79.7908 28.8L87.0611 40.3594C88.383 42.4688 87.7502 45.2391 85.6549 46.5609C83.5596 47.8828 80.7752 47.25 79.4533 45.1547L77.6252 42.2578V67.5C77.6252 69.9891 75.6143 72 73.1252 72C70.6361 72 68.6252 69.9891 68.6252 67.5V54H66.3752Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.legalRepresentation === "PERSONA_NATURAL_NO_IVA" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Persona Natural (No Inscrita IVA)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="PERSONA_NATURAL_IVA"
                onClick={(e) => {
                  formik.setFieldValue("legalRepresentation", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 90 103"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M45 0C59.2031 0 70.7143 11.5112 70.7143 25.7143C70.7143 39.9174 59.2031 51.4286 45 51.4286C30.7969 51.4286 19.2857 39.9174 19.2857 25.7143C19.2857 11.5112 30.7969 0 45 0ZM42.0067 72.1607L38.2701 65.933C36.9844 63.7835 38.5312 61.0714 41.0223 61.0714H45H48.9576C51.4487 61.0714 52.9955 63.8036 51.7098 65.933L47.9732 72.1607L54.683 97.0513L62.6183 64.6674C78.1272 67.0781 90 80.4978 90 96.6897C90 100.105 87.2277 102.857 83.8326 102.857H53.2567H36.7433H6.16741C2.77232 102.857 0 100.085 0 96.6897C0 80.4978 11.8728 67.0781 27.3817 64.6674L35.317 97.0513L42.0268 72.1607H42.0067Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Persona Natural (Inscrita en IVA)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.legalRepresentation === "JURIDICA" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.legalRepresentation === "JURIDICA" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="JURIDICA"
                onClick={(e) => {
                  formik.setFieldValue("legalRepresentation", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 90 72"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M6.75 0C3.02344 0 0 3.02344 0 6.75V65.25C0 68.9766 3.02344 72 6.75 72H20.25V60.75C20.25 57.0234 23.2734 54 27 54C30.7266 54 33.75 57.0234 33.75 60.75V72H46.3922C45.5062 70.5656 45 68.8781 45 67.0641C45 60.4688 48.6281 54.7172 54 51.7078V38.2219V6.75C54 3.02344 50.9766 0 47.25 0H6.75ZM9 33.75C9 32.5125 10.0125 31.5 11.25 31.5H15.75C16.9875 31.5 18 32.5125 18 33.75V38.25C18 39.4875 16.9875 40.5 15.75 40.5H11.25C10.0125 40.5 9 39.4875 9 38.25V33.75ZM24.75 31.5H29.25C30.4875 31.5 31.5 32.5125 31.5 33.75V38.25C31.5 39.4875 30.4875 40.5 29.25 40.5H24.75C23.5125 40.5 22.5 39.4875 22.5 38.25V33.75C22.5 32.5125 23.5125 31.5 24.75 31.5ZM36 33.75C36 32.5125 37.0125 31.5 38.25 31.5H42.75C43.9875 31.5 45 32.5125 45 33.75V38.25C45 39.4875 43.9875 40.5 42.75 40.5H38.25C37.0125 40.5 36 39.4875 36 38.25V33.75ZM11.25 13.5H15.75C16.9875 13.5 18 14.5125 18 15.75V20.25C18 21.4875 16.9875 22.5 15.75 22.5H11.25C10.0125 22.5 9 21.4875 9 20.25V15.75C9 14.5125 10.0125 13.5 11.25 13.5ZM22.5 15.75C22.5 14.5125 23.5125 13.5 24.75 13.5H29.25C30.4875 13.5 31.5 14.5125 31.5 15.75V20.25C31.5 21.4875 30.4875 22.5 29.25 22.5H24.75C23.5125 22.5 22.5 21.4875 22.5 20.25V15.75ZM38.25 13.5H42.75C43.9875 13.5 45 14.5125 45 15.75V20.25C45 21.4875 43.9875 22.5 42.75 22.5H38.25C37.0125 22.5 36 21.4875 36 20.25V15.75C36 14.5125 37.0125 13.5 38.25 13.5ZM81 38.25C81 32.0344 75.9656 27 69.75 27C63.5344 27 58.5 32.0344 58.5 38.25C58.5 44.4656 63.5344 49.5 69.75 49.5C75.9656 49.5 81 44.4656 81 38.25ZM49.5 67.0922C49.5 69.8063 51.6937 72 54.4078 72H85.0922C87.8062 72 90 69.8063 90 67.0922C90 59.8641 84.1359 54 76.9078 54H62.5922C55.3641 54 49.5 59.8641 49.5 67.0922Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.legalRepresentation === "JURIDICA" ? "info" : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Persona Juridica (Empresas)
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mb={2.5} mt={{ xs: 1, md: 3 }}>
        <MDTypography color="info" variant="h5">
          Status del Cliente
        </MDTypography>
        <MDBox>
          <FormControl>
            <RadioGroup row defaultValue="nacional" name="clientStatus" value={formik.values.clientStatus} onChange={formik.handleChange}>
              <FormControlLabel value="nacional" control={<Radio />} label="Nacional" />
              <FormControlLabel value="extranjero" control={<Radio />} label="Extranjero" />
            </RadioGroup>
          </FormControl>
        </MDBox>
      </MDBox>
    </>
  );
}
export default Tipo;
