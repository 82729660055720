export enum Environment {
  PruebasMH = "Pruebas M.H.",
  EmisionesReales = "Emisiones Reales",
  PruebasInternas = "Pruebas Internas",
  TESTING_MH = "TESTING_MH",
  PRODUCTION = "PRODUCTION",
  INTERNAL = "INTERNAL",
}

export enum OperationType {
  GRAVADAS = "Gravadas",
  EXENTAS = "Exentas",
  NO_SUJETAS = "No sujetas",
  No_Sujetas = "No Sujetas",
}

export enum DropdownOptions {
  SELECT_ENV = "Seleccionar entorno",
  AUTOMATIC_TEST_MH = "Pruebas Automáticas Ministerio de Hacienda",
  GENERATE_CONTINGENCY_EVENTS = "Generar Eventos de Contingencia",
  GENERATE_INVALIDATION_EVENTS = "Generar Eventos de Invalidacion",
}
