import gql from "graphql-tag";

export const UPDATE_PRICE_LIST = gql`
  mutation UPDATE_PRICE_LIST($idPriceList: Float!, $data: PriceListDti!) {
    updatePriceList(idPriceList: $idPriceList, data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
