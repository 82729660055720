import { useState } from "react";

// @mui material components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// react-router-dom
import { Link as LinkRouter, useNavigate } from "react-router-dom";

// Images
import svgVentaCompletada from "../../../../assets/icons/venta-completada.svg";

// Types
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { SaleInformation } from "types/saleInformation";

interface Props {
  crearOtroDte: () => void;
  open: boolean;
  handleClose: (value: ActiveValues) => void;
  saleInformation: SaleInformation;
}

function DialogVentaCompletada({ saleInformation, open, handleClose, crearOtroDte }: Props) {
  const navigate = useNavigate();

  // States
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open && !isLoading}
      keepMounted
      onClose={() => {
        handleClose(ActiveValues.ventaCompletada);
      }}
    >
      <DialogTitle color="info">{"La venta fue completada con exito."}</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection="column" justifyContent="center">
          <MDBox display="flex" width="100%" justifyContent="center">
            <img src={svgVentaCompletada} onLoad={handleImageLoad} />
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", gap: 2 }}>
        <MDBox sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}></MDBox>
        <Grid container sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
          <Grid item xs={5}>
            <MDButton
              fullWidth
              onClick={() => {
                navigate("/ventas");
              }}
              variant="outlined"
              color="secondary"
            >
              Ver Ventas
            </MDButton>
          </Grid>
          <Grid item xs={5}>
            <MDButton
              fullWidth
              onClick={() => {
                handleClose(ActiveValues.ventaCompletada);
                crearOtroDte();
              }}
              variant="outlined"
              color="info"
            >
              Crear Otra
            </MDButton>
          </Grid>
        </Grid>
        <MDButton
          fullWidth
          variant="contained"
          color="info"
          component={LinkRouter}
          onClick={() => {
            localStorage.setItem("documentView", JSON.stringify(saleInformation?.successInfomation));
          }}
          to={`/ventas/detalle/${saleInformation?.successInfomation?.id ?? saleInformation?.successInfomation?.idDTE}`}
          underline="none"
          target="_blank"
        >
          Ver Detalles
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogVentaCompletada;
