import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//Graphql
import { useQuery } from "@apollo/client";

// Queries
import { GET_MEASUREMENTS } from "apollo/queries/measurements/getMeasurments";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

interface Row {
  codigo: number;
  nombre: string;
  symbol: string;
}

const columns = [
  { Header: "Code", accessor: "codigo", width: "10%" },
  { Header: "Nombre", accessor: "nombre", width: "50%" },
  { Header: "Simbolo", accessor: "symbol" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];

function UnidadesDeMedida() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Graphql
  const { data, loading, error } = useQuery(GET_MEASUREMENTS, { context, variables: { measurementsFilters: { pagination: PAGINATION } } });

  //state
  const [rowsData, setRowsData] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      setRowsData(data.getMeasurements.data);
    }
  }, [data]);

  const rows: Row[] = useMemo(() => {
    return rowsData.map((item) => ({
      codigo: item?.code,
      nombre: item?.name,
      symbol: item?.symbol,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Unidades de Medida
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui podras Buscar, editar y archivar todos las unidades de medida creadas"}
        </MDTypography>
      </MDBox>
      <DataTable table={table} loading={loading} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }} />
    </>
  );
}
export default UnidadesDeMedida;
