import gql from "graphql-tag";

export const ADD_ARTICLE_TAGS = gql`
  mutation ADD_ARTICLE_TAGS($data: ArticleTagInfoDti!) {
    addArticlesTags(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
