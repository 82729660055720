import React from "react";

// @mui material components
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleCloseDelete: any;
}
function Step1({ setActiveStep, handleCloseDelete }: Props) {
  return (
    <>
      <DialogTitle sx={{ fontSize: "1.7rem" }}>{"¿Estas absolutamente seguro?"}</DialogTitle>
      <DialogContent>
        <MDBox>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            Esta operacion no eliminara el registro como tal de los registros del Ministerio de Hacienda, ni en nuestro sistema. Solamente se le cambaira el estado a{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`"Invalidado"`}.
            </MDTypography>
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            Este evento tambien podria ser sometido a verificacion del Ministerio de Hacienda que cumpla con alguna de las siguientes caracteristicas:
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            1. Error en la identificacion del cliente.
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            2. Error en el producto facturado.
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            3. Error en la fecha de operacion.
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            4. Error en el precio de los bienes o servicios siempre y cuando sean facturas de consumidor fianl o facturas de exportacion.
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            5. Por devolucion de bienes.
          </MDTypography>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" paragraph>
            6.Cuando ya no se lleve a cabo la operacion.
          </MDTypography>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleCloseDelete();
          }}
          variant="outlined"
          color="info"
        >
          cancelar
        </MDButton>
        <MDButton
          onClick={() => {
            setActiveStep(1);
          }}
          variant="contained"
          color="info"
        >
          Suiguiente
        </MDButton>
      </DialogActions>
    </>
  );
}

export default Step1;
