//@mui material components
import { SvgIcon } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function IconExport({ name }: { name: string }) {
  return (
    <MDBox display="flex" alignItems="center" mt={1}>
      <SvgIcon
        viewBox="0 0 25 35"
        sx={{
          height: "2rem",
          width: "2rem",
          flexShrink: 2,
        }}
      >
        <path
          d="M 23.7826 6.91243 L 18.9238 2.05306 C 18.138 1.27194 17.0768 0.833008 15.9766 0.833008 H 4.16667 C 1.86523 0.833008 0 2.69824 0 4.99967 L 0.000423177 29.9997 C 0.000423177 32.3005 1.86566 34.1663 4.16709 34.1663 H 20.8333 C 23.125 34.1663 25 32.2913 25 29.9997 V 9.85645 C 25 8.75619 24.5638 7.69499 23.7826 6.91243 Z M 21.875 29.9997 C 21.875 30.5749 21.4086 31.0413 20.8333 31.0413 H 4.16797 C 3.59258 31.0413 3.1263 30.5749 3.1263 29.9997 L 3.125 5.00814 C 3.125 4.43288 3.59141 3.96647 4.16667 3.96647 H 14.5833 V 9.16634 C 14.5833 10.3167 15.5163 11.2497 16.6667 11.2497 H 21.8164 V 29.9997 H 21.875 Z M 14.9154 16.0218 L 12.5 19.1208 L 10.0846 16.0218 C 9.55729 15.3382 8.58073 15.2145 7.89714 15.7484 C 7.21354 16.2757 7.08984 17.2588 7.6237 17.9424 L 10.5208 21.6663 L 7.62174 25.3936 C 7.09075 26.0752 7.21484 27.0576 7.8944 27.5869 C 8.18359 27.8057 8.51562 27.9163 8.79557 27.9163 C 9.26146 27.9163 9.72331 27.7088 10.0306 27.3141 L 12.5 24.2119 L 14.9128 27.3141 C 15.2214 27.708 15.6836 27.9163 16.1458 27.9163 C 16.4795 27.9163 16.8171 27.8085 17.1042 27.5868 C 17.7839 27.0578 17.9076 26.0751 17.3768 25.3934 L 14.4792 21.6663 L 17.3783 17.9391 C 17.9092 17.2575 17.7852 16.2751 17.1056 15.7458 C 16.4193 15.2145 15.4427 15.3382 14.9154 16.0218 Z"
          fill="#00547A"
        />
      </SvgIcon>
      <MDTypography variant="h5" fontWeight="bold" color="info">
        {name}
      </MDTypography>
    </MDBox>
  );
}

export default IconExport;
