/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";

// Declaring props types for DefaultCell
interface Props {
  value: string;
  suffix?: string | boolean;
}

function DefaultCellSellsEmployees({ value, suffix }: Props): JSX.Element {
  const getColor = (value: string) => {
    if (value === "Activo") {
      return colors.info.focus;
    }
    if (value === "No creado") {
      return "#7b809a";
    }
    return "#00547A";
  };
  return (
    <MDTypography
      variant="inherit"
      fontWeight="400"
      sx={{
        color: getColor(value),
      }}
      fontSize="0.85rem"
    >
      {value}
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

// Declaring default props for DefaultCell
DefaultCellSellsEmployees.defaultProps = {
  suffix: "",
};

export default DefaultCellSellsEmployees;
