import { useEffect, useRef, useState } from "react";

// react-router-dom
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useAddSellContext } from "context/AddSellContext";
import { useCompanyContext } from "context/CompanyContext";

// Custom Components
import InfoSection from "./InfoSection";
import InventoryConfigSelector from "./InventorySelectionModal";
import NewDteDataTable from "./NewDteDataTable";
import ResumeFloatingSection from "./ResumeFloatingSection";
import DialogRegisterDocument from "./dailogs/DialogRegisterDocument";
import DialogVentaCompletada from "./dailogs/DialogVentaCompletada";
import DialogVentaRechazada from "./dailogs/DialogVentaRechazada";
import NewDteFilters from "./filters";
import PaymentMethods from "./payments/PaymentMethodsModal";

// Layouts
import Textarea from "layouts/ventas/ver/components/Info/Textarea";

// Constants
import { initialStateActiveVentas } from "constants/sells";

// Libs
import { Form, useFormikContext } from "formik";
import toast from "react-hot-toast";
import { validateDocuments } from "utils/validateDocuments";

// Hooks
import { useHandleReSendSale } from "hooks/nuevoDTE/handlesSubmits/useHandleReSendSale";
import useSetValuesCorregirDTE from "hooks/nuevoDTE/useSetValuesCorregirDTE";
import { useBulkFexSubmit } from "hooks/nuevoDTE/handlesSubmits/useBulkFexSubmit";
import { useAddDocumentSubmit } from "hooks/nuevoDTE/handlesSubmits/useAddDocumentSubmit";
import { useBulkSubmit } from "hooks/nuevoDTE/handlesSubmits/useBulkSubmit";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { ActiveVentas } from "types/hooks/nuevoDte/activeVentas";
import { PaymentConditions } from "types/payment-conditions";
import { IvaDocumentTypes } from "types/iva-document";
import { SaleInformation } from "types/saleInformation";

const FormsNuevoDte = ({ freeSaleComp, CorregirDTE }: { freeSaleComp?: boolean; CorregirDTE?: boolean }) => {
  const objParams = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  // Constants
  const generationCode = objParams?.generationCode || state?.generationCode;
  const freeSale = freeSaleComp || state?.isSimpleBilling;

  // Context
  const {
    initialValues,
    setCompany,
    global: { products, conditionPayment },
    setGlobal,
    resetGlobal,
  } = useAddSellContext();
  const { sellsConfig } = useSellsConfigContext();
  const { company: companyContext, infoEmployeeDocuments } = useCompanyContext();
  const { resetForm, setValues, values, handleSubmit, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  // States
  const [saleInformation, setSaleInformation] = useState<SaleInformation>();
  const [activeVentas, setActiveVentas] = useState<ActiveVentas>(initialStateActiveVentas);

  // Hooks
  const { dataGetGeneralDocuments, dataClients } = useSetValuesCorregirDTE({ setValues, generationCode });
  const { handleReSendSale, dataReSendSale } = useHandleReSendSale({ freeSale, setSaleInformation, handleOpen, handleClose, dataGetGeneralDocuments });
  const { handleBulkSubmit, dataAddSaleGeneralDocument } = useBulkSubmit({ freeSale, setSaleInformation, handleOpen, handleClose });
  const { handleBulkSubmitFexDte, dataCreateFacturaExportacion } = useBulkFexSubmit({ freeSale, setSaleInformation, handleClose, handleOpen });
  const { handleSubmitRegisterDocument, dataAddDocuments } = useAddDocumentSubmit({ handleClose });

  // Refs
  const DetailFilter = useRef<HTMLInputElement>(null);
  const DetailFilterSimple = useRef<HTMLInputElement>(null);

  function handleClose(value: ActiveValues) {
    setActiveVentas((prev) => ({
      ...prev,
      [value]: false,
    }));
  }
  function handleOpen(value: ActiveValues) {
    setActiveVentas((prev) => ({
      ...prev,
      [value]: true,
    }));
  }
  const handleCloseAll = () => setActiveVentas(initialStateActiveVentas);

  const crearOtroDte = () => {
    handleCloseAll();
    resetGlobal();
    resetForm({
      values: initialValues,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    companyContext && setCompany(companyContext);
    return () => {
      setCompany(null);
    };
  }, [companyContext]);
  useEffect(() => {
    crearOtroDte();
  }, [location.href]);
  useEffect(() => {
    if (!sellsConfig?.selectedSalePoint || !sellsConfig?.costCenter) {
      handleOpen(ActiveValues.InventaryConfig);
    }
  }, [sellsConfig]);
  useEffect(() => {
    return () => {
      resetGlobal();
    };
  }, []);

  return (
    <>
      <InfoSection handleOpen={handleOpen} />

      {Boolean(CorregirDTE && dataClients && !dataGetGeneralDocuments?.loading) || Boolean(!CorregirDTE) ? (
        <MDBox mt={5} mb={9} minHeight="100vh">
          {!sellsConfig?.selectedSalePoint || !sellsConfig?.costCenter ? (
            <InventoryConfigSelector open={activeVentas.InventaryConfig} handleClose={handleClose} />
          ) : (
            <>
              <Form>
                <Grid container justifyContent="center" gap={5}>
                  <NewDteFilters refs={{ DetailFilter, DetailFilterSimple }} freeSale={freeSale} />

                  {products.length < 1 ? (
                    <></>
                  ) : (
                    <>
                      <NewDteDataTable freeSale={freeSale} />
                      <ResumeFloatingSection />

                      <Textarea />

                      {/* <ButtonsNuevoDte generationCode={generationCode} freeSale={freeSale} CorregirDTE={CorregirDTE} handleClose={handleClose} handleOpen={handleOpen} setInformationError={setInformationError} /> */}
                      <Grid container gap={5} justifyContent="center" wrap="nowrap">
                        <MDButton
                          onClick={() => {
                            setGlobal((global) => {
                              return { ...global, products: [] };
                            });
                            navigate("/ventas");
                          }}
                          size="large"
                          color="secondary"
                          sx={{ width: "50%" }}
                        >
                          Cancelar
                        </MDButton>
                        <MDButton
                          id="sendDteButton"
                          onClick={async () => {
                            if (CorregirDTE && products.length > 0) {
                              conditionPayment === PaymentConditions.CREDITO ? handleReSendSale(values, resetForm) : handleOpen(ActiveValues.paymentMethod);
                              return;
                            }
                            if (Object.keys(errors).length > 0 && products.length < 1) {
                              toast.error("Por favor completa los campos requeridos");
                              await handleSubmit();
                              return;
                            } else if (errors.extension && products.length > 0) {
                              toast.error("Por favor completa los campos requeridos");
                              await handleSubmit();
                              return;
                            }
                            conditionPayment === PaymentConditions.CREDITO
                              ? values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX
                                ? handleBulkSubmitFexDte(values, resetForm)
                                : handleBulkSubmit(values, resetForm)
                              : dataAddDocuments?.data?.addDocuments?.isSuccess
                              ? handleOpen(ActiveValues.paymentMethod)
                              : validateDocuments(infoEmployeeDocuments?.documents)
                              ? handleOpen(ActiveValues.paymentMethod)
                              : handleOpen(ActiveValues.registerDocument);
                          }}
                          size="large"
                          color="info"
                          sx={{ width: "50%" }}
                        >
                          {conditionPayment === PaymentConditions.CREDITO ? (
                            Boolean(dataAddSaleGeneralDocument.loading || dataCreateFacturaExportacion.loading || dataReSendSale.loading) ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              "Enviar"
                            )
                          ) : (
                            "Continuar"
                          )}
                        </MDButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
              <PaymentMethods
                dataGetGeneralDocuments={dataGetGeneralDocuments}
                setSaleInformation={setSaleInformation}
                handleClose={handleClose}
                open={activeVentas.paymentMethod}
                freeSale={freeSale}
                CorregirDTE={CorregirDTE}
                handleOpen={handleOpen}
              />

              <DialogRegisterDocument handleSubmitRegisterDocument={handleSubmitRegisterDocument} handleClose={handleClose} open={activeVentas.registerDocument} dataAddDocuments={dataAddDocuments} />

              <DialogVentaRechazada handleClose={handleClose} open={activeVentas.ventaRechazada} saleInformation={saleInformation} crearOtroDte={crearOtroDte} />

              <DialogVentaCompletada saleInformation={saleInformation} handleClose={handleClose} open={activeVentas.ventaCompletada} crearOtroDte={crearOtroDte} />
            </>
          )}
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" height={{ xs: "200px", lg: "400px" }}>
          <CircularProgress color="info" />
        </MDBox>
      )}
    </>
  );
};

export default FormsNuevoDte;
