import { Environment } from "types/environment";

export const getEnvironmentSubmit = (value: string | null) => {
  switch (value) {
    case Environment.EmisionesReales:
      return Environment.PRODUCTION;
    case Environment.PruebasMH:
      return Environment.TESTING_MH;
    case Environment.PruebasInternas:
      return Environment.INTERNAL;
    default:
      return value;
  }
};
