import { useState, useEffect, createContext } from "react";

type ShortcutContextType = {
  shortcuts?: Set<string>;
  registerListener: (shortcut: Set<string>, eventHandler: () => void) => void;
  triggerListener: (shortcut: Set<string>) => void;
  removeListener: (shortcut: Set<string>) => void;
};

const initialValues: ShortcutContextType = {
  shortcuts: new Set(),
  registerListener: (_: Set<string>, eventHandler: () => void) => {},
  triggerListener: (_: Set<string>) => {},
  removeListener: (_: Set<string>) => {},
};

export const ShortcutContext = createContext<ShortcutContextType>(initialValues);

const buildShortcutIndex = (keys: Set<string>) => Array.from(keys).join("+");
const ShortcutContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [shortCutMap, setShortCutMap] = useState<Map<string, () => void>>(new Map());

  const registerListener = (shortcutKey: Set<string>, eventHandler: () => void) => {
    const shortcut = buildShortcutIndex(shortcutKey);
    setShortCutMap(shortCutMap.set(shortcut, eventHandler));
  };

  const triggerListener = (heldKeys: Set<string>) => {
    const listener = shortCutMap.get(buildShortcutIndex(heldKeys));
    if (listener) {
      listener();
    }
  };

  const removeListener = (heldKeys: Set<string>) => {
    shortCutMap.delete(buildShortcutIndex(heldKeys));
  };

  return <ShortcutContext.Provider value={{ registerListener, triggerListener, removeListener }}>{children}</ShortcutContext.Provider>;
};

export default ShortcutContextProvider;
