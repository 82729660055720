import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// @mui material components
import { ButtonBase } from "@mui/material";

// Theme
import colors from "assets/theme/base/colors";

// Dropzone
import { FileWithPath } from "react-dropzone";

//Alert
import { toast } from "react-hot-toast";

//Custom components
import FileDropZone from "layouts/contactos/clientes/components/FileDropZone";
import IconImport from "./components/IconImport";

const buttonStyles = {
  button: {
    borderRadius: "8px",
    color: colors.info.main,
    padding: 1,
    borderColor: colors.info.main,
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
  },
};

interface Props {
  children: JSX.Element;
  service?: {
    downloadTemplate(idCompany: number): Promise<void>;
    handleSubmit(idCompany: number, files: any[]): Promise<void>;
  };
  companyId: number;
}
function ImportarArticle({ children, service, companyId }: Props): JSX.Element {
  const [files, setFiles] = useState<any>([]);

  return (
    <>
      <MDBox>{children}</MDBox>

      <MDBox display="flex" flexDirection="column" alignContent="center" my={2} gap={3}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonBase
            sx={{ ...buttonStyles.button, flexDirection: "column" }}
            onClick={() => {
              if (service) {
                service.downloadTemplate(companyId);
              }
            }}
          >
            <MDTypography variant="h6" color="info">
              Descargar
            </MDTypography>
            <IconImport />
          </ButtonBase>
        </div>

        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Una vez lleno por favor adjuntalo en el siguiente cuadro y oprime el boton de enviar:
        </MDTypography>

        <FileDropZone
          handleDrop={(filesAccepted: FileWithPath[]) => {
            if (filesAccepted.length > 0 && files.length === 3) {
              return toast.error("Cantidad maxima de archivos, inteta eliminar archivos");
            }
            if ((files.length === 2 && filesAccepted.length === 1) || (files.length === 1 && filesAccepted.length <= 2) || (files.length === 0 && filesAccepted.length < 4)) {
              setFiles(() => [...files, ...filesAccepted]);
            }
          }}
        />

        <MDButton variant="contained" color="info" onClick={() => service.handleSubmit(companyId, files)}>
          Enviar
        </MDButton>
      </MDBox>
    </>
  );
}
export default ImportarArticle;
