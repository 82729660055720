import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Autocomplete, Grid, InputAdornment, SvgIcon, Switch, TextField } from "@mui/material";

//Formik
import { FormikProps } from "formik";

//Graphql
import { gql, useQuery } from "@apollo/client";

//Types
import { Bodegas } from "types/bodegas";
import { PAGINATION } from "constants/pagination";

//Queries
const GET_COSTS_CENTERS = gql`
  query {
    getCostsCenters {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

interface Props {
  formik: FormikProps<Bodegas>;
}
interface Oficinas {
  id: number | null;
  label: string | null;
}

function GeneralesBodega({ formik }: Props) {
  const { data, error, loading } = useQuery(GET_COSTS_CENTERS, { variables: { pagination: PAGINATION } });
  const [oficinasRow, setOficinasRow] = useState<Oficinas[]>([{ label: "No Options", id: 0 }]);

  const handleChangeOficinas = (e: React.SyntheticEvent) => {
    formik.setValues((prev) => ({
      ...prev,
      costCenter: {
        id: oficinasRow.filter((item: any) => item.label == e?.currentTarget.textContent).map((i: any) => i.id)[0],
        label: e?.currentTarget.textContent,
      },
    }));
  };

  //Effects
  useEffect(() => {
    if (data) {
      setOficinasRow(
        data.getCostsCenters.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [data]);
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Datos Generales
        </MDTypography>
        {/* <MDTypography
          variant="subtitle2"
          fontWeight="regular"
          color="secondary"
          sx={{ fontSize: "0.875rem" }}
        >
          Esta informacion es requerida por el Ministerio de Hacienda para que puedas ocupar los
          Documentos Tributarios Electronicos.
        </MDTypography> */}
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.name) && formik.touched.name}
              onBlur={() => formik.setFieldTouched("name", true)}
              label="Nombre de la bodega"
              name="name"
              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon viewBox="0 0 16 12">
                      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12}>
                <Autocomplete
                  loading={loading}
                  options={oficinasRow}
                  onChange={handleChangeOficinas}
                  freeSolo
                  value={formik.values.costCenter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Oficina a la que pertenece"
                      variant="standard"
                      error={Boolean(formik.errors.costCenter) && Boolean(formik.touched.costCenter)}
                      helperText={!formik.touched.costCenter || (formik.touched.costCenter && !formik.errors.costCenter) ? " " : `${formik.errors.costCenter}`}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default GeneralesBodega;
