import { useEffect, useMemo } from "react";

// Types
import { FormikProps } from "formik";
import { ValuesArticulos } from "types/Articulos";

// DropZone
import { FileWithPath, useDropzone } from "react-dropzone";

//@mui material components
import { FormHelperText } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Alert
import { toast } from "react-hot-toast";

// Id
import { v4 as uuidv4 } from "uuid";

import "../styles/style.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  justifyContent: "center",
  padding: "20px",
  borderWidth: "0.0625rem",
  borderRadius: "0.375rem",
  borderColor: "#d2d6da",
  borderStyle: "solid",
  backgroundColor: "transparent",
  color: "#7b809a",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "150px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 3,
  border: "1px solid #eaeaea",
  marginBottom: 16,
  marginRight: 16,
  marginTop: 16,
  marginLeft: 16,
  width: 150,
  height: 150,
  padding: 4,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  height: "100%",
  transition: ".5s ease",
  opacity: 1,
};

const middle = {
  transition: ".5s ease",
  opacity: 0,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",
  position: "absolute",
};

const text = {
  backgroundColor: "#db0909",
  color: "white",
  fontSize: "16px",
  padding: "16px 32px",
  cursor: "pointer",
};

interface Props {
  handleDrop: (files: FileWithPath[]) => void;
  formik: FormikProps<ValuesArticulos>;
  setItemDeletePhoto?: React.Dispatch<React.SetStateAction<any[]>>;
}

interface FileImg extends FileWithPath {
  preview: string;
  idImg: string;
}
function DropZoneImg({ handleDrop, formik, setItemDeletePhoto }: Props) {
  const onDrop = (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          idImg: uuidv4(),
        })
      );
      handleDrop(acceptedFiles);
    }
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({ accept: { "image/*": [] }, onDrop, multiple: true, maxFiles: 3 });

  useEffect(() => {
    if (fileRejections.length > 3) {
      toast.error("Muchos archivos😲😲, la cantidad maxima es 3");
    }
  }, [fileRejections]);

  useEffect(() => {
    return () => formik.values?.photos?.forEach((file: FileImg) => URL.revokeObjectURL(file.preview));
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <section className="container">
        <div {...getRootProps({ style: { ...style } })}>
          <input {...getInputProps({})} />
          <p>Arrastra aqui la imagen del producto</p>
        </div>
        <FormHelperText sx={{ mb: 2 }}>La primera imagen sera la imagen principal</FormHelperText>
        <h4>Files:</h4>
        <MDBox sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {formik.values?.photos?.length > 0 &&
            formik.values?.photos.map((file) => (
              <div
                style={{ ...thumb, boxSizing: "border-box", position: "relative" }}
                key={file.id}
                id={file.idImg}
                onClick={(e) => {
                  setItemDeletePhoto(formik.values?.photos.filter((file: any) => file.idImg == e.currentTarget.id));
                  const filterPhoto = formik.values?.photos.filter((file: any) => file.idImg != e.currentTarget.id);
                  formik.setFieldValue("photos", filterPhoto);
                }}
              >
                <div style={{ ...thumbInner }} className="container">
                  <img
                    src={file.preview}
                    style={{ ...img, backfaceVisibility: "hidden" }}
                    // onLoad={() => {
                    //   URL.revokeObjectURL(file.preview);
                    // }}
                    className="ImgDrag"
                  />
                  <MDBox
                    sx={{
                      ...middle,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  >
                    <div style={{ ...text, userSelect: "none" }}>X</div>
                  </MDBox>
                </div>
              </div>
            ))}
        </MDBox>
      </section>
    </>
  );
}
export default DropZoneImg;
