// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useUserContext } from "context/UserContext";

// Types
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";

interface Props {
  handleOpen: (value: ActiveValues) => void;
}

const InfoSection = ({ handleOpen }: Props) => {
  // Context
  const { sellsConfig } = useSellsConfigContext();
  const { user } = useUserContext();

  return (
    <>
      <Grid mt={5} item xs={12}>
        <Card sx={{ padding: 5 }}>
          <Grid container justifyContent="center" gap={5}>
            {handleOpen ? (
              <Grid container display={{ xs: "flex", lg: "grid" }} gridTemplateColumns={"2fr 1fr"} gap={2} justifyContent="space-between">
                <MDTypography color="info" variant="h3" verticalAlign="center" sx={{ textAlign: "center", justifySelf: "center" }}>
                  Nuevo Documento Tributario Electronico
                </MDTypography>
                <MDBox justifySelf={{ xs: "center", lg: "end" }}>
                  <MDButton color="info" size="small" onClick={() => handleOpen(ActiveValues.InventaryConfig)}>
                    Editar
                  </MDButton>
                </MDBox>
              </Grid>
            ) : (
              <MDTypography color="info" variant="h3" verticalAlign="center" sx={{ textAlign: "center" }}>
                Nuevo Documento Tributario Electronico
              </MDTypography>
            )}

            <Grid container flexWrap="nowrap" flexDirection={{ xs: "column", xl: "row" }} justifyContent="space-between" gap={{ xs: 2, lg: 5 }} mx={{ xs: 0, lg: 10 }} my={1}>
              <MDBox width={{ xs: "100%", lg: "30%" }}>
                <MDTypography color="secondary" variant="p" verticalAlign="center" sx={{ textAlign: "center" }}>
                  Salas de Ventas:{" "}
                </MDTypography>
                <MDTypography color="info" variant="p" verticalAlign="center" fontWeight="bold" sx={{ textAlign: "center" }}>
                  {sellsConfig && sellsConfig.costCenter && sellsConfig.costCenter.name}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography color="secondary" variant="p" verticalAlign="center" sx={{ textAlign: "center" }}>
                  Punto de Ventas:{" "}
                </MDTypography>
                <MDTypography color="info" variant="p" verticalAlign="center" fontWeight="bold" sx={{ textAlign: "center" }}>
                  {sellsConfig && sellsConfig.selectedSalePoint && sellsConfig.selectedSalePoint.name}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography color="secondary" variant="p" verticalAlign="center" sx={{ textAlign: "center" }}>
                  Cajero:{" "}
                </MDTypography>
                <MDTypography color="info" variant="p" verticalAlign="center" fontWeight="bold" sx={{ textAlign: "center" }}>
                  {user && user.name}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default InfoSection;
