import { useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Autocomplete, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, SvgIcon, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Types
import { EmployeesComponentsProps } from "types/employeesComponentsProps";
import { GENDER } from "types/gender";

export const genders = [GENDER.MASCULINO, GENDER.FEMENINO];

function GeneralesEmployees({ formik }: EmployeesComponentsProps) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangeFormat = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      formik.setFieldValue("nit", string);
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      formik.setFieldValue("dui", value);
    }
  };
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Informacion Generales
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.dui) && formik.touched.dui}>
                  <InputLabel>Documento Unico de Identidad</InputLabel>
                  <Input
                    endAdornment={
                      <InputAdornment position="start">
                        <SvgIcon viewBox="0 0 16 12">
                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                        </SvgIcon>
                      </InputAdornment>
                    }
                    name="DUI"
                    onBlur={() => formik.setFieldTouched("dui", true)}
                    onChange={handleChangeFormat}
                    value={formik.values.dui}
                  />
                  <FormHelperText>{!formik.touched.dui || (formik.touched.dui && !formik.errors.dui) ? " " : formik.errors.dui}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nit) && formik.touched.nit}>
                  <InputLabel>Numero de Identificacion Tributaria</InputLabel>
                  <Input
                    name="NIT"
                    endAdornment={
                      <InputAdornment position="start">
                        <SvgIcon viewBox="0 0 16 12">
                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                        </SvgIcon>
                      </InputAdornment>
                    }
                    onBlur={() => formik.setFieldTouched("nit", true)}
                    onChange={handleChangeFormat}
                    value={formik.values.nit}
                  />
                  <FormHelperText>{!formik.touched.nit || (formik.touched.nit && !formik.errors.nit) ? " " : formik.errors.nit}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Primer Nombre"
                  name="firstName"
                  variant="standard"
                  value={formik.values.firstName}
                  onBlur={() => formik.setFieldTouched("firstName", true)}
                  error={Boolean(formik.errors.firstName) && formik.touched.firstName}
                  helperText={!formik.touched.firstName || (formik.touched.firstName && !formik.errors.firstName) ? " " : formik.errors.firstName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Segundo Nombre"
                  name="secondName"
                  variant="standard"
                  value={formik.values.secondName}
                  onBlur={() => formik.setFieldTouched("secondName", true)}
                  error={Boolean(formik.errors.secondName) && formik.touched.secondName}
                  helperText={!formik.touched.secondName || (formik.touched.secondName && !formik.errors.secondName) ? " " : formik.errors.secondName}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Primer Apellido"
                  name="firstLastName"
                  variant="standard"
                  value={formik.values.firstLastName}
                  onBlur={() => formik.setFieldTouched("firstLastName", true)}
                  error={Boolean(formik.errors.firstLastName) && formik.touched.firstLastName}
                  helperText={!formik.touched.firstLastName || (formik.touched.firstLastName && !formik.errors.firstLastName) ? " " : formik.errors.firstLastName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Sugundo Apellido"
                  name="secondLastName"
                  variant="standard"
                  value={formik.values.secondLastName}
                  onBlur={() => formik.setFieldTouched("secondLastName", true)}
                  error={Boolean(formik.errors.secondLastName) && formik.touched.secondLastName}
                  helperText={!formik.touched.secondLastName || (formik.touched.secondLastName && !formik.errors.secondLastName) ? " " : formik.errors.secondLastName}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Telefono"
                  name="phone"
                  variant="standard"
                  value={formik.values.phone}
                  onBlur={() => formik.setFieldTouched("phone", true)}
                  error={Boolean(formik.errors.phone) && formik.touched.phone}
                  helperText={!formik.touched.phone || (formik.touched.phone && !formik.errors.phone) ? " " : formik.errors.phone}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Correo Electronico"
                  name="emailNotifications"
                  variant="standard"
                  value={formik.values.emailNotifications}
                  onBlur={() => formik.setFieldTouched("emailNotifications", true)}
                  error={Boolean(formik.errors.emailNotifications) && formik.touched.emailNotifications}
                  helperText={!formik.touched.emailNotifications || (formik.touched.emailNotifications && !formik.errors.emailNotifications) ? " " : formik.errors.emailNotifications}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={genders}
              freeSolo
              openOnFocus
              value={formik.values.gender}
              onChange={(_: any, newValue: string) => formik.setFieldValue("gender", newValue)}
              onBlur={() => formik.setFieldTouched("gender", true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sexo"
                  name="gender"
                  error={Boolean(formik.errors.gender) && formik.touched.gender}
                  helperText={!formik.touched.gender || (formik.touched.gender && !formik.errors.gender) ? " " : formik.errors.gender}
                  variant="standard"
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default GeneralesEmployees;
