import gql from "graphql-tag";

export const ADD_TAG = gql`
  mutation ADD_TAG($data: TagDti!) {
    addTag(data: $data) {
      isSuccess
      data {
        id
        name
        value
      }
    }
  }
`;
