// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Custom components
import IndexFacturacion from "./facturacion";

function Ventas(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <IndexFacturacion />
      <Footer />
    </DashboardLayout>
  );
}

export default Ventas;
