// ProductsList page components
import DefaultCellSellsEmployees from "../components/idCells/DefaultCellSellsEmployees";

export const employeesDataTableData = {
  columns: [
    {
      Header: "Nombre",
      accessor: "nombre",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
    {
      Header: "Email",
      accessor: "emailNotifications",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
    {
      Header: "NIT",
      accessor: "NIT",
      width: "25%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
    {
      Header: "NRC/DUI",
      accessor: "nrc_dui",
      width: "25%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
    {
      Header: "Usuario",
      accessor: "usuario",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
    {
      Header: "Menu",
      accessor: "menu",
      width: "5%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSellsEmployees value={value} />,
    },
  ],
};
