import gql from "graphql-tag";

export const DELETE_EMPLOYEE = gql`
  mutation DELETE_EMPLOYEE($idEmployee: Float!) {
    deleteEmployee(idEmployee: $idEmployee) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
