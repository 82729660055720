// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Grid, TextField } from "@mui/material";

//Formik
import { FormikProps } from "formik";

//Types
import { PtosDeVtas } from "types/PtosDeVtas";

interface Props {
  formik: FormikProps<PtosDeVtas>;
}

function Caracteristicas({ formik }: Props) {
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Registro de Punto de Venta en Hacienda
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Estos campos son opcionales
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.mark) && formik.touched.mark}
              onBlur={() => formik.setFieldTouched("mark", true)}
              label="Marca"
              name="mark"
              helperText={!formik.touched.mark || (formik.touched.mark && !formik.errors.mark) ? " " : formik.errors.mark}
              value={formik.values.mark}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.model) && formik.touched.model}
              onBlur={() => formik.setFieldTouched("model", true)}
              label="Modelo del Punto de Venta"
              name="model"
              helperText={!formik.touched.model || (formik.touched.model && !formik.errors.model) ? " " : formik.errors.model}
              value={formik.values.model}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.serie) && formik.touched.serie}
              onBlur={() => formik.setFieldTouched("serie", true)}
              label="Numero de Serie"
              name="serie"
              helperText={!formik.touched.serie || (formik.touched.serie && !formik.errors.serie) ? " " : formik.errors.serie}
              value={formik.values.serie}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default Caracteristicas;
