// Material Dashboard 2 PRO React TS components
import { lineHeight } from "@mui/system";
import MDTypography from "components/MDTypography";

interface FieldError {
  name?: string;
  touched?: boolean;
  errorName: string;
}

const CustomFieldError = ({ name, errorName = "", touched }: FieldError) => {
  if (!touched) return null;
  if (!errorName) return null;

  return (
    <MDTypography variant="overline" paragraph fontWeight="medium" color="error" pl={1} sx={{ lineHeight: "1.1" }}>
      {errorName}
    </MDTypography>
  );
};

export default CustomFieldError;
