import gql from "graphql-tag";

export const ADD_ARTICLE_GROUPS = gql`
  mutation ADD_ARTICLE_GROUPS($data: ArticleGroupDti!) {
    addArticlesGroups(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
