import { filterLineOfBusinessByType } from "./filterLineOfBusinessByType";

export const actividadComercial: { id: string; label: string }[] = filterLineOfBusinessByType("detalle")
  .map((item) => ({
    id: item.id,
    label: item.name,
  }))
  .reduce((acc, item) => {
    if (acc.find((itemAcc) => itemAcc.label === item.label) === undefined) {
      acc.push(item);
    }
    return acc;
  }, []);
