import dataRegions from "../data/regions.json";

export const paises: { label: string; id: string }[] = dataRegions.data
  .filter((i) => i.type === "country")
  .map((i) => ({
    label: i.name,
    id: i.id,
  }));

export const departamentos: { label: string; id: string }[] = dataRegions.data
  .filter((i) => i.type === "province")
  .map((i) => ({
    label: i.name,
    id: i.id,
  }));

export const municipiosTotal: { label: string; id: string; idParent: string }[] = dataRegions.data.filter((i) => i.type === "city").map((i) => ({ label: i.name, id: i.id, idParent: i.idParent }));

export const getMunicipios = (id: any) => {
  return municipiosTotal
    .filter((item) => item.idParent === id)
    .map((item) => ({
      id: item.id,
      label: item.label,
    }));
};
