import gql from "graphql-tag";

export const GET_ARTICLES_FOR_SELLING = gql`
  query GetArticlesForSelling($filters: ArticlesQueryFilterForSellingDti!) {
    getArticlesForSelling(filters: $filters) {
      data {
        name
        code
        barCode
        id
        type
        description
        operation_type
        type
        measurement {
          id
          name
          symbol
          code
        }
        salesPrices {
          id
          salePrice
          isDefault
          priceList {
            id
            name
          }
        }
      }
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;
