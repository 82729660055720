import gql from "graphql-tag";

export const GET_DTE_STATISTICS = gql`
  query Query {
    getDteStatistics {
      isSuccess
      message {
        code
        message
      }
      data {
        id
        idDteEnvironment
        name_document
        billingEnvironment
        code
        extended_name_document
      }
    }
  }
`;
