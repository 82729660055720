import gql from "graphql-tag";

export const GET_COMPANIES_BY_USER = gql`
  query GetCompaniesByUser($filters: UserEntitiesQueryFilterDti!) {
    getCompaniesByUser(filters: $filters) {
      data {
        idEntity
        idMainEntity
        documents {
          id
          documentNumber
          code
          type
        }
        company {
          name
          id
          contribuyenteType
          legalRepresentation
          documents {
            id
            documentNumber
            code
            type
          }
          mhData {
            productionMhCertificatePasswordSetted
            productionMhUserPasswordSetted
            isProductionCertificateUploaded

            testingMhUserPasswordSetted
            testingMhCertificatePasswordSetted
            isTestingCertificateUploaded
          }
        }
        type
      }
      companyCount
      isSuccess
      message {
        message
        detail
        code
      }
    }
  }
`;
