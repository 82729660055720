import gql from "graphql-tag";

export const GET_STORAGES = gql`
  query GET_STORAGES($filter: StoragesQueryFilterDti) {
    getStorages(filter: $filter) {
      isSuccess
      data {
        name
        id
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
