import gql from "graphql-tag";

export const GET_CLIENTS_NAME_IDCLIENTCOMPANY = gql`
  query {
    getClients(clientFilters: {}) {
      isSuccess
      data {
        name
        idClientCompany
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
