import React, { useEffect, useRef, useState } from "react";

// @mui material components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { DELETE_USER_ENTITY } from "apollo/queries/employees/DeleteUserEntity";
import toast from "react-hot-toast";

// Types

interface Props {
  handleClose: () => void;
  open: boolean;
  employee: any;
  reFetch: () => void;
}

function DialogDeleteUser({ handleClose, open, employee, reFetch }: Props) {
  // Mutations
  const [deleteUserEntity, { loading, error }] = useMutation(DELETE_USER_ENTITY);

  const validationSchema = Yup.object({
    name: Yup.string()
      .oneOf([String(employee?.firstName), null], "El nombre debe coincidir")
      .required("El nombre es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (_, { resetForm }) => {
      try {
        const res = await deleteUserEntity({
          variables: {
            idUserEntity: employee?.idUserEntity,
          },
        });

        if (res.data?.deleteUserEntity?.isSuccess) {
          toast.success("El usuario se a eliminado correctamente");
          reFetch();
          resetForm({
            values: {
              name: "",
            },
          });
          handleClose();
          formik.setTouched({
            name: false,
          });
        } else {
          toast.error(`Hubo un error al eliminar el usuario ${res.data?.createUserEmployee?.message?.detail}`);
        }
      } catch (error) {
        toast.error(`Hubo un error al eliminar el usuario ${error}`);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        formik.setTouched({
          name: false,
        });
      }}
      sx={{ px: 2 }}
    >
      <DialogTitle sx={{ color: "#F44335", pb: 0 }}>{"Eliminar Usuario"}</DialogTitle>
      <DialogTitle sx={{ color: "#00547A", pt: 1 }}>{`${employee?.firstName} ${employee?.secondName} ${employee?.firstLastName} ${employee?.secondLastName}`}</DialogTitle>
      <DialogContent>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Estas seguro que quieres eliminar el{" "}
          <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1rem">
            USUARIO{" "}
          </MDTypography>
          al empleado{" "}
          <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1rem">
            {employee?.firstName}{" "}
          </MDTypography>
          para confirmar esta operacion por favor digita el primer nombre en el campo de abajo.
        </MDTypography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre"
              name="name"
              variant="standard"
              value={formik.values.name}
              onBlur={() => formik.setFieldTouched("name", true)}
              error={Boolean(formik.errors.name) && formik.touched.name}
              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
            formik.setTouched({
              name: false,
            });
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={loading || Boolean(formik.errors.name) || formik.values.name === ""}
          variant="gradient"
          type="submit"
          color="info"
        >
          Guardar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDeleteUser;
