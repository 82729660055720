import gql from "graphql-tag";

export const GET_SALE_POINTS = gql`
  query GET_SALE_POINTS($filters: SalePointrQueryFilterDti!) {
    getSalePoints(filters: $filters) {
      isSuccess
      data {
        id
        name
        code
        mark
        model
        resolution
        serie
        storage {
          id
          name
        }
        costCenter {
          name
          id
        }
      }
      message {
        code
        detail
        message
      }
    }
  }
`;
