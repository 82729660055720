import gql from "graphql-tag";

export const DELETE_ARTICLE_TAGS = gql`
  mutation DELETE_ARTICLE_TAGS($idArticleTag: Float!) {
    deleteArticlesTags(idArticleTag: $idArticleTag) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
