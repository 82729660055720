import { useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Card from "@mui/material/Card";

// Custom Components
import Detalles from "./components/Detalles";
import ImportComponent from "./components/import-component";
import Exportar from "./components/Exportar";
import StepperCat from "components/Global/StepperCat";

export interface Rows {
  idClientCompany: number;
  name: string;
  phone: string;
  emailNotifications: string;
  documents: Document[];
}

export interface Document {
  documentNumber: string;
  type: string;
}

function Clientes(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: <Detalles />,
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: <ImportComponent />,
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: <Exportar />,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <MDBox display="flex" width="100%" justifyContent={{ xs: "center", sm: "flex-start" }}>
          <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
        </MDBox>
        <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clientes;
