/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Page components
import Indicaciones from "layouts/configuracion/factura-electronica/components/Indicaciones";
import PrimerosPasos from "layouts/configuracion/factura-electronica/components/PrimerosPasos";
import Empresa from "./components/Empresa";

// Utils
import { filterCompanyByName } from "utils/filterCompanyByName";

// Constants
import { PAGINATION } from "constants/pagination";

// Types
import type { Company } from "types/company";

// GraphQL
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANIES_BY_USER } from "apollo/queries/companies/getCompaniesByUser";

// Context
import { useCompanyContext } from "context/CompanyContext";

export enum ChooseEnvironment {
  PRUEBAS = "TESTING_MH",
  EMISIONES = "PRODUCTION",
}

function getSteps(): string[] {
  return ["Indicaciones", "Credenciales"];
}

function FacturaElectronica(): JSX.Element {
  const [activeEnv, setActiveEnv] = useState<ChooseEnvironment>(ChooseEnvironment.PRUEBAS);

  const [getCompanies] = useLazyQuery(GET_COMPANIES_BY_USER);
  const { company, setCompany } = useCompanyContext();

  // States
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [{ name, mhData }, setHasConfigInfo] = useState<Company>(company);

  // Values
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleLast = async () => {
    const { data } = await getCompanies({ variables: { filters: { pagination: PAGINATION } } });
    const updatedCompany = filterCompanyByName(data.getCompaniesByUser.data, name);

    setCompany(updatedCompany.company);
    setHasConfigInfo(updatedCompany.company);
  };

  if (mhData?.isProductionCertificateUploaded && mhData?.productionMhCertificatePasswordSetted && mhData.productionMhUserPasswordSetted) {
    return <Empresa enviroment={activeEnv} setActiveEnv={setActiveEnv} />;
  } else if (mhData?.isTestingCertificateUploaded && mhData?.testingMhCertificatePasswordSetted && mhData.testingMhUserPasswordSetted) {
    return <Empresa enviroment={activeEnv} setActiveEnv={setActiveEnv} />;
  }

  function getStepContent(stepIndex: number, setIsValidated: React.Dispatch<React.SetStateAction<boolean>>): JSX.Element {
    switch (stepIndex) {
      case 0:
        return <PrimerosPasos active={activeEnv} setActive={setActiveEnv} />;
      case 1:
        return <Indicaciones isValid={setIsValidated} enviroment={activeEnv} />;
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep, setIsValidated)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="info" onClick={handleBack}>
                        Atras
                      </MDButton>
                    )}
                    <MDButton disabled={activeStep === 1 && !isValidated ? true : false || !activeEnv} variant="gradient" color="info" onClick={!isLastStep ? handleNext : handleLast}>
                      {isLastStep ? "Enviar" : "Siguiente"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FacturaElectronica;
