// @ts-nocheck
import React from "react";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

const options = ["Remover", "Editar"];

const ActionsDropdown = ({ state, handleSelect, id, selectedItem, handleEditSale }: any) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const stateIndex = options.indexOf(state);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    index === 0 && handleSelect(selectedItem);
    index === 1 && handleEditSale(selectedItem);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  const getIcon = (icon: string) => {
    switch (icon) {
      case "Remover":
        return <Icon color="error">playlist_remove_icon</Icon>;
      case "Editar":
        return <Icon color="info">edit_note_icon</Icon>;

      default:
        return <Icon></Icon>;
    }
  };

  return (
    <>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>
      <Popper
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <NotificationItem
                        key={option}
                        icon={getIcon(option)}
                        title={option}
                        customColor={option === "Remover" ? "error" : "info"}
                        disabled={index === stateIndex}
                        selected={index === stateIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      />
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </>
  );
};

export default ActionsDropdown;
