/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef } from "react";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React TS components
import MDInput from "components/MDInput";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// types
interface Props {
  input?: {
    [key: string]: any;
  };
  [key: string]: any;
}

function MDDatePicker({ input, ...rest }: Props): JSX.Element {
  const fp = useRef(null);

  const handleClick = () => {
    if (!fp?.current?.flatpickr) return;

    fp.current.flatpickr.open();
  };
  return (
    <>
      <Flatpickr
        {...rest}
        ref={fp}
        render={({ defaultValue }: any, ref: any) => (
          <div style={{ position: "relative" }}>
            <MDInput type="date" {...input} defaultValue={defaultValue} inputRef={ref} />
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0",
                top: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "1rem",
              }}
              onClick={handleClick}
            >
              <CalendarTodayIcon color="info" onClick={handleClick} />
            </div>
          </div>
        )}
      />
    </>
  );
}

MDDatePicker.defaultProps = {
  input: {},
};

export default MDDatePicker;
