import React, { useEffect, useState } from "react";

// @mui material components
import { Autocomplete, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, SvgIcon, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";

// Types
import { DocumentType } from "types/documentType";
import { validarDUI } from "utils/validations/validarDui";
import { validarNIT } from "utils/validations/validarNit";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";

// Graphql
import { useAddDocumentSubmit } from "hooks/nuevoDTE/handlesSubmits/useAddDocumentSubmit";

interface Props {
  open: boolean;
  handleClose: (value: ActiveValues) => void;
  handleSubmitRegisterDocument: any;
  dataAddDocuments: any;
}
export interface DocumentState {
  type?: string;
  nit?: string;
  dui?: string;
  pasaporte?: string;
}

const initialState: DocumentState = {
  type: "",
  nit: "",
  dui: "",
  pasaporte: "",
};
const Errors = {
  type: "El tipo de documento es requerido",
  invalidNit: "NIT invalido",
  invalidDui: "DUI invalido",
  invalidPasaporte: "El pasaporte debe tener al menos 3 caracteres",
};

function DialogRegisterDocument({ handleClose, open, handleSubmitRegisterDocument, dataAddDocuments }: Props) {
  // State
  const [document, setDocument] = useState<DocumentState>(initialState);
  const [error, setError] = useState<DocumentState>(initialState);

  const handleChangeFormat = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      setDocument((prev) => ({
        ...prev,
        nit: string,
      }));
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{8})(\d{1})/, "$1-$2");
      setDocument((prev) => ({
        ...prev,
        dui: string,
      }));
    }
  };
  const getInputDocument = (type: string) => {
    switch (type) {
      case DocumentType.NIT:
        return (
          <FormControl fullWidth variant="standard" error={Boolean(error?.nit)}>
            <InputLabel>Numero de Identificacion Tributaria</InputLabel>
            <Input
              name="NIT"
              endAdornment={
                <InputAdornment position="start">
                  <SvgIcon viewBox="0 0 16 12">
                    <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                  </SvgIcon>
                </InputAdornment>
              }
              onChange={handleChangeFormat}
              value={document?.nit}
            />
            <FormHelperText>{!error?.nit ? " " : error?.nit}</FormHelperText>
          </FormControl>
        );
      case DocumentType.DUI:
        return (
          <FormControl fullWidth variant="standard" error={Boolean(error?.dui)}>
            <InputLabel>Documento Unico de Identidad</InputLabel>
            <Input
              endAdornment={
                <InputAdornment position="start">
                  <SvgIcon viewBox="0 0 16 12">
                    <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                  </SvgIcon>
                </InputAdornment>
              }
              name="DUI"
              onChange={handleChangeFormat}
              value={document?.dui}
            />
            <FormHelperText>{!error.dui ? " " : error.dui}</FormHelperText>
          </FormControl>
        );
      case DocumentType.PASSPORT.toUpperCase():
        return (
          <FormControl fullWidth variant="standard" error={Boolean(error?.pasaporte)}>
            <InputLabel>Pasaporte</InputLabel>
            <Input
              endAdornment={
                <InputAdornment position="start">
                  <SvgIcon viewBox="0 0 16 12">
                    <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                  </SvgIcon>
                </InputAdornment>
              }
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  setDocument((prev) => ({
                    ...prev,
                    pasaporte: e.target.value,
                  }));
                }
              }}
              name="pasaporte"
              value={document?.pasaporte}
            />
            <FormHelperText>{!error?.pasaporte ? " " : error?.pasaporte}</FormHelperText>
          </FormControl>
        );
      default:
        return <TextField fullWidth label="Numero del documento" disabled={document?.type === null || document?.type === ""} variant="standard" />;
    }
  };

  // Data
  const documentTypesOptions = [DocumentType.NIT, DocumentType.DUI, DocumentType.PASSPORT.toUpperCase()];

  useEffect(() => {
    setDocument(initialState);
    setError(initialState);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(ActiveValues.registerDocument);
        setDocument(initialState);
        setError(initialState);
      }}
    >
      <DialogTitle>{"Registre un documento de identidad"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingBottom: 2 }}>El empleado que utiliza no tiene ninguno de los documentos requeridos para realizar una venta. Registre un documento de identidad para continuar.</DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              fullWidth
              value={document?.type}
              onChange={async (_: React.SyntheticEvent, value: string, reason: string) => {
                if (reason === "clear") {
                  setError(initialState);
                  await setDocument(initialState);
                } else {
                  setError(initialState);
                  await setDocument({
                    type: value,
                    nit: "",
                    dui: "",
                    pasaporte: "",
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  error={Boolean(error?.type)}
                  helperText={!error?.type ? " " : error?.type}
                  variant="standard"
                  label="Tipo de documento"
                />
              )}
              options={documentTypesOptions}
            />
          </Grid>
          <Grid item xs={12}>
            {document?.type === null ? <TextField fullWidth label="Numero del documento" disabled={document?.type === null || document?.type === ""} variant="standard" /> : getInputDocument(document?.type)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose(ActiveValues.registerDocument);
            setDocument(initialState);
            setError(initialState);
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          onClick={() => {
            if (document?.type !== null && document?.type !== "") {
              if (document?.type === DocumentType.NIT) {
                if (validarNIT(document?.nit)) {
                  setError((prev) => ({
                    ...prev,
                    nit: Errors.invalidNit,
                  }));
                  return;
                }
              }
              if (document?.type === DocumentType.DUI) {
                if (validarDUI(document?.dui)) {
                  setError((prev) => ({
                    ...prev,
                    dui: Errors.invalidDui,
                  }));
                  return;
                }
              }
              if (document?.type === DocumentType.PASSPORT) {
                if (document?.pasaporte.length < 3 || document?.pasaporte === null || document?.pasaporte === "") {
                  setError((prev) => ({
                    ...prev,
                    pasaporte: Errors.invalidPasaporte,
                  }));
                  return;
                }
              }
              handleSubmitRegisterDocument(document);
            } else {
              setError({
                type: Errors.type,
                nit: "",
                dui: "",
                pasaporte: "",
              });
            }
          }}
          variant="gradient"
          disabled={dataAddDocuments.loading}
          type="submit"
          color="info"
        >
          {dataAddDocuments?.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Continuar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogRegisterDocument;
