// @mui material components
import { Autocomplete, Card, Grid, TextField } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Libs
import { useFormikContext } from "formik";

// Data
import RecintoFiscal from "../../../../../assets/data/recinto_fiscal.json";
import RegimenExportacion from "../../../../../assets/data/regimen_aduanero.json";
import INCOTERMS from "../../../../../assets/data/incoterms.json";

// Types
import { OptionsOtherDocuemnts } from "types/otherDocuments";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const OtherDocumentData = () => {
  // Context
  const { values, setFieldValue, touched, setFieldTouched, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  const recintoFiscalOptions: OptionsOtherDocuemnts[] = RecintoFiscal.map((item) => {
    return {
      ...item,
      label: (item?.codigo ? item?.codigo + " - " : "") + item.descripcion,
    };
  });
  const regimenExportacionOptions: OptionsOtherDocuemnts[] = RegimenExportacion.map((item) => {
    return {
      ...item,
      label: (item?.codigo ? item?.codigo + " - " : "") + item.descripcion,
    };
  });
  const INCOTERMSOptions: OptionsOtherDocuemnts[] = INCOTERMS.map((item) => {
    return {
      ...item,
      label: (item?.codigo ? item?.codigo + " - " : "") + item.descripcion,
    };
  });

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Otros Datos del Documento Electronico Tributario
            </MDTypography>
          </MDBox>
          <Grid container gap={{ xs: 3, lg: 5 }} my={5} mb={{ xs: 3, lg: 5 }}>
            <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
              <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
                <Autocomplete
                  id="Type"
                  options={["Bienes", "Servicios", "Ambos"]}
                  openOnFocus
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.type}
                  onChange={async (e, value) => {
                    await setFieldValue("type", value);
                  }}
                  onBlur={() => setFieldTouched("type", true)}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField {...params} label="Tipo de ítem a exportar*" name="type" />
                      {/* <ShortCutsLabels
                              keys={keys}
                              command={[
                                { key: "control", label: "CTRL" },
                                { key: "d", label: "D" },
                              ]}
                            /> */}
                      <MDBox position="absolute" width="100%">
                        <CustomFieldError touched={touched.type} errorName={errors.type && (errors.type as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                />
              </Grid>
              {values.type !== "Servicios" ? (
                <>
                  <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
                    <Autocomplete
                      id="INCOTERMS"
                      openOnFocus
                      fullWidth
                      onBlur={() => setFieldTouched("incoterms", true)}
                      renderInput={(params) => (
                        <MDBox position="relative">
                          <TextField /* ref={ProductCode} */ {...params} data-id="autocomplete-INCOTERMS" name="incoterms" label="INCOTERMS*" />
                          {/* <ShortCutsLabels
                              keys={keys}
                              command={[
                                { key: "control", label: "CTRL" },
                                { key: "i", label: "I" },
                              ]}
                            /> */}
                          <MDBox position="absolute" width="100%">
                            <CustomFieldError name="test" touched={touched.incoterms ? true : false} errorName={errors.incoterms && (errors.incoterms as string)} />
                          </MDBox>
                        </MDBox>
                      )}
                      value={values.incoterms}
                      // isOptionEqualToValue={isOptionEqualToValue}
                      onChange={(e, newValue) => setFieldValue("incoterms", newValue)}
                      options={INCOTERMSOptions}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
                    <Autocomplete
                      id="RecintoFiscal"
                      openOnFocus
                      fullWidth
                      onBlur={() => setFieldTouched("recintoFiscal", true)}
                      renderInput={(params) => (
                        <MDBox position="relative">
                          <TextField /* ref={ProductCode} */ {...params} data-id="autocomplete-RecintoFiscal" name="recintoFiscal" label="Recinto Fiscal*" />
                          {/* <ShortCutsLabels
                              keys={keys}
                              command={[
                                { key: "control", label: "CTRL" },
                                { key: "i", label: "I" },
                              ]}
                            /> */}
                          <MDBox position="absolute" width="100%">
                            <CustomFieldError name="test" touched={touched.recintoFiscal ? true : false} errorName={errors.recintoFiscal && (errors.recintoFiscal as string)} />
                          </MDBox>
                        </MDBox>
                      )}
                      value={values.recintoFiscal}
                      // isOptionEqualToValue={isOptionEqualToValue}
                      onChange={(e, newValue) => setFieldValue("recintoFiscal", newValue)}
                      options={recintoFiscalOptions}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
            {values.type !== "Servicios" ? (
              <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
                <Grid item xs={12} sx={{ position: "relative" }}>
                  <Autocomplete
                    id="RegimenExportacion"
                    openOnFocus
                    onBlur={() => setFieldTouched("regimenExportacion", true)}
                    fullWidth
                    renderInput={(params) => (
                      <MDBox position="relative">
                        <TextField /* ref={ProductCode} */ data-id="autocomplete-RegimenExportacion" name="regimenExportacion" label="Regimen de  Exportación*" {...params} />
                        {/* <ShortCutsLabels
                              keys={keys}
                              command={[
                                { key: "control", label: "CTRL" },
                                { key: "i", label: "I" },
                              ]}
                            /> */}
                        <MDBox position="absolute" width="100%">
                          <CustomFieldError name="test" touched={touched.regimenExportacion ? true : false} errorName={errors.regimenExportacion && (errors.regimenExportacion as string)} />
                        </MDBox>
                      </MDBox>
                    )}
                    value={values.regimenExportacion}
                    // isOptionEqualToValue={isOptionEqualToValue}
                    onChange={(e, newValue) => setFieldValue("regimenExportacion", newValue)}
                    options={regimenExportacionOptions}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default OtherDocumentData;
