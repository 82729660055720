import gql from "graphql-tag";

export const GET_CLIENTS = gql`
  query GetClients($clientFilters: ClientQueryFilterDti) {
    getClients(clientFilters: $clientFilters) {
      isSuccess
      data {
        id
        idClientCompany
        name
        address
        phone
        plazoType
        contribuyenteType
        legalRepresentation
        emailUser
        idUserEntity
        region {
          id
          name
          type

          parent {
            id
            name
            parent {
              id
              name
            }
          }
          code {
            id
            code

            goberment {
              id
              name
            }
          }
        }
        documents {
          id
          documentNumber
          type
        }

        isExent
        lineOfBusiness {
          name
        }

        retention
        perception

        isActiveCredit
        creditLimit

        creditPeriod
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
