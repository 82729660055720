import gql from "graphql-tag";

export const CHANGE_DTE_ENVIROMENT = gql`
  mutation ChangeDteEnvironment($idDteEnvironment: Float!, $environment: ElectronicBillingStatus!) {
    changeDteEnvironment(idDteEnvironment: $idDteEnvironment, environment: $environment) {
      isSuccess
      message {
        message
        detail
        code
      }
      data {
        environment
        idDteEnvironment
      }
    }
  }
`;
