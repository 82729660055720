// @mui material components
import { Card, Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//Types
import { DataInfoTable } from "types/dataInfoDtesRechazados";

// react-router-dom
import { useNavigate } from "react-router-dom";

interface Props {
  infoTable: DataInfoTable;
}
function InfoTable({ infoTable }: Props) {
  const pantalla = window.innerWidth;
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <Grid container gap={1} wrap={`${pantalla > 767 ? "nowrap" : "wrap"}`} height="100%">
        {/* DTEs pendientes */}
        {infoTable.nroErrorsPendientes !== 0 ? (
          <Grid item xs={12} md={infoTable.nroErrorsMultados === 0 ? 12 : 6}>
            <Card sx={{ padding: 5, height: "100%" }}>
              <MDTypography color="info" variant="h4" sx={{ mb: 2 }}>
                DTEs Pendientes por corregir
              </MDTypography>
              <MDBox display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <MDTypography>
                  Tienes una posible multa de{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    {infoTable.multaPendientes}
                  </MDTypography>{" "}
                  por DTEs{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    rechazados
                  </MDTypography>
                  . Para evitarla tienes menos de 24 horas, para solventar {`${infoTable.nroErrorsPendientes === 1 ? "un" : "los"}`}{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    {`${infoTable.nroErrorsPendientes}`}
                  </MDTypography>{" "}
                  {`${infoTable.nroErrorsPendientes === 1 ? "error." : "errores."}`}
                </MDTypography>
                <MDButton onClick={() => navigate("/ventas/verDtesPendientes")} sx={{ mt: 2 }} variant="contained" fullWidth color="error">
                  Ver Dtes Pendientes
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        ) : null}
        {/* DTEs multados */}
        {infoTable.nroErrorsMultados !== 0 ? (
          <Grid item xs={12} md={infoTable.nroErrorsPendientes === 0 ? 12 : 6}>
            <Card sx={{ padding: 5, height: "100%" }}>
              <MDTypography color="info" variant="h4" sx={{ mb: 2 }}>
                Multa por DTEs no corregidos
              </MDTypography>
              <MDBox display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <MDTypography>
                  Tienes una multa de{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    {infoTable.multaMultados}
                  </MDTypography>{" "}
                  por DTEs{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    rechazados
                  </MDTypography>
                  . Total de DTEs{" "}
                  <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1.25rem">
                    {`${infoTable.nroErrorsMultados}`}
                  </MDTypography>
                  .
                </MDTypography>
                <MDButton onClick={() => navigate("/ventas/verDtesMultados")} sx={{ mt: 2 }} variant="contained" fullWidth color="error">
                  Ver Dtes Multados
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
export default InfoTable;
