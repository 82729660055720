import gql from "graphql-tag";

export const CREATE_TEST_FACTURA_EXPORTACION = gql`
  mutation CREATE_TEST_FACTURA_EXPORTACION($input: testDteDTI!) {
    createTestFacturaExportacion(input: $input) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
