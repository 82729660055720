// @mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

interface Props {
  namePriceList: string;
  loading: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  handleClickOpen: (value: boolean) => void;
}
function PtosDeVtasLDPTable({ namePriceList, loading, table, handleClickOpen }: Props) {
  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          {`Administracion de Puntos de Ventas`}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Aquí podrás ver todos los puntos de ventas que tienes registrados asociados a la lista llamada{" "}
          <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="info">
            {`"${namePriceList}"`}
          </MDTypography>
          .
        </MDTypography>
      </MDBox>
      <DataTable table={loading ? { columns: table.columns, rows: [] } : table} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }} loading={loading}>
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => handleClickOpen(true)} color="info">
            Agregar Punto de Venta
          </MDButton>
        </Grid>
      </DataTable>
    </>
  );
}

export default PtosDeVtasLDPTable;
