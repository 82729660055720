// ProductsList page components
import DefaultCellSells from "layouts/ventas/facturacion/components/IdCell/DefaultCellSells";

// @mui material components
import { Checkbox } from "@mui/material";

export const sellsDataTableDataRechazados = {
  columns: [
    {
      Header: <Checkbox color="primary" checked={true} readOnly />,
      accessor: "select",
      width: "1%",
      hiddeSort: true,
    },
    {
      Header: "Número de control",
      accessor: "num_control",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Fecha",
      accessor: "date",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Cliente",
      accessor: "client",
      width: "20%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Lugar",
      accessor: "lugar",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Monto",
      accessor: "amount",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Acciones",
      accessor: "actions",
      width: "5%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
  ],
};
