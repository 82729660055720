// Graphql
import { LazyQueryExecFunction, OperationVariables, useMutation } from "@apollo/client";

// Mutations
import { ANULAR_CCFS_CON_NOTA_CREDITO } from "apollo/mutations/dtes/anularCCFsConNotaCredito";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";

// Libs
import toast from "react-hot-toast";

// Utils
import { getDateByMonth } from "utils/getDateByMonth";

// Types
import { SerializationKeys } from "types/apollo";

interface Props {
  getData: LazyQueryExecFunction<any, OperationVariables>;
}

const useHandleSubmitNotaCredito = ({ getData }: Props) => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  const { sellsConfig } = useSellsConfigContext();

  const [anularCCFsConNotaDeCredito, dataAnularCCFsConNotaDeCredito] = useMutation(ANULAR_CCFS_CON_NOTA_CREDITO, { context });

  const handleSubmitNotaCredito = async (selectedItems?: any[], selectedItem?: any) => {
    await anularCCFsConNotaDeCredito({
      variables: {
        input: {
          ccfsIds: selectedItems.length > 0 ? selectedItems.map((item: any) => item.id) : [selectedItem.id],
        },
      },
    })
      .then(({ data }) => {
        if (data?.anularCCFsConNotaCredito?.respuestaHacienda.completeResponse.estado === "PROCESADO") {
          toast.success("DTE Covertido en Nota de Credito correctamente");
          getData({
            variables: {
              filters: {
                idCostCenter: sellsConfig.costCenter.id,
                idSalePoint: sellsConfig.selectedSalePoint.id,
                startDateSale: sellsConfig.emisionStartDate || getDateByMonth(),
                pagination: 1000000,
              },
            },
            notifyOnNetworkStatusChange: true,
          });
        } else if (data.anularCCFsConNotaCredito.respuestaHacienda.completeResponse.estado === "RECHAZADO") {
          toast.error(`Ocurrió un error al agregar venta ${data.anularCCFsConNotaCredito.respuestaHacienda.completeResponse.descripcionMsg}`);
          getData({
            variables: {
              filters: {
                idCostCenter: sellsConfig.costCenter.id,
                idSalePoint: sellsConfig.selectedSalePoint.id,
                startDateSale: sellsConfig.emisionStartDate || getDateByMonth(),
                pagination: 1000000,
              },
            },
            notifyOnNetworkStatusChange: true,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return { handleSubmitNotaCredito, dataAnularCCFsConNotaDeCredito };
};

export default useHandleSubmitNotaCredito;
