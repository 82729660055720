import gql from "graphql-tag";

export const ADD_USER_EMPLOYEE = gql`
  mutation ADD_USER_EMPLOYEE($idEmployeeCompany: Float!, $userData: CreateUserEntityDti!) {
    createUserEmployee(idEmployeeCompany: $idEmployeeCompany, userData: $userData) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
