// Material Dashboard 2 PRO React TS components
import { Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormikProps } from "formik";
import { Link } from "react-router-dom";

interface Props {
  formik: FormikProps<any>;
}
function Indicaciones({ formik }: Props) {
  return (
    <>
      <MDBox my={2.5} display="flex" justifyContent="center">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Cambio de Contraseña
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="subtitle2" color="text">
          ¿Ya tienes una cuenta?{" "}
          <MDTypography component={Link} to="/authentication/sign-in" variant="button" color="secondary" fontWeight="medium" textGradient>
            Ingresa aquí
          </MDTypography>
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} md={6}>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
            Dinos tu correo con el que estes registrado y te enviaremos un codigo de que necesitaras para que cambiar tu contraseña
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Correo"
            onBlur={() => {
              formik.setFieldTouched("email", true);
            }}
            error={Boolean(formik.errors.email)}
            value={formik.values.email ?? ""}
            helperText={!formik.errors.email ? " " : `${formik.errors.email}`}
            onChange={formik.handleChange}
            name="email"
          />
        </Grid>
      </Grid>
    </>
  );
}
export default Indicaciones;
