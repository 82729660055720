import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// GraphQl
import { useMutation } from "@apollo/client";

// Custom components
import GeneralesArticulos from "./components/GeneralesArticulos";
import Precio from "./components/Precio";
import Caracteristica from "./components/Caracteristica";
import Bodega from "./components/Bodega";
import Tipo from "./components/Tipo";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Alert
import { toast } from "react-hot-toast";

// Types
import { ArticleTypes, ValuesArticulos } from "types/Articulos";
import { BodegasArr } from "types/bodegas";
import { TagsArr } from "types/caracteristicas";
import { SerializationKeys } from "types/apollo";
import { OperationType } from "types/environment";
import { PriceListArr } from "types/arrListaDePrecio";

// Axios
import axios from "axios";

// Context
import { useAuthContext } from "context/AuthContext";

// Queries
import { ADD_ARTICLE } from "apollo/mutations/articles/addArticle";
import { ADD_ARTICLE_TO_PRICE_LIST } from "apollo/mutations/priceList/addArticleToPriceList";
import { ADD_STORAGE_ARTICLE } from "apollo/mutations/storage/addStorageArticle";
import { ADD_ARTICLE_TAGS } from "apollo/mutations/tags/addArticleTags";
import { ADD_ARTICLE_GROUPS } from "apollo/mutations/articles/addArticlesGroups";
import { DELETE_ARTICLE } from "apollo/mutations/articles/deleteArticle";

const steps = ["Tipo", "Generales", "Bodega", "Caracteristica", "Precio"];
const stepsServicios = ["Tipo", "Generales", "Precio"];

export const validationSchema = Yup.object().shape({
  allowNegativeKardex: Yup.boolean(),
  defaultPrice: Yup.boolean(),
  barCode: Yup.string().trim().min(4, "El codigo de barra debe tener al menos 4 caracteres").nullable(),
  code: Yup.string().trim().min(4, "El codigo debe tener al menos 4 caracteres").nullable(),
  description: Yup.string().trim().min(4, "La descripcion debe tener al menos 4 caracteres").nullable(),
  measurementUnit: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  name: Yup.string().trim().required("El nombre es requerido").min(4, "El nombre debe tener al menos 4 caracteres"),
  operationType: Yup.string().trim().required("El tipo de operacion es requerido").min(4, "El tipo de operacion debe tener al menos 4 caracteres").ensure(),
  type: Yup.string().trim().required("El tipo de articulo es requerido").min(4, "El tipo de articulo debe tener al menos 4 caracteres").ensure(),
  priceList: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .nullable()
    .required("La lista de precio es requerido"),
  // price: Yup.string().trim().nullable().required("El precio es requerido"),
  groups: Yup.array(
    Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
  ).nullable(),
  // tagValue: Yup.string().trim().nullable().required("El valor de la caracteristica es requerido"),
  tags: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  storage: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .nullable()
    .required("La Bodega es requerida"),
  costsCenters: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .nullable()
    .required("El Centro de Costos es requerido"),
  // max: Yup.string().trim().nullable(),
  // min: Yup.string().trim().nullable(),
  // stock: Yup.string().trim().nullable(),
});

function CrearArticulos() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Mutations
  const [addArticle, { loading }] = useMutation(ADD_ARTICLE, { context });
  const [addArticleToPriceList, dataAddArticleToPriceList] = useMutation(ADD_ARTICLE_TO_PRICE_LIST, { context });
  const [addStorageArticle, dataAddStorageArticle] = useMutation(ADD_STORAGE_ARTICLE, { context });
  const [addArticlesTags, dataAddArticlesTags] = useMutation(ADD_ARTICLE_TAGS, { context });
  const [addArticlesGroups, dataAddArticlesGroups] = useMutation(ADD_ARTICLE_GROUPS, { context });
  const [deleteArticle] = useMutation(DELETE_ARTICLE, { context });

  // States
  const [activeStep, setActiveStep] = useState<number>(0);
  const [arrOfBodegas, setArrOfBodegas] = useState<BodegasArr[]>([]);
  const [arrOfTAgs, setArrOfTAgs] = useState<TagsArr[]>([]);
  const [arrOfPricesList, setArrOfPricesList] = useState<PriceListArr[]>([]);
  const [validate, setValidate] = useState(true);

  const { token } = useAuthContext();

  const navigate = useNavigate();

  const formik = useFormik<ValuesArticulos>({
    initialValues: {
      allowNegativeKardex: false,
      defaultPrice: false,
      barCode: "",
      code: "",
      description: "",
      measurementUnit: { label: "", id: 0 },
      name: "",
      operationType: "Gravadas",
      type: "",
      priceList: { label: "", id: 0 },
      price: "",
      groups: [],
      tags: { label: "", id: 0 },
      tagValue: "",
      storage: { label: "", id: 0 },
      costsCenters: { label: "", id: 0 },
      max: "",
      min: "",
      stock: "",
      photos: [],
    },
    validationSchema: validate ? validationSchema : null,
    onSubmit: async (values) => {
      addArticle({
        variables: {
          data: {
            type: getTypeOnSubmit(values?.type),
            name: values?.name,
            operation_type: getOperationTypeOnSubmit(values?.operationType),
            description: values?.description === "" ? null : values?.description,
            idMeasurementUnit: values.type !== ArticleTypes.SERVICIOS ? Number(values?.measurementUnit?.id) : 56,
            code: values?.code === "" ? null : values?.code,
            barCode: values?.barCode === "" ? null : values?.barCode,
            allowNegativeKardex: values.allowNegativeKardex,
          },
        },
      })
        .then((dataAddArticle) => {
          const { isSuccess, message, data } = dataAddArticle.data.addArticle;
          const { id } = data;
          if (isSuccess) {
            const arrTags: { idTag: number; value: string }[] = [];
            const arrPrice: { idPriceList: number; price: number; isDefault: boolean }[] = [];
            const arrIdGroup: number[] = [];
            const formData = new FormData();

            if (values.photos) {
              values.photos.map((file, index) => {
                if (index === 0) {
                  return formData.append("main", file);
                }
                return formData.append("file", file);
              });
            }
            if (values.type !== ArticleTypes.SERVICIOS) {
              if (arrOfBodegas.length > 0) {
                arrOfBodegas.map(async (item) => {
                  await addStorageArticle({
                    variables: { data: { idStorage: Number(item?.codigo), idArticle: id, min: getMinOnSubmit(item?.min), max: getMaxOnSubmit(item?.max), skul: getStockOnSubmit(item?.stock) } },
                  });
                });
              }
              if (arrOfTAgs.length > 0) {
                arrOfTAgs.map((item) => {
                  arrTags.push({ idTag: Number(item?.codigo), value: item?.tagValue });
                });
              }
            }
            if (arrOfPricesList.length > 0) {
              arrOfPricesList.map((item) => {
                arrPrice.push({ idPriceList: Number(item?.codigo), price: Number(item?.price), isDefault: item?.default === "Si" ? true : false });
              });
            }
            if (formik.values.groups.length > 0) {
              formik.values.groups.map((item) => {
                arrIdGroup.push(item.id);
              });
            }
            Promise.all([
              arrIdGroup.length > 0 ? addArticlesGroups({ variables: { data: { idArticle: id, idsGroups: arrIdGroup } } }) : null,
              values.photos.length > 0
                ? axios.post(`${process.env.REACT_APP_API_URL}/articulos/${id}/photos`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${token}`,
                    },
                  })
                : null,
              arrTags.length > 0 ? addArticlesTags({ variables: { data: { idArticle: id, tags: arrTags } } }) : null,
              arrPrice.length > 0 ? addArticleToPriceList({ variables: { data: arrPrice, idArticle: id } }) : null,
            ])
              .then((dataPromiseAll) => {
                if (values.type === ArticleTypes.SERVICIOS) {
                  if (dataPromiseAll[0] === null && dataPromiseAll[1] === null) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[0] === null && dataPromiseAll[1]) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[1].data.isSucess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[1] === null && dataPromiseAll[0]) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[0].data.addArticlesGroups.isSuccess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[0] && dataPromiseAll[1]) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[0].data.addArticlesGroups.isSuccess && dataPromiseAll[1].data.isSucess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                } else {
                  if (dataPromiseAll[0] === null && dataPromiseAll[1] === null && dataPromiseAll[2] === null) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[0] === null && dataPromiseAll[1] && dataPromiseAll[2]) {
                    if (dataPromiseAll[2].data.addArticlesTags.isSuccess && dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[1].data.isSucess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[1] === null && dataPromiseAll[0] && dataPromiseAll[2]) {
                    if (dataPromiseAll[2].data.addArticlesTags.isSuccess && dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[0].data.addArticlesGroups.isSuccess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[0] === null && dataPromiseAll[2] === null && dataPromiseAll[1]) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[1].data.isSucess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[1] === null && dataPromiseAll[2] === null && dataPromiseAll[0]) {
                    if (dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[0].data.addArticlesGroups.isSuccess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                  if (dataPromiseAll[0] && dataPromiseAll[1] && dataPromiseAll[2]) {
                    if (dataPromiseAll[2].data.addArticlesTags.isSuccess && dataPromiseAll[3].data.addArticleToPriceList.isSuccess && dataPromiseAll[0].data.addArticlesGroups.isSuccess && dataPromiseAll[1].data.isSucess) {
                      return toast.success(`Articulo creado correctamente`), navigate("/inventarios/articulos", { state: 0 });
                    }
                  }
                }
              })
              .catch((e) => {
                toast.error(`Hubo un error al crear el articulo ${e}`);
                console.log(e);
                deleteArticle({
                  variables: {
                    idArticle: id,
                  },
                }).then((dataDeleteArticle) => {
                  if (dataDeleteArticle.data.deleteArticle.isSuccess) {
                    navigate("/inventarios/articulos", { state: 0 });
                  }
                });
              });
          } else {
            toast.error(`Hubo un error al crear el articulo ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al crear el articulo ${e}`);
        });
    },
  });

  const getOperationTypeOnSubmit = (value: string) => {
    switch (value) {
      case OperationType.EXENTAS:
        return OperationType.EXENTAS.toUpperCase();
      case OperationType.GRAVADAS:
        return OperationType.GRAVADAS.toUpperCase();
      case OperationType.NO_SUJETAS:
        return OperationType.NO_SUJETAS.toUpperCase().replace(" ", "_");
      default:
        return value;
    }
  };
  function getStepContent(stepIndex: number) {
    if (formik.values.type === ArticleTypes.SERVICIOS) {
      switch (stepIndex) {
        case 0:
          return <Tipo formik={formik}></Tipo>;
        case 1:
          return <GeneralesArticulos formik={formik} />;
        case 2:
          return <Precio formik={formik} arrOfPricesList={arrOfPricesList} setArrOfPricesList={setArrOfPricesList} />;
      }
    } else {
      switch (stepIndex) {
        case 0:
          return <Tipo formik={formik}></Tipo>;
        case 1:
          return <GeneralesArticulos formik={formik} />;
        case 2:
          return <Bodega formik={formik} arrOfBodegas={arrOfBodegas} setArrOfBodegas={setArrOfBodegas} />;
        case 3:
          return <Caracteristica formik={formik} arrOfTAgs={arrOfTAgs} setArrOfTAgs={setArrOfTAgs} />;
        case 4:
          return <Precio formik={formik} arrOfPricesList={arrOfPricesList} setArrOfPricesList={setArrOfPricesList} />;
      }
    }
  }
  const getTypeOnSubmit = (value: string) => {
    switch (value) {
      case ArticleTypes.BIENES:
        return ArticleTypes.BIENES.toUpperCase();
      case ArticleTypes.SERVICIOS:
        return ArticleTypes.SERVICIOS.toUpperCase();
      case ArticleTypes.AMBOS:
        return ArticleTypes.AMBOS.toUpperCase();
      case ArticleTypes.OTRO:
        return ArticleTypes.OTRO.toUpperCase();
      default:
        return value;
    }
  };
  const handleNext = () => {
    setActiveStep((prev) => {
      if (formik.values.type === ArticleTypes.SERVICIOS) {
        if (prev < 2) return prev + 1;
        return prev;
      } else {
        if (prev < 4) return prev + 1;
        return prev;
      }
    });
  };
  const handleBack = () => {
    /* if (activeStep === 1) {
      formik.setValues((prev) => ({
        ...prev,
        storage: { label: "", id: 0 },
        costsCenters: { label: "", id: 0 },
        min: "",
        max: "",
        stock: "",
        allowNegativeKardex: false,
      }));
      formik.setFieldTouched("stock", false);
      formik.setFieldTouched("min", false);
      formik.setFieldTouched("max", false);
      formik.setFieldTouched("storage", false);
      formik.setFieldTouched("costsCenters", false);
    } */
    setActiveStep((prev) => {
      if (formik.values.type === ArticleTypes.SERVICIOS) {
        if (prev < 3) return prev - 1;
        return prev;
      } else {
        if (prev < 5) return prev - 1;
        return prev;
      }
    });
  };
  const buttonNext = (step: any) => {
    if (formik.values.type === ArticleTypes.SERVICIOS) {
      if (step === 0) {
        return (
          <MDButton
            disabled={!Boolean(formik.values.type)}
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      }
      if (step === 1) {
        return (
          <MDButton
            disabled={
              !Boolean(formik.values.name) ||
              Boolean(formik.errors.operationType) /* ||
              Boolean(formik.errors.barCode) ||
              Boolean(formik.errors.code) */ ||
              Boolean(formik.errors.description)
            }
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      } else {
        return (
          <MDButton
            disabled={arrOfPricesList.length === 0 || loading || dataAddArticleToPriceList.loading || dataAddStorageArticle.loading || dataAddArticlesTags.loading || dataAddArticlesGroups.loading}
            onClick={() => {
              formik.handleSubmit();
            }}
            color="info"
            variant="gradient"
          >
            {loading || dataAddArticleToPriceList.loading || dataAddStorageArticle.loading || dataAddArticlesTags.loading || dataAddArticlesGroups.loading ? "Enviando" : "Enviar"}
          </MDButton>
        );
      }
    } else {
      if (step === 0) {
        return (
          <MDButton
            disabled={!Boolean(formik.values.type)}
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      }
      if (step === 1) {
        return (
          <MDButton
            disabled={
              !Boolean(formik.values.name) ||
              Boolean(formik.errors.operationType) ||
              Boolean(formik.errors.measurementUnit) ||
              Boolean(formik.errors.barCode) ||
              Boolean(formik.errors.code) ||
              Boolean(formik.errors.description) ||
              Boolean(formik.errors.tags)
            }
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      }
      if (step === 2) {
        return (
          <MDButton
            disabled={arrOfBodegas.length === 0}
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      }
      if (step === 3) {
        return (
          <MDButton
            onClick={() => {
              handleNext();
            }}
            color="info"
            variant="gradient"
          >
            Siguiente
          </MDButton>
        );
      } else {
        return (
          <MDButton
            disabled={arrOfPricesList.length === 0 || loading || dataAddArticleToPriceList.loading || dataAddStorageArticle.loading || dataAddArticlesTags.loading || dataAddArticlesGroups.loading}
            onClick={() => {
              formik.handleSubmit();
            }}
            color="info"
            variant="gradient"
          >
            {loading || dataAddArticleToPriceList.loading || dataAddStorageArticle.loading || dataAddArticlesTags.loading || dataAddArticlesGroups.loading ? "Enviando" : "Enviar"}
          </MDButton>
        );
      }
    }
  };
  const getMinOnSubmit = (min: string) => {
    return min === "" ? null : Number(min);
  };
  const getMaxOnSubmit = (max: string) => {
    return max === "" ? null : Number(max);
  };
  const getStockOnSubmit = (stock: string) => {
    return stock === "" ? null : stock;
  };
  //Effect
  useEffect(() => {
    if (formik.values.type === ArticleTypes.SERVICIOS) {
      if (activeStep === 2) {
        setValidate(false);
      } else {
        setValidate(true);
      }
    } else {
      if (activeStep === 4) {
        setValidate(false);
      } else {
        setValidate(true);
      }
    }
  }, [activeStep]);
  useEffect(() => {
    if (formik.values.type === ArticleTypes.SERVICIOS) {
      setArrOfBodegas([]);
      setArrOfTAgs([]);
    }
  }, [formik.values.type]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {formik.values.type === ArticleTypes.SERVICIOS
                  ? stepsServicios.map((step) => (
                      <Step key={step}>
                        <StepLabel color="inherit">{step}</StepLabel>
                      </Step>
                    ))
                  : steps.map((step) => (
                      <Step key={step}>
                        <StepLabel color="inherit">{step}</StepLabel>
                      </Step>
                    ))}
              </Stepper>
            </MDBox>
            <Card>
              <Grid container minHeight={activeStep === 0 ? 600 : formik.values.type === ArticleTypes.SERVICIOS ? 600 : 721} p={4} flexDirection="column" justifyContent="space-between">
                <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" my={3}>
                  {getStepContent(activeStep)}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/inventarios/articulos", { state: 0 })} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default CrearArticulos;
