import gql from "graphql-tag";

export const GET_GENERAL_DOCUMENTS = gql`
  query GetGeneralDocuments($filters: GeneralDocumentsQueryFilterDti!) {
    getGeneralDocuments(filters: $filters) {
      isSuccess
      data {
        generationCodeDte
        jsonDteFex {
          ventaTercero {
            nit
            nombre
          }
          resumen {
            codIncoterms
            condicionOperacion
            descIncoterms
            descuento
            flete
            seguro
            totalDescu
            totalGravada
            totalNoGravado
            totalPagar
            montoTotalOperacion
          }
          emisor {
            tipoItemExpor
            recintoFiscal
            regimen
            codeRegimen
            codeRecintoFiscal
          }
          otrosDocumentos {
            codDocAsociado
            descDocumento
            detalleDocumento
            modoTransp {
              id
              descripcion
              codigo
            }
            placaTrans
            nombreConductor
            numConductor
          }
          cuerpoDocumento {
            cantidad
            codigo
            descripcion
            montoDescu
            noGravado
            numItem
            precioUni
            uniMedida {
              code
              id
              name
              symbol
              value
            }
            ventaGravada
          }
        }
        isSimpleBilling
        status
        dteControlNumber
        paymentCondition
        isr
        client
        documentReceiver
        jsonDte
        mhObservations
        history {
          email_destinatarios
          statusReception
          fecha
          eventType
        }
        paymentMethods {
          paymentMethod {
            id
            name
            code
            hasReferenceNumber
          }
          type
          finalAmount
        }
        clientCompany {
          id
          name
          contribuyenteType
          phone
          address
          plazoType
          tradename
          plazoType
          legalRepresentation
          address
          lineOfBusiness {
            name
          }
          emailNotifications
          retention
          perception
          isActiveCredit
          creditLimit
          excelse
          creditPeriod
          userDocuments {
            id
            documentNumber
            type
            code
          }
        }
        detailDocuments {
          id
          article {
            barCode
            code
            description
            id
            name
            operation_type
            type
          }
          articleType
          detailQuantity
          priceDetail
          idArticlePriceList
          discountDetails
          measurement {
            code
            id
            name
            value
          }
          description
          ivaOperationType
          createdAt
          updatedAt
          unitPrice
          priceList {
            id
            name
          }
        }
        date
        emisionDate
        createdAt
        environment
        idClientCompany
        id
        pdfBase64
        selloRecibido
        pdf
        receptionDate
        costCenter {
          id
          name
        }
        salePoint {
          id
          code
          mhCode
          storage {
            id
            name
            allowSale
            isActive
            createdAt
            updatedAt
          }
          model
          serie
          name
          resolution
          mark
          date
          createdAt
          updatedAt
        }
        receiverName
        cashier {
          idEmployeeCompany
          firstName
          secondName
          firstLastName
          secondLastName
          marriedLastName
          image
          createdAt
          updatedAt
        }
        observations
        perceptionAmount
        retentionAmount
        ivaAmount
        totalToPay
        subtotal
        correlative {
          id
          doc_number
          serial_number
          doc_reference
          createdAt
          updatedAt
        }
        ivaDocument {
          name_document
          id
          isActive
          description
          extended_name_document
        }
        operationType
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
