import React from "react";

// @mui material components
import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Types
import { FormikProps } from "formik";
import { DTE_INFO } from "../../facturacion/components/SellsTable";
import { FormikValues } from "layouts/ventas/verDtesRechazados/components/DtesRechazadosTable";

interface Props {
  formik: FormikProps<FormikValues>;
  dteInformation: DTE_INFO;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleCloseDelete: any;
}

function Step2({ formik, dteInformation, setActiveStep, handleCloseDelete }: Props) {
  return (
    <>
      <DialogTitle
        children={
          <Typography color="info" variant="h4" fontWeight="bold">
            Identifica el documento a invalidar
          </Typography>
        }
      />
      <DialogContent>
        <MDBox>
          <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
            Confirma que quieres anular el{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`${dteInformation.ivaDocumentName} N° ${dteInformation.nroDTE}`}
            </MDTypography>{" "}
            del cliente llamado{" "}
            <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
              {`"${dteInformation.clientName.label}".`}{" "}
            </MDTypography>
            de fecha {dteInformation.date} por un monto de ${dteInformation.amount}
          </MDTypography>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.nroDTE) && formik.touched.nroDTE}
              onBlur={() => formik.setFieldTouched("nroDTE", true)}
              label="Número del DTE"
              name="nroDTE"
              helperText={!formik.touched.nroDTE || (formik.touched.nroDTE && !formik.errors.nroDTE) ? "Escribe el número exacto del DTE a eliminar" : formik.errors.nroDTE}
              onChange={formik.handleChange}
              variant="standard"
              value={formik.values.nroDTE}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleCloseDelete();
          }}
          variant="outlined"
          color="info"
        >
          cancelar
        </MDButton>
        <MDButton
          onClick={() => {
            if (Boolean(formik.errors.nroDTE)) {
              formik.setFieldTouched("nroDTE", true);
            } else {
              setActiveStep(2);
            }
          }}
          disabled={Boolean(formik.errors.nroDTE) || formik.values.nroDTE === ""}
          variant="contained"
          color="info"
        >
          Siguiente
        </MDButton>
      </DialogActions>
    </>
  );
}

export default Step2;
