// @mui material components
import { Card, Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Custom Component
import TabsBox from "./components/TabsBox";

const OtherInfoDte = () => {
  return (
    <Grid item xs={12}>
      <Card sx={{ padding: 5 }}>
        <MDBox>
          <MDTypography color="info" variant="h4">
            Otra Informacion del DTE
          </MDTypography>
          <MDTypography color="secondary" variant="subtitle2">
            Estos datos son opcionales, pero ayudan para tener mas informacion con respecto al DTE
          </MDTypography>
        </MDBox>
        <MDBox my={5}>
          <TabsBox />
        </MDBox>
      </Card>
    </Grid>
  );
};

export default OtherInfoDte;
