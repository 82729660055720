import gql from "graphql-tag";

export const GET_COSTS_CENTERS = gql`
  query GetCostsCenters($filters: CostCenterQueryFilterDti) {
    getCostsCenters(filters: $filters) {
      isSuccess
      data {
        id
        name
        address
        type
        salePoints {
          id
          code
          date
          name
          resolution
          serie
        }
        createdAt
        updatedAt
        region {
          id
          name
          type
          code {
            id
            code
            goberment {
              id
              name
            }
          }
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
