import gql from "graphql-tag";

export const GET_ARTICLES = gql`
  query GET_ARTICLES($articlesFilters: ArticlesQueryFilterDti!) {
    getArticles(articlesFilters: $articlesFilters) {
      isSuccess
      data {
        id
        name
        code
        barCode
        tags {
          id
          name
        }
        salesPrices {
          salePrice
        }
        storages {
          articleStock
          skul
        }
      }
      message {
        detail
        code
        message
      }
    }
  }
`;
