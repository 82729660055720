// Material Dashboard 2 PRO React TS
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// React and GraphQl
import { useState } from "react";

// Custom components
import PatronalesEmployees from "./components/PatronalesEmployees";
import DireccionEmployees from "./components/DireccionEmployees";
import GeneralesEmployees from "./components/GeneralesEmployees";

// Queries
import { ADD_EMPLOYEE } from "apollo/queries/employees/addEmployee";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

//Alert
import { toast } from "react-hot-toast";

// Types
import { SerializationKeys } from "types/apollo";
import { EmployeesValues } from "types/employeesComponentsProps";
import { useMutation } from "@apollo/client";

// Utils
import { validarDUI } from "utils/validations/validarDui";
import { validarNIT } from "utils/validations/validarNit";

// Data
const steps = ["Generales", "Direccion", "Patronal"];

const validationSchema = Yup.object({
  firstName: Yup.string().required("El primer nombre es requerido").nullable(),
  secondName: Yup.string().nullable(),
  firstLastName: Yup.string().required("El primer apellido es requerido").nullable(),
  secondLastName: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  emailNotifications: Yup.string().trim().email("Correo invalido").required("El correo es requerido es requerido").nullable(),
  gender: Yup.string().required("El genero es requerido").nullable(),
  pais: Yup.object({
    label: Yup.string(),
    id: Yup.string(),
  })
    .required("El pais es requerido")
    .nullable(),
  // departamento: Yup.string().required("El telefono es requerido").nullable(),
  // municipio: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
  address: Yup.string().required("La dirreción es requerida").nullable(),
  checkInDate: Yup.string().required("La fecha de ingreso es requerida").nullable(),
  // checkOutDate: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
  // afpType: Yup.string().nullable(),
  // isss: Yup.string().nullable(),
  // nup: Yup.string().nullable(),
});

export const validate = (values: EmployeesValues) => {
  const errors: any = {};

  if (!values.dui && !values.nit) {
    errors.dui = "Al menos un documento de identidad es requerido";
    errors.nit = "Al menos un documento de identidad es requerido";
  }

  if (values.dui && validarDUI(values.dui)) {
    errors.dui = validarDUI(values.dui);
  }

  if (values.nit && validarNIT(values.nit)) {
    errors.nit = validarNIT(values.nit);
  }

  return errors;
};

function CrearEmpleados(): JSX.Element {
  // States
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();

  // mutations
  const [addEmployee, { data, loading, error }] = useMutation(ADD_EMPLOYEE);

  const formik = useFormik<EmployeesValues>({
    initialValues: {
      nit: "",
      dui: "",
      firstName: "",
      secondName: "",
      firstLastName: "",
      secondLastName: "",
      phone: "",
      emailNotifications: "",
      gender: "",
      pais: null,
      departamento: null,
      municipio: null,
      address: "",
      checkInDate: "",
      checkOutDate: "",
      afpType: null,
      isss: "",
      nup: "",
    },
    validationSchema,
    validate,
    onSubmit: async (values) => {
      const arr = [];
      if (values.dui !== "") {
        arr.push({
          documentNumber: values.dui,
          type: "DUI",
        });
      }
      if (values.nit !== "") {
        arr.push({
          documentNumber: values.nit,
          type: "NIT",
        });
      }
      if (values.isss !== "") {
        arr.push({
          documentNumber: values.isss,
          type: "ISSS",
        });
      }
      if (values.nup !== "") {
        arr.push({
          documentNumber: values.nup,
          type: "NUP",
        });
      }
      try {
        const res = await addEmployee({
          variables: {
            employeeData: {
              firstName: values.firstName,
              secondName: values.secondName,
              firstLastName: values.firstLastName,
              secondLastName: values.secondLastName,
              address: values.address,
              idRegion: values?.municipio?.id === "0" || values?.municipio === null ? Number(values.pais.id) : Number(values.municipio.id),
              genre: values.gender.toUpperCase(),
              afpType: values.afpType?.toUpperCase(),
              isSupport: true,
            },
            employeeCompany: {
              email: values.emailNotifications === "" ? null : values.emailNotifications,
              checkInDate: values.checkInDate,
              checkOutDate: values.checkOutDate === "" ? null : values.checkOutDate,
              phone: values.phone === "" ? null : values.phone,
            },
            documents: arr,
          },
        });
        const dataAddEmployee = res;
        if (dataAddEmployee.data.addEmployee?.isSuccess) {
          toast.success("El empleado se creo correctamente");
          navigate("/contactos/empleados");
        } else {
          toast.error(`Hubo un error al crear el empleado ${dataAddEmployee.data.addEmployee?.message?.detail}`);
        }
      } catch (error) {
        toast.error(`Hubo un error al crear el empleado ${error}`);
      }
    },
  });

  function getStepContent(stepIndex: number): JSX.Element {
    switch (stepIndex) {
      case 0:
        return <GeneralesEmployees formik={formik} />;
      case 1:
        return <DireccionEmployees formik={formik} />;
      case 2:
        return <PatronalesEmployees formik={formik} />;
    }
  }
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev >= 1) return prev - 1;
      return prev;
    });
  };
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 2) return prev + 1;
      return prev;
    });
  };
  const buttonNext = (step: number) => {
    if (step === 0) {
      return (
        <MDButton
          disabled={!Boolean(formik.values.firstName) || Boolean(formik.errors.dui) || Boolean(formik.errors.nit) || Boolean(formik.errors.firstLastName) || Boolean(formik.errors.firstLastName) || Boolean(formik.errors.gender)}
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 1) {
      return (
        <MDButton
          disabled={Boolean(formik.errors.pais) || Boolean(formik.errors.departamento) || Boolean(formik.errors.municipio) || Boolean(formik.errors.address)}
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={loading || !Boolean(formik.values.checkInDate)}
          type="submit"
          color="info"
          variant="gradient"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={580} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/contactos/empleados")} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CrearEmpleados;
