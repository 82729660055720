// React
import { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSellsConfigContext } from "context/SellsConfigContext";

// Forms
import axios, { AxiosResponse } from "axios";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";

// Hooks
import { useLocalStorage } from "hooks/useLocalStorage";

// Alert
import toast, { Toaster } from "react-hot-toast";

// Constants
import { AUTH_URL, REFRESH_URL } from "constants/auth";

// Types
import { Props } from "types/react";
import { useNetworkStatus } from "hooks/useNetworkStatus";
import { useUserContext } from "./UserContext";

export interface AuthProviderProps {
  token: string;
  isLoading: boolean;
  isLoggedIn: boolean;
  onLogin: ({ email, password }: AuthCredentials) => Promise<any> | void;
  onLogout: () => Promise<void> | void;
  renderAlert?: () => JSX.Element;
}

export interface AuthCredentials {
  email: string;
  password: string;
}

const AuthContext = createContext<AuthProviderProps>({
  token: null,
  isLoading: false,
  isLoggedIn: false,
  onLogin: () => {
    // do nothing
  },
  onLogout: () => {
    // do nothing
  },
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }: Props) => {
  // States
  const [persistedToken, setPersistedToken] = useLocalStorage("token", null);
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", null);
  const [expiration, setExpiration] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNetworkActive] = useNetworkStatus();

  // Hooks
  const location = useLocation();
  const { user } = useUserContext();

  // Methods
  const handleRefresh = async () => {
    try {
      const resp: AxiosResponse = await axios.get(REFRESH_URL);

      if (resp.data.hasAccessToCachedData) {
        setIsLoggedIn(true);
      } else {
        setPersistedToken(resp.data.data.access_token);
        setExpiration(parseInt(resp.data.data.exp));
      }
    } catch (error) {
      toast.error(`Ocurrió un error al validar la sessión - ${error}`);

      handleLogout();
    }
  };

  const handleLogin = async ({ email, password }: AuthCredentials) => {
    setIsLoading(true);

    if (!isNetworkActive) {
      if (email === user.email) {
        const offlineData = { type: "hazconta-auth", payload: { email, url: AUTH_URL } };

        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage(offlineData);
        });
      } else {
        toast.error(`No tienes conexión a internet y no existe información guardada para el correo ingresado.`);
        setIsLoading(false);

        return;
      }
    }

    try {
      const resp: AxiosResponse = await axios.post(
        AUTH_URL,
        {
          email: email,
          password: isNetworkActive ? password : "",
        },
        {
          headers: {
            withCredentials: true,
          },
        }
      );

      if (!resp?.data?.hasAccessToCachedData) {
        setPersistedToken(resp.data.access_token);
        setExpiration(resp.data.exp);
      }

      setIsLoggedIn(true);
      setIsLoading(false);
      toast.success("Bienvenido!");

      return resp.data;
    } catch (error) {
      toast.error(`Hubo un error al iniciar sesión - ${error}`);

      setIsLoading(false);

      return error;
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const renderAlert = () => {
    return (
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 10000,
          style: {
            fontSize: "1rem",
            maxWidth: "600px",
            color: "#fff",
            borderRadius: "4px",
          },
          success: {
            style: {
              background: colors.success.main,
            },
          },
          error: {
            style: {
              background: colors.error.main,
            },
          },
        }}
      />
    );
  };

  const whiteList = ["/authentication/sign-in", "/gracias", "/precios"];
  // Effects
  useEffect(() => {
    if (expiration > 100000000) {
      const EARLY_REFRESH = expiration / 1000 - 100000;
      setTimeout(handleRefresh, EARLY_REFRESH);
    } else if (expiration < 100000000 && !whiteList.includes(location.pathname)) {
      handleRefresh();
    }
  }, [expiration]);

  const value = {
    token: persistedToken,
    isLoading,
    isLoggedIn,
    onLogin: handleLogin,
    onLogout: handleLogout,
    renderAlert,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
