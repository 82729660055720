import { useEffect } from "react";

// Types
import { Props } from "types/react";

// Context
import { useAuthContext } from "context/AuthContext";

const SignOut = ({ children }: Props) => {
  const { onLogout } = useAuthContext();

  useEffect(() => {
    onLogout();
  }, []);
  return <>{children}</>;
};

export default SignOut;
