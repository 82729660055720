// ProductsList page components
import DefaultCellSells from "../components/IdCell/DefaultCellSells";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

// @mui material components
import { Checkbox } from "@mui/material";

export const sellsDataTableData = {
  columns: [
    {
      Header: <Checkbox color="primary" checked={true} readOnly />,
      accessor: "select",
      width: "1%",
      hiddeSort: true,
    },
    {
      Header: "Nro de control",
      accessor: "num_control",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Fecha",
      accessor: "date",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Estado",
      accessor: "state",
      Cell: ({ value }: any) => {
        let status;

        if (value === "APROBADO") {
          status = <StatusCell icon="done" color="success" status="Aprobado" fontSize="0.85rem" />;
        } else if (value === "INVALIDADO") {
          status = <StatusCell icon="replay" color="dark" status="Invalidado" fontSize="0.85rem" />;
        } else if (value === "RECHAZADO") {
          status = <StatusCell icon="close" color="error" status="Rechazado" fontSize="0.85rem" />;
        } else if (value === "CONTIGENCIA") {
          status = <StatusCell icon="wifi_off_rounded_icon" color="warning" status="Contigencia" fontSize="0.85rem" />;
        } else {
          status = <StatusCell icon="priority_high" color="secondary" status="Sin estado" fontSize="0.85rem" />;
        }

        return status;
      },
      width: "10%",
      align: "start",
    },
    {
      Header: "Cliente",
      accessor: "client",
      width: "25%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Entorno",
      accessor: "environment",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Monto",
      accessor: "amount",
      width: "10%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Acciones",
      accessor: "actions",
      width: "5%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
  ],
};

export const sellsDataTableDataMobile = {
  columns: [
    {
      Header: <Checkbox color="primary" checked={true} readOnly />,
      accessor: "select",
      width: "1%",
      hiddeSort: true,
    },
    {
      Header: "Nro de Control",
      accessor: "num_control",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Fecha",
      accessor: "date",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Estado",
      accessor: "state",
      Cell: ({ value }: any) => {
        let status;

        if (value === "APROBADO") {
          status = <StatusCell icon="done" color="success" status="Aprobado" fontSize="0.85rem" noText />;
        } else if (value === "INVALIDADO") {
          status = <StatusCell icon="replay" color="dark" status="Invalidado" fontSize="0.85rem" noText />;
        } else if (value === "RECHAZADO") {
          status = <StatusCell icon="close" color="error" status="Rechazado" fontSize="0.85rem" noText />;
        } else if (value === "CONTIGENCIA") {
          status = <StatusCell icon="wifi_off_rounded_icon" color="warning" status="Contigencia" fontSize="0.85rem" noText />;
        } else {
          status = <StatusCell icon="priority_high" color="secondary" status="Sin estado" fontSize="0.85rem" noText />;
        }

        return status;
      },
      width: "2%",
      align: "center",
    },
    {
      Header: "Cliente",
      accessor: "client",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    /* {
      Header: "Entorno",
      accessor: "environment",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    {
      Header: "Monto",
      accessor: "amount",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      accessor: "actions",
      width: "1%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
  ],
};
export const sellsDataTableDataMobileMd = {
  columns: [
    {
      Header: <Checkbox color="primary" checked={true} readOnly />,
      accessor: "select",
      width: "1%",
      hiddeSort: true,
    },
    {
      Header: "Nro de Control",
      accessor: "num_control",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Fecha",
      accessor: "date",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      Header: "Estado",
      accessor: "state",
      Cell: ({ value }: any) => {
        let status;

        if (value === "APROBADO") {
          status = <StatusCell icon="done" color="success" status="Aprobado" fontSize="0.85rem" noText />;
        } else if (value === "INVALIDADO") {
          status = <StatusCell icon="replay" color="dark" status="Invalidado" fontSize="0.85rem" noText />;
        } else if (value === "RECHAZADO") {
          status = <StatusCell icon="close" color="error" status="Rechazado" fontSize="0.85rem" noText />;
        } else if (value === "CONTIGENCIA") {
          status = <StatusCell icon="wifi_off_rounded_icon" color="warning" status="Contigencia" fontSize="0.85rem" noText />;
        } else {
          status = <StatusCell icon="priority_high" color="secondary" status="Sin estado" fontSize="0.85rem" noText />;
        }

        return status;
      },
      width: "2%",
      align: "center",
    },
    /* {
      Header: "Cliente",
      accessor: "client",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    /* {
      Header: "Entorno",
      accessor: "environment",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    {
      Header: "Monto",
      accessor: "amount",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      accessor: "actions",
      width: "1%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
  ],
};
export const sellsDataTableDataMobileSm = {
  columns: [
    /* {
      Header: <Checkbox color="primary" checked={true} readOnly />,
      accessor: "select",
      width: "1%",
      hiddeSort: true,
    }, */
    {
      Header: "Nro de Control",
      accessor: "num_control",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    /* {
      Header: "Fecha",
      accessor: "date",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    {
      Header: "Estado",
      accessor: "state",
      Cell: ({ value }: any) => {
        let status;

        if (value === "APROBADO") {
          status = <StatusCell icon="done" color="success" status="Aprobado" fontSize="0.85rem" noText />;
        } else if (value === "INVALIDADO") {
          status = <StatusCell icon="replay" color="dark" status="Invalidado" fontSize="0.85rem" noText />;
        } else if (value === "RECHAZADO") {
          status = <StatusCell icon="close" color="error" status="Rechazado" fontSize="0.85rem" noText />;
        } else if (value === "CONTIGENCIA") {
          status = <StatusCell icon="wifi_off_rounded_icon" color="warning" status="Contigencia" fontSize="0.85rem" noText />;
        } else {
          status = <StatusCell icon="priority_high" color="secondary" status="Sin estado" fontSize="0.85rem" noText />;
        }

        return status;
      },
      width: "2%",
      align: "center",
    },
    /* {
      Header: "Cliente",
      accessor: "client",
      width: "5%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    /* {
      Header: "Entorno",
      accessor: "environment",
      width: "15%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    }, */
    {
      Header: "Monto",
      accessor: "amount",
      width: "2%",
      align: "start",
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
    {
      accessor: "actions",
      width: "1%",
      align: "end",
      hiddeSort: true,
      Cell: ({ value }: any) => <DefaultCellSells value={value} />,
    },
  ],
};
