import gql from "graphql-tag";

export const ADD_EMPLOYEE = gql`
  mutation ADD_EMPLOYEE($employeeData: EmployeeDti!, $employeeCompany: EmployeeCompanyDti!, $documents: [DocumentDti!]!) {
    addEmployee(employeeData: $employeeData, employeeCompany: $employeeCompany, documents: $documents) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
