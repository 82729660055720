import React, { createContext, useState, useContext, useEffect } from "react";

// Libs
import { useParams } from "react-router-dom";

// Graphql
import { useLazyQuery } from "@apollo/client";

// Queries
import { GET_GENERAL_DOCUMENTS } from "apollo/queries/dtes/getGeneralDocuments";

// Types
import { GeneralDocument, JsonDte } from "types/documents";

export interface Props {
  children: React.ReactNode;
}

export interface GetGeneralDocumentProviderProps {
  generalDocument: GeneralDocument;
  jsonDte?: JsonDte;
  loading?: boolean;
  error?: any;
}

const VerGetGeneralDocument = createContext<GetGeneralDocumentProviderProps>({
  generalDocument: null,
});

export const useGetGeneralDocumentContext = () => {
  return useContext(VerGetGeneralDocument);
};

export const GetGeneralDocumentContextProvider = ({ children }: Props) => {
  const [generalDocument, setGeneralDocument] = useState<GeneralDocument>(null);
  const [jsonDte, setJsonDte] = useState<JsonDte>(null);
  const params = useParams();

  const [getGeneralDocument, { data, loading, error }] = useLazyQuery(GET_GENERAL_DOCUMENTS);

  useEffect(() => {
    getGeneralDocument({
      variables: {
        filters: {
          idDte: parseInt(params.id),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      setGeneralDocument(data.getGeneralDocuments.data[0]);
      setJsonDte(JSON.parse(data.getGeneralDocuments.data[0].jsonDte));
    }
  }, [data]);

  const value = {
    generalDocument,
    jsonDte,
    loading,
    error,
  };

  return <VerGetGeneralDocument.Provider value={value}>{children}</VerGetGeneralDocument.Provider>;
};
