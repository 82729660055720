export const haveBeenPass24h = (fecha: string) => {
  const ahora = new Date(); // Fecha y hora actuales
  const fechaPasada = new Date(fecha); // Convertir la fecha pasada a un objeto Date

  // Calcular la diferencia en milisegundos
  const diferencia = Number(ahora) - Number(fechaPasada);

  // Convertir la diferencia a horas
  const horasPasadas = diferencia / (1000 * 60 * 60);

  // Verificar si han pasado 24 horas
  return horasPasadas >= 24;
};
