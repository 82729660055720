// @mui material components
import { ButtonBase, Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Formik
import { FormikProps } from "formik";

// Img
import selfCheck from "../../img/SelfCheck.svg";
import selfCheckBlue from "../../img/SelfCheck1.svg";
import newEntries from "../../img/NewEntries-bro.svg";
import newEntriesBlue from "../../img/NewEntries-bro1.svg";

// Types
import { Bodegas } from "types/bodegas";

// Theme
import colors from "assets/theme/base/colors";

interface Props {
  formik: FormikProps<Bodegas>;
}

function TipoBodegas({ formik }: Props) {
  return (
    <>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mt={2.5} mb={{ xs: 3, md: 6 }}>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Finalidad de la Bodega
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Si seleccionas que la bodega es para la venta, podras asociar a la bodega a un punto de ventas. Si la bodega es solo es para almacenar el producto, no podras asociarlo a un punto de ventas.
        </MDTypography>
      </MDBox>
      <MDBox display="flex">
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.finalidad === "Para la Venta" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.finalidad === "Para la Venta" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Para la Venta"
                onClick={(e) => {
                  formik.setFieldValue("finalidad", e.currentTarget.name);
                }}
              >
                <img src={formik.values.finalidad === "Para la Venta" ? selfCheckBlue : selfCheck} alt={"Amigo"} height={130} />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.finalidad === "Para la Venta" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Para la Venta
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox>
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.finalidad === "Almacenamiento" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.finalidad === "Almacenamiento" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Almacenamiento"
                onClick={(e) => {
                  formik.setFieldValue("finalidad", e.currentTarget.name);
                }}
              >
                <img src={formik.values.finalidad === "Almacenamiento" ? newEntriesBlue : newEntries} alt={"Amigo"} height={130} />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.finalidad === "Almacenamiento" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Almacenamiento
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default TipoBodegas;
