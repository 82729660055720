import gql from "graphql-tag";

export const INVALIDATE_GENERAL_DOCUMENT = gql`
  mutation INVALIDATE_GENERAL_DOCUMENT($input: InvalidGeneralDocumentDti!) {
    invalidateGeneralDocument(input: $input) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
