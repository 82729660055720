import { useEffect, useRef, useContext } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// @mui material components
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "./ShortCutsLabels";

// Utils
import { formatNumber } from "utils/formatNumber";

// GraphQL
import { useLazyQuery } from "@apollo/client";

// Queries
import { GET_ARTICLES_FOR_SELLING } from "apollo/queries/articles/getArticlesForSelling";

// Constants
import { PAGINATION } from "constants/pagination";
import { IVA_AMOUNT } from "constants/finantial";

// Libs
import { useFormikContext } from "formik";
import { toast } from "react-hot-toast";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { ShortcutContext } from "context/ShortcutContext";
import { KeyboardContext } from "context/KeyboardContext";

// Types
import { SalePrice, SellingArticle } from "types/articles-for-selling";
import { OperationType } from "types/environment";
import { SerializationKeys } from "types/apollo";
import { IvaDocumentTypes } from "types/iva-document";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

interface DetailFilter {
  refDetailFilter?: React.MutableRefObject<HTMLInputElement>;
}

const AddDetailsDteFilter = ({ refDetailFilter }: DetailFilter) => {
  // Context
  const { keys } = useContext(KeyboardContext);
  const { sellsConfig } = useSellsConfigContext();
  const { triggerListener, registerListener } = useContext(ShortcutContext);
  const { values, setFieldValue, setValues, touched, handleSubmit, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // GraphQL
  const [getArticlesForSelling, { data: productCodes }] = useLazyQuery(GET_ARTICLES_FOR_SELLING, { context });

  // Refs
  const ProductCode = useRef<HTMLInputElement>(null);
  const quantity = useRef<HTMLInputElement>(null);
  const salePrice = useRef<HTMLInputElement>(null);
  const operation_type = useRef<HTMLInputElement>(null);

  const isFAC = values.IvaDocumentType?.name_document?.includes("FAC");
  const isOptionEqualToValue = (option: any, value: any) => option.label === value.label;

  const changePriceOnOperationTypeChange = (newValue: { label: string; value: string }) => {
    const idSalePrice = values.salePrice?.id;
    const lastPrice = values.productCodes?.salesPrices?.find((item: SalePrice) => item?.id === idSalePrice);
    const updatedSalePrice = {
      id: lastPrice?.id,
      label: isFAC && newValue?.label === OperationType.GRAVADAS ? formatNumber(lastPrice?.salePrice * (1 + IVA_AMOUNT)) : formatNumber(lastPrice?.salePrice),
    };
    if (lastPrice) {
      setValues((prev) => ({
        ...prev,
        operation_type: newValue,
        salePrice: updatedSalePrice,
        detailDocument: {
          ...prev.detailDocument,
          salePrice: Number(
            updatedSalePrice?.label
              .split("")
              .filter((item) => item !== ",")
              .join("")
          ),
        },
      }));
    } else {
      setFieldValue("operation_type", newValue);
    }
  };

  // Effects
  useEffect(() => {
    if (sellsConfig) {
      getArticlesForSelling({
        variables: {
          filters: { pagination: PAGINATION, idSalePoint: sellsConfig?.selectedSalePoint?.id },
        },
      });
    }
  }, []);
  useEffect(() => {
    registerListener(new Set(["control", "t"]), () => {
      if (operation_type.current) {
        operation_type.current.focus();
        operation_type.current.click();
      }
    });
    registerListener(new Set(["control", "i"]), () => {
      if (ProductCode.current) {
        ProductCode.current.focus();
        ProductCode.current.click();
      }
    });
    registerListener(new Set(["control", "g"]), () => {
      if (salePrice.current) {
        salePrice.current.focus();
        salePrice.current.click();
      }
    });

    registerListener(new Set(["control", "q"]), () => {
      const childNode = quantity?.current?.querySelector<HTMLInputElement>("#quantity");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });

    return () => {
      // removeListener(keys);
    };
  }, [triggerListener]);

  return (
    <>
      <Grid item xs={12} ref={refDetailFilter ? refDetailFilter : null}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Agregar Detalles del Documento Electronico Tributario
            </MDTypography>
            <MDTypography color="secondary" variant="subtitle2">
              Puedes buscar los productos por: escribiendo el nombre del producto en el campo de texto o el codigo ya sea escribiendolo o escaneandolo dandole click en el icono a un costado
            </MDTypography>
          </MDBox>
          <Grid container gap={{ xs: 3, lg: 5 }} my={5} mb={1}>
            <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
              <Grid item width={{ xs: "100%", lg: "50%" }} sx={{ position: "relative" }}>
                <Autocomplete
                  id="ProductCode"
                  openOnFocus
                  getOptionDisabled={(option: any) => option?.label === "No hay codigos de producto"}
                  fullWidth
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField ref={ProductCode} data-testid="autocomplete-ProductCode" name="ProductCode" {...params} label="Codigo de Productos*" />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "i", label: "I" },
                        ]}
                      />
                      <MDBox position="absolute" width="100%">
                        <CustomFieldError name="test" touched={touched.productCodes ? true : false} errorName={errors.productCodes && (errors.productCodes as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                  value={values.productCodes ? ({ label: (values.productCodes?.code ? values.productCodes?.code + " - " : "") + values.productCodes?.name } as SellingArticle) : null}
                  isOptionEqualToValue={isOptionEqualToValue}
                  onChange={async (_, newValue: SellingArticle, reason) => {
                    if (reason === "clear") {
                      setValues((prev) => ({
                        ...prev,
                        productCodes: null,
                        salePrice: null,
                        detailDocument: {
                          ...prev.detailDocument,
                          salePrice: null,
                        },
                      }));
                      return;
                    } else {
                      const selectedProduct: any = productCodes.getArticlesForSelling.data.find((product: SellingArticle) => {
                        return newValue.id === product.id;
                      });

                      const { salesPrices } = selectedProduct;
                      const defaultSalePrice = salesPrices?.find((product: SalePrice) => product.isDefault)?.salePrice || salesPrices[0].salePrice;
                      const defaultIdSalePrice = salesPrices?.find((product: SalePrice) => product.isDefault)?.id || salesPrices[0]?.id;

                      const defaultSalePriceInput = {
                        label: defaultSalePrice,
                        id: defaultIdSalePrice,
                      };

                      const updatedSalePrice = isFAC && values.operation_type?.label === OperationType.GRAVADAS && !values.clientCompany?.isExent ? Number(defaultSalePriceInput?.label) * (1 + IVA_AMOUNT) : Number(defaultSalePriceInput?.label);

                      if (defaultSalePriceInput?.id) {
                        setValues({
                          ...values,
                          productCodes: selectedProduct,
                          salePrice: {
                            label: formatNumber(updatedSalePrice),
                            price: String(updatedSalePrice),
                            id: defaultSalePriceInput.id,
                          },
                          detailDocument: {
                            ...values.detailDocument,
                            salePrice: Number(
                              String(updatedSalePrice)
                                .split("")
                                .filter((item: any) => item !== ",")
                                .join("")
                            ),
                            description: selectedProduct?.description,
                          },
                        });
                      } else {
                        setValues({
                          ...values,
                          productCodes: selectedProduct,
                          salePrice: null,
                          detailDocument: {
                            ...values.detailDocument,
                            salePrice: null,
                            description: selectedProduct?.description,
                          },
                        });
                      }
                    }
                  }}
                  options={
                    productCodes?.getArticlesForSelling?.data
                      ? productCodes?.getArticlesForSelling?.data
                          .filter((item: SellingArticle) => item && item.salesPrices && item.salesPrices.length > 0)
                          .map((item: SellingArticle) => {
                            return {
                              ...item,
                              label: (item.code ? item.code + " - " : "") + item.name,
                            };
                          })
                      : [{ label: "No hay codigos de producto" }]
                  }
                />
              </Grid>
              <Grid item width={{ xs: "100%", lg: "50%" }} sx={{ position: "relative" }}>
                <MDBox position="relative">
                  <MDInput
                    id="quantity"
                    ref={quantity}
                    type="number"
                    label="Cantidad*"
                    fullWidth
                    inputProps={{ step: 0.01, min: 0 }}
                    value={values.quantity}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    disabled={values.productCodes ? false : true}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      setValues((prev) => ({
                        ...prev,
                        quantity: parseFloat(event.target.value),
                        detailDocument: {
                          ...prev.detailDocument,
                          quantity: parseFloat(event.target.value),
                        },
                      }));
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "q", label: "Q" },
                    ]}
                  />
                  <MDBox position="absolute" width="100%">
                    <CustomFieldError touched={touched?.quantity ? true : false} errorName={errors?.quantity as string} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" justifyContent="center" alignItems="center" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
              <Grid item width={{ xs: "100%", lg: "20%" }}>
                <Autocomplete
                  id="salePrice"
                  ref={salePrice}
                  openOnFocus
                  fullWidth
                  disabled={values.productCodes ? false : true}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField {...params} label="Precio Unitario*" />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "g", label: "G" },
                        ]}
                      />
                      <MDBox position="absolute" width="100%">
                        <CustomFieldError touched={touched?.salePrice ? true : false} errorName={errors?.salePrice as string} />
                      </MDBox>
                    </MDBox>
                  )}
                  value={values.salePrice}
                  onChange={async (_, newValue, reason) => {
                    if (reason === "clear") {
                      setValues((prev) => ({
                        ...prev,
                        salePrice: null,
                        detailDocument: {
                          ...prev.detailDocument,
                          salePrice: null,
                        },
                      }));
                      return;
                    } else {
                      setValues((prev) => ({
                        ...prev,
                        salePrice: newValue,
                        detailDocument: {
                          ...prev.detailDocument,
                          salePrice: Number(
                            newValue.price
                              .split("")
                              .filter((item: any) => item !== ",")
                              .join("")
                          ),
                        },
                      }));
                    }
                  }}
                  options={
                    values.productCodes?.salesPrices?.map((item: SalePrice) => {
                      const updatedSalePrice = isFAC && values.operation_type?.label === OperationType.GRAVADAS && !values.clientCompany?.isExent ? item.salePrice * (1 + IVA_AMOUNT) : item.salePrice;
                      return {
                        label: formatNumber(updatedSalePrice),
                        price: String(updatedSalePrice),
                        id: item.id,
                      };
                    }) || []
                  }
                />
              </Grid>
              <Grid item width={{ xs: "100%", lg: "20%" }} sx={{ position: "relative" }}>
                {values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? (
                  <TextField
                    id="productDiscount"
                    fullWidth
                    label="Descuento del Producto"
                    sx={{ input: { paddingY: "1.04rem" } }}
                    value={values.productDiscount}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!isNaN(Number(event.target.value))) {
                        await setFieldValue("productDiscount", event.target.value);
                      }
                    }}
                  />
                ) : (
                  <Autocomplete
                    id="operation_type"
                    ref={operation_type}
                    fullWidth
                    openOnFocus
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionDisabled={(option) => {
                      if (values.clientCompany?.isExent) {
                        return option.label === OperationType.GRAVADAS ? true : false;
                      }
                    }}
                    defaultValue={
                      values.clientCompany?.isExent
                        ? {
                            label: OperationType.EXENTAS,
                            value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.EXENTAS),
                          }
                        : {
                            label: OperationType.GRAVADAS,
                            value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS),
                          }
                    }
                    disabled={values?.productCodes ? false : true}
                    renderInput={(params) => (
                      <MDBox position="relative">
                        <TextField {...params} label="Gravado*" />
                        <ShortCutsLabels
                          keys={keys}
                          command={[
                            { key: "control", label: "CTRL" },
                            { key: "t", label: "T" },
                          ]}
                        />
                        <MDBox position="absolute" width="100%">
                          <CustomFieldError touched={touched?.operation_type ? true : false} errorName={errors?.operation_type as string} />
                        </MDBox>
                      </MDBox>
                    )}
                    value={values.operation_type}
                    onChange={async (event, newValue, reason) => {
                      if (values.clientCompany?.isExent && newValue.label === OperationType.GRAVADAS) {
                        toast.error(`El cliente ${values.clientCompany?.name} es exento de IVA, por favor modifica el contacto o selecciona una opción válida`, {
                          duration: 5000,
                        });
                        setFieldValue("operation_type", null);
                      } else {
                        if (reason === "clear") {
                          setValues((prev) => ({
                            ...prev,
                            operation_type: null,
                            salePrice: null,
                            detailDocument: {
                              ...prev.detailDocument,
                              salePrice: null,
                            },
                          }));

                          return;
                        } else {
                          if (newValue?.label !== values.operation_type?.label) {
                            changePriceOnOperationTypeChange(newValue);
                          }
                        }
                      }
                    }}
                    options={
                      values.clientCompany?.isExent
                        ? [
                            { label: OperationType.EXENTAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.EXENTAS) },
                            { label: OperationType.NO_SUJETAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.NO_SUJETAS) },
                          ]
                        : [
                            { label: OperationType.GRAVADAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS) },
                            { label: OperationType.EXENTAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.EXENTAS) },
                            { label: OperationType.NO_SUJETAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.NO_SUJETAS) },
                          ]
                    }
                  />
                )}
              </Grid>
              <Grid item width={{ xs: "100%", lg: "20%" }}>
                <TextField
                  id="totalPrice"
                  fullWidth
                  disabled
                  label="Total"
                  sx={{ input: { paddingY: "1.04rem" } }}
                  value={(values.detailDocument?.salePrice && values.detailDocument?.quantity && formatNumber((values.detailDocument?.salePrice - Number(values.productDiscount ?? "")) * values.detailDocument?.quantity)) || ""}
                />
              </Grid>
              <Grid item width={{ xs: "100%", lg: "10%" }}>
                <MDButton
                  id="addDetailDocument"
                  fullWidth
                  onClick={async () => {
                    handleSubmit();
                  }}
                  color="info"
                  size="small"
                >
                  Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default AddDetailsDteFilter;
