import gql from "graphql-tag";

export const GET_TAGS = gql`
  query GET_TAGS($tagsFilters: QueryFilterDti!) {
    getTags(tagsFilters: $tagsFilters) {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
