import { useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

//Fromik
import { useFormik } from "formik";

//Types
import { Values } from "layouts/authentication/register";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

//yup
import * as Yup from "yup";

//Axios
import axios from "axios";

//Alert
import { toast } from "react-hot-toast";
import CrearEmpresa from "layouts/authentication/register/crearEmpresa";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { getDefaultValues } from "@apollo/client/utilities";

const ADD_COMPANY_ENTITY = gql`
  mutation ADD_COMPANY_ENTITY($companyData: CompanyDti!, $documents: [DocumentDti!]!) {
    addCompanyEntity(companyData: $companyData, documents: $documents) {
      isSuccess
      data {
        id
        name
        contribuyenteType
        documents {
          documentNumber
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  //Company
  contribuyenteType: Yup.string()
    .trim()
    .required("El tamaño del contribuyente es requerido")
    .matches(/(GRANDE|MEDIANO|OTRO)/, { message: "Tamaño del contribuyente invalido" })
    .ensure(),
  companyName: Yup.string().trim().required("El nombre del contribuyente es requerido").min(4, "El nombre del contribuyente debe tener al menos 4 caracteres"),
  nombreComercial: Yup.string().trim().min(4, "El nombre comercial debe tener al menos 4 caracteres"),
  actividadPrincipal: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .required("La actividad principal es requerida")
    .nullable(),
  pais: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .required("El pais es requerido")
    .nullable(),
  departamento: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  municipio: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  nit: Yup.string()
    .trim()
    .required("El NIT es requerido")
    .matches(/(\d{4})-(\d{6})-(\d{3})-(\d{1})/, { message: "NIT invalido" }),
  nrc: Yup.string()
    .trim()
    .required("El NRC es requerido")
    .matches(/(\d\1)-(\d{1})/, { message: "NRC invalido" }),
  telefono: Yup.string().trim().required("El telefono es requerido").min(7, "El telefono debe tener al menos 7 caracteres"),
  direccion: Yup.string().trim().nullable(),
  companyEmail: Yup.string().trim().required("El correo es requerido").email("Correo invalido"),
});

function CrearNuevaEmpresa(): JSX.Element {
  const navigate = useNavigate();

  //State
  const [reset, setReset] = useState<boolean>(false);

  //graphql
  const [addCompanyEntity, result] = useMutation(ADD_COMPANY_ENTITY);

  const formik = useFormik<Values>({
    initialValues: {
      contribuyenteType: "",
      companyName: "",
      nombreComercial: "",
      legalRepresentation: "",
      actividadPrincipal: null,
      nit: "",
      nrc: "",
      pais: null,
      departamento: null,
      municipio: null,
      direccion: "",
      telefono: "",
      companyEmail: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      addCompanyEntity({
        variables: {
          companyData: {
            name: values.companyName,
            representationLegalType: values.legalRepresentation,
            idRegion: values.municipio !== null ? Number(values.municipio.id) : Number(values.pais.id),
            email: values.companyEmail,
            phone: values.telefono,
            address: values.direccion,
            tradename: values.nombreComercial === "" ? values.companyName : values.nombreComercial,
            idLineOfBusiness: Number(values.actividadPrincipal.id),
            contribuyenteType: values.contribuyenteType,
          },
          documents:
            values.nit !== "" && values.nrc !== ""
              ? [
                  { type: "NIT", documentNumber: values.nit },
                  { type: "NRC", documentNumber: values.nrc },
                ]
              : values.nit !== "" && values.nrc === ""
              ? [{ type: "NIT", documentNumber: values.nit }]
              : values.nrc !== "" && values.nit === "" && [{ type: "NRC", documentNumber: values.nrc }],
        },
      })
        .then((data) => {
          console.log(data);
          if (data.data.addCompanyEntity.isSuccess) {
            toast.success("Compañia creada exitosamente");
            navigate("/configuracion/empresa");
          } else {
            toast.error(`Hubo un error al crear la compañia - ${data.data.addCompanyEntity.message.detail}`);
            setReset(true);
          }
        })
        .catch((error) => {
          toast.error(`Hubo un error al crear la compañia - ${error}`);
          setReset(true);
        });
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} mb={9} minHeight="100vh">
        <CrearEmpresa formik={formik} reset={reset} setReset={setReset} result={result} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CrearNuevaEmpresa;
