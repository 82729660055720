import gql from "graphql-tag";

export const ADD_GROUP = gql`
  mutation ADD_TAG($data: GroupDti!) {
    addGroup(data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
      data {
        id
        createdAt
        updatedAt
        company {
          id
          name
        }
      }
    }
  }
`;
