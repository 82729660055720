/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import PageLayout from "views/LayoutContainers/PageLayout";

// Assets
import brandDark from "assets/images/hazconta/logo-dark.png";

// Context
import { useUserContext } from "context/UserContext";

// Declaring props types for BasicLayout
interface Props {
  children: ReactNode;
}

function SinglePageLayout({ children }: Props): JSX.Element {
  const { user } = useUserContext();

  return (
    <PageLayout>
      <Grid container position="absolute" top={{ xs: 20, lg: 40 }} width="100%" minHeight="5vh" justifyContent="space-between" alignItems="center">
        <Grid item xs={11} sm={9} md={8} xl={8} mx="auto">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item xs={4} md={3} lg={3}>
              {brandDark && <MDBox component="img" src={brandDark} alt="Brand" width="100%" />}
            </Grid>

            <Grid item xs={8} sx={{ opacity: "70%" }}>
              <MDTypography
                // display={{ xs: "none", lg: "block" }}
                variant="h3"
                paragraph
                mb={0}
                align="right"
                fontSize={{ xs: "1rem", sm: "1.3rem", lg: "1.5rem", xl: "1.8rem" }}
              >
                Hola {user?.name && user.name}
              </MDTypography>
              <MDTypography
                // display={{ xs: "none", lg: "block" }}
                variant="h5"
                paragraph
                mb={0}
                color="info"
                align="right"
                fontSize={{ xs: "1rem", sm: "1.3rem", lg: "1.5rem", xl: "1.8rem" }}
              >
                Bienvenido a HazConta
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={8} xl={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default SinglePageLayout;
