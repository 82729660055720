// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import Footer from "views/Footer";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";

// Custom Components
import InfoSection from "./components/Info";
import PreviewSection from "./components/Preview";

// Context
import { GeneralDocumentContextProvider } from "context/VerGeneralDocumentContext";
import { GetGeneralDocumentContextProvider } from "context/GetGeneralDocumentContext";

const VerDocumento = () => {
  return (
    <>
      <GetGeneralDocumentContextProvider>
        <GeneralDocumentContextProvider>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={5} mb={9} minHeight="100vh">
              <Grid id="parents" container flexDirection={{ xs: "column", lg: "row" }} justifyContent="center" gap={4} flexWrap={{ xs: "wrap", xxl: "nowrap" }}>
                <PreviewSection />
                <InfoSection />
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </GeneralDocumentContextProvider>
      </GetGeneralDocumentContextProvider>
    </>
  );
};

export default VerDocumento;
