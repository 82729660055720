import { useRef, useState } from "react";
import Icon from "@mui/material/Icon";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { FormikErrors } from "formik";

interface Props {
  options: String[];
  onSelectOption?: React.Dispatch<
    React.SetStateAction<{
      option: string;
      id: string;
    }>
  >;
  id?: string;
  setDeletedItem?: any;
  item?: any;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          name: string;
          description: string;
          nameDeleteForm: string;
          costCenter: {
            id: number;
            label: string;
          };
          storage: {
            id: number;
            label: string;
          };
          updateName: string;
          updateDescription: string;
        }>
      >;
}

function MenuDropdown({ options, onSelectOption, id, item, setDeletedItem, setFieldValue }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>

      <Popper
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        sx={{ color: `${option === "Archivar" || option === "Eliminar" ? "#FF4300" : "#00547A"}` }}
                        onClick={(e) => {
                          setOpen(false);
                          onSelectOption(() => ({
                            option: e.currentTarget.textContent,
                            id,
                          }));
                          if (setFieldValue) {
                            setFieldValue("updateName", item?.name);
                          }
                          if (setDeletedItem) {
                            setDeletedItem(item);
                          }
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </>
  );
}
export default MenuDropdown;
