import { useEffect } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import { Autocomplete, Card, CircularProgress, Grid, TextField } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Graph
import { gql, useMutation, useQuery } from "@apollo/client";

//Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";
import { departamentos, getMunicipios, municipiosTotal, paises } from "utils/getRegions";

//Types
import { Oficinas } from "types/Oficinas";

//Queries
const UPDATE_COST_CENTER = gql`
  mutation UpdateCostCenter($idCostCenter: Int, $costCenterData: UpdateCostCenterDti) {
    updateCostCenter(idCostCenter: $idCostCenter, costCenterData: $costCenterData) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const GET_COST_CENTER = gql`
  query GET_COST_CENTER($idCostCenter: Float!) {
    getCostCenter(idCostCenter: $idCostCenter) {
      isSuccess
      data {
        id
        name
        type
        address
        internalCode
        mhCode
        company {
          email
          phone
          lineOfBusiness {
            id
            name
          }
        }
        region {
          id
          name
          type
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

const validate = (values: Oficinas) => {
  const errors: any = {};

  if (!values.type) {
    errors.type = "El tipo de oficina es requerido";
  }
  //General
  if (!values.nombre) {
    errors.nombre = "El nombre es requerido";
  } else if (values.nombre.length < 4) {
    errors.nombre = "El nombre debe tener al menos 4 caracteres";
  }
  if (values.telefono) {
    if (!/^[0-9]{8}$/i.test(values.telefono)) {
      errors.telefono = "Telefono invalido";
    }
  }
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Correo invalido";
    }
  }

  // Direccion
  if (!values.pais) {
    errors.pais = "El pais es requerido";
  }
  if (values.pais?.label === "EL SALVADOR" && values.departamento?.label.length < 4 && !values.departamento) {
    errors.departamento = "El departamento debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.municipio?.label.length < 4 && !values.municipio) {
    errors.municipio = "El municipio debe ser mayor a 4 caracteres";
  }
  if (values.direccion && values.direccion.length < 4) {
    errors.direccion = "La direccion debe ser mayor a 4 caracteres";
  }

  return errors;
};

function EditarOficinas(): JSX.Element {
  // React-Router-Dom
  const navigate = useNavigate();
  const { idCostCenter } = useParams();

  //Queries and Mutations
  const { data, loading } = useQuery(GET_COST_CENTER, {
    variables: {
      idCostCenter: Number(idCostCenter),
    },
  });
  const [updateCostCenter, dataUpdataCostCenter] = useMutation(UPDATE_COST_CENTER);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      actividadPrincipal: {
        id: 0,
        label: "",
      },
      internalCode: "",
      mhCode: "",
      type: "",
      telefono: "",
      email: "",
      pais: {
        label: "",
        id: "0",
      },
      departamento: null,
      municipio: null,
      direccion: "",
    },
    validate,
    onSubmit: async (values) => {
      updateCostCenter({
        variables: {
          idCostCenter: Number(idCostCenter),
          costCenterData: {
            name: values.nombre,
            address: values.direccion,
            internalCode: values.internalCode,
            phone: values.telefono,
            mhCode: values.mhCode,
            type: getTypeOfficeValueOnSubmit(values.type),
            idRegion: values?.municipio?.id ? Number(values?.municipio?.id) : Number(values?.pais?.id),
          },
        },
      })
        .then((dataUpdataCostCenter) => {
          if (dataUpdataCostCenter.data.updateCostCenter.isSuccess) {
            toast.success("La oficina se actualizo correctamente");
            navigate("/configuracion/empresa", { state: 1 });
          } else {
            toast.success(`Hubo un error al actualizar la oficina. ${dataUpdataCostCenter.data.updateCostCenter?.message?.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Hubo un error al actualizar la oficina. Intente nuevamente");
        });
    },
  });

  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.nombre && formik.touched.nombre) {
        toast.error(formik.errors.nombre);
      }
      if (formik.errors.telefono && formik.touched.telefono) {
        toast.error(formik.errors.telefono);
      }
      if (formik.errors.email && formik.touched.email) {
        toast.error(formik.errors.email);
      }
      if (formik.errors.mhCode && formik.touched.mhCode) {
        toast.error(formik.errors.mhCode);
      }
      if (formik.errors.internalCode && formik.touched.internalCode) {
        toast.error(formik.errors.internalCode);
      }
      if (formik.errors.pais && formik.touched.pais) {
        toast.error("El pais debe ser mayor a 4 caracteres");
      }
      if (formik.errors.departamento && formik.touched.departamento) {
        toast.error("El departamento debe ser mayor a 4 caracteres");
      }
      if (formik.errors.municipio && formik.touched.municipio) {
        toast.error("El municipio debe ser mayor a 4 caracteres");
      }
      if (formik.errors.direccion && formik.touched.direccion) {
        toast.error(formik.errors.direccion);
      }
    }
  };

  const getTypeOfficeValue = (value: string) => {
    switch (value) {
      case "SUCURSAL":
      case "01":
        return "Sucursal";
      case "CASA_MATRIZ":
      case "02":
        return "Casa Matriz";
      case "BODEGA":
      case "04":
        return "Bodega";
      case "PREDIO":
      case "07":
        return "Predio";
      case "OTRO":
      case "20":
        return "Otro";
      default:
        return value;
    }
  };

  const getTypeOfficeValueOnSubmit = (value: string) => {
    switch (value) {
      case "Casa Matriz":
        return "CASA_MATRIZ";
      case "Sucursal":
        return "SUCURSAL";
      case "Predio":
        return "PREDIO";
      case "Otro":
        return "OTRO";
    }
  };

  //Effects
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        nombre: data.getCostCenter.data?.name,
        actividadPrincipal: {
          label: data.getCostCenter.data.company.lineOfBusiness?.name,
          id: data.getCostCenter.data.lineOfBusiness?.id,
        },
        internalCode: data.getCostCenter.data.internalCode,
        mhCode: data.getCostCenter.data.mhCode,
        type: getTypeOfficeValue(data.getCostCenter.data.type),
        telefono: data.getCostCenter.data.company?.phone,
        email: data.getCostCenter.data.company?.email,
        direccion: data.getCostCenter.data?.address,
      }));
      const paisId = paises.find((item: any) => item.id == data.getCostCenter.data?.region?.id);
      const departamentoId = departamentos.find((item: any) => item.id == data.getCostCenter.data?.region?.id);
      const cityId: any = municipiosTotal.find((item: any) => item.id == data.getCostCenter.data?.region?.id);
      if (cityId) {
        formik.setFieldValue("municipio", cityId);
        const depCity = departamentos.find((item) => item.id === cityId.idParent);
        formik.setFieldValue("departamento", depCity);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (departamentoId) {
        formik.setFieldValue("departamento", departamentoId);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (paisId) {
        formik.setFieldValue("pais", paisId);
      }
    }
  }, [data]);

  //Effects
  useEffect(() => {
    if (formik.values?.pais?.label !== "EL SALVADOR") {
      formik.setValues((prev) => ({
        ...prev,
        departamento: null,
        municipio: null,
      }));
    }
  }, [formik.values.pais]);
  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Nombre Legal"
                              error={Boolean(formik.errors.nombre) && formik.touched.nombre}
                              name="nombre"
                              helperText={!formik.touched.nombre || (formik.touched.nombre && !formik.errors.nombre) ? " " : formik.errors.nombre}
                              onChange={formik.handleChange}
                              onBlur={() => formik.setFieldTouched("nombre", true)}
                              value={formik.values.nombre}
                              color="info"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          freeSolo
                          options={["Casa Matriz", "Sucursal", "Predio", "Otro"]}
                          value={formik.values.type}
                          onChange={(e, value) => {
                            formik.setFieldValue("type", value);
                          }}
                          onBlur={() => formik.setFieldTouched("type", true)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Oficina"
                              variant="standard"
                              error={Boolean(formik.errors.type) && formik.touched.type}
                              helperText={!formik.touched.type || (formik.touched.type && !formik.errors.type) ? " " : formik.errors.type}
                              fullWidth
                              color="info"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Medios de Notificacion
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                      Estos datos se utilizaran para enviarte los documentos tributarios electroncios.
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Telefonos"
                        error={Boolean(formik.errors.telefono)}
                        value={formik.values.telefono}
                        onChange={formik.handleChange}
                        name="telefono"
                        helperText={!formik.errors.telefono ? " " : `${formik.errors.telefono}`}
                        color="info"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Direccion
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Direccion"
                        name="direccion"
                        error={Boolean(formik.errors.direccion)}
                        helperText={!formik.errors.direccion ? " " : `${formik.errors.direccion}`}
                        value={formik.values.direccion}
                        onChange={formik.handleChange}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 9.5 }}>
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            id="pais"
                            options={paises}
                            freeSolo
                            value={formik.values.pais}
                            onChange={(e, value) => formik.setFieldValue("pais", value)}
                            onBlur={() => formik.setFieldTouched("pais", true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pais"
                                error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                                helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && (
                            <Autocomplete
                              freeSolo
                              onChange={(e, value) => formik.setFieldValue("departamento", value)}
                              value={formik.values.departamento}
                              onBlur={() => formik.setFieldTouched("departamento", true)}
                              options={departamentos}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Departamento"
                                  error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                                  helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                            <Autocomplete
                              freeSolo
                              value={formik.values.municipio}
                              onChange={(e, value) => formik.setFieldValue("municipio", value)}
                              onBlur={() => formik.setFieldTouched("municipio", true)}
                              options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Municipio"
                                  error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                                  helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/configuracion/empresa")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdataCostCenter.loading}
                    onClick={() => {
                      validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdataCostCenter.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditarOficinas;
