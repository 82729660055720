import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// @mui material components
import { Card } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//react-router-dom
import { useLocation } from "react-router-dom";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";
import StepperCat from "components/Global/StepperCat";
import ImportLDP from "./Imports/ArticulosImportLDP";
import ExportLDP from "./Exports/ExportLDP";
import PtosDeVtasLDPTable from "./PtosDeVtasLDPTable";
import DialogDeletePtosDeVtasLDP from "./Dialogs/DialogDeletePtosDeVtasLDP";

// Alert
import { toast } from "react-hot-toast";

// GraphQl
import { FetchResult, useLazyQuery, useMutation } from "@apollo/client";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Queries and mutations
import { GET_COSTS_CENTERS } from "apollo/queries/costsCenters/getCostsCenters";
import { GET_SALE_POINTS } from "apollo/queries/salePoints/getSalePoints";
import { ADD_SALE_POINT_TO_PRICE_LIST } from "apollo/mutations/salePoints/addSalePointToPriceList";
import { GET_SALE_POINTS_BY_PRICE_LIST } from "apollo/queries/salePoints/getSalePointsByPriceList";
import DialogAddPtosDeVtasLDP from "./Dialogs/DialogAddPtosDeVtasLDP";

// Types
import { LabelandId } from "types/labelAndId";
import { NameAndId } from "./DetallesLDP";
import { SerializationKeys } from "types/apollo";
import { ValuesLDP } from "types/ValuesLDP";
import { PAGINATION } from "constants/pagination";

const columns = [
  { Header: "Codigo", accessor: "codigo" },
  { Header: "Nombre", accessor: "nombre" },
  { Header: "Depende", accessor: "depende" },
  { Header: "Equipo", accessor: "equipo" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];
const options = ["Eliminar"];

function PtosDeVtasLDPIndex() {
  const location = useLocation();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries and mutations
  const [getSalePointsByPriceList, dataGetSalePointsByPriceList] = useLazyQuery(GET_SALE_POINTS_BY_PRICE_LIST, { context });
  const [getSalePoints, dataGetSalePoints] = useLazyQuery(GET_SALE_POINTS, { context });
  const [getCostsCenters, dataGetCostsCenters] = useLazyQuery(GET_COSTS_CENTERS, { context });
  const [addSalePointToPriceList, dataAddSalePointToPriceList] = useMutation(ADD_SALE_POINT_TO_PRICE_LIST, { context });

  // States
  const [activeStep, setActiveStep] = useState(0);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [costCenters, setCostCenters] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [salePoints, setSalePoints] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [nameDelete, setNameDelete] = useState<NameAndId>();
  const [open, setOpen] = useState({
    openAdd: false,
    openDelete: false,
  });

  const validationSchema = Yup.object({
    costCenter: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
      .nullable()
      .required("El centro de costo es requerido"),
    salePoint: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
      .nullable()
      .required("El punto de venta es requerido"),
    nameSalePointDelete: Yup.string().oneOf([nameDelete?.name, null], "El nombre del punto de venta debe coincidir"),
  });

  const formik = useFormik<ValuesLDP>({
    initialValues: {
      costCenter: {
        id: 0,
        label: "",
      },
      salePoint: {
        id: 0,
        label: "",
      },
      nameSalePointDelete: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      addSalePointToPriceList({
        variables: {
          data: {
            idPriceList: Number(location.state.id),
            idsSalePoint: [Number(values.salePoint.id)],
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addSalePointToPriceList;
          if (isSuccess) {
            getSalePointsByPriceList({
              variables: {
                filters: {
                  idPriceList: Number(location.state.id),
                  pagination: PAGINATION,
                },
              },
            });
            toast.success("Punto de venta agregado correctamente");
            handleClose(true);
            formik.setFieldValue("costCenter", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("costCenter", false);
            formik.setFieldValue("salePoint", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("salePoint", false);
          } else {
            toast.error(`Hubo un error al agregar el punto de venta ${message.detail}`);
            handleClose(true);
            formik.setFieldValue("costCenter", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("costCenter", false);
            formik.setFieldValue("salePoint", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("salePoint", false);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al agregar el punto de venta ${e}`);
          handleClose(true);
          formik.setFieldValue("costCenter", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("costCenter", false);
          formik.setFieldValue("salePoint", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("salePoint", false);
        });
    },
  });

  const handleSucces = () => {
    getSalePointsByPriceList({
      variables: {
        filters: {
          idPriceList: Number(location.state.id),
          pagination: PAGINATION,
        },
      },
    });
    toast.success("Punto de venta eliminado correctamente");
    handleClose(false);
    setNameDelete({ name: "", id: null });
    formik.setFieldValue("nameSalePointDelete", "");
    formik.setFieldTouched("nameSalePointDelete", false);
  };
  const handleFail = (data?: FetchResult<any>, e?: any) => {
    if (data) {
      toast.error(`Hubo un error al eliminar el punto de venta ${data.data.deleteSalePointFromPriceList.message.detail}`);
      handleClose(false);
      setNameDelete({ name: "", id: null });
      formik.setFieldValue("nameSalePointDelete", "");
      formik.setFieldTouched("nameSalePointDelete", false);
    } else {
      toast.error(`Hubo un error al eliminar el punto de venta ${e}`);
      handleClose(false);
      setNameDelete({ name: "", id: null });
      formik.setFieldValue("nameSalePointDelete", "");
      formik.setFieldTouched("nameSalePointDelete", false);
    }
  };
  const handleClickOpen = (value: boolean) => {
    if (value) {
      setOpen((prev) => ({
        ...prev,
        openAdd: true,
      }));
    } else {
      setOpen((prev) => ({
        ...prev,
        openDelete: true,
      }));
    }
  };
  function handleClose(value: boolean) {
    if (value) {
      setOpen((prev) => ({
        ...prev,
        openAdd: false,
      }));
    } else {
      setOpen((prev) => ({
        ...prev,
        openDelete: false,
      }));
    }
  }

  //Effects
  useEffect(() => {
    if (location.state.id) {
      getSalePointsByPriceList({
        variables: {
          filters: {
            idPriceList: Number(location.state.id),
            pagination: PAGINATION,
          },
        },
      });
    }
  }, [location.state.id]);

  useEffect(() => {
    if (dataGetSalePointsByPriceList.data) {
      setRowsData(dataGetSalePointsByPriceList.data?.getSalePointsByPriceList?.data.filter((item: any) => item.salePoints.length > 0));
    }
  }, [dataGetSalePointsByPriceList.data]);

  useEffect(() => {
    if (dataGetCostsCenters?.data) {
      setCostCenters(
        dataGetCostsCenters?.data?.getCostsCenters?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetCostsCenters?.data]);

  useEffect(() => {
    if (dataGetSalePoints?.data) {
      setSalePoints(
        dataGetSalePoints?.data?.getSalePoints?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetSalePoints?.data]);

  useEffect(() => {
    if (open.openAdd) {
      getCostsCenters({
        variables: {
          filters: {
            pagination: PAGINATION,
          },
        },
      });
    }
  }, [open.openAdd]);

  useEffect(() => {
    if (formik.values.costCenter.id) {
      getSalePoints({
        variables: {
          filters: {
            idCostCenter: Number(formik.values.costCenter.id),
            pagination: PAGINATION,
          },
        },
      });
    }
  }, [formik.values.costCenter.id]);

  useEffect(() => {
    if (selectedMenuOption.option === "Eliminar") {
      const name = dataGetSalePointsByPriceList.data?.getSalePointsByPriceList?.data
        .filter((item: any) => item?.salePoints.length > 0)
        .filter((item: any) => item.salePoints.some((item: any) => item.id == selectedMenuOption.id))[0]
        .salePoints.filter((item: any) => item.id == selectedMenuOption.id)[0];
      console.log(name, selectedMenuOption.id);
      setNameDelete(() => ({
        id: name.id,
        name: name.name,
      }));
      handleClickOpen(false);
    }
  }, [selectedMenuOption]);

  //Data
  const rows = useMemo(() => {
    return rowsData
      .map((item) => {
        return item.salePoints.map((itemSale: any) => ({
          codigo: itemSale?.code ?? "No hay codigo",
          nombre: itemSale?.name,
          depende: item.name ?? "No hay dependencia",
          equipo: itemSale?.mark ?? "No hay nombre del equipo",
          menu: <MenuDropdown options={options} id={String(itemSale?.id)} onSelectOption={setSelectedMenuOption} />,
        }));
      })
      .flat();
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows ? (rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows) : [{ nombre: "No hay informacion" }],
  };

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: (
        <>
          <PtosDeVtasLDPTable namePriceList={location.state.namePriceList} loading={dataGetSalePointsByPriceList.loading} table={table} handleClickOpen={handleClickOpen} />
          <DialogDeletePtosDeVtasLDP open={open} handleClose={handleClose} formik={formik} nameDelete={nameDelete} location={location} handleFail={handleFail} handleSucces={handleSucces} />
          <DialogAddPtosDeVtasLDP open={open} handleClose={handleClose} formik={formik} loading={dataAddSalePointToPriceList.loading} costCenters={costCenters} salePoints={salePoints} />
        </>
      ),
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: (
        <ImportLDP>
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Importar puntos de venta a la Lista de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>

            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`En esta parte podrás importar puntos de venta a la listas de precio llamada ${location.state.namePriceList}, pero debes de cumplir con el formato que puedes descargar en el siguiente botón:`}
            </MDTypography>
          </>
        </ImportLDP>
      ),
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: (
        <ExportLDP name="Puntos De Venta">
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Exportar puntos de venta de la Listas de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`Solamente haz clic en el botón de descargar y podrás obtener toda la información de los puntos de venta relacionados a la listas de precios llamada ${location.state.namePriceList} en un archivo de excel.`}
            </MDTypography>
          </>
        </ExportLDP>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9} minHeight="100vh">
          <MDBox>
            <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
          </MDBox>
          <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
export default PtosDeVtasLDPIndex;
