import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";

export const tagsService = {
  /**
   * Descarga la plantilla desde la URL
   */
  async downloadTemplate(idCompany: number): Promise<void> {
    try {
      const response: AxiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/articles-tag/download-template`, {
        responseType: "blob",
        headers: { authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}` },
      });
      fileDownload(response.data, "plantilla-articulo.xlsx ");
    } catch (e) {
      toast.error(`Ocurrió un error al realizar la descarga de la plantilla`);
    }
  },

  async handleSubmit(idCompany: number, files: any[]) {
    if (files.length > 0) {
      const formData = new FormData();

      files.map((file: any) => {
        return formData.append("file", file);
      });
      axios
        .post(`${process.env.REACT_APP_API_URL}/articles-tag/upload/${idCompany}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
        })
        .then((data) => {
          console.log(data);
          if (data.data.isSucess) {
            toast.success("Archivos subidos correctamente");
          }
        })
        .catch((error) => {
          toast.error(`Hubo un error al subir los archivos ${error.message}`);
        });
    }
  },
};
