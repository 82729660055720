/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Declaring props types for IdCell
interface Props {
  id?: string;
  checked?: boolean;
  selectedItem: any;
  idDoc?: number;
  setChecked: (checkedItems: any) => void;
}

function CustomIdCell({ id, checked, selectedItem, setChecked, idDoc }: Props): JSX.Element {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <Checkbox
          color="primary"
          checked={checked ? true : false}
          onChange={(e) => {
            if (setChecked) {
              if (e.target.checked) {
                setChecked((checkedItems: any) => {
                  return [...checkedItems, selectedItem];
                });
              } else {
                setChecked((checkedItems: any) => {
                  return checkedItems.filter((item: any) => item.id !== idDoc);
                });
              }
            }
          }}
        />
      </MDBox>
      <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }} fontSize="0.85rem">
        {id}
      </MDTypography>
    </MDBox>
  );
}

// Declaring default props for IdCell
CustomIdCell.defaultProps = {
  checked: false,
};

export default CustomIdCell;
