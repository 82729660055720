import { Props } from "types/react";
import { useAuthContext } from "context/AuthContext";
import { Navigate } from "react-router-dom";

const OnlyPublicRoute = ({ children }: Props) => {
  const { isLoggedIn } = useAuthContext();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default OnlyPublicRoute;
