/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// Apollo Client
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "views/Sidenav";
import Configurator from "views/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/hazconta/logo.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Contexts
import { UserContextProvider } from "context/UserContext";
import { AuthContextProvider } from "context/AuthContext";
import { CompanyContextProvider } from "context/CompanyContext";
import { SellsConfigContextProvider } from "context/SellsConfigContext";
import { AddSellContextProvider } from "context/AddSellContext";
import KeyboardContextProvider from "context/KeyboardContext";
import ShortcutContextProvider from "context/ShortcutContext";

// Custom components
import PrivateRoute from "components/Authentication/PrivateRoute";
import NetworkStatusTag from "components/Global/NetworkStatusTag";

// Libs
import { Toaster } from "react-hot-toast";

// Layouts
import NuevoDTE from "layouts/ventas/nuevoDTE";
import Comenzar from "layouts/authentication/sign-in/comenzar";
import VerDocumento from "layouts/ventas/ver";
import CrearClientes from "layouts/contactos/clientes/crear";
import EditarClientes from "layouts/contactos/clientes/editar";
import EditarOficinas from "layouts/configuracion/empresa/editar/editarOficinas";
import CrearOficina from "layouts/configuracion/empresa/crear/crearOficina";
import CrearBodega from "layouts/configuracion/empresa/crear/crearAlmacen";
import EditarBodegas from "layouts/configuracion/empresa/editar/editarBodegas";
import CrearPtosDeVtas from "layouts/configuracion/empresa/crear/crearPtosDeVtas";
import EditarPtosDeVtas from "layouts/configuracion/empresa/editar/editarPtosDeVtas";
import CrearArticulos from "layouts/inventarios/Articulos/crear/crearArticulos";
import EditarArticulos from "layouts/inventarios/Articulos/editar/editarArticulos";
import SignInIllustration from "layouts/authentication/register";
import ResetPassword from "layouts/authentication/reset-password";
import ArticulosLDPTable from "layouts/inventarios/ListaDePrecios/components/ArticulosLDPTable";
import ClientesLDPTable from "layouts/inventarios/ListaDePrecios/components/ClientesLDPTable";
import PtosDeVtasLDPTable from "layouts/inventarios/ListaDePrecios/components/PtosDeVtasLDPIndex";
import VerDtesRechazadosMultados from "layouts/ventas/verDtesRechazados/Multados";
import VerDtesRechazadosPendientes from "layouts/ventas/verDtesRechazados/Pendientes";
import CrearEmpleados from "layouts/contactos/empleados/crear";
import EditarEmpleados from "layouts/contactos/empleados/editar";
// import PricingPage from "layouts/pages/pricing-page";
// import ThankYouPage from "layouts/pages/thank-you";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map((route: { name: string; collapse: any; route: string; component: ReactElement<any, string | JSXElementConstructor<any>>; key: Key }) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <AuthContextProvider>
            <CompanyContextProvider>
              <AddSellContextProvider>
                <SellsConfigContextProvider>
                  <ShortcutContextProvider>
                    <KeyboardContextProvider>
                      <ThemeProvider theme={darkMode ? themeDark : theme}>
                        <NetworkStatusTag />
                        <CssBaseline />

                        {layout === "dashboard" && (
                          <>
                            <Sidenav color={sidenavColor} brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite} brandName="" routes={routes} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
                          </>
                        )}
                        {layout === "vr" && <Configurator />}
                        <Toaster
                          position="bottom-center"
                          toastOptions={{
                            duration: 5000,
                            style: {
                              fontSize: "1rem",
                              maxWidth: "600px",
                              color: "#fff",
                              borderRadius: "4px",
                            },
                            success: {
                              style: {
                                background: colors.success.main,
                              },
                            },
                            error: {
                              style: {
                                background: colors.error.main,
                              },
                            },
                          }}
                        />
                        <Routes>
                          {getRoutes(routes)}
                          <Route
                            path="*"
                            element={
                              <PrivateRoute>
                                <Comenzar />
                              </PrivateRoute>
                            }
                          />
                          <Route path="/authentication/register" element={<SignInIllustration />} />
                          <Route
                            path="/ventas/NuevoDTE"
                            element={
                              <PrivateRoute>
                                <NuevoDTE />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/ventas/NuevoDTE-Simple"
                            element={
                              <PrivateRoute>
                                <NuevoDTE freeSale />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/ventas/corregirDTE/:generationCode"
                            element={
                              <PrivateRoute>
                                <NuevoDTE CorregirDTE />
                              </PrivateRoute>
                            }
                          />
                          {/* <Route path="/gracias" element={<ThankYouPage />} />
                          <Route path="/precios" element={<PricingPage />} /> */}
                          <Route path="/authentication/resetPassword" element={<ResetPassword />} />
                          <Route
                            path="/ventas/detalle/:id"
                            element={
                              <PrivateRoute>
                                <VerDocumento />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/contactos/clientes/crear"
                            element={
                              <PrivateRoute>
                                <CrearClientes />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/contactos/clientes/editar/:idClientCompany"
                            element={
                              <PrivateRoute>
                                <EditarClientes />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/oficinas/crear"
                            element={
                              <PrivateRoute>
                                <CrearOficina />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/oficinas/editar/:idCostCenter"
                            element={
                              <PrivateRoute>
                                <EditarOficinas />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/bodegas/crear"
                            element={
                              <PrivateRoute>
                                <CrearBodega />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/bodegas/editar/:idStorage"
                            element={
                              <PrivateRoute>
                                <EditarBodegas />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/PtosDeVtas/crear"
                            element={
                              <PrivateRoute>
                                <CrearPtosDeVtas />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/configuracion/empresa/PtosDeVtas/editar/:idSalePoint"
                            element={
                              <PrivateRoute>
                                <EditarPtosDeVtas />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/inventarios/articulos/crear"
                            element={
                              <PrivateRoute>
                                <CrearArticulos />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/inventarios/articulos/editar"
                            element={
                              <PrivateRoute>
                                <EditarArticulos />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/inventarios/lista-de-precio/artiulosAsociados"
                            element={
                              <PrivateRoute>
                                <ArticulosLDPTable />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/inventarios/lista-de-precio/clientesAsociados"
                            element={
                              <PrivateRoute>
                                <ClientesLDPTable />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/inventarios/lista-de-precio/ptosDeVtasAsociados"
                            element={
                              <PrivateRoute>
                                <PtosDeVtasLDPTable />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/ventas/verDtesPendientes"
                            element={
                              <PrivateRoute>
                                <VerDtesRechazadosPendientes />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/ventas/verDtesMultados"
                            element={
                              <PrivateRoute>
                                <VerDtesRechazadosMultados />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/contactos/empleados/crear"
                            element={
                              <PrivateRoute>
                                <CrearEmpleados />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/contactos/empleados/editar"
                            element={
                              <PrivateRoute>
                                <EditarEmpleados />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </ThemeProvider>
                    </KeyboardContextProvider>
                  </ShortcutContextProvider>
                </SellsConfigContextProvider>
              </AddSellContextProvider>
            </CompanyContextProvider>
          </AuthContextProvider>
        </UserContextProvider>
      </ApolloProvider>
    </>
  );
}
