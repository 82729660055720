import gql from "graphql-tag";

export const GET_IVA_DOCUMENTS = gql`
  query GetIvaDocuments($documentsFilters: IvaDocumentsQueryFilterDti) {
    getIvaDocuments(documentsFilters: $documentsFilters) {
      isSuccess
      data {
        id
        name_document
        extended_name_document
        createdAt
        updatedAt
      }
    }
  }
`;
