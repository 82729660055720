import { LabelandId } from "./labelAndId";

export enum ArticleTypes {
  BIENES = "Bienes",
  SERVICIOS = "Servicios",
  AMBOS = "Ambos",
  OTRO = "Otro",
}

export interface ValuesArticulos {
  allowNegativeKardex: boolean;
  barCode: string;
  code: string;
  description: string;
  measurementUnit: LabelandId | null;
  name: string;
  operationType: string;
  type: string;
  priceList: LabelandId | null;
  defaultPrice: boolean;
  price: string;
  groups: LabelandId[] | null;
  tags: LabelandId | null;
  tagValue: string;
  storage: LabelandId | null;
  costsCenters: LabelandId | null;
  max: string;
  min: string;
  stock: string;
  photos?: any[];
}
