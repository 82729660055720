import gql from "graphql-tag";

export const GET_MEASUREMENTS = gql`
  query GET_MEASUREMENTS($measurementsFilters: QueryFilterDti!) {
    getMeasurements(measurementsFilters: $measurementsFilters) {
      isSuccess
      data {
        id
        name
        symbol
        code
      }
      message {
        code
        message
      }
    }
  }
`;
