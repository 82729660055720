import gql from "graphql-tag";

export const ADD_SALE_POINT_TO_PRICE_LIST = gql`
  mutation ADD_SALE_POINT_TO_PRICE_LIST($data: SalePointPriceListDti!) {
    addSalePointToPriceList(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
