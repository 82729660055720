import gql from "graphql-tag";

export const UPDATE_EMPLOYEE_COMPANY = gql`
  mutation UPDATE_EMPLOYEE_COMPANY($idEmployeeCompany: Float!, $data: EmployeeCompanyDti!) {
    updateEmployeeCompany(idEmployeeCompany: $idEmployeeCompany, data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
