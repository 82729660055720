import gql from "graphql-tag";

export const DELETE_STORAGE_ARTICLE = gql`
  mutation DELETE_STORAGE_ARTICLE($idStorageArticle: Float!) {
    deleteStorageArticle(idStorageArticle: $idStorageArticle) {
      isSuccess
      message {
        message
        code
        detail
      }
    }
  }
`;
