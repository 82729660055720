import { useEffect } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import { Autocomplete, Card, CircularProgress, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Stack, SvgIcon, Switch, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Graph
import { gql, useLazyQuery, useMutation } from "@apollo/client";

//Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

//Data
import { actividadComercial } from "utils/getActividadPrincipal";
import { departamentos, getMunicipios, municipiosTotal, paises } from "utils/getRegions";

//Types
import { ClientORCompanyForm } from "types/clientsForm";
import { SerializationKeys } from "types/apollo";
import { DocumentType } from "types/documentType";
import { legalRepresetantion } from "types/legalRepresentation";

// Utils
import { validarNRC } from "utils/validations/validarNrc";
import { validarDUI } from "utils/validations/validarDui";
import { validarNIT } from "utils/validations/validarNit";

const GET_CLIENT = gql`
  query GET_CLIENT($idClientCompany: Float!) {
    getClient(idClientCompany: $idClientCompany) {
      isSuccess
      data {
        name
        id
        contribuyenteType
        phone
        address
        plazoType
        emailNotifications
        tradename
        legalRepresentation
        region {
          id
          name
          type
          parent {
            code {
              code
            }
          }
        }
        documents {
          id
          documentNumber
          type
        }
        address
        isExent
        lineOfBusiness {
          name
          id
        }
        retention
        perception
        isActiveCredit
        creditLimit
        excelse
        creditPeriod
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const UPDATE_CLIENT_COMPANY = gql`
  mutation UPDATE_CLIENT_COMPANY($idClientCompany: Float!, $clientData: UpdateClientCompleteDti!) {
    updateClientCompany(idClientCompany: $idClientCompany, clientData: $clientData) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($idDocument: Float!, $documentNumber: String!) {
    updateDocument(idDocument: $idDocument, documentNumber: $documentNumber) {
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;
const ADD_DOCUMENTS = gql`
  mutation ADD_DOCUMENTS($data: CreateDocumentsDti!) {
    addDocuments(data: $data) {
      isSuccess
      message {
        code
        detail
      }
    }
  }
`;
const DELETE_DOCUMENT = gql`
  mutation DELETE_DOCUMET($idDocument: Float!) {
    deleteDocument(idDocument: $idDocument) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;

const validate = (values: ClientORCompanyForm) => {
  const errors: any = {};

  // Tipo
  if (!values.legalRepresentation) {
    errors.legalRepresentation = "La estructura legal es requerida";
  }

  //General
  if (!values.dui && !values.nit && !values.nrc && !values.pasaporte && !values.carnet_residente) {
    errors.dui = "Al menos un documento de identidad es requerido";
    errors.nit = "Al menos un documento de identidad es requerido";
    errors.nrc = "Al menos un documento de identidad es requerido";
    errors.pasaporte = "Al menos un documento de identidad es requerido";
    errors.carnet_residente = "Al menos un documento de identidad es requerido";
  }
  /* if (values.dui) {
    if (!/(\d{8})-(\d{1})/.test(values.dui)) {
      errors.dui = "DUI invalido";
    }
  }
  if (values.nit) {
    if (!/(\d{4})-(\d{6})-(\d{3})-(\d{1})/.test(values.nit)) {
      errors.nit = "NIT invalido";
    }
  } */
  if (values.pasaporte && values.pasaporte.length < 3) {
    errors.pasaporte = "El pasaporte debe tener al menos 3 caracteres";
  }
  if (values.carnet_residente && values.carnet_residente.length < 3) {
    errors.carnet_residente = "El carnet de residente debe tener al menos 3 caracteres";
  }
  if (values.nrc && !validarNRC(values.nrc)) {
    errors.nrc = "NRC invalido";
  }
  if (values.dui && validarDUI(values.dui)) {
    errors.dui = "DUI invalido";
  }
  if (values.nit && validarNIT(values.nit)) {
    errors.nit = "NIT invalido";
  }
  if (!values.nombre) {
    errors.nombre = "El nombre es requerido";
  } else if (values.nombre.length < 4) {
    errors.nombre = "El nombre debe tener al menos 4 caracteres";
  }
  if (values.telefono && values.telefono.length < 8) {
    errors.telefono = "Telefono invalido";
  }
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Correo invalido";
    }
  }
  if (values.nombreComercial) {
    if (values.nombreComercial.length < 4) {
      errors.nombreComercial = "El nombre comercial debe tener al menos 4 caracteres";
    }
  }
  if (values.legalRepresentation === legalRepresetantion.PERSONA_NATURAL_IVA) {
    /*  if (!values.dui && !values.nit) {
      errors.dui = "Al menos un documento de identidad es requerido";
      errors.nit = "Al menos un documento de identidad es requerido";
    } */
    if (values.clientStatus === "nacional") {
      if (!values.nrc) {
        errors.nrc = "El NRC es obligatorio";
      }
    }
    if (!values.actividadPrincipal) {
      errors.actividadPrincipal = "La actividad principal es obligatoria";
    }
  }

  // Direccion
  if (!values.pais) {
    errors.pais = "El pais es requerido";
  }
  if (values.pais?.label === "EL SALVADOR" && values.departamento?.label.length < 4 && !values.departamento) {
    errors.departamento = "El departamento debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.municipio?.label.length < 4 && !values.municipio) {
    errors.municipio = "El municipio debe ser mayor a 4 caracteres";
  }
  if (values.direccion && values.direccion.length < 4) {
    errors.direccion = "La direccion debe ser mayor a 4 caracteres";
  }

  //Facturacion
  if (values.legalRepresentation === "Persona Juridica" || values.legalRepresentation === "Persona Natural con IVA") {
    if (!values.contribuyenteType) {
      errors.contribuyenteType = "El tamaño del contribuyente es requerido";
    }
  }
  if (values.isActiveCredit && !values.creditLimit && values.perception) {
    errors.creditLimit = "El monto maximo de credito es requerido";
  }
  if (values.isActiveCredit && !values.creditPeriod) {
    errors.creditPeriod = "El periodo de credito es requerido";
  }

  return errors;
};

function EditarClientes(): JSX.Element {
  // Theme
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  // React-Router-Dom
  const navigate = useNavigate();
  const { idClientCompany } = useParams();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Contacts };

  //Queries and Mutations
  const [getClient, { data, loading }] = useLazyQuery(GET_CLIENT, {
    context,
  });
  const [updateClientCompany, dataUpdateClientCompany] = useMutation(UPDATE_CLIENT_COMPANY, { context });
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, { context });
  const [addDocuments] = useMutation(ADD_DOCUMENTS, { context });
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, { context });

  const formik = useFormik<ClientORCompanyForm>({
    initialValues: {
      legalRepresentation: "",
      dui: "",
      nit: "",
      nrc: "",
      nombre: "",
      pasaporte: "",
      carnet_residente: "",
      actividadPrincipal: {
        id: 0,
        label: "",
      },
      telefono: null,
      email: null,
      nombreComercial: "",
      pais: {
        label: "",
        id: "0",
      },
      departamento: null,
      municipio: null,
      direccion: null,
      contribuyenteType: null,
      retention: false,
      isExent: false,
      isActiveCredit: false,
      creditLimit: 0,
      perception: false,
      creditPeriod: 0,
      clientStatus: "nacional",
    },
    validate,
    onSubmit: async (values) => {
      // Update Documents
      data.getClient.data.documents.map((item: any) => {
        if (item.type === DocumentType.NRC) {
          if (item.documentNumber !== values.nrc && values.nrc !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nrc,
              },
            });
          }
        }
        if (item.type === DocumentType.NIT) {
          if (item.documentNumber !== values.nit && values.nit !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nit,
              },
            });
          }
        }
        if (item.type === DocumentType.DUI) {
          if (item.documentNumber !== values.dui && values.dui !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.dui,
              },
            });
          }
        }
        if (item.type === DocumentType.CARNET_RESIDENTE) {
          if (item.documentNumber !== values.carnet_residente && values.carnet_residente !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.carnet_residente,
              },
            });
          }
        }
        if (item.type === DocumentType.PASSPORT.toUpperCase()) {
          if (item.documentNumber !== values.pasaporte && values.pasaporte !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.pasaporte,
              },
            });
          }
        }
      });
      //Add Documents
      const arr = [];
      const existeNit = data.getClient.data.documents.find((item: any) => item.type === DocumentType.NIT);
      const existeNrc = data.getClient.data.documents.find((item: any) => item.type === DocumentType.NRC);
      const existeDui = data.getClient.data.documents.find((item: any) => item.type === DocumentType.DUI);
      const existeCarnetResidente = data.getClient.data.documents.find((item: any) => item.type === DocumentType.CARNET_RESIDENTE);
      const existePasaporte = data.getClient.data.documents.find((item: any) => item.type === DocumentType.PASSPORT.toUpperCase());
      if (values.dui !== "" && !existeDui) {
        arr.push({
          documentNumber: values.dui,
          type: DocumentType.DUI,
        });
      }
      if (values.nit !== "" && !existeNit) {
        arr.push({
          documentNumber: values.nit,
          type: DocumentType.NIT,
        });
      }
      if (values.nrc !== "" && !existeNrc) {
        arr.push({
          documentNumber: values.nrc,
          type: DocumentType.NRC,
        });
      }
      if (values.carnet_residente !== "" && !existeCarnetResidente) {
        arr.push({
          documentNumber: values.carnet_residente,
          type: DocumentType.CARNET_RESIDENTE,
        });
      }
      if (values.pasaporte !== "" && !existePasaporte) {
        arr.push({
          documentNumber: values.pasaporte,
          type: DocumentType.PASSPORT.toUpperCase(),
        });
      }
      if (arr.length > 0) {
        addDocuments({
          variables: {
            data: {
              idUser: data.getClient.data.id,
              userType: "CLIENT",
              documents: arr,
            },
          },
        });
      }
      //Delete Documents
      if (values.dui === "" && existeDui) {
        deleteDocument({
          variables: {
            idDocument: existeDui.id,
          },
        });
      }
      if (values.nit === "" && existeNit) {
        deleteDocument({
          variables: {
            idDocument: existeNit.id,
          },
        });
      }
      if (values.nrc === "" && existeNrc) {
        deleteDocument({
          variables: {
            idDocument: existeNrc.id,
          },
        });
      }
      if (values.carnet_residente === "" && existeCarnetResidente) {
        deleteDocument({
          variables: {
            idDocument: existeCarnetResidente.id,
          },
        });
      }
      if (values.pasaporte === "" && existePasaporte) {
        deleteDocument({
          variables: {
            idDocument: existePasaporte.id,
          },
        });
      }
      //Update Client
      updateClientCompany({
        variables: {
          idClientCompany: Number(idClientCompany),
          clientData: {
            idRegion: !values?.municipio?.id ? Number(values?.pais?.id) : Number(values?.municipio?.id),
            email: values.email === "" ? null : values.email,
            idLineOfBusiness: values.actividadPrincipal.id,
            name: values.nombre,
            legalRepresentationType: getLegalRepresetationOnSubmit(values.legalRepresentation),
            contribuyenteType: values.contribuyenteType,
            plazoType: "DIAS",
            isExcent: values.isExent,
            retention: values.retention,
            perception: values.perception,
            activeCredit: values.isActiveCredit,
            creditLimit: values.creditLimit === undefined ? 0 : Number(values.creditLimit),
            creditExcelse: values.isActiveCredit,
            creditPeriod: values.creditPeriod === undefined ? 0 : Number(values.creditPeriod),
            isActive: true,
            address: values.direccion === "" ? null : values.direccion,
            tradename: values?.nombreComercial === "" ? values.nombre : values?.nombreComercial,
            phone: values.telefono === "" ? null : values.telefono,
          },
        },
      })
        .then((dataUpdateClient) => {
          if (dataUpdateClient.data.updateClientCompany.isSuccess) {
            toast.success("El cliente se actualizo correctamente");
            navigate("/contactos/clientes");
          } else {
            toast.error(`Hubo un error al actualizar el cliente. ${dataUpdateClient.data.updateClientCompany?.message?.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Hubo un error al actualizar el cliente. Intente nuevamente");
        });
    },
  });

  const handleChangeFormat: any = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      formik.setFieldValue("nit", string);
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      formik.setFieldValue("dui", value);
    }
    if (e.target.name === "NRC") {
      e.currentTarget.maxLength = 8;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      formik.setFieldValue("nrc", value);
    }
  };
  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.dui === "Al menos un documento de identidad es requerido" || formik.errors.dui === "Al menos un documento de identidad es requerido" || formik.errors.dui === "Al menos un documento de identidad es requerido") {
        toast.error("Al menos un documento de identidad es requerido");
      }
      if (formik.errors.dui && formik.errors.dui !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.dui);
      }
      if (formik.errors.nit && formik.errors.nit !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.nit);
      }
      if (formik.errors.nrc && formik.errors.nrc !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.nrc);
      }
      if (formik.errors.telefono) {
        toast.error(formik.errors.telefono);
      }
      if (formik.errors.email) {
        toast.error(formik.errors.email);
      }
      if (formik.errors.pais) {
        toast.error("El pais debe ser mayor a 4 caracteres");
      }
      if (formik.errors.departamento) {
        toast.error("El departamento debe ser mayor a 4 caracteres");
      }
      if (formik.errors.municipio) {
        toast.error("El municipio debe ser mayor a 4 caracteres");
      }
      if (formik.errors.direccion) {
        toast.error(formik.errors.direccion);
      }
      if (formik.errors.creditLimit && formik.values.isActiveCredit && formik.values.perception) {
        toast.error(formik.errors.creditLimit);
      }
      if (formik.errors.creditPeriod && formik.values.isActiveCredit) {
        toast.error(formik.errors.creditPeriod);
      }
    }
  };
  const getLegalRepresetationOnSubmit = (value: string) => {
    switch (value) {
      case "Persona Juridica":
        return "JURIDICA";
      case "Persona Natural con IVA":
        return "PERSONA_NATURAL_IVA";
      case "Persona Natural sin IVA":
        return "PERSONA_NATURAL_NO_IVA";
    }
  };
  const getLegalRepresetation = (value: string) => {
    switch (value) {
      case "JURIDICA":
        return "Persona Juridica";
      case "PERSONA_NATURAL_IVA":
        return "Persona Natural con IVA";
      case "PERSONA_NATURAL_NO_IVA":
        return "Persona Natural sin IVA";
    }
  };

  //Effects
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        nombre: data.getClient.data?.name,
        nombreComercial: data.getClient.data?.tradename,
        actividadPrincipal: {
          label: data.getClient.data?.lineOfBusiness?.name,
          id: data.getClient.data?.lineOfBusiness?.id,
        },
        legalRepresentation: getLegalRepresetation(data.getClient.data?.legalRepresentation),
        contribuyenteType: data.getClient.data?.contribuyenteType,
        telefono: data.getClient.data?.phone,
        email: data.getClient.data?.emailNotifications,
        retention: data.getClient.data?.retention === false && data.getClient.data?.contribuyenteType === "GRANDE" ? true : data.getClient.data?.retention,
        isExent: data.getClient.data?.isExent,
        isActiveCredit: data.getClient.data?.isActiveCredit,
        isActive: data.getClient.data?.isActiveCredit,
        perception: data.getClient.data?.perception,
        creditLimit: data.getClient.data?.creditLimit,
        creditPeriod: data.getClient.data?.creditPeriod,
        direccion: data.getClient.data?.address,
      }));
      data.getClient.data?.documents.map((item: any) => {
        if (item.type === DocumentType.NRC) {
          formik.setFieldValue(DocumentType.NRC.toLowerCase(), item.documentNumber);
        }
        if (item.type === DocumentType.NIT) {
          formik.setFieldValue(DocumentType.NIT.toLowerCase(), item.documentNumber);
        }
        if (item.type === DocumentType.DUI) {
          formik.setFieldValue(DocumentType.DUI.toLowerCase(), item.documentNumber);
        }
        if (item.type === DocumentType.CARNET_RESIDENTE) {
          formik.setFieldValue(DocumentType.CARNET_RESIDENTE.toLowerCase(), item.documentNumber);
        }
        if (item.type === DocumentType.PASSPORT.toUpperCase()) {
          formik.setFieldValue(DocumentType.PASSPORT.toLowerCase(), item.documentNumber);
        }
      });
      const pais = paises.find((item: any) => item.id == data.getClient.data?.region.id);
      const departamentoId = departamentos.find((item: any) => item.id == data.getClient.data?.region.id);
      const cityId: any = municipiosTotal.find((item: any) => item.id == data.getClient.data?.region.id);
      if (cityId) {
        formik.setFieldValue("municipio", cityId);
        const depCity = departamentos.find((item) => item.id === cityId.idParent);
        formik.setFieldValue("departamento", depCity);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (departamentoId) {
        formik.setFieldValue("departamento", departamentoId);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (pais) {
        formik.setFieldValue("pais", pais);
      }
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.pais?.label !== "EL SALVADOR") {
      formik.setValues((prev) => ({
        ...prev,
        departamento: null,
        municipio: null,
      }));
    }
  }, [formik.values.pais]);

  useEffect(() => {
    if (idClientCompany) {
      getClient({
        variables: {
          idClientCompany: Number(idClientCompany),
        },
      });
    }
  }, [idClientCompany]);

  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Nombre Legal"
                              error={Boolean(formik.errors.nombre) && formik.touched.nombre}
                              name="nombre"
                              helperText={!formik.touched.nombre || (formik.touched.nombre && !formik.errors.nombre) ? " " : formik.errors.nombre}
                              onChange={formik.handleChange}
                              onBlur={() => formik.setFieldTouched("nombre", true)}
                              value={formik.values.nombre}
                              color="info"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="standard"
                              value={formik.values.nombreComercial}
                              error={Boolean(formik.errors.nombreComercial)}
                              helperText={!formik.errors.nombreComercial ? " " : `${formik.errors.nombreComercial}`}
                              onChange={formik.handleChange}
                              name="nombreComercial"
                              label="Nombre Comercial"
                              color="info"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 4 }}>
                          {formik.values.pais?.label === "EL SALVADOR" ? (
                            <>
                              {/* Verifica si el cliente no es Juridico */}
                              {formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? (
                                <Grid item xs={12} md={4}>
                                  <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.dui) && formik.touched.dui}>
                                    <InputLabel>Documento Unico de Identidad</InputLabel>
                                    <Input
                                      endAdornment={
                                        <InputAdornment position="start">
                                          <SvgIcon viewBox="0 0 16 12">
                                            <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                          </SvgIcon>
                                        </InputAdornment>
                                      }
                                      name="DUI"
                                      onBlur={() => formik.setFieldTouched("dui", true)}
                                      onChange={handleChangeFormat}
                                      value={formik.values.dui}
                                    />
                                    <FormHelperText>{!formik.touched.dui || (formik.touched.dui && !formik.errors.dui) ? " " : formik.errors.dui}</FormHelperText>
                                  </FormControl>
                                </Grid>
                              ) : null}
                              <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                                <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nit) && formik.touched.nit}>
                                  <InputLabel>Numero de Identificacion Tributaria</InputLabel>
                                  <Input
                                    name="NIT"
                                    endAdornment={
                                      <InputAdornment position="start">
                                        <SvgIcon viewBox="0 0 16 12">
                                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                        </SvgIcon>
                                      </InputAdornment>
                                    }
                                    onBlur={() => formik.setFieldTouched("nit", true)}
                                    onChange={handleChangeFormat}
                                    value={formik.values.nit}
                                  />
                                  <FormHelperText>{!formik.touched.nit || (formik.touched.nit && !formik.errors.nit) ? " " : formik.errors.nit}</FormHelperText>
                                </FormControl>
                              </Grid>
                              {formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (
                                <Grid item xs={12} md={formik.values.legalRepresentation !== legalRepresetantion.JURIDICA.toUpperCase() ? 4 : 6}>
                                  <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nrc) && formik.touched.nrc}>
                                    <InputLabel>Numero de Registro Contribuyente</InputLabel>
                                    <Input
                                      endAdornment={
                                        <InputAdornment position="start">
                                          <SvgIcon viewBox="0 0 16 12">
                                            <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                          </SvgIcon>
                                        </InputAdornment>
                                      }
                                      onChange={handleChangeFormat}
                                      value={formik.values.nrc}
                                      onBlur={() => formik.setFieldTouched("nrc", true)}
                                      name="NRC"
                                    />
                                    <FormHelperText>{!formik.touched.nrc || (formik.touched.nrc && !formik.errors.nrc) ? " " : formik.errors.nrc}</FormHelperText>
                                  </FormControl>
                                </Grid>
                              ) : (
                                <Grid item xs={12} md={4}>
                                  <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.pasaporte) && formik.touched.pasaporte}>
                                    <InputLabel>Pasaporte</InputLabel>
                                    <Input
                                      endAdornment={
                                        <InputAdornment position="start">
                                          <SvgIcon viewBox="0 0 16 12">
                                            <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                          </SvgIcon>
                                        </InputAdornment>
                                      }
                                      onChange={(e) => {
                                        if (!isNaN(Number(e.target.value))) {
                                          formik.handleChange(e);
                                        }
                                      }}
                                      value={formik.values.pasaporte}
                                      onBlur={() => formik.setFieldTouched("pasaporte", true)}
                                      name="pasaporte"
                                    />
                                    <FormHelperText>{!formik.touched.pasaporte || (formik.touched.pasaporte && !formik.errors.pasaporte) ? " " : formik.errors.pasaporte}</FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="standard" error={Boolean(formik.errors.carnet_residente) && formik.touched.carnet_residente}>
                                  <InputLabel>Carnet de Residente</InputLabel>
                                  <Input
                                    endAdornment={
                                      <InputAdornment position="start">
                                        <SvgIcon viewBox="0 0 16 12">
                                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                        </SvgIcon>
                                      </InputAdornment>
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values.carnet_residente}
                                    onBlur={() => formik.setFieldTouched("carnet_residente", true)}
                                    name="carnet_residente"
                                  />
                                  <FormHelperText>{!formik.touched.carnet_residente || (formik.touched.carnet_residente && !formik.errors.carnet_residente) ? " " : formik.errors.carnet_residente}</FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="standard" error={Boolean(formik.errors.pasaporte) && formik.touched.pasaporte}>
                                  <InputLabel>Pasaporte</InputLabel>
                                  <Input
                                    endAdornment={
                                      <InputAdornment position="start">
                                        <SvgIcon viewBox="0 0 16 12">
                                          <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                        </SvgIcon>
                                      </InputAdornment>
                                    }
                                    onChange={(e) => {
                                      if (!isNaN(Number(e.target.value))) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    value={formik.values.pasaporte}
                                    onBlur={() => formik.setFieldTouched("pasaporte", true)}
                                    name="pasaporte"
                                  />
                                  <FormHelperText>{!formik.touched.pasaporte || (formik.touched.pasaporte && !formik.errors.pasaporte) ? " " : formik.errors.pasaporte}</FormHelperText>
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={actividadComercial}
                          freeSolo
                          value={formik.values.actividadPrincipal?.label ?? ""}
                          onChange={(e, value) => formik.setFieldValue("actividadPrincipal", value)}
                          renderInput={(params) => <TextField {...params} label="Actividad Principal" color="info" variant="standard" />}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={3}>
                      <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                        Portal de Cliente
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDTypography fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                        Con esta funcion podras activar un sitio web para tu cliente donde podra gestionar las descargas de los DTEs solicitar estados de cuentas, ver detalles sobre su cuenta por cobrar entre otros.
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack direction="row" justifyContent={{ xs: "flex-start", md: "flex-end" }} alignItems="center">
                        <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                          Activar Portal
                        </MDTypography>
                        <Switch name="Activar Portal" />
                      </Stack>
                      <MDBox display="flex" justifyContent={{ xs: "flex-start", md: "flex-end" }} alignItems="center">
                        <MDTypography color="info">Copiar Link del Portal</MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Medios de Notificacion
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                      Estos datos se utilizaran para enviarte los documentos tributarios electroncios.
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Telefonos"
                        error={Boolean(formik.errors.telefono)}
                        value={formik.values.telefono ?? ""}
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value)) && e.target.value.length < 21) {
                            formik.handleChange(e);
                          }
                        }}
                        name="telefono"
                        helperText={!formik.errors.telefono ? " " : `${formik.errors.telefono}`}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Correo Electronico"
                        error={Boolean(formik.errors.email)}
                        value={formik.values.email ?? ""}
                        helperText={!formik.errors.email ? " " : `${formik.errors.email}`}
                        onChange={formik.handleChange}
                        name="email"
                        color="info"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Datos Tributarios
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} mb={1}>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        freeSolo
                        options={["Persona Juridica", "Persona Natural con IVA", "Persona Natural sin IVA"]}
                        value={formik.values.legalRepresentation}
                        onChange={(e, value) => formik.setFieldValue("legalRepresentation", value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estructura Legal"
                            variant="standard"
                            error={Boolean(formik.errors.legalRepresentation) && formik.touched.legalRepresentation}
                            helperText={!formik.touched.legalRepresentation || (formik.touched.legalRepresentation && !formik.errors.legalRepresentation) ? " " : formik.errors.legalRepresentation}
                            fullWidth
                            color="info"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        freeSolo
                        options={["GRANDE", "MEDIANO", "OTRO"]}
                        value={formik.values.contribuyenteType}
                        onChange={(e, value) => {
                          formik.setFieldValue("contribuyenteType", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tamaño del Contribuyente"
                            variant="standard"
                            error={Boolean(formik.errors.contribuyenteType) && formik.touched.contribuyenteType}
                            helperText={!formik.touched.contribuyenteType || (formik.touched.contribuyenteType && !formik.errors.contribuyenteType) ? " " : formik.errors.contribuyenteType}
                            fullWidth
                            color="info"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Stack mt={1} direction="row" alignItems="center">
                        <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                          ¿Al cliente se le retiene?
                        </MDTypography>
                        <Switch name="retention" checked={formik.values.retention} disabled={formik.values.contribuyenteType === "GRANDE"} onChange={formik.handleChange} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Stack mt={1} direction="row" alignItems="center">
                        <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                          ¿El cliente es exento?
                        </MDTypography>
                        <Switch name="isExent" onChange={formik.handleChange} checked={formik.values.isExent} />
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Datos Crediticios
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} mb={1}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Stack mt={1} direction="row" alignItems="center">
                        <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                          ¿Tiene Credito Activo?
                        </MDTypography>
                        <Switch
                          name="isActiveCredit"
                          onChange={(e, newValue) => {
                            formik.setValues((prev) => ({
                              ...prev,
                              isActiveCredit: newValue,
                              perception: false,
                              creditLimit: 0,
                              creditPeriod: 0,
                            }));
                          }}
                          checked={formik.values.isActiveCredit}
                        />
                      </Stack>
                    </Grid>
                    {formik.values.isActiveCredit && (
                      <>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            variant="standard"
                            label="¿Cuantos dias de credito tiene?"
                            error={Boolean(formik.errors.creditPeriod) && formik.touched.creditPeriod}
                            onBlur={() => formik.setFieldTouched("creditPeriod", true)}
                            helperText={!formik.touched.creditPeriod || (formik.touched.creditPeriod && !formik.errors.creditPeriod) ? " " : formik.errors.creditPeriod}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                formik.handleChange(e);
                              }
                            }}
                            value={formik.values.creditPeriod === 0 ? "" : formik.values.creditPeriod}
                            name="creditPeriod"
                            color="info"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Stack mt={1} direction="row" alignItems="center">
                            <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                              ¿Tiene un Limite de Credito?
                            </MDTypography>
                            <Switch name="perception" checked={formik.values.perception} onChange={formik.handleChange} />
                          </Stack>
                        </Grid>
                        {formik.values.perception && (
                          <>
                            <Grid item xs={12} md={3}>
                              <TextField
                                fullWidth
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                error={Boolean(formik.errors.creditLimit) && formik.touched.creditLimit}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value))) {
                                    formik.handleChange(e);
                                  }
                                }}
                                helperText={!formik.touched.creditLimit || (formik.touched.creditLimit && !formik.errors.creditLimit) ? " " : formik.errors.creditLimit}
                                value={formik.values.creditLimit === 0 ? "" : formik.values.creditLimit}
                                onBlur={() => formik.setFieldTouched("creditLimit", true)}
                                name="creditLimit"
                                variant="standard"
                                label="¿Cual es el monto maximo de credito que tiene?"
                                color="info"
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Direccion
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Direccion"
                        name="direccion"
                        error={Boolean(formik.errors.direccion)}
                        helperText={!formik.errors.direccion ? " " : `${formik.errors.direccion}`}
                        value={formik.values.direccion ?? ""}
                        onChange={formik.handleChange}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 9.5 }}>
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            id="pais"
                            options={paises}
                            freeSolo
                            value={formik.values.pais}
                            onChange={(e, value) => formik.setFieldValue("pais", value)}
                            onBlur={() => formik.setFieldTouched("pais", true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pais"
                                error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                                helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && (
                            <Autocomplete
                              freeSolo
                              onChange={(e, value) => formik.setFieldValue("departamento", value)}
                              value={formik.values.departamento}
                              onBlur={() => formik.setFieldTouched("departamento", true)}
                              options={departamentos}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Departamento"
                                  error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                                  helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                            <Autocomplete
                              freeSolo
                              value={formik.values.municipio}
                              onChange={(e, value) => formik.setFieldValue("municipio", value)}
                              onBlur={() => formik.setFieldTouched("municipio", true)}
                              options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Municipio"
                                  error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                                  helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/contactos/clientes")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdateClientCompany.loading}
                    onClick={() => {
                      validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdateClientCompany.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditarClientes;
