import { SvgIcon, SxProps, Theme } from "@mui/material";

function OtroIcon({ sx }: { sx: SxProps<Theme> }) {
  return (
    <SvgIcon viewBox="0 0 80 80" sx={sx}>
      <path d="M 40 0 C 17.9062 0 0 17.9062 0 40 C 0 62.0938 17.9062 80 40 80 C 62.0938 80 80 62.0938 80 40 C 80 17.9062 62.0938 0 40 0 Z M 40 72.5 C 22.0781 72.5 7.5 57.9203 7.5 40 C 7.5 22.0797 22.0781 7.5 40 7.5 C 57.9219 7.5 72.5 22.0797 72.5 40 C 72.5 57.9203 57.9219 72.5 40 72.5 Z M 40 52.5 C 37.1875 52.5 35 54.6875 35 57.5 C 35 60.3125 37.0469 62.5 40 62.5 C 42.6719 62.5 45 60.3125 45 57.5 C 45 54.6875 42.6719 52.5 40 52.5 Z M 45.1719 20 H 37.1875 C 31.0938 20 26.25 24.8438 26.25 30.9375 C 26.25 32.9688 27.9688 34.6875 30 34.6875 C 32.0312 34.6875 33.75 32.9688 33.75 30.9375 C 33.75 29.0625 35.1719 27.5 37.0469 27.5 H 45.0312 C 47.0469 27.5 48.75 29.0625 48.75 30.9375 C 48.75 32.1875 48.125 33.1406 47.0312 33.7656 L 38.125 39.2188 C 36.875 40 36.25 41.25 36.25 42.5 V 45 C 36.25 47.0312 37.9688 48.75 40 48.75 C 42.0312 48.75 43.75 47.0312 43.75 45 V 44.6875 L 50.7969 40.3125 C 54.0781 38.2812 56.1094 34.6875 56.1094 30.9375 C 56.25 24.8438 51.4062 20 45.1719 20 Z" />
    </SvgIcon>
  );
}

export default OtroIcon;
