import { SvgIcon } from "@mui/material";

const DocuementIcon = () => {
  return (
    <SvgIcon viewBox="0 0 16 12">
      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
    </SvgIcon>
  );
};

export default DocuementIcon;
