import { ChangeEvent, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import CustomDropZone from "components/Global/CustomDropZone";

// Forms
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

// GraphQL
import { gql, useMutation } from "@apollo/client";

// Alert
import toast from "react-hot-toast";

// Context
import { useCompanyContext } from "context/CompanyContext";
import { useAuthContext } from "context/AuthContext";

// Types
import { ChooseEnvironment } from "../..";
interface Values {
  certificate?: File;
  "api-password": string;
  "api-password-confirm": string;
  "certificate-password": string;
  "certificate-password-confirm": string;
}

// Mutations
import { UPDATE_MH_USER_PASSWORD } from "apollo/mutations/configuration/updateMhUserPassword";
import { CHANGE_PASSWORD_MH_CERTIFICATE } from "apollo/mutations/configuration/changePasswordMhCertificate";

function Indicaciones({ isValid, enviroment }: { isValid: React.Dispatch<React.SetStateAction<boolean>>; enviroment: ChooseEnvironment }): JSX.Element {
  const fileEnv = enviroment === ChooseEnvironment.PRUEBAS ? "test" : "prod";
  // States
  const [hasUploadedCertificate, setHasUploadedCertificate] = useState<boolean>(false);

  // Context
  const { company } = useCompanyContext();
  const { token } = useAuthContext();

  // Queries
  const [ChangePasswordMhCertificate] = useMutation(CHANGE_PASSWORD_MH_CERTIFICATE);
  const [UpadteMhUserpassword] = useMutation(UPDATE_MH_USER_PASSWORD);

  const formik = useFormik<Values>({
    initialValues: {
      "api-password": "",
      "api-password-confirm": "",
      "certificate-password": "",
      "certificate-password-confirm": "",
    },

    onSubmit: async (values) => {
      await ChangePasswordMhCertificate({
        variables: { newPassword: values["certificate-password"], environment: enviroment },
      })
        .then((res) => {
          res.data.changePasswordMhCertificate.isSuccess && toast.success("La contraseña del certificado se actualizó correctamente");
          !res.data.changePasswordMhCertificate.isSuccess && toast.error("Hubo un error al actualizar la contraseña del certificado \n " + res.data.upadteMhUserpassword.message.message);
        })
        .catch((err) => {
          toast.error(`Error al actualizar la contraseña del certificado`);
        });
      await UpadteMhUserpassword({ variables: { password: values["api-password"], environment: enviroment } })
        .then((res) => {
          res.data.upadteMhUserpassword.isSuccess && toast.success("La contraseña de la API se actualizó correctamente");
          !res.data.upadteMhUserpassword.isSuccess && toast.error("Hubo un error al actualizar la contraseña de la API \n " + res.data.upadteMhUserpassword.message.message);
        })
        .catch((err) => {
          toast.error(`Error al actualizar la contraseña de la API`);
        });
    },
    validationSchema: Yup.object({
      "api-password": Yup.string().required("La contraseña es un campo requerido"),
      "api-password-confirm": Yup.string()
        .required("La confirmación de la contraseña es un campo requerido")
        .oneOf([Yup.ref("api-password"), null], "Las contraseñas no son iguales"),
      "certificate-password": Yup.string().required("La contraseña del certificado es un campo requerido"),
      "certificate-password-confirm": Yup.string()
        .required("La confirmación de la contraseña es un campo requerido")
        .oneOf([Yup.ref("certificate-password"), null], "Las contraseñas no son iguales"),
    }),
  });

  return (
    <>
      <MDBox>
        <MDBox mb={2} textAlign="center">
          <MDBox mb={1}>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              Credenciales del Protal del Ministerio de Hacienda
            </MDTypography>
          </MDBox>
        </MDBox>
        <Grid container gap="1rem" wrap="nowrap" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
          <Grid item xs={12} sm={6} mb={2}>
            <MDBox mt={2}>
              <MDTypography variant="h6" align="center" color="info">
                Certificado
              </MDTypography>

              <MDTypography variant="body2" paragraph mb={2} color="secondary">
                Puedes ver un video para saber como obtener el certificado desde el portal del Ministerio de Hacienda. para saber cómo. {""}
                <a href="/">
                  <MDTypography variant="string" color="error" fontWeight="medium">
                    Haz clic aqui
                  </MDTypography>
                </a>
              </MDTypography>

              <CustomDropZone
                env={enviroment}
                handleDrop={async (file) => {
                  const formData = new FormData();
                  formData.append("file", file[0]);

                  axios
                    .post(`${process.env.REACT_APP_API_URL}/certificate/company/${company.id}/env/${fileEnv}`, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then((res) => {
                      setHasUploadedCertificate(true);
                      res.data.isSuccess && toast.success(res.data.message ? res.data.message : "Certificado cargado con éxito");
                      !res.data.isSuccess && toast.error(res.data.detail ? res.data.detail : "Hubo un error al cargar el certificado");
                    })
                    .catch((err) => {
                      toast.error(
                        `
                      ${err.response.data.message.message ? err.response.data.message.detail : "Error al cargar el archivo"}`.trim()
                      );
                    });
                }}
              />
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            mb={2}
            sx={{
              opacity: !hasUploadedCertificate ? "40%" : "100%",
              pointerEvents: !hasUploadedCertificate ? "none" : "auto",
            }}
          >
            <Grid mt={3}>
              <MDTypography variant="h6" mb={2} align="center" color="info">
                Contraseña
              </MDTypography>
              <MDTypography variant="body2" paragraph mb={2} color="secondary">
                Necesitamos la contraseña de la api del Ministerio de Hacienda para saber como obtenerla {""}
                <a href="/">
                  <MDTypography variant="string" color="error" fontWeight="medium">
                    haz clic aqui
                  </MDTypography>
                </a>
              </MDTypography>
              <Grid item mb={2}>
                <MDInput
                  name="api-password"
                  label="Contraseña de la API"
                  type="password"
                  fullWidth
                  autoComplete="new-password"
                  disabled={!hasUploadedCertificate}
                  onBlur={() => {
                    formik.setFieldTouched("api-password", true);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CustomFieldError name="api-password" errorName={formik.errors["api-password"]} touched={formik.touched["api-password"]} />
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  name="api-password-confirm"
                  label="Confirma contraseña de la API"
                  type="password"
                  autoComplete="off"
                  fullWidth
                  disabled={!hasUploadedCertificate}
                  onBlur={() => {
                    formik.setFieldTouched("api-password-confirm", true);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CustomFieldError name="api-password-confirm" errorName={formik.errors["api-password-confirm"]} touched={formik.touched["api-password-confirm"]} />
              </Grid>
            </Grid>

            <Grid mt={3}>
              <MDTypography variant="body2" paragraph mb={2} color="secondary">
                Necesitamos la contraseña privada de los certificados que colocas en el Ministerio de Hacienda para saber como obtenerla {""}
                <a href="/">
                  <MDTypography variant="string" color="error" fontWeight="medium">
                    haz clic aqui
                  </MDTypography>
                </a>
              </MDTypography>
              <Grid item mb={2}>
                <MDInput
                  name="certificate-password"
                  label="Contraseña privada del certificado"
                  type="password"
                  fullWidth
                  autoComplete="off"
                  disabled={!hasUploadedCertificate}
                  onBlur={() => {
                    formik.setFieldTouched("certificate-password", true);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CustomFieldError name="certificate-password" errorName={formik.errors["certificate-password"]} touched={formik.touched["certificate-password"]} />
              </Grid>
              <Grid item mb={2}>
                <MDInput
                  name="certificate-password-confirm"
                  label="Confirma la contraseña privada del certificado"
                  type="password"
                  autoComplete="off"
                  fullWidth
                  disabled={!hasUploadedCertificate}
                  onBlur={() => {
                    formik.setFieldTouched("certificate-password-confirm", true);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CustomFieldError name="certificate-password-confirm" errorName={formik.errors["certificate-password-confirm"]} touched={formik.touched["certificate-password-confirm"]} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDBox mb={2} textAlign="center" px={{ lg: 10 }} my={{ lg: 6 }}>
          <MDBox
            sx={{
              cursor: "pointer",
              opacity: formik.isSubmitting ? 0.5 : 1,
              pointerEvents: formik.isSubmitting ? "none" : "auto",
              width: "100%",
            }}
            p={2}
            bgColor="warning"
            borderRadius="lg"
            onClick={async () => {
              formik.setSubmitting(true);
              await formik.handleSubmit();
              isValid(true);
            }}
          >
            {formik.isSubmitting ? (
              <MDBox color="white" display="flex" justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color="inherit" size={33} />
              </MDBox>
            ) : (
              <MDTypography color="white" variant="h4" sx={{ color: "white" }}>
                Verificar Conexion con el Ministerio de Hacienda
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Indicaciones;
