// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// React and GraphQl
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

//Components
import TipoOficina from "../components/TipoOficina";
import GeneralOficinas from "../components/GeneralOficinas";
import DireccionOficinas from "../components/DireccionOficinas";

// Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

// Data
const steps = ["Tipo", "Generales", "Direccion"];

export interface Values1 {
  type: string;
  nombre: string;
  telefono: string;
  mhCode: string;
  internalCode: string;
  pais: {
    id: string;
    label: string;
  };
  departamento: {
    label: string;
    id: string;
  } | null;
  municipio: {
    label: string;
    id: string;
    idParent: string;
  } | null;
  direccion: string;
}

const validate = (values: Values1) => {
  const errors: any = {};

  if (!values.type) {
    errors.type = "El tipo de oficina es requerido";
  }
  //General
  if (!values.nombre) {
    errors.nombre = "El nombre es requerido";
  } else if (values.nombre.length < 4) {
    errors.nombre = "El nombre debe tener al menos 4 caracteres";
  }
  if (!values.mhCode) {
    errors.mhCode = "El codigo MH es requerido";
  } else if (values.mhCode.length < 4) {
    errors.mhCode = "El codigo MH debe tener al menos 4 caracteres";
  }
  if (!values.internalCode) {
    errors.internalCode = "El codigo interno es requerido";
  } else if (values.internalCode.length < 4) {
    errors.internalCode = "El codigo interno debe tener al menos 4 caracteres";
  }

  // Direccion
  if (!values.pais) {
    errors.pais = "El pais es requerido";
  } else if (values?.pais?.label.length < 4) {
    errors.pais = "El pais debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.departamento?.label.length < 4 && !values.departamento) {
    errors.departamento = "El departamento debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.municipio?.label.length < 4 && !values.municipio) {
    errors.municipio = "El municipio debe ser mayor a 4 caracteres";
  }
  if (values.direccion && values.direccion.length < 4) {
    errors.direccion = "La direccion debe ser mayor a 4 caracteres";
  }
  if (values.telefono && !/^[0-9]{8}$/i.test(values.telefono)) {
    errors.telefono = "El telefono es invalido";
  }

  return errors;
};

const ADD_COST_CENTER = gql`
  mutation ADD_COST_CENTER($costCenterData: CostCenterDti!) {
    addCostCenter(costCenterData: $costCenterData) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;

function CrearOficina() {
  //Querys and Mutations
  const [addCostCenter, { loading }] = useMutation(ADD_COST_CENTER);

  // States
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      type: "",
      nombre: "",
      telefono: "",
      mhCode: "",
      internalCode: "",
      pais: {
        label: "",
        id: "0",
      },
      departamento: null,
      municipio: null,
      direccion: "",
    },
    validate,
    onSubmit: async (values) => {
      addCostCenter({
        variables: {
          costCenterData: {
            name: values.nombre,
            address: values.direccion,
            phone: values.telefono,
            mhCode: values.mhCode,
            internalCode: values.internalCode,
            type: values.type,
            idRegion: values?.municipio?.id ? Number(values?.municipio?.id) : Number(values?.pais?.id),
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addCostCenter;
          if (isSuccess) {
            toast.success("Oficina creada correctamente");
            navigate("/configuracion/empresa", { state: 1 });
          } else {
            toast.error(`Hubo un error al crear la oficina ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al crear la oficina ${e}`);
        });
    },
  });

  //Effects
  useEffect(() => {
    if (formik.values?.pais?.label !== "EL SALVADOR") {
      formik.setValues((prev) => ({
        ...prev,
        departamento: null,
        municipio: null,
      }));
    }
  }, [formik.values.pais]);
  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <TipoOficina formik={formik} />;
      case 1:
        return <GeneralOficinas formik={formik} />;
      case 2:
        return <DireccionOficinas formik={formik} />;
    }
  }
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 2) return prev + 1;
      return prev;
    });
  };
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev >= 1) return prev - 1;
      return prev;
    });
  };
  const buttonNext = (step: any) => {
    if (step === 0) {
      return (
        <MDButton
          disabled={!Boolean(formik.values.type)}
          onClick={() => {
            handleNext();
          }}
          variant="gradient"
          color="info"
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 1) {
      return (
        <MDButton
          disabled={Boolean(formik.errors.nombre) || Boolean(formik.errors.mhCode) || Boolean(formik.errors.internalCode)}
          onClick={() => {
            handleNext();
          }}
          variant="gradient"
          color="info"
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          disabled={loading || Boolean(formik.errors.pais) || Boolean(formik.errors.direccion) || Boolean(formik.errors.departamento) || Boolean(formik.errors.municipio)}
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={580} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/configuracion/empresa")} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default CrearOficina;
