import gql from "graphql-tag";

export const GET_EMPLOYEES = gql`
  query GET_EMPLOYEES($filters: EmployeeQueryFilterDti!) {
    getEmployees(filters: $filters) {
      isSuccess
      data {
        id
        idEmployeeCompany
        firstName
        secondName
        firstLastName
        secondLastName
        marriedLastName
        emailNotifications
        documents {
          id
          documentNumber
          type
        }
        hasUser
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
