// Graphql
import { OperationVariables, QueryResult, useMutation } from "@apollo/client";

// Mutation
import { FIX_FACTURA_EXPORTACION } from "apollo/mutations/sales/fixFacturaExportacion";

// Utils
import { getTime } from "utils/getTime";
import { capitalizeWords } from "utils/capitalizeWords";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useAddSellContext } from "context/AddSellContext";

// Libs
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Tyes
import { SerializationKeys } from "types/apollo";
import { ArticleTypes } from "types/Articulos";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { PaymentConditions } from "types/payment-conditions";
import { FormikState } from "formik";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { SaleInformation } from "types/saleInformation";

interface Props {
  values: AddSaleGeneralDocumentFilterValues;
  handleOpen: (value: ActiveValues) => void;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  freeSale: boolean;
  dataGetGeneralDocuments: QueryResult<any, OperationVariables>;
  resetForm: (nextState?: Partial<FormikState<AddSaleGeneralDocumentFilterValues>>) => void;
}

export const useHandleFixFacturaExportacion = () => {
  const navigate = useNavigate();

  // Context
  const {
    global: { products, fleteFex, seguroFex, paymentMethods, saleDiscount, conditionPayment },
    resetGlobal,
  } = useAddSellContext();
  const { sellsConfig } = useSellsConfigContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Mutation
  const [fixFacturaExportacion, dataFixFacturaExportacion] = useMutation(FIX_FACTURA_EXPORTACION, { context });

  const handleFixFacturaExportacion = async ({ values, handleOpen, setSaleInformation, freeSale, dataGetGeneralDocuments, resetForm }: Props) => {
    const companyExportador = {
      codIncoterms: values?.type !== ArticleTypes.SERVICIOS ? values?.incoterms?.codigo : null,
      recintoFiscal: values?.type !== ArticleTypes.SERVICIOS ? values?.recintoFiscal?.codigo : null,
      regimenExportacion: values?.type !== ArticleTypes.SERVICIOS ? values?.regimenExportacion?.codigo : null,
      fechaOperacion: `${values?.date} ${getTime()}`,
      idCostCenter: sellsConfig?.costCenter?.id,
      idSalePoint: sellsConfig?.selectedSalePoint?.id,
      tipoItem: values?.type,
    };

    const itemsConListaPrecio = products.map((product, index) => {
      return {
        cantidad: String(product?.quantity),
        idSalesPrices: product?.idSalePrice,
        montoDescu: Number(product?.productDiscount).toFixed(2),
        noGravado: "0",
        numItem: index + 1,
      };
    });
    const itemsNoRegistrados = products.map((product, index) => {
      return {
        descripcion: product?.name,
        cantidad: String(product?.quantity),
        codigo: product?.code,
        montoDescu: Number(product?.productDiscount).toFixed(2),
        precioUni: String(product?.unitPrice),
        noGravado: "0",
        idUniMedida: product?.type !== ArticleTypes.SERVICIOS ? product?.measurement?.id : 56,
        numItem: index + 1,
      };
    });
    const otrosDocumentosAsociados =
      values?.otherAssociatedDocuments?.length > 0
        ? values?.otherAssociatedDocuments.map((document) => {
            return {
              descripcionDocAsociado: document?.description,
              documentoAsociado: document?.type,
              identificaciondDocAsociado: document?.document,
              modoTransporte: document?.type === "Transporte" ? Number(document?.typeOfTransport.codigo) : null,
              nombreConductor: document?.type === "Transporte" ? document?.NameDriver : null,
              numConductor: document?.type === "Transporte" ? document?.documentDriver : null,
              placaTransporte: document?.type === "Transporte" ? document?.vehiclePlate : null,
            };
          })
        : null;
    const resumen = {
      condicionOperacion: typeof values?.conditionPayment === "object" ? values?.conditionPayment?.value.toUpperCase() : values?.conditionPayment.toUpperCase(),
      descuento: Number(saleDiscount).toFixed(2) ?? "0.00",
      metodosPagos:
        conditionPayment === PaymentConditions.CREDITO
          ? null
          : paymentMethods.map((item) => ({
              amount: String(item?.amount),
              numeroReferencia: item?.numeroReferencia ?? null,
              paymentMethod: capitalizeWords(item?.selectedPaymentMethod?.name).replaceAll(" ", ""),
            })),
      /*  detalleCredito:
            conditionPayment === PaymentConditions.CREDITO
              ? {
                  periodo: values?.clientCompany?.creditPeriod,
                  plazo: values?.clientCompany?.plazoType,
                }
              : null, */
      observaciones: values?.extension?.observations,
      seguro: Number(seguroFex).toFixed(2) ?? "0.00",
      flete: Number(fleteFex).toFixed(2) ?? "0.00",
    };

    const vtasTercero =
      values?.ventasCuentasTerceros?.nit || values?.ventasCuentasTerceros?.name
        ? {
            nit: values?.ventasCuentasTerceros?.nit.replaceAll("-", ""),
            nombre: values?.ventasCuentasTerceros?.name,
          }
        : null;

    const inputFixFacturaExportacion: any = {
      clienteReceptor: { idClientCompany: values?.clientCompany?.idClientCompany, notifyTo: values?.notifyTo },
      codeGeneration: dataGetGeneralDocuments?.data?.getGeneralDocuments.data[0]?.generationCodeDte,
      companyExportador,
      cuerpoDocumento: {
        itemsConListaPrecio: freeSale ? null : itemsConListaPrecio,
        itemsNoRegistrados: freeSale ? itemsNoRegistrados : null,
      },
      otrosDocumentosAsociados,
      resumen,
      vtasTercero,
    };

    await fixFacturaExportacion({
      variables: {
        input: inputFixFacturaExportacion,
      },
    })
      .then(async ({ data }) => {
        if (data.fixFacturaExportacion.selloRecibido && data.fixFacturaExportacion.respuestaHacienda.completeResponse.estado === "PROCESADO") {
          toast.success("DTE corregido correctamente");
          navigate("/ventas");
          resetGlobal();
          resetForm({});
        } else if (data.fixFacturaExportacion.respuestaHacienda.completeResponse.estado === "RECHAZADO") {
          toast.error(`Ocurrió un error al agregar venta ${data.fixFacturaExportacion.respuestaHacienda.completeResponse.descripcionMsg}`);
          setSaleInformation({
            errorInformation: {
              mhObservations: data.resendSale.data?.mhObservations,
              nameClient: data.resendSale.data?.clientCompany?.name,
              generationCodeDte: data?.resendSale?.data?.generationCodeDte,
            },
          });
          handleOpen(ActiveValues.ventaRechazada);
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(`Ocurrió un error al reenviar la venta \n Por favor confirma que los campos son correctos e intente nuevamente o contacta a soporte`);
      });
  };

  return { handleFixFacturaExportacion, dataFixFacturaExportacion };
};
