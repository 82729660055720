import { useState } from "react";

// @mui material components
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// React router dom
import { useNavigate } from "react-router-dom";

//images
import imageError from "../../../../assets/images/error-sale-complete.svg";

// Types
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { SaleInformation } from "types/saleInformation";
import { useAddSellContext } from "context/AddSellContext";

interface Props {
  saleInformation: SaleInformation;
  crearOtroDte: () => void;
  open: boolean;
  handleClose: (value: ActiveValues) => void;
}
function DialogVentaRechazada({ open, handleClose, saleInformation, crearOtroDte }: Props) {
  const navigate = useNavigate();

  const { resetGlobal } = useAddSellContext();
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <Dialog
      open={open && !isLoading}
      keepMounted
      onClose={() => {
        handleClose(ActiveValues.ventaRechazada);
      }}
    >
      <DialogTitle color="info">{"Oops tenemos un problema en esta venta."}</DialogTitle>
      {saleInformation ? (
        <>
          <DialogContent>
            <MDBox display="flex" flexDirection="column" justifyContent="center">
              <MDBox display="flex" width="100%" justifyContent="center">
                <img src={imageError} width={300} height={300} onLoad={handleImageLoad} />
              </MDBox>
              <MDTypography sx={{ px: 2 }} variant="subtitle2" fontWeight="regular" color="secondary">
                <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1rem">
                  Observación:{" "}
                </MDTypography>
                El DTE emitido al cliente{" "}
                <MDTypography variant="button" color="info" fontWeight="bold" fontSize="1rem">
                  {saleInformation?.errorInformation?.nameClient}{" "}
                </MDTypography>
                fue{" "}
                <MDTypography variant="button" color="error" fontWeight="bold" fontSize="1rem">
                  RECHAZADO{" "}
                </MDTypography>
                {saleInformation?.errorInformation?.mhObservations}
              </MDTypography>
            </MDBox>
          </DialogContent>
        </>
      ) : null}
      <DialogActions sx={{ flexDirection: "column", gap: 2 }}>
        <MDBox sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
          <MDButton
            onClick={() => {
              resetGlobal();
              navigate("/ventas");
            }}
            variant="outlined"
            color="info"
          >
            Ver Ventas
          </MDButton>
          <MDButton
            onClick={() => {
              crearOtroDte();
            }}
            color="info"
          >
            Crear Otra
          </MDButton>
        </MDBox>
        <MDButton
          fullWidth
          onClick={() => {
            handleClose(ActiveValues.ventaRechazada);
            resetGlobal();
            navigate(`/ventas/corregirDTE/${saleInformation?.errorInformation.generationCodeDte}`);
          }}
          color="error"
        >
          Corregir Dte
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogVentaRechazada;
