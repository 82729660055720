// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Custom components
import FormsNuevoDte from "./components/formsNuevoDte";

// Utils
import { validateAddSellForm } from "utils/validations/validateAddSellForm";
import { validationSchemaVentas } from "utils/validations/validationSchemaVentas";

// Libs
import { Formik } from "formik";

// Hooks
import { useHandleSubmitFormikVentas } from "hooks/nuevoDTE/handlesSubmits/useHandleSubmitFormikVentas";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const NuevoDTE = ({ freeSale, CorregirDTE }: { freeSale?: boolean; CorregirDTE?: boolean }) => {
  // Context
  const {
    initialValues,
    global: { totalToPay, subTotal, products },
  } = useAddSellContext();

  const { handleSubmitFormikVentas } = useHandleSubmitFormikVentas();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          handleSubmitFormikVentas(values, resetForm);
        }}
        validationSchema={validationSchemaVentas}
        validate={(values: AddSaleGeneralDocumentFilterValues) => validateAddSellForm(products, values, totalToPay, subTotal, freeSale)}
      >
        <FormsNuevoDte freeSaleComp={freeSale} CorregirDTE={CorregirDTE} />
      </Formik>
      <Footer />
    </DashboardLayout>
  );
};

export default NuevoDTE;
