import gql from "graphql-tag";

export const GET_GROUPS = gql`
  query groupsFilters($groupsFilters: QueryFilterDti!) {
    getGroups(groupsFilters: $groupsFilters) {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
      }
    }
  }
`;
