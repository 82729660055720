import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Libs
import { useFormikContext } from "formik";

// Custom components
import DialogEditRetISR from "./dailogs/DialogEditRetISR";
import DialogEditSaleDiscount from "./dailogs/DialogEditSaleDiscount";
import DialogEditFleteFex from "./dailogs/DialogEditFleteFex";
import DialogEditSeguroFex from "./dailogs/DialogEditSeguroFex";

// Types
import { ContribuyenteTypes } from "types/contribuyenteTypes";
import { legalRepresetantion } from "types/legalRepresentation";
import { IvaDocumentTypes } from "types/iva-document";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const ResumeFloatingSection = () => {
  // Context
  const {
    global: { iva, retention, perception, totalGravados, fleteFex, seguroFex, saleDiscount, totalExentos, totalNoSujetos, totalToPay, subTotal, clientCompany, retentionISR },
    company,
  } = useAddSellContext();
  const { values } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  // States
  const [openEditISR, setOpenEditISR] = useState(false);
  const [openSaleDiscount, setOpenSaleDiscount] = useState(false);
  const [openSeguroFex, setOpenSeguroFex] = useState(false);
  const [openFleteFex, setOpenFleteFex] = useState(false);

  const handleCloseSaleDiscount = () => {
    setOpenSaleDiscount(false);
  };
  const handleOpenSaleDiscount = () => {
    setOpenSaleDiscount(true);
  };
  const handleCloseEditISR = () => {
    setOpenEditISR(false);
  };
  const handleOpenEditISR = () => {
    setOpenEditISR(true);
  };
  const handleCloseFleteFex = () => {
    setOpenFleteFex(false);
  };
  const handleOpenFleteFex = () => {
    setOpenFleteFex(true);
  };
  const handleCloseSeguroFex = () => {
    setOpenSeguroFex(false);
  };
  const handleOpenSeguroFex = () => {
    setOpenSeguroFex(true);
  };

  return (
    <>
      <Grid id="resumeFloatingSection" item xs={12}>
        <Card sx={{ paddingX: 2, paddingY: 4 }}>
          <Grid container justifyContent="space-between" wrap="nowrap" gap={2} flexDirection={{ xs: "column", md: "row" }}>
            <MDBox display="flex" alignItems="center">
              <MDTypography color="info" variant="h2">
                Totales:
              </MDTypography>
            </MDBox>
            <Grid container alignItems="end" justifyContent={{ xs: "center", md: "flex-end" }} flexWrap={{ xs: "wrap", md: "nowrap" }} flexDirection="row" gap={3}>
              {values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? (
                <>
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(Number(seguroFex ?? ""))}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      Seguro{" "}
                      <EditIcon
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#5AA9E6",
                            transition: "all 200ms ease",
                          },
                        }}
                        onClick={() => handleOpenSeguroFex()}
                        color="info"
                      />
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(Number(fleteFex ?? ""))}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      Flete{" "}
                      <EditIcon
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#5AA9E6",
                            transition: "all 200ms ease",
                          },
                        }}
                        onClick={() => handleOpenFleteFex()}
                        color="info"
                      />
                    </MDTypography>
                  </MDBox>

                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(Number(saleDiscount ?? ""))}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      Descuento de la Venta{" "}
                      <EditIcon
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#5AA9E6",
                            transition: "all 200ms ease",
                          },
                        }}
                        onClick={() => handleOpenSaleDiscount()}
                        color="info"
                      />
                    </MDTypography>
                  </MDBox>
                </>
              ) : (
                <>
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(totalNoSujetos)}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      No Sujeto
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(totalExentos)}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      Exento
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(totalGravados)}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      Gravado
                    </MDTypography>
                  </MDBox>
                  {clientCompany?.legalRepresentation && clientCompany?.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA && company?.legalRepresentation === legalRepresetantion.PERSONA_NATURAL_IVA ? (
                    <MDBox>
                      <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                        {formatNumberToMoney(retentionISR)}
                      </MDTypography>
                      <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                        Ret. ISR{" "}
                        <EditIcon
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#5AA9E6",
                              transition: "all 200ms ease",
                            },
                          }}
                          onClick={() => handleOpenEditISR()}
                          color="info"
                        />
                      </MDTypography>
                    </MDBox>
                  ) : null}
                  {values.IvaDocumentType?.name_document !== "FAC" && (
                    <>
                      <MDBox>
                        <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                          {formatNumberToMoney(iva)}
                        </MDTypography>
                        <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                          IVA
                        </MDTypography>
                      </MDBox>
                    </>
                  )}
                  <MDBox>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {formatNumberToMoney(retention + perception)}
                    </MDTypography>
                    <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                      {company?.contribuyenteType === ContribuyenteTypes.GRANDE ? "Percepción" : "Retención IVA"}
                    </MDTypography>
                  </MDBox>
                </>
              )}
              <MDBox>
                <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                  {formatNumberToMoney(subTotal)}
                </MDTypography>
                <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                  Sub Total
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                  {formatNumberToMoney(totalToPay)}
                </MDTypography>
                <MDTypography color="info" variant="body1" sx={{ textAlign: "center" }}>
                  Total
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <DialogEditRetISR open={openEditISR} handleClose={handleCloseEditISR} />
      <DialogEditSaleDiscount open={openSaleDiscount} handleClose={handleCloseSaleDiscount} />
      <DialogEditSeguroFex open={openSeguroFex} handleClose={handleCloseSeguroFex} />
      <DialogEditFleteFex open={openFleteFex} handleClose={handleCloseFleteFex} />
    </>
  );
};

export default ResumeFloatingSection;
