import { useEffect } from "react";

//@mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Formik
import { FormikProps } from "formik";

//Types
import { departamentos, getMunicipios, paises } from "utils/getRegions";
import { Values } from "../..";

interface Props {
  formik: FormikProps<Values>;
}
function Direccion({ formik }: Props) {
  useEffect(() => {
    if (formik.values?.pais?.label !== "EL SALVADOR") {
      formik.setFieldValue("municipio", null);
      formik.setFieldValue("departamento", null);
    }
  }, [formik.values.pais]);

  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);
  return (
    <>
      <MDBox my={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Direccion
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
          Esta informacion es requerida por el Ministerio de Hacienda para que puedas ocupar los Documentos Tributarios Electronicos
        </MDTypography>
      </MDBox>
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 9.5 }}>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="pais"
                  options={paises}
                  freeSolo
                  value={formik.values.pais}
                  onChange={(e, value) => formik.setFieldValue("pais", value)}
                  onBlur={() => formik.setFieldTouched("pais", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pais"
                      error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                      helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              {formik.values.pais?.label === "EL SALVADOR" && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    freeSolo
                    onChange={(e, value) => formik.setFieldValue("departamento", value)}
                    value={formik.values.departamento}
                    onBlur={() => formik.setFieldTouched("departamento", true)}
                    options={departamentos}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Departamento"
                        error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                        helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              )}
              {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    freeSolo
                    value={formik.values.municipio}
                    onChange={(e, value) => formik.setFieldValue("municipio", value)}
                    onBlur={() => formik.setFieldTouched("municipio", true)}
                    options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Municipio"
                        error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                        helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Direccion"
              error={Boolean(formik.errors.direccion)}
              value={formik.values.direccion ?? ""}
              helperText={!formik.errors.direccion ? " " : `${formik.errors.direccion}`}
              onChange={formik.handleChange}
              name="direccion"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Telefono"
                  name="telefono"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={Boolean(formik.errors.telefono) && Boolean(formik.touched.telefono)}
                  value={formik.values.telefono ?? ""}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched("telefono", true)}
                  helperText={!formik.touched.telefono || (formik.touched.telefono && !formik.errors.telefono) ? " " : formik.errors.telefono}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  error={Boolean(formik.errors.companyEmail) && Boolean(formik.touched.companyEmail)}
                  label="Correo Electronico de la Empresa"
                  name="companyEmail"
                  value={formik.values.companyEmail ?? ""}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("companyEmail", true)}
                  helperText={!formik.touched.companyEmail || (formik.touched.companyEmail && !formik.errors.companyEmail) ? " " : formik.errors.companyEmail}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default Direccion;
