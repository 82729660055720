// Constants
import { RETENTION_PERCEPTION } from "constants/finantial";

// Types
import { Company } from "types/company";
import { ClientCompany } from "types/documents";
import { ContribuyenteTypes } from "types/contribuyenteTypes";

export const calculateRetention = (totalGravados: number, company: Company, clientCompany: ClientCompany) => {
  if (clientCompany.contribuyenteType === ContribuyenteTypes.GRANDE && company.contribuyenteType !== ContribuyenteTypes.GRANDE) {
    return totalGravados * RETENTION_PERCEPTION * -1;
  }
  return 0;
};
