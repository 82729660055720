import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";

export const priceListService = {
  /**
   * Descarga la plantilla desde la URL
   */
  async downloadTemplate(idCompany: number): Promise<void> {
    try {
      const response: AxiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/price-list/download-template/relationship/articles/${idCompany}`, {
        responseType: "blob",
        headers: { authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}` },
      });
      fileDownload(response.data, "plantilla-listaDePrecio.xlsx");
    } catch (e) {
      toast.error(`Ocurrió un error al realizar la descarga de la plantilla`);
    }
  },
};
