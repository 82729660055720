import gql from "graphql-tag";

export const GENERATE_INVALIDATION_EVENTS = gql`
  mutation {
    generateTestsInvalidationEvents {
      isSuccess
      data {
        totalInvalidated
        totalNotInvalidated
      }
      message {
        message
        code
        detail
      }
    }
  }
`;
