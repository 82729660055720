import React from "react";

// @mui material components
import { Box, Tab, Tabs } from "@mui/material";

// Custom Component
import OtherAssociatedDocuments from "./OtherAssociatedDocuments";
import VentaCuentasTerceros from "./VentaCuentasTerceros";
import CustomTabPanel from "./CustomTabPanel";

const TabsBox = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Otros Documentos Asociados" />
        <Tab label="Venta a Cuenta de Terceros" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <OtherAssociatedDocuments />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <VentaCuentasTerceros />
      </CustomTabPanel>
    </Box>
  );
};

export default TabsBox;
