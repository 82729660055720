/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import SignInBasic from "layouts/authentication/sign-in";
import SignOut from "layouts/authentication/sign-out";
import CrearNuevaEmpresa from "layouts/configuracion/crear-nueva-empresa";
import Empresa from "layouts/configuracion/empresa";
import FacturaElectronica from "layouts/configuracion/factura-electronica";
import Ventas from "layouts/ventas";
import Clientes from "layouts/contactos/clientes";
import NuevoDTE from "layouts/ventas/nuevoDTE";
import Articulos from "layouts/inventarios/Articulos";
import ListaDePrecio from "layouts/inventarios/ListaDePrecios";

// Material Dashboard 2 PRO React TS components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

// Images
import profilePicture from "assets/images/hazconta/default-avatar.png";

// Custom components
import PrivateRoute from "components/Authentication/PrivateRoute";
import Comenzar from "layouts/authentication/sign-in/comenzar";
import Empleados from "layouts/contactos/empleados";
import Proveedores from "layouts/contactos/proveedores";

const persistedUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "Bievenido";

const routes = [
  {
    type: "collapse",
    name: persistedUser?.name,
    key: "profile-picture",
    icon: <MDAvatar src={profilePicture} alt={"Profile Picture"} size="sm" />,
    collapse: [
      {
        name: "Cambiar de Empresa",
        key: "cambiar de empresa",
        route: "/",
        component: (
          <PrivateRoute>
            <Comenzar />
          </PrivateRoute>
        ),
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Ventas",
    key: "ventas",
    icon: <Icon fontSize="medium">attach_money</Icon>,
    collapse: [
      {
        name: "Lista",
        key: "ventas",
        route: "/ventas",
        component: (
          <PrivateRoute>
            <Ventas />
          </PrivateRoute>
        ),
      },
      {
        name: "Crear venta",
        key: "NuevoDTE",
        route: "/ventas/NuevoDTE",
        component: (
          <PrivateRoute>
            <NuevoDTE />
          </PrivateRoute>
        ),
      },
      {
        name: "Crear venta manual",
        key: "NuevoDTE-Manual",
        route: "/ventas/NuevoDTE-Manual",
        component: (
          <PrivateRoute>
            <NuevoDTE freeSale />
          </PrivateRoute>
        ),
      },
    ],
  },
  { type: "divider", key: "divider-3" },

  {
    type: "collapse",
    name: "Contactos",
    key: "contactos",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    collapse: [
      {
        name: "Clientes",
        key: "clientes",
        route: "/contactos/clientes",
        component: <Clientes />,
      },
      /* {
        name: "Proveedores",
        key: "proveedores",
        route: "/contactos/proveedores",
        component: <Proveedores />,
      }, */
      {
        name: "Empleados",
        key: "empleados",
        route: "/contactos/empleados",
        component: <Empleados />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Inventarios",
    key: "inventarios",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Articulos",
        key: "articulos",
        route: "/inventarios/articulos",
        noCollapse: true,
        component: <Articulos />,
      },
      {
        name: "Lista de Precios",
        key: "lista-de-precio",
        route: "/inventarios/lista-de-precio",
        component: <ListaDePrecio />,
      },
    ],
  },
  { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Configuración",
    key: "configuration",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Empresa",
        key: "empresa",
        route: "/configuracion/empresa",
        component: <Empresa />,
      },
      {
        name: "Crear nueva empresa",
        key: "crear-nueva-empresa",
        route: "/configuracion/crear-nueva-empresa",
        component: <CrearNuevaEmpresa />,
      },
      {
        name: "Factura electrónica",
        key: "factura-electronica",
        route: "/configuracion/factura-electronica",
        component: (
          <PrivateRoute>
            <FacturaElectronica />
          </PrivateRoute>
        ),
      },
    ],
  },
  { type: "divider", key: "divider-5" },
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    icon: <SupervisorAccountOutlinedIcon />,
    collapse: [
      {
        name: "Contacto",
        key: "contacto",
        route: "https://api.whatsapp.com/send/?phone=%2B50375304289&text&type=phone_number&app_absent=0",
      },
      {
        name: "Notificaciones",
        key: "notification",
        route: "#",
      },
    ],
  },
  { type: "divider", key: "divider-6" },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/authentication/sign-in",
    noCollapse: true,
    icon: <LogoutIcon />,
    component: (
      <SignOut>
        <SignInBasic />
      </SignOut>
    ),
  },
];

export default routes;
