import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//@mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

//Formik
import { FormikProps } from "formik";

//Graphql
import { gql, useQuery } from "@apollo/client";

//Types
import { PtosDeVtas } from "types/PtosDeVtas";
import { PAGINATION } from "constants/pagination";

//Queries
const GET_COSTS_CENTERS = gql`
  query {
    getCostsCenters {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_STORAGE = gql`
  query {
    getStorages {
      isSuccess
      data {
        id
        name
        isActive
        allowSale
        costCenter {
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

interface Props {
  formik: FormikProps<PtosDeVtas>;
}
interface OficinasOrBodegas {
  id: number | null;
  label: string | null;
}

function GeneralesPtosDeVtas({ formik }: Props) {
  const dataCostCenter = useQuery(GET_COSTS_CENTERS, { variables: { pagination: PAGINATION } });
  const dataStorage = useQuery(GET_STORAGE);
  const [oficinas, setOficinas] = useState<OficinasOrBodegas[]>([{ label: "No Options", id: 0 }]);
  const [bodegas, setBodegas] = useState<OficinasOrBodegas[]>([{ label: "No Options", id: 0 }]);

  //Effects
  useEffect(() => {
    if (dataCostCenter?.data) {
      setOficinas(
        dataCostCenter?.data?.getCostsCenters?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataCostCenter.data]);
  useEffect(() => {
    if (dataStorage?.data) {
      setBodegas(
        dataStorage?.data?.getStorages?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataStorage.data]);
  useEffect(() => {
    formik.setFieldValue("establecimiento", null);
  }, [formik.values.tipoFacturacion]);
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Datos Generales
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Todos estos campos son obligatorios
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="tipoFacturacion"
                  freeSolo
                  options={["Bodega", "Oficina"]}
                  value={formik.values.tipoFacturacion}
                  onChange={(e, value) => formik.setFieldValue("tipoFacturacion", value)}
                  onBlur={() => formik.setFieldTouched("tipoFacturacion", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de lugar donde facturaras"
                      error={Boolean(formik.errors.tipoFacturacion) && Boolean(formik.touched.tipoFacturacion)}
                      helperText={!formik.touched.tipoFacturacion || (formik.touched.tipoFacturacion && !formik.errors.tipoFacturacion) ? " " : `${formik.errors.tipoFacturacion}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="establecimiento"
                  options={formik.values.tipoFacturacion === "Bodega" ? bodegas : formik.values.tipoFacturacion === "Oficina" ? oficinas : [{ label: "No options", id: 0 }]}
                  freeSolo
                  value={formik.values?.establecimiento?.label ?? ""}
                  getOptionDisabled={(option) => option.label === "No options" && option.id === 0}
                  onChange={(e, value) => formik.setFieldValue("establecimiento", value)}
                  onBlur={() => formik.setFieldTouched("establecimiento", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Lugar exacto desde donde facturaras"
                      error={Boolean(formik.errors.establecimiento) && Boolean(formik.touched.establecimiento)}
                      helperText={!formik.touched.establecimiento || (formik.touched.establecimiento && !formik.errors.establecimiento) ? " " : `${formik.errors.establecimiento}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.name) && formik.touched.name}
              onBlur={() => formik.setFieldTouched("name", true)}
              label="Nombre del Punto de Venta"
              name="name"
              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : `${formik.errors.name}`}
              value={formik.values.name}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.code) && formik.touched.code}
              onBlur={() => formik.setFieldTouched("code", true)}
              label="Codigo Interno"
              name="code"
              helperText={!formik.touched.code || (formik.touched.code && !formik.errors.code) ? " " : `${formik.errors.code}`}
              value={formik.values.code}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.mhCode) && formik.touched.mhCode}
              onBlur={() => formik.setFieldTouched("mhCode", true)}
              label="Numero Asignado"
              name="mhCode"
              helperText={!formik.touched.mhCode || (formik.touched.mhCode && !formik.errors.mhCode) ? " " : formik.errors.mhCode}
              value={formik.values.mhCode}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default GeneralesPtosDeVtas;
