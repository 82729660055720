// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";

// Services
import { tagsService } from "services/tags-service";
import { groupsService } from "services/groups-service";
import { measurementService } from "services/measurement-service";
import { articleService } from "services/article-service";

//Custom components
import ExportArticle from "./Exports";

function GetExportComponent({ step }: { step: number }): JSX.Element {
  const titles = ["Exportar Articulos", "Exportar Caracteristicas", "Exportar Grupos", "Exportar Unidades de Medida"];
  const names = ["Articulos", "Caracteristicas", "Grupos", "Unidades de Medida"];

  const getService = (step: number): any => {
    const services = [articleService, tagsService, groupsService, measurementService];
    return services[step];
  };
  return (
    <ExportArticle name="Caracteristicas">
      <>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          {titles[step]}
        </MDTypography>

        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {`Solamente haz clic en el boton de descargar y podras obtener toda la informacion de ${step % 2 === 0 ? "los" : "las"} ${names[step].toLowerCase()} en un archivo de excel.`}
        </MDTypography>
      </>
    </ExportArticle>
  );
}

export default GetExportComponent;
