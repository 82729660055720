import gql from "graphql-tag";

export const CREATE_TEST_NOTA_CREDITO = gql`
  mutation CREATE_TEST_NOTA_CREDITO($input: testDteDTINC!) {
    createTestNotaCredito(input: $input) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
