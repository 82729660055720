import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//Graphql
import { useMutation, useQuery } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";
import GroupsDialog from "./GroupsDialog";

//Alerts
import { toast } from "react-hot-toast";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Queries
import { GET_GROUPS } from "apollo/queries/groups/getGroups";
import { DELETE_GROUP } from "apollo/mutations/groups/deleteGroup";
import { ADD_GROUP } from "apollo/mutations/groups/addGroup";
import { UPDATE_GROUP } from "apollo/mutations/groups/updateGroup";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

interface Row {
  nombre: string;
  menu: JSX.Element;
}

const validationSchema = Yup.object({
  group: Yup.string().required("El nombre del grupo debe tener al menos 1 caracter"),
});

const columns = [
  { Header: "Nombre", accessor: "nombre", width: "90%" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center", width: "10%" },
];

const options = ["Editar", "Archivar"];

function Agrupacion() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  //Querys and mutations
  const { data, loading, refetch } = useQuery(GET_GROUPS, { context, variables: { groupsFilters: { pagination: PAGINATION } } });
  const [deleteGroup, dataDeleteGroup] = useMutation(DELETE_GROUP, { context });
  const [addGroup, dataAddGroup] = useMutation(ADD_GROUP, { context });
  const [updateGroup, dataUpdateGroup] = useMutation(UPDATE_GROUP, { context });

  //Formik
  const formik = useFormik({
    initialValues: {
      group: "",
    },
    validationSchema,
    onSubmit: (values) => {
      addGroup({
        variables: {
          data: {
            grupo: values.group,
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addGroup;
          if (isSuccess) {
            refetch();
            formik.setFieldValue("group", "");
            formik.setFieldTouched("group", false);
            toast.success("Grupo creada correctamente");
            handleClose();
          } else {
            toast.error(`Hubo un error al crear el grupo ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al crear el grupo ${e}`);
        });
    },
  });

  //state
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [open, setOpen] = useState<{ state: boolean; type?: number }>({ state: false, type: null });

  const handleClickOpen = () => {
    setOpen({ state: true, type: 0 });
  };

  function handleClose() {
    setOpen({ state: false, type: null });
    formik.setFieldValue("group", "");
    formik.setFieldTouched("group", false);
    setSelectedMenuOption({
      option: "",
      id: "",
    });
  }
  const handleSubmitUpdateGroup = () => {
    updateGroup({
      variables: {
        idGroup: Number(selectedMenuOption.id),
        groupData: {
          grupo: formik.values.group,
        },
      },
    }).then((data: any) => {
      if (data.data.updateGroup.isSuccess) {
        refetch();
        toast.success("Grupo actualizada correctamente");
        handleClose();
      }
    });
  };

  //Effects
  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      setOpen({ state: true, type: 1 });
    }
    if (selectedMenuOption.option === "Archivar") {
      deleteGroup({
        variables: {
          idGroup: Number(selectedMenuOption.id),
        },
      }).then((data: any) => {
        if (data.data.deleteGroup.isSuccess) {
          refetch();
          toast.success("Se archivo el grupo correctamente");
        }
      });
    }
  }, [selectedMenuOption]);
  useEffect(() => {
    if (data) {
      setRowsData(data.getGroups.data);
    }
  }, [data]);

  const rows: Row[] = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item?.name,
      menu: <MenuDropdown onSelectOption={setSelectedMenuOption} id={item?.id} options={options} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows,
  };

  const loadingsDialog = {
    loadingAdd: dataAddGroup.loading,
    loadingUpdate: dataUpdateGroup.loading,
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de los Grupos{" "}
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui podras Buscar, editar y archivar todos los grupos creados"}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteGroup.loading ? { columns: columns, rows: [] } : table}
        loading={loading || dataDeleteGroup.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={handleClickOpen} color="info">
            Crear Nuevo Grupo
          </MDButton>
        </Grid>
      </DataTable>
      <GroupsDialog handleSubmitUpdateGroup={handleSubmitUpdateGroup} formik={formik} open={open} handleClose={handleClose} loading={loadingsDialog} />
    </>
  );
}
export default Agrupacion;
