import { DocumentType } from "types/documentType";

export const handleChangeFormat = (e: any, documentType: string, setFieldValue: (field: string, value: any) => void, field: string) => {
  if (documentType === DocumentType.NIT) {
    e.currentTarget.maxLength = 14;
    let string = e.currentTarget.value;
    string = string.replace(/\D/g, "");
    string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
    setFieldValue(field, string);
  }
  if (documentType === DocumentType.DUI) {
    e.currentTarget.maxLength = 9;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
    setFieldValue(field, value);
  }
  if (documentType === DocumentType.NRC) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d+)(\d{1})/, "$1-$2");
    setFieldValue(field, value);
  }
  if (documentType === DocumentType.PASSPORT) {
    setFieldValue(field, e.currentTarget.value);
  }
};
