import { Navigate } from "react-router-dom";

// Context
import { useAuthContext } from "context/AuthContext";

// Types
import { Props } from "types/react";

const PrivateRoute = ({ children }: Props) => {
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) {
    return <Navigate to="/authentication/sign-in" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
