import gql from "graphql-tag";

export const GET_COMPANY = gql`
  query {
    getCompany {
      isSuccess
      data {
        id
        name
        tradename
        address
        email
        phone
        legalRepresentation
        contribuyenteType
        lineOfBusiness {
          id
          name
          code
        }
        region {
          id
          parent {
            name
          }
        }

        documents {
          id
          documentNumber
          type
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
