/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";

// react-router-dom components
import { Link } from "react-router-dom";

// Images
import bgImage from "assets/images/hazconta/bg-login-resized.png";
import OnlyPublicRoute from "components/Authentication/OnlyPublicRoute";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useAuthContext } from "context/AuthContext";

// Contexts
import { useUserContext } from "context/UserContext";

// Forms
import { useFormik } from "formik";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";

interface Values {
  email: string;
  password: string;
}

function Basic(): JSX.Element {
  // Contexts
  const { setUser } = useUserContext();
  const { onLogin, isLoggedIn, renderAlert, isLoading } = useAuthContext();

  // States
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  // Methods
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik<Values>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const { user } = await onLogin({
          email: values.email,
          password: values.password,
        });
        user && setUser(user);
      } catch (error) {
        console.error("Error at /login -", error);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email("El correo electrónico ingresado es inválido").required("El correo electrónico es un campo requerido"),
      password: Yup.string().required("La contraseña es un campo requerido"),
    }),
  });

  if (isLoggedIn) {
    return (
      <OnlyPublicRoute>
        <Navigate to="/authentication/sign-in/comenzar" />;
        <Navigate to="/authentication/register" />;
      </OnlyPublicRoute>
    );
  }

  return (
    <BasicLayout image={bgImage} login={true}>
      <Card>
        <MDBox variant="gradient" bgColor="primary" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Iniciar sesión
          </MDTypography>
        </MDBox>
        <form onSubmit={formik.handleSubmit}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  label="Correo"
                  fullWidth
                  onBlur={() => {
                    formik.setFieldTouched("email", true);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CustomFieldError touched={formik.touched.email} errorName={formik.errors.email} />
              </MDBox>
              <MDBox mb={2} position="relative">
                <MDInput
                  id="password"
                  name="password"
                  label="Contraseña"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  onBlur={() => {
                    formik.setFieldTouched("password", true);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <MDBox display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: 0, right: 0, width: "10%", height: "100%" }}>
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {formik.values.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </MDBox>
                <CustomFieldError touched={formik.touched.password} errorName={formik.errors.password} />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetRememberMe} sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                  &nbsp;&nbsp;Recuerdame
                </MDTypography>
              </MDBox>

              <MDBox mt={2} mb={1}>
                <MDButton
                  // onClick={() => formik.handleSubmit()}
                  type="submit"
                  variant="gradient"
                  startIcon={isLoading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                >
                  {`${isLoading ? "Iniciando sesion..." : "Entrar"}`}
                </MDButton>
              </MDBox>
              <MDBox textAlign="center" display="flex" flexDirection="column">
                <MDTypography variant="button" color="text" sx={{ my: 1 }}>
                  ¿No tienes una cuenta? &nbsp;
                  <MDTypography component={Link} to="/authentication/register" variant="button" color="info" fontWeight="medium" textGradient>
                    Haz Clic Aquí
                  </MDTypography>
                </MDTypography>
                <MDTypography variant="button" color="text">
                  ¿Olvidaste tu contraseña? &nbsp;
                  <MDTypography component={Link} to="/authentication/resetPassword" variant="button" color="info" fontWeight="medium" textGradient>
                    Cambiala acá
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
      {renderAlert()}
    </BasicLayout>
  );
}

export default Basic;
