import gql from "graphql-tag";

export const DELETE_SALE_POINT_FROM_PRICE_LIST = gql`
  mutation DELETE_SALE_POINT_FROM_PRICE_LIST($idSalePoint: Float!, $idPriceList: Float!) {
    deleteSalePointFromPriceList(idSalePoint: $idSalePoint, idPriceList: $idPriceList) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
