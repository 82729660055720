import React from "react";

// @mui material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Icon from "@mui/material/Icon";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import NotificationItem from "views/Items/NotificationItem";

// Libs
import { useNavigate } from "react-router-dom";

// Utils
import { haveBeenPass24h } from "utils/haveBeenPass24h";

// Type
import { JsonDte } from "types/documents";
import { DTE_INFO } from "./SellsTable";
import { IvaDocumentTypes } from "types/iva-document";
import { MutationResult } from "@apollo/client";

const options = ["Ver Detalles", "Imprimir", /* "Descargar", */ "Descargar PDF", "Descargar JSON", "Enviar", "Duplicar", "Anular"];
const optionsNotaCredito = ["Ver Detalles", "Imprimir", /* "Descargar", */ "Descargar PDF", "Descargar JSON", "Enviar", "Duplicar", "Nota Credito"];
const optionsRechazados = ["Ver Detalles", /* "Descargar", */ "Descargar PDF", "Descargar JSON", "Corregir DTE"];

interface Props {
  state?: any;
  id: number;
  selectedItem: any;
  status: string | null;
  setDteInformation: React.Dispatch<React.SetStateAction<DTE_INFO>>;
  handleOpenDelete: () => void;
  handleClickOpenEmail: (selectedItem: any) => void;
  handleSubmitNotaCredito?: (selectedItems: any[], selectedItem: any) => void;
  selectedItems?: any[];
  dataAnularCCFsConNotaDeCredito: MutationResult<any>;
}

const SellsDropdown = ({ handleSubmitNotaCredito, selectedItems, id, selectedItem, status, setDteInformation, handleOpenDelete, handleClickOpenEmail, dataAnularCCFsConNotaDeCredito }: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const document = selectedItem;
  const parsedJson: JsonDte = JSON.parse(document?.jsonDte as any);

  const handleMenuItemClick = async (option: string, index: number) => {
    /*
     * View individual DTE was built using localStorage because there was no
     *  getGeneralDocumentById query at the moment
     */

    localStorage.setItem("documentView", JSON.stringify(selectedItem));

    index === 0 && navigate(`/ventas/detalle/${id}`, { state: { IvaDocumentType: selectedItem?.ivaDocument?.extended_name_document } });

    if (option === "Duplicar") {
      console.log(selectedItem);
      /* navigate("/ventas/nuevoDTE", {
        state: {
          generationCode: document?.generationCodeDte,
          isSimpleBilling: document?.isSimpleBilling,
        },
      }); */
    }
    if (option === "Imprimir") {
      let byteCharacters = atob(document.pdfBase64);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    if (option === "Descargar PDF") {
      const base64PDF = document?.pdfBase64;
      const link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();
    }
    if (option === "Descargar JSON") {
      const jsonDte: JsonDte = document?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = window.document.createElement("a");

      link.href = url;
      link.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link.click();
    }
    if (option === "Descargar") {
      const jsonDte: JsonDte = document?.jsonDte;
      const blob = new Blob([jsonDte as any], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link2 = window.document.createElement("a");

      link2.href = url;
      link2.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
      link2.click();

      var base64PDF = document?.pdfBase64;
      var link = window.document.createElement("a");
      link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
      link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
      link.click();
    }
    if (option === "Remover") {
      const pdfWindow = window.open(document.pdf);
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
    if (option === "Enviar") {
      handleClickOpenEmail(selectedItem);
    }
    if (option === "Corregir DTE") {
      navigate(`/ventas/corregirDTE/${selectedItem.generationCodeDte}`, { state: selectedItem });
    }
    if (option === "Anular") {
      const getNumControl = (string: string) => {
        const parts = string.split("-");
        const lastPart = parts[parts.length - 1];
        const number = parseInt(lastPart, 10);

        return number;
      };
      setDteInformation({
        idDte: selectedItem?.id,
        nroDTE: getNumControl(selectedItem?.dteControlNumber),
        generationCode: selectedItem?.generationCodeDte,
        clientName: {
          label: selectedItem?.client ?? "Cliente Anonimo",
          id: selectedItem?.idClientCompany ?? 0,
        },
        date: selectedItem.date,
        ivaDocumentName: selectedItem.ivaDocument.extended_name_document,
        amount: selectedItem.totalToPay,
        idEmployeeCompany: selectedItem.cashier.idEmployeeCompany,
      });
      handleOpenDelete();
    }
    if (option === "Nota Credito") {
      selectedItems.length > 1 ? handleSubmitNotaCredito(selectedItems, selectedItem) : handleSubmitNotaCredito(selectedItems, selectedItem);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "Imprimir":
        return <Icon color="info">print</Icon>;
      case "Descargar":
        return <CloudDownloadOutlinedIcon color="info" />;
      case "Descargar PDF":
        return <Icon color="info">document_scanner_icon</Icon>;
      case "Descargar JSON":
        return <Icon color="info">code_icon</Icon>;
      case "Ver Detalles":
        return <Icon color="info">plagiarism</Icon>;
      case "Enviar":
        return <Icon color="info">forward_to_inbox_icon</Icon>;
      case "Duplicar":
        return <Icon color="info">copy_all_icon</Icon>;
      case "Agregar Pago":
        return <Icon color="info">price_check_icon</Icon>;
      case "Anular":
        return <Icon color="error">close</Icon>;
      case "Nota Credito":
        return <ChangeCircleOutlinedIcon color="error" />;
      case "Corregir DTE":
        return <Icon color="error">construction</Icon>;
      case "Loading":
        return <CircularProgress size={20} sx={{ color: "#fff" }} />;
      default:
        return <Icon>circle</Icon>;
    }
  };

  return (
    <>
      <Icon ref={anchorRef} fontSize="medium" onClick={handleToggle} color="info" sx={{ cursor: "pointer" }}>
        more_vert
      </Icon>
      <Popper
        id="popper-menu"
        sx={{
          zIndex: 1,
        }}
        defaultChecked={false}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {status === "RECHAZADO"
                      ? optionsRechazados.map((option, index) => (
                          <NotificationItem key={option} icon={getIcon(option)} title={option} customColor={option === "Corregir DTE" ? "error" : "info"} onClick={(event) => handleMenuItemClick(option, index)} />
                        ))
                      : selectedItem.ivaDocument.name_document === IvaDocumentTypes.CCF && haveBeenPass24h(selectedItem.createdAt)
                      ? optionsNotaCredito.map((option, index) => (
                          <NotificationItem key={option} icon={getIcon(option)} title={option} customColor={option === "Nota Credito" ? "error" : "info"} onClick={(event) => handleMenuItemClick(option, index)} />
                        ))
                      : options.map((option, index) => <NotificationItem key={option} icon={getIcon(option)} title={option} customColor={option === "Anular" ? "error" : "info"} onClick={(event) => handleMenuItemClick(option, index)} />)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </>
  );
};

export default SellsDropdown;
