import { DataTableDTEObject } from "types/dataTableDteObject";

export const reduceDuplicatedItems = (items: DataTableDTEObject[]) => {
  return items.reduce((acc, obj) => {
    if (acc.has(obj.id ?? obj.numItem)) {
      acc.set(obj.id ?? obj.numItem, { ...obj, quantity: acc.get(obj.id ?? obj.numItem).quantity + obj.quantity, totalPrice: acc.get(obj.id ?? obj.numItem).totalPrice + obj.totalPrice });
    } else {
      acc.set(obj.id ?? obj.numItem, obj);
    }
    return acc;
  }, new Map());
};
