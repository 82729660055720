import gql from "graphql-tag";

export const DELETE_PRICE_LISTS = gql`
  mutation DELETE_PRICE_LISTS($idPriceList: Float!) {
    deletePriceList(idPriceList: $idPriceList) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
