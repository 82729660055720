import gql from "graphql-tag";

export const ADD_ARTICLE_TO_PRICE_LIST = gql`
  mutation ADD_ARTICLE_TO_PRICE_LIST($data: [ArticlePriceListDti!]!, $idArticle: Float!) {
    addArticleToPriceList(data: $data, idArticle: $idArticle) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
