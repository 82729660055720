import * as Yup from "yup";

export const validationSchemaVentas = Yup.object({
  IvaDocumentType: Yup.object().required("El campo 'Tipo de Documento Tributario' es un campo requerido.").nullable(),
  operation_type: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required("El campo de 'Gravado' es requerido")
    .nullable(),
});
