import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography, CircularProgress } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//react-router-dom
import { useLocation } from "react-router-dom";

// Services
import { priceListService } from "services/priceList-service";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Custom Components
import MenuDropdown from "../../../contactos/clientes/components/MenuDropdown";
import StepperCat from "components/Global/StepperCat";
import ImportLDP from "./Imports/ArticulosImportLDP";
import ExportLDP from "./Exports/ExportLDP";

//Alert
import { toast } from "react-hot-toast";

// GraphQl
import { gql, useLazyQuery, useMutation } from "@apollo/client";

//Formik
import { useFormik } from "formik";
import * as Yup from "yup";

//types
import { LabelandId } from "types/labelAndId";
import { SerializationKeys } from "types/apollo";

const columns = [
  { Header: "Codigo Interno", accessor: "codigoInterno" },
  { Header: "Codigo de Barras", accessor: "codigoDeBarra" },
  { Header: "Nombre Del Articulo", accessor: "nombre" },
  { Header: "Precio", accessor: "precio" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];
const options = ["Editar Precio", "Eliminar"];

const GET_ARTICLES_BY_PRICE_LIST = gql`
  query GET_ARTICLES_BY_PRICE_LIST($idPriceList: Float!, $filters: ArticlesQueryFilterDti!) {
    getArticlesByPriceList(idPriceList: $idPriceList, filters: $filters) {
      isSuccess
      message {
        code
        detail
        message
      }
      data {
        quantity
        articles {
          id
          name
          code
          barCode
          salePrice
          idArticlePriceList
          storages {
            articleStock
            id
            skul
            storage {
              name
              id
            }
          }
        }
      }
    }
  }
`;
const DELETE_ARTICLE_FROM_PRICE_LIST = gql`
  mutation DELETE_ARTICLE_FROM_PRICE_LIST($idArticlePriceList: Float!) {
    deleteArticleFromPriceList(idArticlePriceList: $idArticlePriceList) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const ADD_ARTICLE_TO_PRICE_LIST = gql`
  mutation ADD_ARTICLE_TO_PRICE_LIST($idArticle: Float!, $data: [ArticlePriceListDti!]!) {
    addArticleToPriceList(idArticle: $idArticle, data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const GET_ARTICLES = gql`
  query {
    getArticles(articlesFilters: {}) {
      isSuccess
      data {
        id
        name
        type
        code
        barCode
        salesPrices {
          id
          salePrice
          isDefault
          priceList {
            id
            name
          }
        }
      }
      message {
        detail
        code
        message
      }
    }
  }
`;
const UPDATE_ARTICLE_PRICE_FROM_PRICE_LIST = gql`
  mutation UPDATE_ARTICLE_PRICE_FROM_PRICE_LIST($idArticlePriceList: Float!, $idArticle: Float!, $price: Float!, $isDefaultPricelist: Boolean!) {
    updateArticlePriceFromPriceList(idArticlePriceList: $idArticlePriceList, idArticle: $idArticle, price: $price, isDefaultPricelist: $isDefaultPricelist) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
interface nameAndId2 {
  id: number;
  name: string;
  idArticle: number;
  price?: string;
}

function ArticulosLDPTable() {
  const location = useLocation();

  // Constantes
  const valuesHandleClick = ["add", "delete", "chancePrice"];

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries and mutations
  const [getArticlesByPriceList, dataGetArticlesByPriceList] = useLazyQuery(GET_ARTICLES_BY_PRICE_LIST, { context });
  const [getArticles, dataGetArticles] = useLazyQuery(GET_ARTICLES, { context });
  const [deleteArticleFromPriceList, dataDeleteArticleFromPriceList] = useMutation(DELETE_ARTICLE_FROM_PRICE_LIST, { context });
  const [addArticleToPriceList, dataAddArticleToPriceList] = useMutation(ADD_ARTICLE_TO_PRICE_LIST, { context });
  const [updateArticlePriceFromPriceList, dataupdateArticlePriceFromPriceList] = useMutation(UPDATE_ARTICLE_PRICE_FROM_PRICE_LIST, { context });

  const [activeStep, setActiveStep] = useState(0);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [articles, setArticles] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [nameDelete, setNameDelete] = useState<nameAndId2>();

  const [open, setOpen] = useState({
    openAdd: false,
    openDelete: false,
    openPriceChange: false,
  });

  const validationSchema = Yup.object({
    article: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
      .nullable()
      .required("El articulo es requerido"),
    precio: Yup.string().trim().nullable().required("El precio es requerido"),
    isDefault: Yup.boolean(),
    nameArticleDelete: Yup.string().oneOf([nameDelete?.name, null], "El nombre del articulo debe coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      article: {
        id: 0,
        label: "",
      },
      precio: "",
      isDefault: false,
      nameArticleDelete: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      addArticleToPriceList({
        variables: {
          idArticle: Number(values.article.id),
          data: [{ idPriceList: Number(location.state.id), price: Number(values.precio), isDefault: values.isDefault }],
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addArticleToPriceList;
          if (isSuccess) {
            getArticlesByPriceList({
              variables: {
                idPriceList: Number(location.state.id),
                filters: {
                  pagination: 10000,
                },
              },
            });
            formik.setFieldValue("article", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("article", false);
            formik.setFieldValue("precio", "");
            formik.setFieldTouched("precio", false);
            formik.setFieldValue("isDefault", false);
            formik.setFieldTouched("isDefault", false);
            toast.success("Articulo agregado correctamente");
            handleClose(valuesHandleClick[0]);
          } else {
            getArticlesByPriceList({
              variables: {
                idPriceList: Number(location.state.id),
                filters: {
                  pagination: 10000,
                },
              },
            });
            formik.setFieldValue("article", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("article", false);
            formik.setFieldValue("precio", "");
            formik.setFieldTouched("precio", false);
            formik.setFieldValue("isDefault", false);
            formik.setFieldTouched("isDefault", false);
            toast.error(`Hubo un error al agregar el articulo ${message.detail}`);
            handleClose(valuesHandleClick[0]);
          }
        })
        .catch((e) => {
          getArticlesByPriceList({
            variables: {
              idPriceList: Number(location.state.id),
              filters: {
                pagination: 10000,
              },
            },
          });
          formik.setFieldValue("article", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("article", false);
          formik.setFieldValue("precio", "");
          formik.setFieldTouched("precio", false);
          formik.setFieldValue("isDefault", false);
          formik.setFieldTouched("isDefault", false);
          toast.error(`Hubo un error al agregar el articulo ${e}`);
          handleClose(valuesHandleClick[0]);
        });
    },
  });

  const handleClickOpen = (value: string) => {
    if (value === valuesHandleClick[0]) {
      setOpen((prev) => ({
        ...prev,
        openAdd: true,
      }));
    }
    if (value === valuesHandleClick[1]) {
      setOpen((prev) => ({
        ...prev,
        openDelete: true,
      }));
    }
    if (value === valuesHandleClick[2]) {
      setOpen((prev) => ({
        ...prev,
        openPriceChange: true,
      }));
    }
  };

  function handleClose(value: string) {
    if (value === valuesHandleClick[0]) {
      setOpen((prev) => ({
        ...prev,
        openAdd: false,
      }));
    }
    if (value === valuesHandleClick[1]) {
      setOpen((prev) => ({
        ...prev,
        openDelete: false,
      }));
    }
    if (value === valuesHandleClick[2]) {
      setOpen((prev) => ({
        ...prev,
        openPriceChange: false,
      }));
    }
  }

  const handleDeleteArticleLDP = () => {
    if (nameDelete?.id !== null || nameDelete?.name !== "" || nameDelete !== null) {
      deleteArticleFromPriceList({
        variables: {
          idArticlePriceList: Number(nameDelete?.id),
        },
      })
        .then((data) => {
          if (data.data.deleteArticleFromPriceList.isSuccess) {
            getArticlesByPriceList({
              variables: {
                idPriceList: Number(location.state.id),
                filters: {
                  pagination: 10000,
                },
              },
            });
            toast.success("Articulo eliminada correctamente");
            handleClose(valuesHandleClick[1]);
            setNameDelete({ name: "", id: null, idArticle: null });
            formik.setFieldValue("nameArticleDelete", "");
            formik.setFieldTouched("nameArticleDelete", false);
          } else {
            toast.error(`Hubo un error al eliminar el articulo ${data.data.deleteArticleFromPriceList.message.detail}`);
            handleClose(valuesHandleClick[1]);
            setNameDelete({ name: "", id: null, idArticle: null });
            formik.setFieldValue("nameArticleDelete", "");
            formik.setFieldTouched("nameArticleDelete", false);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al eliminar el articulo ${e}`);
          handleClose(valuesHandleClick[1]);
          setNameDelete({ name: "", id: null, idArticle: null });
          formik.setFieldValue("nameArticleDelete", "");
          formik.setFieldTouched("nameArticleDelete", false);
        });
    }
  };

  const handleSubmitChangePrice = () => {
    if (nameDelete?.id !== null && nameDelete?.idArticle !== null && nameDelete !== null) {
      updateArticlePriceFromPriceList({
        variables: {
          idArticlePriceList: Number(nameDelete?.id),
          idArticle: Number(nameDelete?.idArticle),
          price: Number(formik.values.precio),
          isDefaultPricelist: formik.values.isDefault,
        },
      })
        .then((data) => {
          if (data.data.updateArticlePriceFromPriceList.isSuccess) {
            getArticlesByPriceList({
              variables: {
                idPriceList: Number(location.state.id),
                filters: {
                  pagination: 99,
                },
              },
            });
            toast.success("Precio del articulo actualizado correctamente");
            handleClose(valuesHandleClick[2]);
            setNameDelete({ name: "", id: null, idArticle: null });
            formik.setFieldValue("precio", "");
            formik.setFieldTouched("precio", false);
            formik.setFieldValue("isDefault", false);
            formik.setFieldTouched("isDefault", false);
          } else {
            toast.error(`Hubo un error al actualizar el precio del articulo ${data.data.updateArticlePriceFromPriceList.message.detail}`);
            handleClose(valuesHandleClick[2]);
            setNameDelete({ name: "", id: null, idArticle: null });
            formik.setFieldValue("precio", "");
            formik.setFieldTouched("precio", false);
            formik.setFieldValue("isDefault", false);
            formik.setFieldTouched("isDefault", false);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al actualizar el precio del articulo ${e}`);
          handleClose(valuesHandleClick[2]);
          setNameDelete({ name: "", id: null, idArticle: null });
          formik.setFieldValue("precio", "");
          formik.setFieldTouched("precio", false);
          formik.setFieldValue("isDefault", false);
          formik.setFieldTouched("isDefault", false);
        });
    }
  };

  //Effects
  useEffect(() => {
    if (location.state.id) {
      getArticlesByPriceList({
        variables: {
          idPriceList: Number(location.state.id),
          filters: {
            pagination: 99,
          },
        },
      });
    }
  }, [location.state.id]);

  useEffect(() => {
    if (dataGetArticlesByPriceList.data) {
      setRowsData(dataGetArticlesByPriceList.data?.getArticlesByPriceList.data?.articles);
    }
  }, [dataGetArticlesByPriceList.data]);

  useEffect(() => {
    if (dataGetArticles?.data) {
      if (location.state.id) {
        const articles = dataGetArticles?.data?.getArticles?.data.filter((item: any) => item?.salesPrices.every((item: any) => item.priceList.id != Number(location.state.id)));
        setArticles(
          articles.map((item: any) => ({
            id: item.id,
            label: item.name,
          }))
        );
      }
    }
  }, [dataGetArticles?.data]);

  useEffect(() => {
    if (open.openAdd) {
      getArticles();
    }
  }, [open.openAdd]);

  useEffect(() => {
    if (selectedMenuOption.option === "Editar Precio") {
      const name = dataGetArticlesByPriceList.data?.getArticlesByPriceList.data?.articles.filter((item: any) => item.idArticlePriceList == selectedMenuOption.id);
      console.log(name);
      setNameDelete(() => ({
        id: name[0].idArticlePriceList,
        name: name[0].name,
        idArticle: name[0].id,
      }));
      formik.setFieldValue("precio", String(name[0].salePrice));
      handleClickOpen(valuesHandleClick[2]);
    }
    if (selectedMenuOption.option === "Eliminar") {
      const name = dataGetArticlesByPriceList.data?.getArticlesByPriceList.data?.articles.filter((item: any) => item.idArticlePriceList == selectedMenuOption.id);
      setNameDelete(() => ({
        id: name[0].idArticlePriceList,
        name: name[0].name,
        idArticle: name[0].id,
      }));
      handleClickOpen(valuesHandleClick[1]);
    }
  }, [selectedMenuOption]);

  //Data
  const rows = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item.name,
      codigoInterno: item.code ?? "No hay codigo interno",
      codigoDeBarra: item.barCode ?? "No hay codigo de barra",
      precio: formatNumberToMoney(item.salePrice) ?? "No hay precio",
      menu: <MenuDropdown options={options} id={String(item.idArticlePriceList)} onSelectOption={setSelectedMenuOption} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows,
  };

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: (
        <>
          <MDBox>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Artículos asosiados a la lista de precios: "${location.state.namePriceList}"`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              Administración de Artículos Asociados a la Lista de Precios.
            </MDTypography>
          </MDBox>
          <DataTable
            table={dataGetArticlesByPriceList.loading ? { columns: columns, rows: [] } : table}
            entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
            canSearch
            pagination={{ variant: "contained", color: "info" }}
            loading={dataGetArticlesByPriceList.loading /*  || dataDeleteClientCompany.loading */}
          >
            <Grid container alignItems="center" justifyContent="end">
              <MDButton onClick={() => handleClickOpen(valuesHandleClick[0])} color="info">
                Agregar Articulo
              </MDButton>
            </Grid>
          </DataTable>
          <Dialog
            open={open.openAdd}
            keepMounted
            onClose={() => {
              handleClose(valuesHandleClick[0]);
              formik.setFieldValue("article", {
                id: 0,
                label: "",
              });
              formik.setFieldTouched("article", false);
              formik.setFieldValue("precio", "");
              formik.setFieldTouched("precio", false);
              formik.setFieldValue("isDefault", false);
              formik.setFieldTouched("isDefault", false);
            }}
          >
            <DialogTitle>{"¿Desea agregar un articulo a esta lista de precios?"}</DialogTitle>
            <MDTypography sx={{ px: 2 }} variant="subtitle2" fontWeight="regular" color="secondary">
              Busca un articulo para agregarlo a la lista de precios.
            </MDTypography>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="article"
                    freeSolo
                    options={articles}
                    value={formik.values.article}
                    getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                    onChange={(e, value) => formik.setFieldValue("article", value)}
                    onBlur={() => formik.setFieldTouched("article", true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nombre del Articulo"
                        error={Boolean(formik.errors.article) && Boolean(formik.touched.article)}
                        helperText={!formik.touched.article || (formik.touched.article && !formik.errors.article) ? " " : `${formik.errors.article}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Precio sin Impuestos"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="precio"
                    error={Boolean(formik.errors.precio) && formik.touched.precio}
                    value={formik.values.precio}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("precio", true)}
                    helperText={!formik.touched.precio || (formik.touched.precio && !formik.errors.precio) ? " " : formik.errors.precio}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack mt={{ xs: 0, md: 1.2 }} direction="row" alignItems="center">
                    <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                      ¿Este precio es el determinado?
                    </MDTypography>
                    <Switch checked={formik.values.isDefault} onChange={formik.handleChange} name="isDefault" />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <MDButton
                onClick={() => {
                  handleClose(valuesHandleClick[0]);
                  formik.setFieldValue("article", {
                    id: 0,
                    label: "",
                  });
                  formik.setFieldTouched("article", false);
                  formik.setFieldValue("precio", "");
                  formik.setFieldTouched("preico", false);
                  formik.setFieldValue("isDefault", false);
                  formik.setFieldTouched("isDefault", false);
                }}
                variant="outlined"
                color="info"
              >
                Atras
              </MDButton>
              <MDButton
                disabled={dataAddArticleToPriceList.loading}
                onClick={() => {
                  formik.handleSubmit();
                }}
                startIcon={dataAddArticleToPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                variant="gradient"
                type="submit"
                color="info"
              >
                {dataAddArticleToPriceList.loading ? "Enviando" : "Enviar"}
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open.openDelete}
            keepMounted
            onClose={() => {
              handleClose(valuesHandleClick[1]);
              formik.setFieldValue("nameArticleDelete", "");
              formik.setFieldTouched("nameArticleDelete", false);
              setNameDelete({ name: "", id: null, idArticle: null });
            }}
          >
            <DialogTitle
              children={
                <Typography color="error" variant="h4" fontWeight="bold">
                  Eliminar Articulo
                </Typography>
              }
            />
            <DialogContent>
              <MDBox>
                <MDTypography color="info" variant="h6" fontWeight="bold">
                  {`¿Estas seguro que quieres eliminar este articulo de la lista de precios: ${location.state.namePriceList}?`}
                </MDTypography>
                <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
                  Confirma que quieres{" "}
                  <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                    {`Eliminar`}
                  </MDTypography>{" "}
                  de la lista de precios este articulo llamado:{" "}
                  <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                    {`"${nameDelete?.name}".`}{" "}
                  </MDTypography>
                  Escribiendo su nombre en el siguiente campo de texto:
                </MDTypography>
              </MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(formik.errors.nameArticleDelete) && formik.touched.nameArticleDelete}
                    onBlur={() => formik.setFieldTouched("nameArticleDelete", true)}
                    label="Nombre del Articulo"
                    name="nameArticleDelete"
                    helperText={!formik.touched.nameArticleDelete || (formik.touched.nameArticleDelete && !formik.errors.nameArticleDelete) ? "Escribe el nombre exacto del articulo a eliminar" : formik.errors.nameArticleDelete}
                    onChange={formik.handleChange}
                    variant="standard"
                    value={formik.values.nameArticleDelete}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <MDButton
                onClick={() => {
                  handleClose(valuesHandleClick[1]);
                  setNameDelete({ name: "", id: null, idArticle: null });
                  formik.setFieldValue("nameArticleDelete", "");
                  formik.setFieldTouched("nameArticleDelete", false);
                }}
                variant="outlined"
                color="info"
              >
                cancelar
              </MDButton>
              <MDButton
                disabled={dataDeleteArticleFromPriceList.loading || Boolean(formik.errors.nameArticleDelete) || formik.values.nameArticleDelete === ""}
                onClick={() => {
                  handleDeleteArticleLDP();
                }}
                startIcon={dataDeleteArticleFromPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                variant="contained"
                type="submit"
                color="error"
              >
                {dataDeleteArticleFromPriceList.loading ? "Eliminando..." : "Eliminar"}
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open.openPriceChange}
            keepMounted
            onClose={() => {
              handleClose(valuesHandleClick[2]);
              setNameDelete({ name: "", id: null, idArticle: null });
              formik.setFieldValue("precio", "");
              formik.setFieldTouched("precio", false);
              formik.setFieldValue("isDefault", false);
              formik.setFieldTouched("isDefault", false);
            }}
          >
            <DialogTitle>{"Editar precio del articulo"}</DialogTitle>
            <MDTypography sx={{ px: 2 }} fontWeight="regular" color="info">
              {nameDelete?.name}
            </MDTypography>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Precio sin Impuestos"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="precio"
                    error={Boolean(formik.errors.precio) && formik.touched.precio}
                    value={formik.values.precio}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("precio", true)}
                    helperText={!formik.touched.precio || (formik.touched.precio && !formik.errors.precio) ? " " : formik.errors.precio}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack mt={{ xs: 0, md: 1.2 }} direction="row" alignItems="center">
                    <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                      ¿Este precio es el determinado?
                    </MDTypography>
                    <Switch checked={formik.values.isDefault} onChange={formik.handleChange} name="isDefault" />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <MDButton
                onClick={() => {
                  handleClose(valuesHandleClick[2]);
                  setNameDelete({ name: "", id: null, idArticle: null });
                  formik.setFieldValue("precio", "");
                  formik.setFieldTouched("preico", false);
                  formik.setFieldValue("isDefault", false);
                  formik.setFieldTouched("isDefault", false);
                }}
                variant="outlined"
                color="info"
              >
                Atras
              </MDButton>
              <MDButton
                disabled={dataupdateArticlePriceFromPriceList.loading}
                onClick={() => {
                  handleSubmitChangePrice();
                }}
                startIcon={dataupdateArticlePriceFromPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                variant="gradient"
                type="submit"
                color="info"
              >
                {dataupdateArticlePriceFromPriceList.loading ? "Enviando" : "Enviar"}
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: (
        <ImportLDP service={priceListService}>
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Importar articulos a la Lista de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>

            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`En esta parte podrás importar artículos a la listas de precio llamada ${location.state.namePriceList}, pero debes de cumplir con el formato que puedes descargar en el siguiente botón:`}
            </MDTypography>
          </>
        </ImportLDP>
      ),
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: (
        <ExportLDP name="Articulos">
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Exportar artículos de la Listas de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`Solamente haz clic en el botón de descargar y podrás obtener toda la información de los artículos relacionados a la listas de precios llamada ${location.state.namePriceList} en un archivo de excel.`}
            </MDTypography>
          </>
        </ExportLDP>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9} minHeight="100vh">
          <MDBox>
            <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
          </MDBox>
          <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
export default ArticulosLDPTable;
