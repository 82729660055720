import gql from "graphql-tag";

export const GET_REINTENTOS = gql`
  query GET_REINTENTOS($id: Float!, $generationCodeDte: String!) {
    getReintentosByGenerationCode(idSale: $id, generationCodeDte: $generationCodeDte) {
      isSuccess
      data {
        id
        observations
        receiverName
        documentReceiver
        paymentCondition
        idClientCompany
        generationCodeDte
        client
        operationType

        history {
          eventType
          fecha
          statusReception
          email_destinatarios
        }
        date
        observations
        receptionDate
        emisionDate
        date
        status
        environment
        operationType
        dteControlNumber
        selloRecibido
        mhObservations
        totalToPay
        subtotal
        ivaAmount

        clientCompany {
          id
          legalRepresentation
          contribuyenteType
          name
          tradename
          phone
          address
          plazoType
          emailNotifications
          creditExcelse
          isActive
          isExcent
          creditLimit
          isActiveCredit
          excelse
          retention
          perception
          lineOfBusiness {
            id
            name
          }
        }
        ivaDocument {
          name_document
          id
          isActive
          code
          description
          extended_name_document
        }

        detailDocuments {
          id
          priceList {
            id

            name
            active
            description
          }
          article {
            id
            name
            barCode

            code
            tags {
              id
              name
            }
            operation_type

            photos {
              id
              urlPhoto
            }
            type
            measurement {
              id
              code
              name
            }
          }

          priceDetail
          unitPrice
          ivaOperationType
          detailQuantity
          description
          ivaOperationType
          createdAt
          updatedAt
        }
        correlative {
          id
          doc_number
          serial_number
          doc_reference
          createdAt
        }

        paymentMethods {
          paymentMethod {
            id
            name
            hasReferenceNumber
            code
          }
          type
          finalAmount
        }
        history {
          eventType
        }
      }
    }
  }
`;
