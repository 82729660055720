import { useNetworkStatus } from "hooks/useNetworkStatus";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { useUserContext } from "context/UserContext";
// import { queueLink } from "apollo/apollo";

const NetworkStatusTag = () => {
  const [isNetworkActive] = useNetworkStatus();
  const { user } = useUserContext();
  // useEffect(() => {
  //   if (isNetworkActive) {
  //     queueLink.open();
  //   } else {
  //     queueLink.close();
  //   }
  // }, [isNetworkActive]);

  return (
    <>
      {!isNetworkActive && (
        <MDBox
          sx={(theme) => ({
            position: "fixed",
            top: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.2rem",
            boxShadow: theme.shadows[2],
            background: theme.palette.warning.main,
            zIndex: 100,
          })}
        >
          <Icon>wifi_off_icon</Icon>
          <MDTypography variant="button" fontWeight="medium" sx={{ marginLeft: "0.5rem" }}>
            Estas usando HazConta sin conexión a internet.
            {user && ` La aplicación solo está disponible para el usuario ${user?.email}.`}
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};
export default NetworkStatusTag;
