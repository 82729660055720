import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// GraphQL
import { useLazyQuery, useQuery } from "@apollo/client";

// Queries
import { GET_GENERAL_DOCUMENTS } from "apollo/queries/dtes/getGeneralDocuments";
import { GET_DTE_RECHAZADOS } from "apollo/queries/dtes/getDteRechazados";

// Custom Components
import SellsTable from "./components/SellsTable";
import TableFilters from "./components/TableFilters";
import InfoTable from "./components/InfoTable";
import { DataInfoTable } from "types/dataInfoDtesRechazados";

// Types
import { SerializationKeys } from "types/apollo";
import { formatNumberToMoney } from "utils/formatNumberToMoney";

const IndexFacturacion = () => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Queries
  const [getGeneralDocuments, { data: generalDocuments, loading, refetch }] = useLazyQuery(GET_GENERAL_DOCUMENTS, {
    context,
  });
  const { data: DTEsRechazados } = useQuery(GET_DTE_RECHAZADOS, {
    context,
    variables: {
      filters: { priority: "PENDIENTES" },
    },
  });
  const { data: DTEsRechazados2 } = useQuery(GET_DTE_RECHAZADOS, {
    context,
    variables: {
      filters: { priority: "MULTADOS" },
    },
  });
  const [infoTable, setInfoTable] = useState<DataInfoTable>();

  useEffect(() => {
    if (DTEsRechazados) {
      if (DTEsRechazados2) {
        if (DTEsRechazados?.getDteRechazados?.isSuccess) {
          if (DTEsRechazados2?.getDteRechazados?.isSuccess) {
            const opciones = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };
            setInfoTable({
              multaPendientes: formatNumberToMoney(DTEsRechazados?.getDteRechazados?.data?.multa),
              nroErrorsPendientes: DTEsRechazados?.getDteRechazados?.data?.dtes.length,
              multaMultados: formatNumberToMoney(DTEsRechazados2?.getDteRechazados?.data?.multa),
              nroErrorsMultados: DTEsRechazados2?.getDteRechazados?.data?.dtes.length,
            });
          }
        }
      }
    }
  }, [DTEsRechazados, DTEsRechazados2]);

  return (
    <>
      <MDBox mt={5} mb={9} minHeight="100vh">
        <Grid container justifyContent="center" gap={5}>
          <TableFilters getGeneralDocuments={getGeneralDocuments} />
          {infoTable && <InfoTable infoTable={infoTable} />}
          {generalDocuments && !loading && <SellsTable data={generalDocuments.getGeneralDocuments.data} getData={getGeneralDocuments} refetch={refetch} />}
          {loading && (
            <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" height={{ xs: "200px", lg: "400px" }}>
              <CircularProgress color="info" />
            </MDBox>
          )}
        </Grid>
      </MDBox>
    </>
  );
};

export default IndexFacturacion;
