import gql from "graphql-tag";

export const DELETE_ARTICLE_FROM_PRICE_LIST = gql`
  mutation DELETE_ARTICLE_FROM_PRICE_LIST($idArticlePriceList: Float!) {
    deleteArticleFromPriceList(idArticlePriceList: $idArticlePriceList) {
      isSuccess
      message {
        message
        code
        detail
      }
    }
  }
`;
