import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";

export const clientService = {
  /**
   * Descarga la plantilla desde la URL
   */
  async downloadTemplate(): Promise<void> {
    try {
      const response: AxiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/client/download-template`, {
        responseType: "blob",
        headers: { authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}` },
      });
      fileDownload(response.data, "plantilla-cliente.xlsx");
    } catch (e) {
      toast.error(`Ocurrió un error al realizar la descarga de la plantilla`);
    }
  },
};
