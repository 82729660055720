import gql from "graphql-tag";

export const GET_DTE_RECHAZADOS = gql`
  query GET_DTE_RECHAZADOS($filters: DteRachazadosFiltersDti!) {
    getDteRechazados(filters: $filters) {
      isSuccess
      data {
        multa
        dtes {
          id
          status
          mhObservations
          emisionDate
          idClientCompany
          client
          clientCompany {
            id
            name
          }
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
