// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Types
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { DocumentType } from "types/documentType";
import { validarDUI } from "./validarDui";
import { validarNRC } from "./validarNrc";
import { validarNIT } from "./validarNit";
import { MINIMUM_AMOUNT } from "types/minimumAmount";
import { DataTableDTEObject } from "types/dataTableDteObject";

interface FormErrors {
  IvaDocumentType?: string;
  recintoFiscal?: string;
  regimenExportacion?: string;
  ventasCuentasTerceros?: {
    nit: string;
    name: string;
  };
  infoAssociateDocument?: {
    type: string;
    document: string;
    description: string;
    typeOfTransport: string;
    vehiclePlate: string;
    documentDriver: string;
    NameDriver: string;
  };
  incoterms?: string;
  clientCompany?: string;
  TotalAmount?: string;
  retention1?: string;
  perception1?: string;
  date?: string;
  idIvaDocument?: string;
  idPaymentType?: string;
  idCashier?: string;
  salePrice?: string;
  quantity?: string;
  idSalePoint?: string;
  idCostCenter?: string;
  notifyTo?: string;
  conditionPayment?: string;
  taxes?: string;
  paymentMethods?: string;
  extension?: {
    receiverName: string;
    receiverDocument: string;
    observations: string;
  };
  calcuations?: string;
  productCodes?: string;
  detailDocuments?: {
    salePrice?: string;
  };
  plazoType?: string;
  periodo?: string;
  measurementUnit?: string;
  type?: string;
}

export const validateAddSellForm = (products: DataTableDTEObject[], values: AddSaleGeneralDocumentFilterValues, totalToPay: number, subTotal: number, freeSale?: boolean) => {
  const { conditionPayment, productCodes, quantity, salePrice, clientCompany, paymentMethods, periodo, extension, IvaDocumentType } = values;
  const isCCF = IvaDocumentType?.name_document?.includes("CCF");
  const isFAC = IvaDocumentType?.name_document?.includes("FAC");
  const isFEX = IvaDocumentType?.name_document?.includes("FEX");
  const isClientExent = values?.clientCompany?.isExent;

  const errors: FormErrors = {
    extension: {
      receiverName: "",
      receiverDocument: "",
      observations: "",
    },
    ventasCuentasTerceros: {
      nit: "",
      name: "",
    },
    infoAssociateDocument: {
      type: "",
      document: "",
      description: "",
      typeOfTransport: "",
      vehiclePlate: "",
      documentDriver: "",
      NameDriver: "",
    },
  };
  // General
  if (products.length < 1) {
    if (!productCodes) errors.productCodes = "El campo de 'Códigos de productos' es requerido";
    if (!salePrice) errors.salePrice = "El campo de 'Precio Unitario' es requerido";
  }
  if (!conditionPayment) errors.conditionPayment = "El campo de 'Condición de pago' es requerido";
  if (!quantity) errors.quantity = "El campo de 'Cantidad' es requerido";
  if (totalToPay > MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT && !clientCompany) {
    errors.clientCompany = `Si el monto total es mayor a ${formatNumberToMoney(MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT)} el campo de 'Cliente' es requerido`;
  }

  // Free sale
  if (freeSale) {
    if (!values.type) errors.type = "El campo de 'Tipo' es requerido";
    if (Boolean(values?.type === "Ambos" || values?.type === "Bienes" || values?.type === "Otro") && !productCodes?.measurement) errors.measurementUnit = "El campo 'Unidad de Medida' es requerido";
  }

  // Comprobante de credito fiscal
  if (isCCF) {
    if (!clientCompany) errors.clientCompany = "El campo de 'Cliente' es requerido";
    if (subTotal > MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT) {
      if (!extension?.receiverDocument) {
        errors.extension.receiverDocument = `Si el monto total es mayor a ${formatNumberToMoney(MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT)} el campo de 'Documento de identificación' es requerido`;
      }
      if (!extension?.receiverName) {
        errors.extension.receiverName = `Si el monto total es mayor a ${formatNumberToMoney(MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT)} el campo de "Nombre" es requerido`;
      }
    }
  }

  // Facturas consumidor final
  if (isFAC) {
    if (!clientCompany) errors.clientCompany = "El campo de 'Cliente' es requerido";
    if (totalToPay > MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT) {
      if (!extension?.receiverName) {
        errors.extension.receiverName = `Si el monto total es mayor a ${formatNumberToMoney(MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT)} el campo de "Nombre" es requerido`;
      }
      if (!extension?.receiverDocument) {
        errors.extension.receiverDocument = `Si el monto total es mayor a ${formatNumberToMoney(MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT)} el campo de 'Documento de identificación' es requerido`;
      }
    }
  }

  // Factura de exportacion
  if (isFEX) {
    if (!clientCompany) errors.clientCompany = "El campo de 'Cliente' es requerido";
    if (!values.type) errors.type = "El campo de 'Tipo' es requerido";
    if (values?.type === "Bienes") {
      if (!values.recintoFiscal) errors.recintoFiscal = "El campo de 'Recinto Fiscal' es requerido";
      if (!values.regimenExportacion) errors.regimenExportacion = "El campo de 'Regimen de Exportación' es requerido";
      if (!values.incoterms) errors.incoterms = "El campo de 'INCOTERMS' es requerido";
    }
    if (values.ventasCuentasTerceros?.name) {
      if (values.ventasCuentasTerceros?.name.length < 3) {
        errors.ventasCuentasTerceros.name = "El nombre debe tener al menos 3 caracteres";
      }
    }
    if (values.ventasCuentasTerceros?.nit) {
      if (validarNIT(values.ventasCuentasTerceros?.nit)) {
        errors.ventasCuentasTerceros.nit = "NIT invalido";
      }
    }
    if (values.infoAssociateDocument?.document) {
      if (values.infoAssociateDocument?.document.length < 3) {
        errors.infoAssociateDocument.document = "El documento debe tener al menos 3 caracteres";
      }
    }
    if (values.infoAssociateDocument?.description) {
      if (values.infoAssociateDocument?.description.length < 5) {
        errors.infoAssociateDocument.description = " La descripcion debe de tener al menos 5 caracteres";
      }
    }
    if (values.infoAssociateDocument?.NameDriver) {
      if (values.infoAssociateDocument?.NameDriver.length < 3) {
        errors.infoAssociateDocument.NameDriver = "El nombre debe de tener al menos 3 caracteres";
      }
    }
    if (values.infoAssociateDocument?.vehiclePlate) {
      if (values.infoAssociateDocument?.vehiclePlate.length < 3) {
        errors.infoAssociateDocument.vehiclePlate = "La placa debe tener al menos 3 caracteres";
      }
    }
    if (values.infoAssociateDocument?.documentDriver) {
      if (values.infoAssociateDocument?.documentDriver.length < 3) {
        errors.infoAssociateDocument.documentDriver = "El documento debe tener al menos 3 caracteres";
      }
    }
  }

  // Receptor
  if (values.documentType === DocumentType.DUI && extension?.receiverDocument && validarDUI(extension?.receiverDocument)) {
    errors.extension.receiverDocument = `DUI invalido`;
  }
  if (extension?.receiverName && extension?.receiverName.length < 5) {
    errors.extension.receiverName = `El campo de 'Nombre' debe tener al menos 5 caracteres`;
  }
  if (values.documentType === DocumentType.NRC && extension?.receiverDocument && validarNRC(extension?.receiverDocument)) {
    errors.extension.receiverDocument = `NRC invalido`;
  }
  if (values.documentType === DocumentType.NIT && extension?.receiverDocument && validarNIT(extension?.receiverDocument)) {
    errors.extension.receiverDocument = "NIT invalido";
  }
  if (values.documentType === DocumentType.PASSPORT && extension?.receiverDocument && extension?.receiverDocument.length < 4) {
    errors.extension.receiverDocument = "El pasaporte debe tener al menos 4 caracteres";
  }

  if (!errors.extension?.observations) delete errors.extension?.observations;
  if (!errors.extension?.receiverDocument) delete errors.extension?.receiverDocument;
  if (!errors.extension?.receiverName) delete errors.extension?.receiverName;
  if (!errors.ventasCuentasTerceros?.nit) delete errors.ventasCuentasTerceros?.nit;
  if (!errors.ventasCuentasTerceros?.name) delete errors.ventasCuentasTerceros?.name;
  if (!errors.infoAssociateDocument?.type) delete errors.infoAssociateDocument?.type;
  if (!errors.infoAssociateDocument?.vehiclePlate) delete errors.infoAssociateDocument?.vehiclePlate;
  if (!errors.infoAssociateDocument?.documentDriver) delete errors.infoAssociateDocument?.documentDriver;
  if (!errors.infoAssociateDocument?.NameDriver) delete errors.infoAssociateDocument?.NameDriver;
  if (!errors.infoAssociateDocument?.document) delete errors.infoAssociateDocument?.document;
  if (!errors.infoAssociateDocument?.description) delete errors.infoAssociateDocument?.description;
  if (!errors.infoAssociateDocument?.typeOfTransport) delete errors.infoAssociateDocument?.typeOfTransport;
  if (Object.entries(errors.extension).length < 1) delete errors.extension;
  if (Object.entries(errors.infoAssociateDocument).length < 1) delete errors.infoAssociateDocument;
  if (Object.entries(errors.ventasCuentasTerceros).length < 1) delete errors.ventasCuentasTerceros;

  return errors;
};
