import { useState } from "react";

// react-router-dom
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField } from "@mui/material";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Utils
import { haveBeenPass24h } from "utils/haveBeenPass24h";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Graphql
import { MutationResult, useMutation } from "@apollo/client";

// Queries
import { SEND_DTE_BY_EMAIL } from "apollo/mutations/dtes/sendDteByEmail";

// Alert
import toast from "react-hot-toast";

// Types
import { SerializationKeys } from "types/apollo";
import { IvaDocumentTypes } from "types/iva-document";
import { GeneralDocument, JsonDte } from "types/documents";

const validationSchema = Yup.object({
  emails: Yup.array().of(Yup.string().trim().email("El email es invalido")).required("Los emails son requeridos"),
  email: Yup.string().trim().email("El email es invalido"),
});

interface Props {
  handleSubmitNotaCredito?: (selectedItems: any) => void;
  selectedItems: GeneralDocument[];
  handleOpenDelete: any;
  setDteInformation: any;
  setSelectedItems: any;
  dataAnularCCFsConNotaDeCredito?: MutationResult<any>;
}
const SelectedItemsMenu = ({ dataAnularCCFsConNotaDeCredito, handleSubmitNotaCredito, selectedItems, handleOpenDelete, setDteInformation, setSelectedItems }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const document: GeneralDocument = selectedItems.length === 1 ? selectedItems[0] : null;
  const parsedJson: JsonDte = selectedItems.length === 1 ? JSON.parse(selectedItems[0]?.jsonDte as any) : null;

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Mutation
  const [sendDteByEmail, { data, loading }] = useMutation(SEND_DTE_BY_EMAIL, { context });

  const formik = useFormik({
    initialValues: {
      emails: [],
      email: "",
    },
    onSubmit: () => {
      sendEmail();
    },
    validationSchema,
  });
  function sendEmail() {
    sendDteByEmail({ variables: { data: { idSale: selectedItems[0]?.id, emails: formik.values.emails } } })
      .then(({ data }) => {
        if (data.sendDte.isSuccess) {
          toast.success("Email enviado correctamente");
          handleClose();
        } else {
          toast.error(`Ocurrió un error al enviar el DTE, ${data.sendDte.message?.detail}`);
          handleClose();
        }
      })
      .catch((err) => {
        err && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
        handleClose();
      });
  }
  function handleClickOpen() {
    setOpen(true);
    formik.setFieldValue("email", document?.clientCompany?.emailNotifications);
  }
  function handleClose() {
    setOpen(false);
    formik.setFieldValue("email", "");
    formik.setFieldTouched("email", false);
    formik.setFieldValue("emails", "");
    formik.setFieldTouched("emails", false);
  }
  function handleDescargar() {
    const base64PDF = document?.pdfBase64;
    const link = window.document.createElement("a");
    link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
    link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
    link.click();

    const jsonDte = document?.jsonDte;
    const blob = new Blob([jsonDte as any], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link2 = window.document.createElement("a");

    link2.href = url;
    link2.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
    link2.click();
  }
  const handleDescargarJSON = () => {
    const jsonDte = document?.jsonDte;
    const blob = new Blob([jsonDte as any], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link2 = window.document.createElement("a");

    link2.href = url;
    link2.download = `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.json`;
    link2.click();
  };
  const handleDescargarPDF = () => {
    const base64PDF = document?.pdfBase64;
    const link = window.document.createElement("a");
    link.setAttribute("href", "data:application/pdf;base64," + base64PDF);
    link.setAttribute("download", `${parsedJson?.identificacion?.numeroControl || "hazconta-dte"}.pdf`);
    link.click();
  };
  const getNumControl = (string: string) => {
    const parts = string.split("-");
    const lastPart = parts[parts.length - 1];
    const number = parseInt(lastPart, 10);

    return number;
  };

  const amounts = selectedItems && selectedItems.length > 1 && selectedItems.map((item: any) => item.totalToPay).reduce((sum: any, amount: any) => parseFloat(sum) + parseFloat(amount));

  return (
    <>
      {selectedItems && selectedItems.length > 0 && (
        <Grid item justifyContent="center" xs={12}>
          <Card sx={{ py: 2, px: 4 }}>
            <Grid container justifyContent="space-between" wrap="nowrap">
              <Grid item xs={12}>
                <MDBox sx={{ my: 1 }}>
                  <MDTypography variant="h5" color="info">
                    {selectedItems.length === 1 && `1 Seleccionado`}
                    {selectedItems.length > 1 && `${selectedItems.length} Seleccionados`}
                  </MDTypography>
                  <MDTypography variant="h6" color="info" fontWeight="bold">
                    {amounts ? `$ ${amounts && amounts.toFixed(2)}` : `$ ${selectedItems && selectedItems[0].totalToPay.toFixed(2)}`}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid container wrap="nowrap" gap={5} width={{ xs: "100%", lg: "auto" }} justifyItems="center">
                {selectedItems && selectedItems.length === 1 && selectedItems[0]?.status === "APROBADO" && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    sx={{
                      cursor: "pointer",
                      "&:hover span": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                    }}
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <Icon color="info" fontSize="large">
                      forward_to_inbox_icon
                    </Icon>
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      Enviar
                    </MDTypography>
                  </Grid>
                )}

                {/* {selectedItems && selectedItems.length === 1 && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    onClick={() => {
                      handleDescargar();
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover svg": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                    }}
                  >
                    <CloudDownloadOutlinedIcon color="info" fontSize="large" />
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      Descargar
                    </MDTypography>
                  </Grid>
                )} */}
                {/* // ? Descargar pdf */}
                {selectedItems && selectedItems.length === 1 && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    onClick={() => {
                      handleDescargarPDF();
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover span": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                    }}
                  >
                    <Icon color="info" fontSize="large">
                      document_scanner_icon
                    </Icon>
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      Descargar
                    </MDTypography>
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      PDF
                    </MDTypography>
                  </Grid>
                )}
                {/* // ? Descargar json */}
                {selectedItems && selectedItems.length === 1 && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    onClick={() => {
                      handleDescargarJSON();
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover span": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                    }}
                  >
                    <Icon color="info" fontSize="large">
                      code_icon
                    </Icon>
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      Descargar
                    </MDTypography>
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      JSON
                    </MDTypography>
                  </Grid>
                )}
                {/* // ?  Ver detalles */}
                {selectedItems && selectedItems.length === 1 && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    onClick={() => {
                      /*
                       * View individual DTE was built using localStorage because there was no
                       *  getGeneralDocumentById query at the moment
                       */
                      localStorage.setItem("documentView", JSON.stringify(selectedItems[0]));
                      navigate(`/ventas/detalle/${selectedItems[0].id}`);
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover svg": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#5AA9E6",
                        transition: "all 200ms ease",
                      },
                    }}
                  >
                    <PlagiarismOutlinedIcon color="info" fontSize="large" />
                    <MDTypography variant="h5" color="info" fontWeight="regular">
                      Ver
                    </MDTypography>
                  </Grid>
                )}
                {/* // ?  Anular */}
                {selectedItems && selectedItems.length === 1 && selectedItems[0]?.status === "APROBADO" && !haveBeenPass24h(selectedItems[0]?.createdAt) && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    sx={{
                      cursor: "pointer",
                      "&:hover svg": {
                        color: "#f65f53",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#f65f53",
                        transition: "all 200ms ease",
                      },
                    }}
                    onClick={() => {
                      setDteInformation({
                        idDte: selectedItems[0]?.id,
                        nroDTE: getNumControl(selectedItems[0]?.dteControlNumber),
                        clientName: {
                          label: selectedItems[0]?.client ?? "Cliente Anonimo",
                          id: selectedItems[0]?.idClientCompany ?? 0,
                        },
                        date: selectedItems[0].date,
                        ivaDocumentName: selectedItems[0].ivaDocument.extended_name_document,
                        amount: selectedItems[0].totalToPay,
                        idEmployee: selectedItems[0].cashier.idEmployeeCompany,
                      });
                      handleOpenDelete();
                    }}
                  >
                    <DeleteForeverOutlinedIcon color="error" fontSize="large" />
                    <MDTypography variant="h5" color="error" fontWeight="regular">
                      Anular
                    </MDTypography>
                  </Grid>
                )}
                {/* // ? Anular CCF a Nota de Credito */}
                {selectedItems && selectedItems.every((item) => item.ivaDocument.name_document === IvaDocumentTypes.CCF && item.status === "APROBADO" && haveBeenPass24h(item.createdAt)) && (
                  <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    sx={{
                      cursor: "pointer",
                      "&:hover svg": {
                        color: "#f65f53",
                        transition: "all 200ms ease",
                      },
                      "&:hover h5": {
                        color: "#f65f53",
                        transition: "all 200ms ease",
                      },
                    }}
                    onClick={() => {
                      handleSubmitNotaCredito(selectedItems);
                    }}
                  >
                    <ChangeCircleOutlinedIcon color="error" fontSize="large" />
                    <MDTypography variant="h5" color="error" fontWeight="regular">
                      Nota
                    </MDTypography>
                    <MDTypography variant="h5" color="error" fontWeight="regular">
                      Credito
                    </MDTypography>
                  </Grid>
                )}

                <Grid
                  container
                  alignItems="center"
                  flexDirection="column"
                  sx={{
                    cursor: "pointer",
                    "&:hover span": {
                      color: "#DB9D47",
                      transition: "all 200ms ease",
                    },
                    "&:hover h5": {
                      color: "#DB9D47",
                      transition: "all 200ms ease",
                    },
                  }}
                  onClick={() => {
                    setSelectedItems([]);
                  }}
                >
                  <Icon color="info" fontSize="large">
                    close_outline_icon
                  </Icon>
                  <MDTypography variant="h5" color="info" fontWeight="regular">
                    Cancelar
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{"¿A que correos deseas enviar?"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.errors.email) && Boolean(formik.touched.email)}
                onBlur={() => formik.setFieldTouched("email", true)}
                value={formik.values.email}
                label="Correo electronico"
                name="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  formik.setFieldValue("email", e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <GroupAddOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      fontSize="medium"
                      onClick={() => {
                        if (formik.values.email !== "") {
                          formik.setFieldValue("emails", [...formik.values.emails, formik.values.email]);
                          formik.setFieldValue("email", "");
                        }
                      }}
                    />
                  ),
                }}
                helperText={!formik.touched.email || (formik.touched.email && !formik.errors.email) ? " " : formik.errors.email}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                forcePopupIcon={false}
                options={[]}
                multiple
                open={false}
                value={formik.values.emails}
                onChange={(e, value, reason) => {
                  formik.setFieldValue("emails", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(formik.errors.emails) && Boolean(formik.touched.emails)}
                    helperText={!Boolean(formik.touched.emails) || (formik.touched.emails && !formik.errors.emails) ? " " : "Los emails son requeridos"}
                    label="Correos electronicos a enviar"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            color="info"
          >
            Atras
          </MDButton>
          <MDButton
            disabled={loading}
            onClick={() => {
              formik.handleSubmit();
            }}
            variant="gradient"
            type="submit"
            color="info"
          >
            {loading ? "Enviando" : "Enviar"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedItemsMenu;
