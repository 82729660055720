import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";

// GraphQL
import { useLazyQuery, useQuery } from "@apollo/client";

// Queries
import { GET_DTE_RECHAZADOS } from "apollo/queries/dtes/getDteRechazados";
import { GET_DTE_RECHAZADOS_ALL } from "apollo/queries/dtes/getDteRechazadosAll";

// Custom Components
import InfoTable from "../../facturacion/components/InfoTable";
import DTEsRechazadosTable from "../components/DtesRechazadosTable";

// Types
import { DataInfoTable } from "types/dataInfoDtesRechazados";
import { SerializationKeys } from "types/apollo";

const VerDtesRechazadosMultados = () => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Queries
  const [getDtesRechazadosAll, { data: DtesRechazadosAll, loading: DtesRechazadosAllLoading }] = useLazyQuery(GET_DTE_RECHAZADOS_ALL, { context });
  const { data: DTEsRechazados } = useQuery(GET_DTE_RECHAZADOS, {
    variables: {
      filters: { priority: "PENDIENTES" },
    },
    context,
  });
  const { data: DTEsRechazados2 } = useQuery(GET_DTE_RECHAZADOS, {
    variables: {
      filters: { priority: "MULTADOS" },
    },
    context,
  });
  const [infoTable, setInfoTable] = useState<DataInfoTable>();

  useEffect(() => {
    if (DTEsRechazados) {
      if (DTEsRechazados2) {
        if (DTEsRechazados?.getDteRechazados?.isSuccess) {
          if (DTEsRechazados2?.getDteRechazados?.isSuccess) {
            setInfoTable({
              multaPendientes: new Intl.NumberFormat("es", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(DTEsRechazados?.getDteRechazados?.data?.multa),
              nroErrorsPendientes: DTEsRechazados?.getDteRechazados?.data?.dtes.length,
              multaMultados: new Intl.NumberFormat("es", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(DTEsRechazados2?.getDteRechazados?.data?.multa),
              nroErrorsMultados: DTEsRechazados2?.getDteRechazados?.data?.dtes.length,
            });
          }
        }
      }
    }
  }, [DTEsRechazados, DTEsRechazados2]);

  useEffect(() => {
    getDtesRechazadosAll({
      variables: {
        filters: { priority: "MULTADOS" },
      },
    });
  }, []);

  const rechazadosData = DtesRechazadosAll ? [...DtesRechazadosAll?.getDteRechazados?.data?.dtes] : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <Grid container justifyContent="center" gap={5}>
          <MDTypography color="info" variant={"h3"} verticalAlign="center" fontSize={{ xs: "1.4rem", lg: "1.875rem" }} sx={{ textAlign: "center" }}>
            Documentos rechazados y multados.
          </MDTypography>
          {DtesRechazadosAll && infoTable && <InfoTable infoTable={infoTable} />}
          <DTEsRechazadosTable type="Multados" loadingData={DtesRechazadosAllLoading} data={rechazadosData?.reverse()} getData={getDtesRechazadosAll} />
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default VerDtesRechazadosMultados;
