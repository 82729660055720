import { validarDUI } from "./validations/validarDui";
import { validarNIT } from "./validations/validarNit";
import { validarNRC } from "./validations/validarNrc";

export const documentTypeSelector = (documentNumber: string): string => {
  if (!validarDUI(documentNumber)) {
    return "DUI";
  } else if (!validarNIT(documentNumber)) {
    return "NIT";
  } else if (!validarNRC(documentNumber)) {
    return "NRC";
  } else {
    return "Pasaporte";
  }
};
