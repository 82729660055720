import gql from "graphql-tag";

export const DELETE_CLIENT_FROM_PRICE_LIST = gql`
  mutation DELETE_CLIENT_FROM_PRICE_LIST($idClientPriceList: Float!) {
    deleteClientFromPriceList(idClientPriceList: $idClientPriceList) {
      isSuccess
      message {
        detail
        code
        message
      }
    }
  }
`;
