import gql from "graphql-tag";

export const UPDATE_TAG = gql`
  mutation UPDATE_TAG($idTag: Float!, $data: TagDti!) {
    updateTag(idTag: $idTag, data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
