import React, { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// @mui material components
import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Types
import { DataTableDTEObject } from "types/dataTableDteObject";

const RemoveItemModal = ({ deletedItem, setDeletedItem }: { deletedItem: DataTableDTEObject; setDeletedItem: React.Dispatch<React.SetStateAction<DataTableDTEObject>>; deletedItemIndex: number }) => {
  const [confirmationString, setConfirmationString] = useState("");

  const { setGlobal } = useAddSellContext();

  const isAllowed = () => {
    if (deletedItem?.code && confirmationString === deletedItem?.code) {
      return false;
    } else if (deletedItem?.name && confirmationString === deletedItem?.name) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal open={deletedItem ? true : false} onClose={() => setDeletedItem(null)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Card
          sx={{
            padding: 3,
            maxWidth: "33%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <MDTypography variant="h4" color="error" mb={1}>
            Eliminar Item
          </MDTypography>
          <MDTypography color="info" fontWeight="bold" mb={1}>
            ¿Estas seguro que quieres eliminar este item de la factura?
          </MDTypography>
          <MDTypography color="info" mb={1}>
            Para confirmar que quieres <span style={{ color: "#f44335", fontWeight: "bold" }}>Eliminar</span> {""}
            el item del DTE, por favor ingresa el codigo del articulo a eliminar que es: {""}
            <span style={{ color: "#f44335", fontWeight: "bold" }}>{deletedItem?.code || deletedItem?.name}</span>. <br />
            Que pertenece al articulo llamado: <span style={{ color: "#f44335", fontWeight: "bold" }}>{deletedItem?.name}</span> con {""}
            <span style={{ color: "#f44335", fontWeight: "bold" }}>{deletedItem?.quantity > 1 ? `${deletedItem?.quantity} unidades` : `${deletedItem?.quantity} unidad`}</span> {""}
            {deletedItem?.quantity > 1 ? `ingresadas` : `ingresada`} y es una operacion de tipo: <span style={{ color: "#f44335", fontWeight: "bold" }}>{deletedItem?.operation_type}</span>.
          </MDTypography>

          <MDBox container my={2}>
            <MDInput label="Digita el codigo de producto" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationString(e.target.value)} sx={{ width: "100%", input: { padding: "1rem" } }} />
          </MDBox>

          <MDBox display="flex" justifyContent="space-between">
            <MDButton
              variant="outlined"
              color="info"
              onClick={() => {
                setDeletedItem(null);
                setConfirmationString(null);
              }}
            >
              Cancelar
            </MDButton>
            <MDButton
              color="error"
              disabled={isAllowed()}
              onClick={() => {
                setGlobal((global) => {
                  return {
                    ...global,
                    products: global.products.filter((item) => item.id !== deletedItem.id),
                    paymentMethodsFull: [],
                    paymentMethods: [],
                  };
                });
                setConfirmationString(null);
                setDeletedItem(null);
              }}
            >
              Eliminar
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
};

export default RemoveItemModal;
