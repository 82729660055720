import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

// GraphQL
import { useMutation } from "@apollo/client";
import { SEND_DTE_BY_EMAIL } from "apollo/mutations/dtes/sendDteByEmail";

// Libs
import { toast } from "react-hot-toast";

// Types
import { SerializationKeys } from "types/apollo";

//Formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";

// Custom components
import DialogEmail from "layouts/ventas/components/DialogEmail";

const validationSchema = Yup.object({
  emails: Yup.array().of(Yup.string().trim().email("El email es invalido")).required("Los emails son requeridos"),
  email: Yup.string().trim().email("El email es invalido"),
});

const SendEmails = () => {
  // States
  const [open, setOpen] = useState(false);

  // Context
  const { document } = useGeneralDocumentContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Graphql
  const [sendDteByEmail, { loading }] = useMutation(SEND_DTE_BY_EMAIL, {
    context,
  });
  const formik = useFormik({
    initialValues: {
      emails: [],
      email: "",
    },
    onSubmit: () => {
      sendEmail();
    },
    validationSchema,
  });

  function sendEmail() {
    sendDteByEmail({ variables: { data: { idSale: document?.idSale, emails: formik.values.emails } } })
      .then(({ data }) => {
        if (data.sendDte.isSuccess) {
          toast.success("Email enviado correctamente");
          handleClose();
        } else {
          toast.error(`Ocurrió un error al enviar el DTE, ${data.sendDte.message?.detail}`);
          handleClose();
        }
      })
      .catch((err) => {
        err && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
        handleClose();
      });
  }
  function handleClickOpen() {
    setOpen(true);
    formik.setFieldValue("email", document?.clientCompany?.emailNotifications);
  }
  function handleClose() {
    setOpen(false);
    formik.setFieldValue("email", "");
    formik.setFieldTouched("email", false);
    formik.setFieldValue("emails", "");
    formik.setFieldTouched("emails", false);
  }
  function verPortapapeles() {
    navigator.clipboard
      .readText()
      .then((texto) => {
        console.log("Texto en el portapapeles:", texto);
      })
      .catch((error) => {
        console.error("Error al leer el portapapeles:", error);
      });
  }
  function copyPdf(string: string) {
    navigator.clipboard
      .writeText(string)
      .then(() => {
        toast.success("Texto copiado en el portapapeles");
        verPortapapeles();
      })
      .catch((error) => {
        console.error("Error al copiar el texto al portapapeles:", error);
      });
  }

  return (
    <>
      <Card sx={{ padding: 3, width: "100%" }}>
        <MDBox display="flex" flexDirection="column" gap={2} mb={2}>
          <Grid container justifyContent="space-between" alignItems="center" gap={2}>
            <MDTypography variant="h3" color="info">
              Email
            </MDTypography>
            <MDBox gap={1}>
              <Icon color="info" fontSize="large">
                forward_to_inbox
              </Icon>
            </MDBox>
          </Grid>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="body2" color="info">
              ----
            </MDTypography>
            <MDTypography variant="body2" color="info">
              {document?.clientCompany?.emailNotifications}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDButton onClick={handleClickOpen} variant="contained" color="info" size="large">
          {!loading ? "Enviar Correo" : <CircularProgress size={20} color="inherit" />}
        </MDButton>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" onClick={() => copyPdf(document?.urlPdf)} sx={{ cursor: "pointer" }}>
          <MDTypography variant="body2" color="info">
            Enlace al documento
          </MDTypography>
          <Icon fontSize="medium">copy_all</Icon>
        </MDBox>
      </Card>
      <DialogEmail formik={formik} handleClose={handleClose} loading={loading} open={open} />
    </>
  );
};

export default SendEmails;
