import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { GeneralDocument } from "types/documents";
import { useLazyQuery } from "@apollo/client";
import { GET_DTE_BY_ID } from "apollo/queries/dtes/getDteById";
import { JsonDte } from "types/documents";

export interface Props {
  children: React.ReactNode;
}

export interface GeneralDocumentProviderProps {
  document: GeneralDocument;
  jsonDte?: JsonDte;
  loading?: boolean;
  error?: any;
}

const VerGeneralDocument = createContext<GeneralDocumentProviderProps>({
  document: null,
});

export const useGeneralDocumentContext = () => {
  return useContext(VerGeneralDocument);
};

export const GeneralDocumentContextProvider = ({ children }: Props) => {
  const [document, setDocument] = useState<GeneralDocument>(null);
  const [jsonDte, setJsonDte] = useState<JsonDte>(null);
  const params = useParams();

  const [getDteById, { data, loading, error }] = useLazyQuery(GET_DTE_BY_ID);

  useEffect(() => {
    getDteById({ variables: { id: parseInt(params.id) } });
  }, []);

  useEffect(() => {
    if (data) {
      setDocument(data.getDteFiles.data);
      setJsonDte(JSON.parse(data.getDteFiles.data.jsonDte));
    }
  }, [data]);

  const value = {
    document,
    jsonDte,
    loading,
    error,
  };

  return <VerGeneralDocument.Provider value={value}>{children}</VerGeneralDocument.Provider>;
};
