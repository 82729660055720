// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface ShortCutsLabelsProps {
  label: string;
  key: string;
}

const ShortCutsLabels = ({ keys, command }: { keys: Set<string>; command: ShortCutsLabelsProps[] }) => {
  return (
    <MDBox
      display="flex"
      alignItems="center"
      gap={0.3}
      sx={({ palette }) => ({
        position: "absolute",
        right: 0,
        top: -45,
        height: "100%",
        pointerEvents: "none",
      })}
    >
      {command.map((comand, index: number) => {
        if (index > 0 && index <= command.length - 1) {
          return (
            <MDBox display="flex" alignItems="center" key={index + "shortcut"}>
              <MDTypography variant="caption" fontWeight="bold">
                +
              </MDTypography>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                sx={({ palette }: any) => {
                  if (!Array.from(keys).includes("control")) {
                    return {
                      opacity: 0.5,
                      background: palette.background.default,
                      color: "inherit",
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 0.5,
                      boxShadow: 1,
                    };
                  } else {
                    return {
                      opacity: !Array.from(keys).includes(comand.key) ? 0.5 : 1,
                      background: !Array.from(keys).includes(comand.key) ? palette.background.default : palette.secondary.main,
                      color: !Array.from(keys).includes(comand.key) ? "inherit" : palette.secondary.contrastText,
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 0.5,
                      boxShadow: 1,
                    };
                  }
                }}
              >
                {comand.label}
              </MDTypography>
            </MDBox>
          );
        } else {
          return (
            <MDBox display="flex" alignItems="center" key={index + "shortcut"}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                sx={({ palette }: any) => {
                  if (!Array.from(keys).includes("control")) {
                    return {
                      opacity: 0.5,
                      background: palette.background.default,
                      color: "inherit",
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 0.5,
                      boxShadow: 1,
                    };
                  } else {
                    return {
                      opacity: !Array.from(keys).includes(comand.key) ? 0.5 : 1,
                      background: !Array.from(keys).includes(comand.key) ? palette.background.default : palette.secondary.main,
                      color: !Array.from(keys).includes(comand.key) ? "inherit" : palette.secondary.contrastText,
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 0.5,
                      boxShadow: 1,
                    };
                  }
                }}
              >
                {comand.label}
              </MDTypography>
            </MDBox>
          );
        }
      })}
    </MDBox>
  );
};

export default ShortCutsLabels;
