export function validarNIT(nit: string | null) {
  let valorstr = 0;
  let multiplicadorstr = 0;
  let acumuladorstr = 0;
  let posicionstr = 1;
  let residuostr = 0;
  let numeronit = nit?.replace(/-/g, "").replace(/ /g, "");

  const message = "N.I.T. invalido";
  if (isNumeric(numeronit)) {
    if (numeronit.length !== 14) {
      return "No esta completo";
    } else {
      valorstr = parseInt(numeronit.substring(10, 13), 10);
      if (valorstr <= 100) {
        multiplicadorstr = 14;
        posicionstr = 1;
        for (let i = 0; i < 13; i++) {
          valorstr = parseInt(numeronit.substring(posicionstr - 1, posicionstr), 10);
          valorstr *= multiplicadorstr;
          acumuladorstr += valorstr;
          posicionstr++;
          multiplicadorstr--;
        }

        residuostr = acumuladorstr % 11;

        if (residuostr === 10) {
          residuostr = 0;
        }
      } else {
        for (let i = 0; i < 13; i++) {
          multiplicadorstr = 3 + 6 * Math.floor((posicionstr + 4) / 6) - posicionstr;
          valorstr = parseInt(numeronit.substring(posicionstr - 1, posicionstr), 10);
          valorstr *= multiplicadorstr;
          acumuladorstr += valorstr;
          posicionstr++;
        }

        residuostr = acumuladorstr % 11;

        if (residuostr > 1) {
          residuostr = 11 - residuostr;
        } else {
          residuostr = 0;
        }
      }

      if (residuostr === parseInt(numeronit.substring(13, 14), 10)) {
        return; // NIT válido
      } else {
        return message;
      }
    }
  } else {
    return message;
  }
}

function isNumeric(str: any) {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(parseFloat(str));
}
