// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// React and GraphQl
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

//Components
import GeneralesPtosDeVtas from "../components/GeneralPtosDeVtas";
import Registro from "../components/Registro";
import Caracteristicas from "../components/Caracteristicas";

// Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

//Types
import { PtosDeVtas } from "types/PtosDeVtas";

// Data
const steps = ["Generales", "Registro", "Caracteristicas"];

const validate = (values: PtosDeVtas) => {
  const errors: any = {};

  //General
  if (!values.name) {
    errors.name = "El nombre es requerido";
  } else if (values.name.length < 4) {
    errors.name = "El nombre debe tener al menos 4 caracteres";
  }
  if (!values.code) {
    errors.code = "El codigo es requerido";
  } else if (values.code.length < 4) {
    errors.code = "El codigo interno debe tener al menos 4 caracteres";
  }
  if (!values.tipoFacturacion) {
    errors.tipoFacturacion = "El tipo de lugar donde facturas es requerido";
  }
  if (!values.establecimiento) {
    errors.establecimiento = "El lugar donde facturas es requerido";
  }
  //Registro

  /* if (!values.date) {
    errors.date = "La fecha de registro es requerida";
  }
  if (!values.resolution) {
    errors.resolution = "La resolucion es requerida";
  } */
  if (!values.mhCode) {
    errors.mhCode = "El numero asignado es requerido";
  } else if (values.mhCode.length < 4) {
    errors.mhCode = "El numero asignado debe tener al menos 4 caracteres";
  }

  // Caracteristicas
  /* if (!values.mark) {
    errors.mark = "La marca es requerida";
  }
  if (!values.model) {
    errors.model = "El modelo es requerida";
  }
  if (!values.serie) {
    errors.serie = "El numero de serie es requerido";
  } */

  return errors;
};

const ADD_SALE_POINT = gql`
  mutation ADD_SALE_POINT($data: SalePointDti!) {
    addSalePoint(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;

function CrearPtosDeVtas() {
  //Querys and Mutations
  const [addSalePoint, { loading }] = useMutation(ADD_SALE_POINT);

  // States
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      code: "",
      date: "",
      tipoFacturacion: null,
      establecimiento: null,
      mark: "",
      mhCode: "",
      model: "",
      name: "",
      resolution: "",
      serie: "",
    },
    validate,
    onSubmit: async (values) => {
      addSalePoint({
        variables: {
          data: {
            name: values?.name,
            code: values?.code ?? null,
            idCostCenter: values.tipoFacturacion === "Oficina" ? values?.establecimiento?.id : null,
            idStorage: values.tipoFacturacion === "Bodega" ? values?.establecimiento?.id : null,
            date: values?.date,
            resolution: values?.resolution,
            mhCode: values?.mhCode,
            mark: values?.mark,
            model: values?.model,
            serie: values?.serie,
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addSalePoint;
          if (isSuccess) {
            toast.success("Punto de venta creado correctamente");
            navigate("/configuracion/empresa", { state: 3 });
          } else {
            toast.error(`Hubo un error al crear el punto de venta ${message.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e === "ApolloError: Unauthorized") {
            toast.error(`Su sesion a expirado, porfavor vuelva a registrarse.`);
            navigate("/");
          }
          toast.error(`Hubo un error al crear el punto de venta ${e}`);
        });
    },
  });

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <GeneralesPtosDeVtas formik={formik} />;
      case 1:
        return <Registro formik={formik} />;
      case 2:
        return <Caracteristicas formik={formik} />;
    }
  }
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 2) return prev + 1;
      return prev;
    });
  };
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev >= 1) return prev - 1;
      return prev;
    });
  };
  const buttonNext = (step: any) => {
    if (step === 0) {
      return (
        <MDButton
          disabled={Boolean(formik.errors.tipoFacturacion) || Boolean(formik.errors.establecimiento) || Boolean(formik.errors.name) || Boolean(formik.errors.code) || Boolean(formik.errors.mhCode)}
          onClick={() => {
            handleNext();
          }}
          variant="gradient"
          color="info"
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 1) {
      return (
        <MDButton
          onClick={() => {
            handleNext();
          }}
          variant="gradient"
          color="info"
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          disabled={loading}
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={580} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/configuracion/empresa")} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default CrearPtosDeVtas;
