import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

type step = {
  label: string;
  icon?: JSX.Element;
  component?: JSX.Element;
};
interface Props {
  color: "default" | "light" | "white" | "dark" | "info" | "primary" | "secondary" | "success" | "warning" | "error";
  steps: step[];
  state?: [number | Record<string, number>, React.Dispatch<React.SetStateAction<any>>];
  name?: string;
}
function StepperCat({ color, steps, state, name }: Props) {
  const getColor = (color: string) => {
    switch (color) {
      case "default":
        return "#f0f2f5";
      case "light":
        return "#f0f2f5";
      case "white":
        return "#ffffff";
      case "dark":
        return "#344767";
      case "info":
        return "#00547A";
      case "primary":
        return "#e91e63";
      case "secondary":
        return "#7b809a";
      case "success":
        return "#4CAF50";
      case "warning":
        return "#5AA9E6";
      case "error":
        return "#F44335";
    }
  };
  const getStateUse = (state: any, name?: string) => {
    if (typeof state === "number") {
      return state;
    } else if (typeof state === "object") {
      return state[name];
    }
  };
  return (
    <MDBox bgColor="#F8F9FA" borderRadius="md" shadow="md" opacity={1} p={{ xs: 1, sm: 2 }} mb={2} display="inline-flex" width={{ xs: 1, sm: "auto" }} flexDirection={{ xs: "column", sm: "row" }} gap={2}>
      {steps.map((item, index) => (
        <MDButton
          key={index}
          color={`${getStateUse(state[0], name ?? "") === index ? color : "light"}`}
          onClick={() => {
            if (typeof state[0] === "number") {
              state[1](index);
            } else if (typeof state[0] === "object") {
              state[1]((prev: any) => ({
                ...prev,
                [name]: index,
              }));
            }
          }}
          sx={{
            p: "0.85rem",
            "&:focus:not(:hover)": {
              backgroundColor: `${getStateUse(state[0], name) === index ? getColor(color) : "#f0f2f5"}`,
            },
          }}
          startIcon={item.icon ?? null}
        >
          {item.label}
        </MDButton>
      ))}
    </MDBox>
  );
}
export default StepperCat;
