import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

//Yup
import * as Yup from "yup";

//Alerts
import { toast } from "react-hot-toast";

//Custom Components
import Indicaciones from "./components/Indicaciones";
import Credenciales from "./components/Credenciales";

//Formik
import { useFormik } from "formik";

// Assets
import brandDark from "assets/images/hazconta/logo-dark.png";
import axios from "axios";
import { useAuthContext } from "context/AuthContext";

interface Values {
  email: string;
  password: string;
  passwordConfirm: string;
  securityCode: string;
}

const steps = ["Indicaciones", "Credenciales"];

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().required("El correo es requerido").email("Correo invalido"),
  password: Yup.string().trim().required("La contraseña es requerido").min(4, "La contraseña debe tener al menos 4 caracteres"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de contraseña es requerida"),
  securityCode: Yup.string().trim().required("El codigo de seguridad es requerido").min(6, "La contraseña debe tener al menos 6 caracteres"),
});

function ResetPassword() {
  const navigate = useNavigate();

  const formik = useFormik<Values>({
    initialValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      securityCode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
        newPassword: values.password,
        verificationCode: values.securityCode,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, JSON.stringify(obj), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          console.log("data Success", data);
          if (data.data.isSuccess) {
            toast.success(`Se ha cambiado la contraseña correctamente`);
            navigate("/authentication/sign-in");
          }
        })
        .catch((error) => {
          toast.error(`Hubo un error al intentar cambiar la contraseña ${error.response.data.error?.message}`);
        });
    },
  });

  const { token } = useAuthContext();

  //state
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loadingAxios, SetLoadingAxios] = useState<boolean>(false);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <Indicaciones formik={formik} />;
      case 1:
        return <Credenciales formik={formik} />;
    }
  }
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev < 2) return prev - 1;
      return prev;
    });
  };
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 1) return prev + 1;
      return prev;
    });
  };

  const buttonNext = (step: any) => {
    if (step < 1) {
      return (
        <MDButton
          onClick={() => {
            const emailJson = {
              email: formik.values.email,
            };
            SetLoadingAxios(true);
            axios
              .post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, JSON.stringify(emailJson), {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((data) => {
                console.log("data Success", data);
                if (data.data.isSuccess) {
                  SetLoadingAxios(false);
                  toast.success(`Se ha enviado un código al corrreo electrónico`);
                  handleNext();
                }
              })
              .catch((error) => {
                toast.error(`Hubo un error al intentar cambiar la contraseña ${error.response.data.error?.message}`);
              });
          }}
          color="info"
          variant="gradient"
          disabled={Boolean(formik.values.email === "") || Boolean(formik.errors.email) || loadingAxios}
        >
          {loadingAxios ? "Enviando" : "Enviar"}
        </MDButton>
      );
    } else {
      return (
        <MDButton
          onClick={() => {
            formik.handleSubmit();
          }}
          color="info"
          variant="gradient"
          disabled={Boolean(formik.errors.password) || Boolean(formik.errors.passwordConfirm) || formik.isSubmitting}
        >
          {formik.isSubmitting === true ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };

  return (
    <>
      <Grid container position="absolute" top={{ xs: 20, lg: 40 }} width="100%" minHeight="5vh" justifyContent="space-between" alignItems="center">
        <Grid item xs={11} sm={9} md={8} xl={8} mx="auto">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item xs={4} md={3} lg={3}>
              {brandDark && <MDBox component="img" src={brandDark} alt="Brand" width="100%" />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MDBox mt={{ xs: 6, md: 12 }} mb={3} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={6.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={400} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  <MDButton
                    onClick={() => {
                      if (activeStep === 0) {
                        return navigate("/authentication/sign-in");
                      } else {
                        handleBack();
                      }
                    }}
                    variant="outlined"
                    color="info"
                  >
                    Atras
                  </MDButton>
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default ResetPassword;
