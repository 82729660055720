import gql from "graphql-tag";

export const CHANGE_PASSWORD_MH_CERTIFICATE = gql`
  mutation ChangePasswordMhCertificate($newPassword: String!, $environment: MH_BILLING_ENVIORONMENTS!) {
    changePasswordMhCertificate(newPassword: $newPassword, environment: $environment) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
