import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { ShortcutContext } from "./ShortcutContext";

type KeyboardContextType = {
  keys: Set<string>;
};

const initialValues: KeyboardContextType = {
  keys: new Set(),
};

export const KeyboardContext = createContext(initialValues);

export interface Props {
  children: React.ReactNode;
}

const KeyboardContextProvider = ({ children }: Props) => {
  const { triggerListener } = useContext(ShortcutContext);
  const [keys, setKeys] = useState<Set<string>>(new Set());

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const newKeys = new Set([...Array.from(keys), e?.key?.toLocaleLowerCase()]);
      triggerListener(newKeys);
      setKeys(newKeys);
    },
    [keys, triggerListener]
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      // const newKeys = new Set(
      //   ...[Array.from(keys).filter((key) => key !== e?.key?.toLocaleLowerCase())]
      // );
      setKeys(new Set([]));
    },
    [keys]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeyDown]);

  const value = {
    keys,
  };
  return <KeyboardContext.Provider value={value}>{children}</KeyboardContext.Provider>;
};

export default KeyboardContextProvider;
