import gql from "graphql-tag";

export const ADD_DOCUMENTS = gql`
  mutation ADD_DOCUMENTS($data: CreateDocumentsDti!) {
    addDocuments(data: $data) {
      isSuccess
      data {
        id
        documentNumber
        code
        type
      }
      message {
        code
        detail
      }
    }
  }
`;
