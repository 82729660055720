import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// @mui material components
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { Card } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// react-router-dom
import { useLocation } from "react-router-dom";

// Context
import { useCompanyContext } from "context/CompanyContext";

// Layouts
import Detalles from "layouts/contactos/clientes/components/Detalles";

// Custom Component
import ArticulosTable from "./components/ArticulosTable";
import Caracteristicas from "./components/Caracteristicas";
import Agrupacion from "./components/Agrupacion";
import UnidadesDeMedida from "./components/UnidadesDeMedida";
import StepperCat from "components/Global/StepperCat";
import GetImportComponent from "./components/GetImportComponent";
import GetExportComponent from "./components/GetExportComponent";

function Articulos() {
  const [activeStep, setActiveStep] = useState({
    module: 0,
    action: 0,
  });

  const location = useLocation();

  const { company } = useCompanyContext();

  //Effects
  useEffect(() => {
    if (location.state) {
      setActiveStep((prev) => ({
        ...prev,
        module: location.state,
      }));
    }
  }, [location.state]);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [activeStep.module]);

  const steps1 = [
    {
      label: "Articulos",
      icon: <DiamondOutlinedIcon />,
      component: <ArticulosTable />,
    },
    {
      label: "Caracteristicas",
      icon: <SellOutlinedIcon />,
      component: <Caracteristicas />,
    },
    {
      label: "Agrupacion",
      icon: <AccountTreeOutlinedIcon />,
      component: <Agrupacion />,
    },
    {
      label: "Unidades de Medidas",
      icon: <StraightenOutlinedIcon />,
      component: <UnidadesDeMedida />,
    },
  ];

  const steps2 = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: <Detalles />,
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: <GetImportComponent step={activeStep.module} companyId={company.id} />,
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: <GetExportComponent step={activeStep.module} />,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        {/* Module */}
        <MDBox justifyContent={{ xs: "center", sm: "flex-start" }} display="flex" width="100%">
          <StepperCat color="info" steps={steps1} state={[activeStep, setActiveStep]} name="module" />
        </MDBox>
        {/* Action */}
        <MDBox>
          <StepperCat color="error" steps={steps2} state={[activeStep, setActiveStep]} name="action" />
        </MDBox>
        <Card sx={{ p: 4 }}>{activeStep.action === 0 ? steps1[activeStep.module].component : steps2[activeStep.action].component /* activeStep.action ==== 1 ? steps2[activeStep.action].component :activeStep.action === 2 ? :null */}</Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Articulos;
