import gql from "graphql-tag";

export const CREATE_FACTURA_EXPORTACION = gql`
  mutation CREATE_FACTURA_EXPORTACION($input: CreateFacturaExportacionInputDti!) {
    createFacturaExportacion(input: $input) {
      selloRecibido
      idDTE
      identificacion {
        numeroControl
        fecEmi
        codigoGeneracion
        tipoDte
      }
      receptor {
        nombre
      }
      resumen {
        totalPagar
      }
      respuestaHacienda {
        status
        descripcionMsg
        fhRecibido
        observaciones
        completeResponse {
          ambiente
          codigoGeneracion
          descripcionMsg
          estado
          observaciones
        }
      }
      pdfs {
        pdf_url
        pdf_base64
      }
    }
  }
`;
