//@mui material components
import { Autocomplete, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Formik
import { FormikProps } from "formik";

// Type
import { ClientORCompanyForm } from "types/clientsForm";
import { legalRepresetantion } from "types/legalRepresentation";

// Data
const types = ["GRANDE", "MEDIANO", "OTRO"];

interface Props {
  formik: FormikProps<ClientORCompanyForm>;
}

function Facturacion({ formik }: Props) {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChange = (value: string) => {
    formik.setFieldValue("contribuyenteType", value);
    if (value === "GRANDE") {
      formik.setFieldValue("retention", true);
    }
  };

  return (
    <>
      {formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (
        <MDBox mt={2.5} mb={{ xs: 2, lg: 5 }}>
          <MDTypography sx={{ mb: 1 }} color="info" variant="h4" fontWeight="bold">
            Datos Tributarios
          </MDTypography>
          <Grid container spacing={{ xs: 0, lg: 3 }}>
            <Grid item xs={12} lg={4.5}>
              <Autocomplete
                options={types}
                freeSolo
                value={formik.values.contribuyenteType}
                onChange={(e, value) => handleChange(value)}
                onBlur={() => formik.setFieldTouched("contribuyenteType", true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tamaño del Contribuyente"
                    error={Boolean(formik.errors.contribuyenteType) && formik.touched.contribuyenteType}
                    helperText={!formik.touched.contribuyenteType || (formik.touched.contribuyenteType && !formik.errors.contribuyenteType) ? " " : formik.errors.contribuyenteType}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <Stack mt={{ xs: 0, lg: 1.2 }} direction="row" alignItems="center">
                <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                  ¿Al cliente se le retiene?
                </MDTypography>
                <Switch checked={formik.values.retention} disabled={formik.values.contribuyenteType === "GRANDE"} onChange={formik.handleChange} name="retention" />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <Stack mt={{ xs: 0, lg: 1.2 }} direction="row" alignItems="center">
                <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                  ¿El cliente es exento?
                </MDTypography>
                <Switch checked={formik.values.isExent} onChange={formik.handleChange} name="isExent" />
              </Stack>
            </Grid>
          </Grid>
        </MDBox>
      ) : null}
      <MDBox mt={formik.values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? 0 : 2.5} gap={{ xs: 1, lg: 2.5 }} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Datos Crediticios
        </MDTypography>
        <Grid container spacing={lgDown ? 2 : 6}>
          <Grid item xs={12} lg={6}>
            <Stack mt={{ xs: 0, lg: 1.2 }} direction="row" alignItems="center">
              <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                Tiene Credito Activo
              </MDTypography>
              <Switch checked={formik.values.isActiveCredit} onChange={formik.handleChange} name="isActiveCredit" />
            </Stack>
          </Grid>
          {formik.values.isActiveCredit && (
            <Grid item xs={12} lg={6}>
              <Stack mt={{ xs: 0, lg: 1.2 }} direction="row" alignItems="center">
                <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                  ¿Tiene un Limite de Credito?
                </MDTypography>
                <Switch checked={formik.values.perception} onChange={formik.handleChange} name="perception" />
              </Stack>
            </Grid>
          )}
        </Grid>
        {formik.values.isActiveCredit && formik.values.perception && (
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} xl={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ fontWeight: "400", fontSize: "0.875rem" }} color="info">
                    ¿Cual es su monto maximo de credito?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    error={Boolean(formik.errors.creditLimit) && formik.touched.creditLimit}
                    helperText={!formik.touched.creditLimit || (formik.touched.creditLimit && !formik.errors.creditLimit) ? " " : formik.errors.creditLimit}
                    onBlur={() => formik.setFieldTouched("creditLimit", true)}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.creditLimit !== 0 ? formik.values.creditLimit : ""}
                    name="creditLimit"
                    fullWidth
                    placeholder="$ 0.00"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography variant="subtitle2" sx={{ fontWeight: "400", fontSize: "0.875rem" }} color="info">
                    ¿Cuantos dias de credito tiene?{" "}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    placeholder="30"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.creditPeriod !== 0 ? formik.values.creditPeriod : ""}
                    name="creditPeriod"
                    error={Boolean(formik.errors.creditPeriod) && formik.touched.creditPeriod}
                    onBlur={() => formik.setFieldTouched("creditPeriod", true)}
                    helperText={!formik.touched.creditPeriod || (formik.touched.creditPeriod && !formik.errors.creditPeriod) ? " " : formik.errors.creditPeriod}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </>
  );
}
export default Facturacion;
