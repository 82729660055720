import { useEffect } from "react";

// Graphql
import { useLazyQuery, useQuery } from "@apollo/client";

// Queries
import { GET_ARTICLES_FOR_SELLING } from "apollo/queries/articles/getArticlesForSelling";
import { GET_CLIENTS } from "apollo/queries/clients/getClients";
import { GET_GENERAL_DOCUMENTS } from "apollo/queries/dtes/getGeneralDocuments";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Utils
import { duplicateCorregirDte } from "utils/duplicateCorregirDte";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

const useSetValuesCorregirDTE = ({ generationCode, setValues }: any) => {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Context
  const { setGlobal } = useAddSellContext();

  const [getGeneralDocuments, dataGetGeneralDocuments] = useLazyQuery(GET_GENERAL_DOCUMENTS, { context });
  const [getArticlesForSelling, dataGetArticlesForSelling] = useLazyQuery(GET_ARTICLES_FOR_SELLING, { context });
  const { data: dataClients } = useQuery(GET_CLIENTS, { context, variables: { pagination: PAGINATION } });

  useEffect(() => {
    if (generationCode) {
      getGeneralDocuments({
        variables: {
          filters: {
            generationCode,
          },
        },
      }).then((data) => {
        if (data.data?.getGeneralDocuments?.isSuccess && data.data?.getGeneralDocuments?.data.length !== 0) {
          getArticlesForSelling({
            variables: {
              filters: { pagination: PAGINATION, idSalePoint: data.data?.getGeneralDocuments.data[0]?.salePoint?.id },
            },
          });
        }
      });
    }
  }, [generationCode]);
  useEffect(() => {
    if (dataGetGeneralDocuments?.data?.getGeneralDocuments?.isSuccess && dataClients?.getClients?.isSuccess && dataGetArticlesForSelling?.data?.getArticlesForSelling?.isSuccess) {
      duplicateCorregirDte({ dataGetGeneralDocuments, dataClients, setValues, setGlobal, dataGetArticlesForSelling });
    }
  }, [dataGetGeneralDocuments?.data?.getGeneralDocuments, dataClients?.getClients, dataGetArticlesForSelling?.data?.getArticlesForSelling]);

  return { dataGetGeneralDocuments, dataClients, dataGetArticlesForSelling };
};

export default useSetValuesCorregirDTE;
