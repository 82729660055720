import React, { createContext, useState, useContext, useEffect } from "react";
import User from "types/user";

// hooks
import { useLocalStorage } from "hooks/useLocalStorage";

export interface Props {
  children: React.ReactNode;
}

export interface UserProviderProps {
  user: User;
  setUser: (user: User) => void;
}

const UserContext = createContext<UserProviderProps>({
  user: null,
  setUser: () => {},
});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }: Props) => {
  const [persistedUser, setPersistedUser] = useLocalStorage("user", null);
  const [user, setUser] = useState<User>(persistedUser);

  useEffect(() => {
    setPersistedUser(user);
  }, [user]);

  const value = {
    user,
    setUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
