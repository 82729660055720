import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import { Autocomplete, Card, CircularProgress, Grid, InputAdornment, Stack, SvgIcon, Switch, TextField } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Graph
import { gql, useMutation, useQuery } from "@apollo/client";

//Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

// Types
import { Bodegas } from "types/bodegas";
import { PAGINATION } from "constants/pagination";

//Queries
const UPDATE_STORAGE = gql`
  mutation UPDATE_STORAGE($idStorage: Float!, $storageData: StoragesDti!) {
    updateStorage(idStorage: $idStorage, storageData: $storageData) {
      isSuccess
      data {
        id
        name
        isActive
        costCenter {
          id
          name
          address
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_STORAGE = gql`
  query GET_STORAGE($idStorage: Float!) {
    getStorage(idStorage: $idStorage) {
      isSuccess
      data {
        id
        name
        allowSale
        isActive
        costCenter {
          id
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_COSTS_CENTERS = gql`
  query {
    getCostsCenters {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

interface Oficinas {
  id: number | null;
  label: string | null;
}

const validate = (values: Bodegas) => {
  const errors: any = {};

  if (!values.finalidad) {
    errors.finalidad = "La finalidad de la bodega es requerida";
  }
  //General
  if (!values.name) {
    errors.name = "El nombre de la bodega es requerido";
  } else if (values.name.length < 4) {
    errors.name = "El nombre de la bodega debe tener al menos 4 caracteres ";
  }
  if (!values.costCenter?.label) {
    errors.costCenter = "La oficina es requerida";
  }
  return errors;
};

function EditarBodegas(): JSX.Element {
  // React-Router-Dom
  const navigate = useNavigate();
  const { idStorage } = useParams();

  //States
  const [oficinasRow, setOficinasRow] = useState<Oficinas[]>([{ label: "No Options", id: 0 }]);

  //Queries and Mutations
  const { data, loading } = useQuery(GET_STORAGE, {
    variables: {
      idStorage: Number(idStorage),
    },
  });
  const dataGetCostCenters = useQuery(GET_COSTS_CENTERS, { variables: { pagination: PAGINATION } });
  const [updateStorage, dataUpdataStorage] = useMutation(UPDATE_STORAGE);

  const handleChangeOficinas = (e: React.SyntheticEvent) => {
    formik.setValues((prev) => ({
      ...prev,
      costCenter: {
        id: oficinasRow.filter((item: any) => item.label == e?.currentTarget.textContent).map((i: any) => i.id)[0],
        label: e?.currentTarget.textContent,
      },
    }));
  };

  const formik = useFormik({
    initialValues: {
      finalidad: "",
      isActive: false,
      name: "",
      costCenter: {
        id: 0,
        label: "",
      },
    },
    validate,
    onSubmit: async (values) => {
      updateStorage({
        variables: {
          idStorage: Number(idStorage),
          storageData: {
            idCostCenter: values.costCenter.id,
            name: values.name,
            allowSale: values.finalidad === "Para la Venta" ? true : false,
            isActive: values.isActive,
          },
        },
      })
        .then((dataUpdataStorage) => {
          if (dataUpdataStorage.data.updateStorage.isSuccess) {
            toast.success("La bodega se actualizo correctamente");
            navigate("/configuracion/empresa", { state: 2 });
          } else {
            toast.success(`Hubo un error al actualizar la bodega ${dataUpdataStorage.data.updateStorage?.message?.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Hubo un error al actualizar la bodega. Intente nuevamente");
        });
    },
  });

  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.finalidad) {
        toast.error(formik.errors.finalidad);
      }
      if (formik.errors.name) {
        toast.error(formik.errors.name);
      }
      if (formik.errors.costCenter) {
        toast.error("La oficina es requerida");
      }
    }
  };

  //Effects
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        name: data.getStorage.data?.name,
        costCenter: {
          label: data.getStorage.data.costCenter?.name,
          id: data.getStorage.data.costCenter?.id,
        },
        finalidad: data.getStorage.data?.allowSale ? "Para la Venta" : "Almacenamiento",
        isActive: data.getStorage.data?.isActive,
      }));
    }
  }, [data]);
  useEffect(() => {
    if (dataGetCostCenters.data) {
      setOficinasRow(
        dataGetCostCenters.data.getCostsCenters.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetCostCenters.data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              error={Boolean(formik.errors.name) && formik.touched.name}
                              onBlur={() => formik.setFieldTouched("name", true)}
                              label="Nombre de la bodega"
                              name="name"
                              helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
                              onChange={formik.handleChange}
                              variant="standard"
                              value={formik.values.name}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon viewBox="0 0 16 12">
                                      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                    </SvgIcon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              loading={dataGetCostCenters.loading}
                              options={oficinasRow}
                              onChange={(e, value) => formik.setFieldValue("costCenter", value)}
                              freeSolo
                              value={formik.values.costCenter}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Oficina a la que pertenece"
                                  variant="standard"
                                  error={Boolean(formik.errors.costCenter) && Boolean(formik.touched.costCenter)}
                                  helperText={!formik.touched.costCenter || (formik.touched.costCenter && !formik.errors.costCenter) ? " " : `${formik.errors.costCenter}`}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Autocomplete
                              options={["Para la Venta", "Almacenamiento"]}
                              onChange={(e, value) => {
                                formik.setFieldValue("finalidad", value);
                              }}
                              freeSolo
                              value={formik.values.finalidad}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de Bodega"
                                  variant="standard"
                                  error={Boolean(formik.errors.finalidad) && Boolean(formik.touched.finalidad)}
                                  helperText={!formik.touched.finalidad || (formik.touched.finalidad && !formik.errors.finalidad) ? " " : `${formik.errors.finalidad}`}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Stack mt={{ xs: 0, md: 1.2 }} direction="row" alignItems="center" justifyContent="center">
                              <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                                Estado de la Bodega
                              </MDTypography>
                              <Switch checked={formik.values.isActive} onChange={formik.handleChange} name="isActive" />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/configuracion/empresa")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdataStorage.loading}
                    onClick={() => {
                      validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdataStorage.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditarBodegas;
