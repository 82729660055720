// @mui material components
import { ButtonBase, Grid, SvgIcon } from "@mui/material";

// Material Dashboard 2 PRO React TS components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Formik
import { FormikProps } from "formik";
import { Values1 } from "../../crearOficina";
import colors from "assets/theme/base/colors";

//Types

interface Props {
  formik: FormikProps<Values1>;
}

function TipoOficina({ formik }: Props) {
  return (
    <>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mt={2.5} mb={{ xs: 3, md: 6 }}>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Tipo de Oficina
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Selecciona el tipo de oficina:
        </MDTypography>
      </MDBox>
      <MDBox display="flex">
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "CASA_MATRIZ" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "CASA_MATRIZ" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="CASA_MATRIZ"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 80 107"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M 10 0 C 4.47917 0 0 4.47917 0 10 V 96.6667 C 0 102.188 4.47917 106.667 10 106.667 H 30 V 90 C 30 84.4792 34.4792 80 40 80 C 45.5208 80 50 84.4792 50 90 V 106.667 H 70 C 75.5208 106.667 80 102.188 80 96.6667 V 10 C 80 4.47917 75.5208 0 70 0 H 10 Z M 13.3333 50 C 13.3333 48.1667 14.8333 46.6667 16.6667 46.6667 H 23.3333 C 25.1667 46.6667 26.6667 48.1667 26.6667 50 V 56.6667 C 26.6667 58.5 25.1667 60 23.3333 60 H 16.6667 C 14.8333 60 13.3333 58.5 13.3333 56.6667 V 50 Z M 36.6667 46.6667 H 43.3333 C 45.1667 46.6667 46.6667 48.1667 46.6667 50 V 56.6667 C 46.6667 58.5 45.1667 60 43.3333 60 H 36.6667 C 34.8333 60 33.3333 58.5 33.3333 56.6667 V 50 C 33.3333 48.1667 34.8333 46.6667 36.6667 46.6667 Z M 53.3333 50 C 53.3333 48.1667 54.8333 46.6667 56.6667 46.6667 H 63.3333 C 65.1667 46.6667 66.6667 48.1667 66.6667 50 V 56.6667 C 66.6667 58.5 65.1667 60 63.3333 60 H 56.6667 C 54.8333 60 53.3333 58.5 53.3333 56.6667 V 50 Z M 16.6667 20 H 23.3333 C 25.1667 20 26.6667 21.5 26.6667 23.3333 V 30 C 26.6667 31.8333 25.1667 33.3333 23.3333 33.3333 H 16.6667 C 14.8333 33.3333 13.3333 31.8333 13.3333 30 V 23.3333 C 13.3333 21.5 14.8333 20 16.6667 20 Z M 33.3333 23.3333 C 33.3333 21.5 34.8333 20 36.6667 20 H 43.3333 C 45.1667 20 46.6667 21.5 46.6667 23.3333 V 30 C 46.6667 31.8333 45.1667 33.3333 43.3333 33.3333 H 36.6667 C 34.8333 33.3333 33.3333 31.8333 33.3333 30 V 23.3333 Z M 56.6667 20 H 63.3333 C 65.1667 20 66.6667 21.5 66.6667 23.3333 V 30 C 66.6667 31.8333 65.1667 33.3333 63.3333 33.3333 H 56.6667 C 54.8333 33.3333 53.3333 31.8333 53.3333 30 V 23.3333 C 53.3333 21.5 54.8333 20 56.6667 20 Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "CASA_MATRIZ" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Casa Matriz
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox>
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "SUCURSAL" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "SUCURSAL" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="SUCURSAL"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 80 76"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M 78.1668 15.262 L 69.7418 1.92613 C 68.9919 0.735163 67.654 0 66.2277 0 H 13.7665 C 12.3403 0 11.0023 0.735163 10.2524 1.92613 L 1.81277 15.262 C -2.53939 22.1431 1.31286 31.7149 9.44376 32.8177 C 10.0319 32.8912 10.6347 32.9353 11.2229 32.9353 C 15.0604 32.9353 18.4716 31.2591 20.8094 28.6713 C 23.1472 31.2591 26.5583 32.9353 30.3959 32.9353 C 34.2334 32.9353 37.6446 31.2591 39.9824 28.6713 C 42.3202 31.2591 45.7314 32.9353 49.5689 32.9353 C 53.4212 32.9353 56.8176 31.2591 59.1555 28.6713 C 61.508 31.2591 64.9044 32.9353 68.742 32.9353 C 69.3448 32.9353 69.933 32.8912 70.5211 32.8177 C 78.6814 31.7296 82.5484 22.1578 78.1815 15.262 H 78.1668 Z M 71.1239 37.4786 H 71.1092 C 70.3299 37.5815 69.536 37.6403 68.7273 37.6403 C 66.9041 37.6403 65.1544 37.361 63.5223 36.8611 V 56.4605 H 16.4719 V 36.8464 C 14.8252 37.361 13.0608 37.6403 11.2376 37.6403 C 10.4289 37.6403 9.6202 37.5815 8.84093 37.4786 H 8.82622 C 8.22339 37.3904 7.63526 37.2875 7.06183 37.1404 V 56.4605 V 65.8706 C 7.06183 71.0608 11.2817 75.2807 16.4719 75.2807 H 63.5223 C 68.7126 75.2807 72.9324 71.0608 72.9324 65.8706 V 56.4605 V 37.1404 C 72.3443 37.2875 71.7562 37.4051 71.1239 37.4786 Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "SUCURSAL" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Sucursal
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox>
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "PREDIO" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "PREDIO" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="PREDIO"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 80 64"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M 70 20 C 75.525 20 80 15.525 80 10 C 80 4.475 75.525 0 70 0 C 64.475 0 60 4.475 60 10 C 60 15.525 64.475 20 70 20 Z M 6.9875 64 H 47.6375 H 57.0125 H 72.3625 C 76.5875 64 80 60.575 80 56.3625 C 80 54.9625 79.6125 53.5875 78.8875 52.3875 L 62.3875 25.35 C 61.875 24.5125 60.975 24 60 24 C 59.025 24 58.125 24.5125 57.6125 25.3375 L 51.5875 35.2125 L 35.85 10.125 C 35.025 8.8 33.5625 8 32 8 C 30.4375 8 28.9875 8.8 28.15 10.125 L 1.075 53.3 C 0.375 54.4125 0 55.7 0 57.0125 C 0 60.875 3.125 64 6.9875 64 Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "PREDIO" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Predio
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox>
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "OTRO" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "OTRO" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="OTRO"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 80 80"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M 40 0 C 17.9062 0 0 17.9062 0 40 C 0 62.0938 17.9062 80 40 80 C 62.0938 80 80 62.0938 80 40 C 80 17.9062 62.0938 0 40 0 Z M 40 72.5 C 22.0781 72.5 7.5 57.9203 7.5 40 C 7.5 22.0797 22.0781 7.5 40 7.5 C 57.9219 7.5 72.5 22.0797 72.5 40 C 72.5 57.9203 57.9219 72.5 40 72.5 Z M 40 52.5 C 37.1875 52.5 35 54.6875 35 57.5 C 35 60.3125 37.0469 62.5 40 62.5 C 42.6719 62.5 45 60.3125 45 57.5 C 45 54.6875 42.6719 52.5 40 52.5 Z M 45.1719 20 H 37.1875 C 31.0938 20 26.25 24.8438 26.25 30.9375 C 26.25 32.9688 27.9688 34.6875 30 34.6875 C 32.0312 34.6875 33.75 32.9688 33.75 30.9375 C 33.75 29.0625 35.1719 27.5 37.0469 27.5 H 45.0312 C 47.0469 27.5 48.75 29.0625 48.75 30.9375 C 48.75 32.1875 48.125 33.1406 47.0312 33.7656 L 38.125 39.2188 C 36.875 40 36.25 41.25 36.25 42.5 V 45 C 36.25 47.0312 37.9688 48.75 40 48.75 C 42.0312 48.75 43.75 47.0312 43.75 45 V 44.6875 L 50.7969 40.3125 C 54.0781 38.2812 56.1094 34.6875 56.1094 30.9375 C 56.25 24.8438 51.4062 20 45.1719 20 Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "OTRO" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Otro
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default TipoOficina;
