// Constants
import { IVA_AMOUNT } from "constants/finantial";

// Utils
import { filterGravados } from "./gravados";
import { DataTableDTEObject } from "types/dataTableDteObject";

// Types

export const calculateIva = (products: DataTableDTEObject[]) => {
  return (
    filterGravados(products).reduce((acc, product) => {
      return acc + product?.quantity * product?.unitPrice * IVA_AMOUNT;
    }, 0) || 0
  );
};
