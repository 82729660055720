// @mui material components
import { ButtonBase, Grid } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import OtroIcon from "./OtroIcon";

// Theme
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Formik
import { FormikProps } from "formik";

// Types
import { ValuesArticulos } from "types/Articulos";

interface Props {
  formik: FormikProps<ValuesArticulos>;
}

function Tipo({ formik }: Props) {
  return (
    <>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mt={2.5} mb={{ xs: 3, md: 6 }}>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Tipo de Articulo
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Selecciona el tipo que tu articulo tiene:
        </MDTypography>
      </MDBox>
      <MDBox display="flex" mt={{ xs: 0, md: 5 }}>
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "Bienes" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "Bienes" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Bienes"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <AddShoppingCartIcon
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "Bienes" ? "info" : "#5AA9E6"}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Bienes
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "Servicios" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "Servicios" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Servicios"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <EngineeringIcon
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "Servicios" ? colors.info.main : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Servicios
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "Ambos" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "Ambos" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Ambos"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <SyncAltIcon
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "Ambos" ? "info" : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Ambos
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.type === "Otro" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.type === "Otro" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="Otro"
                onClick={(e) => {
                  formik.setFieldValue("type", e.currentTarget.name);
                }}
              >
                <OtroIcon
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                />
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.type === "Otro" ? "info" : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Otro
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default Tipo;
