import gql from "graphql-tag";

export const DELETE_TAG = gql`
  mutation DELETE_TAG($idTag: Float!) {
    deleteTag(idTag: $idTag) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
