import { useEffect } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import { Autocomplete, Card, CircularProgress, Grid, TextField } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// Graph
import { useLazyQuery, useMutation } from "@apollo/client";

// Formik
import { useFormik } from "formik";

// Alert
import { toast } from "react-hot-toast";

// Utils
import { departamentos, getMunicipios, municipiosTotal, paises } from "utils/getRegions";
import { actividadComercial } from "utils/getActividadPrincipal";

// Types
import { ClientORCompanyForm } from "types/clientsForm";
import { SerializationKeys } from "types/apollo";
import { ContribuyenteTypes } from "types/contribuyenteTypes";
import { legalRepresetantion } from "types/legalRepresentation";

// Context
import { useCompanyContext } from "context/CompanyContext";

// Queries
import { GET_COMPANY } from "apollo/queries/companies/getCompany";
import { UPDATE_COMPANY } from "apollo/queries/companies/updateCompany";
import { UPDATE_DOCUMENT } from "apollo/queries/documents/updateDocument";
import { ADD_DOCUMENTS } from "apollo/queries/documents/addDocuments";
import { DELETE_DOCUMENT } from "apollo/queries/documents/deleteDocument";

const validate = (values: ClientORCompanyForm) => {
  const errors: any = {};

  //
  if (!values.legalRepresentation) {
    errors.legalRepresentation = "La estructura legal es requerida";
  }

  //General
  if (!values.dui && !values.nit && !values.nrc) {
    errors.dui = "Al menos un documento de identidad es requerido";
    errors.nit = "Al menos un documento de identidad es requerido";
    errors.nrc = "Al menos un documento de identidad es requerido";
  }
  if (values.dui) {
    if (!/(\d{8})-(\d{1})/.test(values.dui)) {
      errors.dui = "DUI invalido";
    }
  }
  if (values.nit) {
    if (!/(\d{4})-(\d{6})-(\d{3})-(\d{1})/.test(values.nit)) {
      errors.nit = "NIT invalido";
    }
  }
  if (values.nrc) {
    if (!/(\d\1)-(\d{1})/.test(values.nrc)) {
      errors.nrc = "NRC invalido";
    }
  }
  if (!values.nombre) {
    errors.nombre = "El nombre es requerido";
  } else if (values.nombre.length < 4) {
    errors.nombre = "El nombre debe tener al menos 4 caracteres";
  }
  if (!values.telefono) {
    errors.telefono = "El telefono es requerido";
  } else if (!/^[0-9]{8}$/i.test(values.telefono)) {
    errors.telefono = "Telefono invalido";
  }
  if (!values.email) {
    errors.email = "El correo es requerido";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Correo invalido";
  }
  if (values.nombreComercial) {
    if (values.nombreComercial.length < 4) {
      errors.nombreComercial = "El nombre comercial debe tener al menos 4 caracteres";
    }
  }

  // Direccion
  if (!values.pais) {
    errors.pais = "El pais es requerido";
  }
  if (values.pais?.label === "EL SALVADOR" && values.departamento?.label.length < 4 && !values.departamento) {
    errors.departamento = "El departamento debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.municipio?.label.length < 4 && !values.municipio) {
    errors.municipio = "El municipio debe ser mayor a 4 caracteres";
  }
  if (!values.direccion) {
    errors.direccion = "La direccion es requerida";
  } else if (values.direccion.length < 4) {
    errors.direccion = "La direccion debe ser mayor a 4 caracteres";
  }

  //Facturacion
  if (!values.contribuyenteType) {
    errors.contribuyenteType = "El tamaño del contribuyente es requerido";
  }
  if (values.isActiveCredit && !values.creditLimit) {
    errors.creditLimit = "El monto maximo de credito es requerido";
  }
  if (values.isActiveCredit && values.perception && !values.creditPeriod) {
    errors.creditPeriod = "El periodo de credito es requerido";
  }

  return errors;
};

function EditarEmpresa(): JSX.Element {
  // Context
  const { company, setCompany } = useCompanyContext();

  // React-Router-Dom
  const navigate = useNavigate();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Configuration };

  //Queries and Mutations
  const [getCompany, { data, loading }] = useLazyQuery(GET_COMPANY, { context });
  const [updateCompany, dataUpdateCompany] = useMutation(UPDATE_COMPANY, { context });
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, { context });
  const [addDocuments] = useMutation(ADD_DOCUMENTS, { context });
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, { context });

  const formik = useFormik({
    initialValues: {
      legalRepresentation: "",
      dui: "",
      nit: "",
      nrc: "",
      nombre: "",
      actividadPrincipal: {
        id: 0,
        label: "",
      },
      telefono: "",
      email: "",
      nombreComercial: "",
      pais: {
        label: "",
        id: "0",
      },
      departamento: null,
      municipio: null,
      direccion: "",
      contribuyenteType: "",
      retention: false,
      isExent: false,
      isActiveCredit: false,
      creditLimit: 0,
      perception: false,
      creditPeriod: 0,
    },
    validate,
    onSubmit: async (values) => {
      // Update Documents
      data.getCompany.data.documents.map((item: any) => {
        if (item.type === "NRC") {
          if (item.documentNumber !== values.nrc && values.nrc !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nrc,
              },
            });
          }
        }
        if (item.type === "NIT") {
          if (item.documentNumber !== values.nit && values.nit !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nit,
              },
            });
          }
        }
        if (item.type === "DUI") {
          if (item.documentNumber !== values.dui && values.dui !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.dui,
              },
            });
          }
        }
      });
      //Add Documents
      const arr = [];
      const existeNit = data.getCompany.data.documents.find((item: any) => item.type === "NIT");
      const existeNrc = data.getCompany.data.documents.find((item: any) => item.type === "NRC");
      const existeDui = data.getCompany.data.documents.find((item: any) => item.type === "DUI");
      if (values.dui !== "" && !existeDui) {
        arr.push({
          documentNumber: values.dui,
          type: "DUI",
        });
      }
      if (values.nit !== "" && !existeNit) {
        arr.push({
          documentNumber: values.nit,
          type: "NIT",
        });
      }
      if (values.nrc !== "" && !existeNrc) {
        arr.push({
          documentNumber: values.nrc,
          type: "NRC",
        });
      }
      if (arr.length > 0) {
        addDocuments({
          variables: {
            data: {
              idUser: data.getCompany.data.id,
              userType: "COMPANY",
              documents: arr,
            },
          },
        });
      }
      //Delete Documents
      if (values.dui === "" && existeDui) {
        deleteDocument({
          variables: {
            idDocument: existeDui.id,
          },
        });
      }
      if (values.nit === "" && existeNit) {
        deleteDocument({
          variables: {
            idDocument: existeNit.id,
          },
        });
      }
      if (values.nrc === "" && existeNrc) {
        deleteDocument({
          variables: {
            idDocument: existeNrc.id,
          },
        });
      }
      //Update Company
      updateCompany({
        variables: {
          companyData: {
            idRegion: !values?.municipio?.id ? Number(values?.pais?.id) : Number(values?.municipio?.id),
            email: values.email,
            idLineOfBusiness: values.actividadPrincipal.id,
            name: values.nombre,
            representationLegalType: getLegalRepresetationOnSubmit(values.legalRepresentation),
            contribuyenteType: getcontribuyenteTypeValue(values.contribuyenteType),
            address: values.direccion,
            tradename: values?.nombreComercial === "" ? values.nombre : values?.nombreComercial,
            phone: values.telefono,
          },
        },
      })
        .then((dataUpdateCompany) => {
          if (dataUpdateCompany.data.updateCompany.isSuccess) {
            setCompany({
              ...company,
              legalRepresentation: dataUpdateCompany.data.updateCompany?.data?.legalRepresentation,
              contribuyenteType: dataUpdateCompany.data.updateCompany?.data?.contribuyenteType,
              name: dataUpdateCompany.data.updateCompany?.data?.name,
            });
            toast.success("La empresa se actualizo correctamente");
          } else {
            toast.error(`Hubo un error al editar la empresa. ${dataUpdateCompany.data.updateCompany?.message?.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Hubo un error al editar la empresa. Intente nuevamente");
        });
    },
  });

  const getcontribuyenteTypeValue = (value: string) => {
    switch (value) {
      case "Grande":
        return ContribuyenteTypes.GRANDE;
      case "Mediano":
        return ContribuyenteTypes.MEDIANO;
      case "Otro":
        return ContribuyenteTypes.OTRO;
      default:
        return value;
    }
  };
  const getLegalRepresetationOnSubmit = (value: string) => {
    switch (value) {
      case "Persona Juridica":
        return legalRepresetantion.JURIDICA.toUpperCase();
      case "Persona Natural con IVA":
        return legalRepresetantion.PERSONA_NATURAL_IVA;
      case "Persona Natural sin IVA":
        return legalRepresetantion.PERSONA_NATURAL_NO_IVA;
      default:
        return value;
    }
  };
  const getLegalRepresetation = (value: string) => {
    switch (value) {
      case legalRepresetantion.JURIDICA.toUpperCase():
        return "Persona Juridica";
      case legalRepresetantion.PERSONA_NATURAL_IVA:
        return "Persona Natural con IVA";
      case legalRepresetantion.PERSONA_NATURAL_NO_IVA:
        return "Persona Natural sin IVA";
    }
  };
  const handleChangeFormat: any = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      formik.setFieldValue("nit", string);
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      formik.setFieldValue("dui", value);
    }
    if (e.target.name === "NRC") {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      formik.setFieldValue("nrc", value);
    }
  };
  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.dui === "Al menos un documento de identidad es requerido" || formik.errors.nrc === "Al menos un documento de identidad es requerido" || formik.errors.nit === "Al menos un documento de identidad es requerido") {
        if (formik.touched.dui || formik.touched.nrc || formik.touched.nit) {
          toast.error("Al menos un documento de identidad es requerido");
        }
      }
      if (formik.touched.dui && formik.errors.dui && formik.errors.dui !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.dui);
      }
      if (formik.touched.nit && formik.errors.nit && formik.errors.nit !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.nit);
      }
      if (formik.touched.nrc && formik.errors.nrc && formik.errors.nrc !== "Al menos un documento de identidad es requerido") {
        toast.error(formik.errors.nrc);
      }
      if (formik.errors.telefono && formik.touched.telefono) {
        toast.error(formik.errors.telefono);
      }
      if (formik.errors.email && formik.touched.email) {
        toast.error(formik.errors.email);
      }
      if (formik.errors.pais && formik.touched.pais) {
        toast.error("El pais debe ser mayor a 4 caracteres");
      }
      if (formik.errors.departamento && formik.touched.departamento) {
        toast.error("El departamento debe ser mayor a 4 caracteres");
      }
      if (formik.errors.municipio && formik.touched.municipio) {
        toast.error("El municipio debe ser mayor a 4 caracteres");
      }
      if (formik.errors.direccion && formik.touched.direccion) {
        toast.error(formik.errors.direccion);
      }
    }
  };

  //Effects
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        nombre: data.getCompany.data?.name,
        nombreComercial: data.getCompany.data?.tradename,
        actividadPrincipal: {
          label: data.getCompany.data?.lineOfBusiness?.name,
          id: data.getCompany.data?.lineOfBusiness?.id,
        },
        legalRepresentation: getLegalRepresetation(data.getCompany.data?.legalRepresentation),
        contribuyenteType: data.getCompany.data?.contribuyenteType,
        telefono: data.getCompany.data?.phone,
        email: data.getCompany.data?.email,
        direccion: data.getCompany.data?.address,
      }));
      data.getCompany.data?.documents.map((item: any) => {
        if (item.type === "NRC") {
          formik.setFieldValue("nrc", item.documentNumber);
        }
        if (item.type === "NIT") {
          formik.setFieldValue("nit", item.documentNumber);
        }
        if (item.type === "DUI") {
          formik.setFieldValue("dui", item.documentNumber);
        }
      });
      const paisId = paises.find((item: any) => item.id == data.getCompany.data?.region.id);
      const departamentoId = departamentos.find((item: any) => item.id == data.getCompany.data?.region.id);
      const cityId: any = municipiosTotal.find((item: any) => item.id == data.getCompany.data?.region.id);
      if (cityId) {
        formik.setFieldValue("municipio", cityId);
        const depCity = departamentos.find((item) => item.id === cityId.idParent);
        formik.setFieldValue("departamento", depCity);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (departamentoId) {
        formik.setFieldValue("departamento", departamentoId);
        formik.setFieldValue("pais", { id: "64", label: "EL SALVADOR" });
      } else if (paisId) {
        formik.setFieldValue("pais", paisId);
      }
    }
  }, [data]);

  useEffect(() => {
    if (formik.values?.pais?.label !== "EL SALVADOR") {
      formik.setValues((prev) => ({
        ...prev,
        departamento: null,
        municipio: null,
      }));
    }
  }, [formik.values.pais]);

  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Nombre Legal"
                              error={Boolean(formik.errors.nombre) && formik.touched.nombre}
                              name="nombre"
                              helperText={!formik.touched.nombre || (formik.touched.nombre && !formik.errors.nombre) ? " " : formik.errors.nombre}
                              onChange={formik.handleChange}
                              onBlur={() => formik.setFieldTouched("nombre", true)}
                              value={formik.values.nombre}
                              color="info"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="standard"
                              value={formik.values.nombreComercial}
                              error={Boolean(formik.errors.nombreComercial)}
                              helperText={!formik.errors.nombreComercial ? " " : `${formik.errors.nombreComercial}`}
                              onChange={formik.handleChange}
                              name="nombreComercial"
                              label="Nombre Comercial"
                              color="info"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 9 }}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Documento Unico de Identidad"
                              fullWidth
                              variant="standard"
                              error={Boolean(formik.errors.dui) && formik.touched.dui}
                              onBlur={() => formik.setFieldTouched("dui", true)}
                              onChange={handleChangeFormat}
                              value={formik.values.dui}
                              color="info"
                              name="DUI"
                              helperText={!formik.errors.dui ? " " : formik.touched.dui && formik.errors.dui}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Numero de Identificacion Tributaria"
                              color="info"
                              error={Boolean(formik.errors.nit) && formik.touched.nit}
                              onBlur={() => formik.setFieldTouched("nit", true)}
                              onChange={handleChangeFormat}
                              value={formik.values.nit}
                              name="NIT"
                              helperText={!formik.errors.nit ? " " : formik.touched.nit && formik.errors.nit}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              variant="standard"
                              onChange={handleChangeFormat}
                              value={formik.values.nrc}
                              error={Boolean(formik.errors.nrc) && formik.touched.nrc}
                              onBlur={() => formik.setFieldTouched("nrc", true)}
                              label="Numero de Registro de IVA"
                              name="NRC"
                              helperText={!formik.errors.nrc ? " " : formik.touched.nrc && formik.errors.nrc}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={actividadComercial}
                          freeSolo
                          value={formik.values.actividadPrincipal?.label ?? ""}
                          onChange={(e, value) => formik.setFieldValue("actividadPrincipal", value)}
                          renderInput={(params) => <TextField {...params} label="Actividad Principal" color="info" variant="standard" />}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Medios de Notificacion
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                      Estos datos se utilizaran para enviarte los documentos tributarios electroncios.
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Telefonos"
                        error={Boolean(formik.errors.telefono) && formik.touched.telefono}
                        value={formik.values.telefono}
                        onChange={formik.handleChange}
                        onBlur={() => formik.setFieldTouched("telefono", true)}
                        name="telefono"
                        helperText={!formik.touched.telefono || (formik.touched.telefono && !formik.errors.telefono) ? " " : `${formik.errors.telefono}`}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Correo Electronico"
                        error={Boolean(formik.errors.email) && formik.touched.email}
                        value={formik.values.email}
                        helperText={!formik.touched.email || (formik.touched.email && !formik.errors.email) ? " " : `${formik.errors.email}`}
                        onChange={formik.handleChange}
                        onBlur={() => formik.setFieldTouched("email", true)}
                        name="email"
                        color="info"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Datos Tributarios
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} mb={1}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        freeSolo
                        options={["Persona Juridica", "Persona Natural con IVA"]}
                        value={formik.values.legalRepresentation}
                        onChange={(e, value) => formik.setFieldValue("legalRepresentation", value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estructura Legal"
                            variant="standard"
                            error={Boolean(formik.errors.legalRepresentation) && formik.touched.legalRepresentation}
                            helperText={!formik.touched.legalRepresentation || (formik.touched.legalRepresentation && !formik.errors.legalRepresentation) ? " " : formik.errors.legalRepresentation}
                            fullWidth
                            color="info"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        freeSolo
                        options={["Grande", "Mediano", "Otro"]}
                        value={formik.values.contribuyenteType}
                        onChange={(e, value) => {
                          formik.setFieldValue("contribuyenteType", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tamaño del Contribuyente"
                            variant="standard"
                            error={Boolean(formik.errors.contribuyenteType) && formik.touched.contribuyenteType}
                            helperText={!formik.touched.contribuyenteType || (formik.touched.contribuyenteType && !formik.errors.contribuyenteType) ? " " : formik.errors.contribuyenteType}
                            fullWidth
                            color="info"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Direccion
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Direccion"
                        name="direccion"
                        error={Boolean(formik.errors.direccion) && formik.touched.direccion}
                        helperText={!formik.touched.direccion || (formik.touched.direccion && !formik.errors.direccion) ? " " : `${formik.errors.direccion}`}
                        value={formik.values.direccion}
                        onChange={formik.handleChange}
                        onBlur={() => formik.setFieldTouched("direccion", true)}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 9.5 }}>
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            id="pais"
                            options={paises}
                            freeSolo
                            value={formik.values.pais}
                            onChange={(e, value) => formik.setFieldValue("pais", value)}
                            onBlur={() => formik.setFieldTouched("pais", true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pais"
                                error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                                helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && (
                            <Autocomplete
                              freeSolo
                              onChange={(e, value) => formik.setFieldValue("departamento", value)}
                              value={formik.values.departamento}
                              onBlur={() => formik.setFieldTouched("departamento", true)}
                              options={departamentos}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Departamento"
                                  error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                                  helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                            <Autocomplete
                              freeSolo
                              value={formik.values.municipio}
                              onChange={(e, value) => formik.setFieldValue("municipio", value)}
                              onBlur={() => formik.setFieldTouched("municipio", true)}
                              options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Municipio"
                                  error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                                  helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/configuracion/empresa")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdateCompany.loading}
                    onClick={() => {
                      validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdateCompany.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
}

export default EditarEmpresa;
