import gql from "graphql-tag";

export const UPDATE_EMPLOYEE = gql`
  mutation ($idEmployee: Float!, $data: EmployeeDti!) {
    updateEmployee(idEmployee: $idEmployee, data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
