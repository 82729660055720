// Material Dashboard 2 PRO React
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

// React and GraphQl
import { useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

//Components
import General from "./components/General";
import Tipo from "./components/Tipo";
import Direccion from "./components/Direccion";
import Facturacion from "./components/Facturacion";

// Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

// Utils
import { validarDUI } from "utils/validations/validarDui";
import { validarNIT } from "utils/validations/validarNit";
import { validarNRC } from "utils/validations/validarNrc";

// Types
import { ClientORCompanyForm } from "types/clientsForm";
import { SerializationKeys } from "types/apollo";
import { legalRepresetantion } from "types/legalRepresentation";
import { DocumentType } from "types/documentType";
import { PAGINATION } from "constants/pagination";

// Data
const steps = ["Tipo", "Generales", "Direccion", "Facturacion"];

const validate = (values: ClientORCompanyForm) => {
  const errors: any = {};

  // Tipo
  if (!values.legalRepresentation) {
    errors.legalRepresentation = "La estructura legal es requerida";
  }

  //General
  if (!values.dui && !values.nit && !values.nrc && !values.pasaporte && !values.carnet_residente) {
    errors.dui = "Al menos un documento de identidad es requerido";
    errors.nit = "Al menos un documento de identidad es requerido";
    errors.nrc = "Al menos un documento de identidad es requerido";
    errors.pasaporte = "Al menos un documento de identidad es requerido";
    errors.carnet_residente = "Al menos un documento de identidad es requerido";
  }
  /* if (values.dui) {
    if (!/(\d{8})-(\d{1})/.test(values.dui)) {
      errors.dui = "DUI invalido";
    }
  }
  if (values.nit) {
    if (!/(\d{4})-(\d{6})-(\d{3})-(\d{1})/.test(values.nit)) {
      errors.nit = "NIT invalido";
    }
  } */
  if (values.pasaporte && values.pasaporte.length < 3) {
    errors.pasaporte = "El pasaporte debe tener al menos 3 caracteres";
  }
  if (values.carnet_residente && values.carnet_residente.length < 3) {
    errors.carnet_residente = "El carnet de residente debe tener al menos 3 caracteres";
  }
  if (values.nrc && !validarNRC(values.nrc)) {
    errors.nrc = "NRC invalido";
  }
  if (values.dui && validarDUI(values.dui)) {
    errors.dui = "DUI invalido";
  }
  if (values.nit && validarNIT(values.nit)) {
    errors.nit = "NIT invalido";
  }
  if (!values.nombre) {
    errors.nombre = "El nombre es requerido";
  } else if (values.nombre.length < 5) {
    errors.nombre = "El nombre debe tener al menos 5 caracteres";
  }
  if (values.telefono) {
    if (values.telefono && values.telefono.length < 8) {
      errors.telefono = "Telefono invalido";
    }
  }
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Correo invalido";
    }
  }
  if (values.nombreComercial) {
    if (values.nombreComercial.length < 4) {
      errors.nombreComercial = "El nombre comercial debe tener al menos 4 caracteres";
    }
  }
  if (values.legalRepresentation === legalRepresetantion.PERSONA_NATURAL_IVA) {
    /*  if (!values.dui && !values.nit) {
      errors.dui = "Al menos un documento de identidad es requerido";
      errors.nit = "Al menos un documento de identidad es requerido";
    } */
    if (values.clientStatus === "nacional") {
      if (!values.nrc) {
        errors.nrc = "El NRC es obligatorio";
      }
    }
    if (!values.actividadPrincipal) {
      errors.actividadPrincipal = "La actividad principal es obligatoria";
    }
  }

  // Direccion
  if (!values.pais) {
    errors.pais = "El pais es requerido";
  }
  if (values.pais?.label === "EL SALVADOR" && values.departamento?.label.length < 4 && !values.departamento) {
    errors.departamento = "El departamento debe ser mayor a 4 caracteres";
  }
  if (values.pais?.label === "EL SALVADOR" && values.municipio?.label.length < 4 && !values.municipio) {
    errors.municipio = "El municipio debe ser mayor a 4 caracteres";
  }
  if (values.direccion && values.direccion.length < 4) {
    errors.direccion = "La direccion debe ser mayor a 4 caracteres";
  }

  //Facturacion
  if (values.legalRepresentation === "Persona Juridica" || values.legalRepresentation === "Persona Natural con IVA") {
    if (!values.contribuyenteType) {
      errors.contribuyenteType = "El tamaño del contribuyente es requerido";
    }
  }
  if (values.isActiveCredit && !values.creditLimit) {
    errors.creditLimit = "El monto maximo de credito es requerido";
  }
  if (values.isActiveCredit && values.perception && !values.creditPeriod) {
    errors.creditPeriod = "El periodo de credito es requerido";
  }

  return errors;
};

const ADD_CLIENT = gql`
  mutation ADD_CLIENT($clientCompleteInfo: ClientCompleteDti!, $documents: [DocumentDti!]!, $clientInfo: ClientDti!) {
    addClient(clientInfo: $clientInfo, clientCompleteInfo: $clientCompleteInfo, documents: $documents) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;

const GET_ALL_CLIENTS = gql`
  query GET_ALL_CLIENTS($filters: ClientSimpleQueryFilterDti) {
    getAllClients(filters: $filters) {
      isSuccess
      data {
        name
        id
        idClientCompany
        region {
          id
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

const ADD_CLIENT_TO_COMPANY = gql`
  mutation ADD_CLIENT_TO_COMPANY($idClient: Float!, $data: ClientCompleteDti!) {
    addClientToCompany(idClient: $idClient, data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;

const GET_CLIENTS = gql`
  query GET_CLIENTS($clientFilters: ClientQueryFilterDti) {
    getClients(clientFilters: $clientFilters) {
      isSuccess
      data {
        name
        idClientCompany
        documents {
          documentNumber
        }
      }
    }
  }
`;

function CrearClientes(): JSX.Element {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Contacts };
  //Querys and Mutations
  const [addClient, { loading }] = useMutation(ADD_CLIENT, { context });
  const [getAllClients] = useLazyQuery(GET_ALL_CLIENTS, { context });
  const [addClientToCompany] = useMutation(ADD_CLIENT_TO_COMPANY, { context });
  const [getClients] = useLazyQuery(GET_CLIENTS, { context, variables: { pagination: PAGINATION } });

  // States
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();

  const formik = useFormik<ClientORCompanyForm>({
    initialValues: {
      legalRepresentation: "",
      dui: "",
      nit: "",
      nrc: "",
      nombre: "",
      pasaporte: "",
      carnet_residente: "",
      actividadPrincipal: null,
      telefono: null,
      email: null,
      nombreComercial: "",
      pais: {
        label: "",
        id: "0",
      },
      departamento: null,
      municipio: null,
      direccion: null,
      contribuyenteType: null,
      retention: false,
      isExent: false,
      isActiveCredit: false,
      creditLimit: 0,
      perception: false,
      creditPeriod: 0,
      clientStatus: "nacional",
    },
    validate,
    onSubmit: async (values) => {
      const arr = [];
      if (values.dui !== "") {
        arr.push({
          documentNumber: values.dui,
          type: DocumentType.DUI,
        });
      }
      if (values.nit !== "") {
        arr.push({
          documentNumber: values.nit,
          type: DocumentType.NIT,
        });
      }
      if (values.nrc !== "") {
        arr.push({
          documentNumber: values.nrc,
          type: DocumentType.NRC,
        });
      }
      if (values.pasaporte !== "") {
        arr.push({
          documentNumber: values.pasaporte,
          type: DocumentType.PASSPORT.toUpperCase(),
        });
      }
      if (values.carnet_residente !== "") {
        arr.push({
          documentNumber: values.carnet_residente,
          type: DocumentType.CARNET_RESIDENTE,
        });
      }
      try {
        const dataAddclient = await addClient({
          variables: {
            clientInfo: {
              name: values.nombre,
              contribuyenteType: values.contribuyenteType,
              idLineOfBusiness: values.actividadPrincipal?.id ?? null,
              legalRepresentation: values.legalRepresentation,
            },
            documents: arr,
            clientCompleteInfo: {
              idRegion: values?.municipio?.id ? Number(values?.municipio?.id) : Number(values?.pais?.id),
              email: values?.email === "" ? null : values.email,
              isExcent: values.isExent,
              retention: values.retention,
              perception: values.perception,
              activeCredit: values.isActiveCredit,
              creditLimit: values?.creditLimit === undefined ? 0 : Number(values.creditLimit),
              creditExcelse: values.isActiveCredit,
              creditPeriod: values?.creditPeriod === undefined ? 0 : Number(values.creditPeriod),
              isActive: true,
              plazoType: "DIAS",
              address: values.direccion === "" ? null : values.direccion,
              tradename: values.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA ? (values?.nombreComercial === "" ? values.nombre : values?.nombreComercial) : null,
              phone: values.telefono === "" ? null : values.telefono,
            },
          },
        });
        if (dataAddclient.data.addClient?.isSuccess) {
          toast.success("El cliente se creo correctamente");
          navigate("/contactos/clientes");
        } else if (dataAddclient.data.addClient?.message) {
          const { detail } = dataAddclient.data.addClient.message;
          const { code } = dataAddclient.data.addClient.message;
          if (code === "A1011") {
            arr.forEach(async (item) => {
              await getAllClients({
                variables: {
                  filters: {
                    filter: item.documentNumber,
                  },
                },
              }).then(async (dataAllClients) => {
                await getClients({
                  variables: {
                    clientFilters: {
                      documentNumber: item.documentNumber,
                    },
                  },
                })
                  .then((dataGetClients) => {
                    if (dataGetClients.data.getClients.data.length > 0) {
                      navigate(`/contactos/clientes/editar/${dataAllClients.data.getAllClients.data[0].idClientCompany}`);
                      toast.error("El cliente ya existe en tu empresa");
                    } else if (dataAllClients.data.getAllClients.isSuccess) {
                      addClientToCompany({
                        variables: {
                          idClient: dataAllClients.data.getAllClients.data[0].id,
                          data: {
                            idRegion: dataAllClients.data.getAllClients.data[0].region.id,
                            activeCredit: false,
                            creditLimit: 0,
                            creditExcelse: false,
                            creditPeriod: 0,
                            isExcent: false,
                            retention: false,
                            perception: false,
                            isActive: false,
                            plazoType: "DIAS",
                            name: dataAllClients.data.getAllClients.data[0].name,
                            legalRepresentationType: dataAllClients.data.getAllClients.data[0].legalRepresentation,
                            contribuyenteType: dataAllClients.data.getAllClients.data[0].contribuyenteType,
                            idLineOfBusiness: dataAllClients.data.getAllClients.data[0].lineOfBusiness.id,
                          },
                        },
                      }).then(() => {
                        navigate("/contactos/clientes");
                        toast.success("Se agrego el cliente a tu empresa");
                      });
                    } else {
                      navigate("/contactos/clientes");
                      toast.error(`Hubo un error al crear el cliente`);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    toast.error(`Hubo un error al crear el cliente ${e}`);
                  });
              });
            });
          } else {
            toast.error(`Error al crear el cliente, intente mas tarde`);
          }
        } else {
          toast.error(`Hubo un error al crear el cliente`);
        }
      } catch (error: any) {
        toast.error(`Hubo un error al crear el cliente`);
        console.log(error);
      }
    },
  });

  function getStepContent(stepIndex: number): JSX.Element {
    switch (stepIndex) {
      case 0:
        return <Tipo formik={formik} />;
      case 1:
        return <General formik={formik} />;
      case 2:
        return <Direccion formik={formik} />;
      case 3:
        return <Facturacion formik={formik} />;
    }
  }
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 3) return prev + 1;
      return prev;
    });
  };
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev >= 1) return prev - 1;
      return prev;
    });
  };
  const buttonNext = (step: any) => {
    if (step === 0) {
      return (
        <MDButton
          disabled={!Boolean(formik.values.legalRepresentation)}
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 1) {
      return (
        <MDButton
          disabled={
            Boolean(formik.errors.dui) ||
            Boolean(formik.errors.nit) ||
            Boolean(formik.errors.nrc) ||
            Boolean(formik.errors.pasaporte) ||
            Boolean(formik.errors.carnet_residente) ||
            Boolean(formik.errors.nombre) ||
            Boolean(formik.errors.nombreComercial) ||
            Boolean(formik.errors.actividadPrincipal) ||
            Boolean(formik.errors.telefono) ||
            Boolean(formik.errors.email)
          }
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 2) {
      return (
        <MDButton
          disabled={Boolean(formik.errors.pais) || Boolean(formik.errors.direccion) || Boolean(formik.errors.departamento) || Boolean(formik.errors.municipio)}
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={loading || Boolean(formik.errors.contribuyenteType) || Boolean(formik.errors.creditLimit) || Boolean(formik.errors.creditPeriod)}
          type="submit"
          color="info"
          variant="gradient"
        >
          {loading ? "Enviando" : "Enviar"}
        </MDButton>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={5} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={580} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  {activeStep === 0 ? (
                    <MDButton onClick={() => navigate("/contactos/clientes")} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  ) : (
                    <MDButton onClick={handleBack} variant="outlined" color="info">
                      Atras
                    </MDButton>
                  )}
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CrearClientes;
