import React, { useEffect, useRef } from "react";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// @mui material components
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

// Utils
import { formatNumberToMoney } from "utils/formatNumberToMoney";

// Libs
import { useFormik, useFormikContext } from "formik";

// Views
import PaymentsDataTable from ".";

// Hooks
import { useHandleReSendSale } from "hooks/nuevoDTE/handlesSubmits/useHandleReSendSale";
import { useBulkSubmit } from "hooks/nuevoDTE/handlesSubmits/useBulkSubmit";
import { useBulkFexSubmit } from "hooks/nuevoDTE/handlesSubmits/useBulkFexSubmit";

// Data
import DataPaymentMethods from "../../../../assets/data/paymentMethods.json";

// Types
import { DataTablePaymentMethods } from "context/AddSellContext";
import { PaymentConditions } from "types/payment-conditions";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { IvaDocumentTypes } from "types/iva-document";
import { OperationVariables, QueryResult } from "@apollo/client";
import { SaleInformation } from "types/saleInformation";
import { useHandleFixFacturaExportacion } from "hooks/nuevoDTE/handlesSubmits/useHandleFixFacturaExportacion";

interface Props {
  handleClose: (value: ActiveValues) => void;
  freeSale?: boolean;
  handleOpen: (value: ActiveValues) => void;
  CorregirDTE?: boolean;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  dataGetGeneralDocuments: QueryResult<any, OperationVariables>;
}

const Contado = ({ setSaleInformation, handleClose, freeSale, handleOpen, CorregirDTE, dataGetGeneralDocuments }: Props) => {
  const documentView = JSON.parse(localStorage.getItem("documentView"));

  // Context
  const { global, setGlobal } = useAddSellContext();
  const { values, resetForm } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  const buttonSubmit = useRef<HTMLButtonElement>(null);
  const PaymentMethods = useRef<HTMLInputElement>(null);

  // Hooks
  const { handleReSendSale, dataReSendSale } = useHandleReSendSale({ freeSale, setSaleInformation, handleOpen, handleClose, dataGetGeneralDocuments });
  const { handleBulkSubmit, dataAddSaleGeneralDocument } = useBulkSubmit({ setSaleInformation, freeSale, handleOpen, handleClose });
  const { handleBulkSubmitFexDte, dataCreateFacturaExportacion } = useBulkFexSubmit({ freeSale, setSaleInformation, handleClose, handleOpen });
  const { handleFixFacturaExportacion, dataFixFacturaExportacion } = useHandleFixFacturaExportacion();

  const formik = useFormik<DataTablePaymentMethods>({
    initialValues: {
      amount: parseFloat(global?.totalToPay.toFixed(2)),
      selectedPaymentMethod: null,
      numeroReferencia: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      setGlobal((global) => {
        return {
          ...global,
          paymentMethodsFull: [...global?.paymentMethodsFull, values as DataTablePaymentMethods],
          paymentMethods: [
            ...global.paymentMethods,
            {
              amount: values?.amount,
              paymentOperationType: global?.conditionPayment,
              paymentMethodCode: values?.selectedPaymentMethod?.code,
              selectedPaymentMethod: values.selectedPaymentMethod,
              numeroReferencia: values.numeroReferencia,
            } as DataTablePaymentMethods,
          ],
        };
      });
      setSubmitting(false);
    },
    validate: (values) => {
      let errors: any = {};
      if (!values?.amount && global?.totalToPay !== global?.paymentMethodsFull?.reduce((acc: any, item: any) => acc + item.amount, 0) + values.amount) {
        errors.amount = "El campo de 'Monto' es requerido";
      }
      if (!values?.selectedPaymentMethod) {
        errors.selectedPaymentMethod = "El campo de 'Método de pago' es requerido";
      }
      if (values.amount > parseFloat(global?.totalToPay.toFixed(2)) || parseFloat(global?.totalToPay.toFixed(2)) < global?.paymentMethodsFull?.reduce((acc: any, item: any) => acc + item.amount, 0) + values.amount) {
        errors.amount = "La suma de los montos no puede ser mayor al total a pagar";
      }
      if (values.amount === global?.totalToPay + global?.paymentMethodsFull?.reduce((acc: any, item: any) => acc + item.amount, 0)) {
        errors = {};
      }
      return errors;
    },
  });

  const paymentMethodsOptions1: DataTablePaymentMethods[] = DataPaymentMethods?.data?.map((paymentMethod: any) => {
    return { ...paymentMethod, label: paymentMethod.name };
  });
  const sendReSend = () => {
    if (values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX && CorregirDTE) {
      handleFixFacturaExportacion({ values, handleOpen, setSaleInformation, freeSale, dataGetGeneralDocuments, resetForm });
    } else {
      handleReSendSale(values, resetForm, documentView?.isSimpleBilling);
    }
  };

  const loading = dataFixFacturaExportacion?.loading || dataCreateFacturaExportacion?.loading || dataReSendSale?.loading || dataAddSaleGeneralDocument?.loading;

  useEffect(() => {
    const paymentMethod = paymentMethodsOptions1?.find((item) => item.code === "01");
    formik.setFieldValue("selectedPaymentMethod", {
      name: paymentMethod?.name,
      code: paymentMethod?.code,
      hasReferenceNumber: paymentMethod?.hasReferenceNumber,
      id: paymentMethod?.id,
      label: paymentMethod?.name,
    });
    PaymentMethods.current.focus();
    PaymentMethods.current.click();
  }, []);

  const dataTable = {
    columns: [
      {
        Header: "Tipo de pago",
        accessor: "payment_type",
        align: "start",
        width: "55%",
        hiddeSort: true,
      },
      {
        Header: "Monto",
        accessor: "amount",
        align: "start",
        width: "40%",
        hiddeSort: true,
      },
      {
        Header: "Acción",
        accessor: "action",
        align: "end",
        hiddeSort: true,
        width: "5%",
      },
      ,
    ],
    rows: global?.paymentMethodsFull?.map((item: any, index: any) => {
      return {
        payment_type: item?.selectedPaymentMethod?.label,
        amount: formatNumberToMoney(item.amount),
        action: (
          <ClearIcon
            sx={{ cursor: "pointer" }}
            fontSize="large"
            color="error"
            id={item?.id}
            onClick={(e) => {
              setGlobal((global) => {
                return {
                  ...global,

                  paymentMethodsFull: [...global.paymentMethodsFull.slice(0, Number(e.currentTarget.id)), ...global.paymentMethodsFull.slice(Number(e.currentTarget.id) + 1)],
                  paymentMethods: [...global.paymentMethods.slice(0, Number(e.currentTarget.id)), ...global.paymentMethods.slice(Number(e.currentTarget.id) + 1)],
                };
              });
            }}
          />
        ),
      };
    }),
  };

  return (
    <>
      <MDTypography variant="h4" color="info" mb={1}>
        ¿De que Forma te Pagaran?
      </MDTypography>
      <MDTypography variant="body2" fontWeight="regular" color="info" mb={4}>
        Por favor ingresa la forma de pago, el monto y luego oprime el boton de agregar forma de pago.
      </MDTypography>
      <MDBox id="paymentMethodsContado" display="flex" justifyContent="spacebetween" flexDirection="column">
        <form onSubmit={formik.handleSubmit}>
          <Grid item width={{ xs: "100%" }} mb={3} sx={{ position: "relative" }}>
            <Autocomplete
              // noOptionsText={loading ? "Cargando tipos de pago..." : "No hay tipos de pago"}
              disablePortal
              ref={PaymentMethods}
              fullWidth
              value={formik.values.selectedPaymentMethod}
              onChange={async (_: React.SyntheticEvent, value: DataTablePaymentMethods) => {
                formik.setFieldValue("selectedPaymentMethod", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="paymentOperationType"
                  InputProps={{
                    ...params.InputProps,
                    // endAdornment: (
                    //   <>
                    //     {loading ? <CircularProgress color="inherit" size={20} /> : null}{}
                    //     {params.InputProps.endAdornment}
                    //   </>
                    // ),
                  }}
                  label="Tipo de Pago*"
                />
              )}
              options={paymentMethodsOptions1 || []}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={formik.touched.selectedPaymentMethod ? true : false} errorName={formik.errors.selectedPaymentMethod && (formik.errors.selectedPaymentMethod as string)} />
            </MDBox>
          </Grid>
          <Grid item width={{ xs: "100%" }} mb={3} sx={{ position: "relative" }}>
            <TextField
              fullWidth
              label="Ingresa el Monto"
              type="number"
              name="amount"
              value={formik.values.amount}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("amount", Number(e.target.value));
              }}
            />
            <MDBox position="absolute" width="100%">
              <CustomFieldError touched={formik.touched.amount ? true : false} errorName={formik.errors.amount && (formik.errors.amount as string)} />
            </MDBox>
          </Grid>
          {formik.values.selectedPaymentMethod?.hasReferenceNumber ? (
            <Grid item width={{ xs: "100%" }} mb={3} sx={{ position: "relative" }}>
              <TextField
                fullWidth
                label="Referencia"
                name="numeroReferencia"
                value={formik.values.numeroReferencia}
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("numeroReferencia", e.target.value);
                }}
              />
              <MDBox position="absolute" width="100%">
                <CustomFieldError touched={formik.touched.amount ? true : false} errorName={formik.errors.amount && (formik.errors.amount as string)} />
              </MDBox>
            </Grid>
          ) : null}
          <Grid item width={{ xs: "100%" }} mb={2}>
            <MDButton
              type="submit"
              disabled={parseFloat(global?.totalToPay.toFixed(2)) === global?.paymentMethodsFull?.reduce((acc: any, item: any) => acc + item.amount, 0) || formik.values.selectedPaymentMethod === null}
              variant="contained"
              color="info"
              sx={{ width: "100%" }}
            >
              Agregar Forma de Pago
            </MDButton>
          </Grid>
        </form>
      </MDBox>

      <Grid item width={{ xs: "100%" }} minHeight="200px" minWidth="275px" mb={2}>
        <PaymentsDataTable table={dataTable} showTotalEntries={false} entriesPerPage={false} pagination={{ variant: "contained", color: "info" }} />
      </Grid>
      <Grid item width={{ xs: "100%" }} mb={2}>
        {global.conditionPayment === PaymentConditions.OTRO ? (
          <>
            <MDTypography textAlign="right" variant="body1" fontWeight="bold">
              Remanente a crédito: {formatNumberToMoney(parseFloat(global?.totalToPay.toFixed(2)) - global?.paymentMethods?.reduce((acc, item) => acc + item.amount, 0))}
            </MDTypography>
            <MDTypography textAlign="right" variant="body1" fontWeight="bold" color="info" mb={2}>
              Pago en efectivo: {formatNumberToMoney(global?.paymentMethods?.reduce((acc, item) => acc + item.amount, 0))}
            </MDTypography>
            <MDTypography textAlign="right" variant="body1" fontWeight="bold" color="info" mb={2}>
              Total: {formatNumberToMoney(global?.totalToPay)}
            </MDTypography>
          </>
        ) : (
          <>
            <MDTypography textAlign="right" variant="body1" fontWeight="bold" color="info">
              Restante:{" "}
              <MDTypography variant="button" color={`${global?.totalToPay - global?.paymentMethods?.reduce((acc, item) => acc + item.amount, 0) === 0 ? "info" : "error"}`} fontWeight="bold" fontSize="1.25rem">
                {formatNumberToMoney(parseFloat(global?.totalToPay.toFixed(2)) - global?.paymentMethods?.reduce((acc, item) => acc + item.amount, 0))}
              </MDTypography>
            </MDTypography>
            <MDTypography textAlign="right" variant="body1" fontWeight="bold" color="info" mb={2}>
              Total: {formatNumberToMoney(global?.totalToPay)}
            </MDTypography>
          </>
        )}
      </Grid>
      <Grid container flexWrap="nowrap" justifyContent="space-between">
        <Grid item mb={2}>
          <MDButton onClick={() => handleClose(ActiveValues.paymentMethod)} variant="outlined" color="info" size="large" xs={{ width: "100%" }}>
            Cancelar
          </MDButton>
        </Grid>
        <Grid item mb={2}>
          <MDButton
            color="info"
            ref={buttonSubmit}
            size="large"
            type="submit"
            disabled={parseFloat(global?.totalToPay.toFixed(2)) > global?.paymentMethods?.reduce((acc, item) => acc + item.amount, 0)}
            onClick={() => {
              CorregirDTE ? sendReSend() : values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? handleBulkSubmitFexDte(values, resetForm) : handleBulkSubmit(values, resetForm);
            }}
          >
            {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "#fff" }} />}
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Contado;
