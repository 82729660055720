import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//@mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

//react-router-dom
import { useNavigate } from "react-router-dom";

//Graphql
import { useLazyQuery, useMutation } from "@apollo/client";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";
import DialogDeleteEmployee from "./dialogs/DialogDeleteEmployee";

//Alerts
import { toast } from "react-hot-toast";

// Queries
import { GET_EMPLOYEES } from "apollo/queries/employees/getEmployees";
import { DELETE_EMPLOYEE } from "apollo/queries/employees/deleteEmployee";

// Data
import { employeesDataTableData } from "../data/dataTableDataEmployees";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

const options = ["Editar", "Archivar"];

function EmpleadosTable() {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  //Querys and mutations
  const [getEmployees, { data, loading, refetch, error }] = useLazyQuery(GET_EMPLOYEES, {
    context,
  });
  const [deleteEmployee, dataDeleteEmployee] = useMutation(DELETE_EMPLOYEE, { context });

  // States
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [deletedItem, setDeletedItem] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log(deletedItem);
  }, [deletedItem]);

  const navigate = useNavigate();

  const deleteEmployeeHandle = () => {
    deleteEmployee({
      variables: {
        idEmployee: Number(selectedMenuOption.id),
      },
    })
      .then((data: any) => {
        if (data.data.deleteEmployee.isSuccess) {
          handleCloseDelete();
          toast.success("Se archivo el empleado correctamente");
          refetch({
            filters: {},
          });
        } else {
          toast.error(`Hubo un error al archivar el empleado ${data.data.deleteEmployee.message.detail}`);
        }
      })
      .catch((e) => {
        toast.error(`Hubo un error al archivar el empleado ${e}`);
      });
  };

  const handleCloseDelete = () => {
    setOpen(false);
    setDeletedItem(null);
  };
  const handleOpenDelete = () => {
    setOpen(true);
  };

  //Effects
  useEffect(() => {
    getEmployees({
      variables: {
        filters: {
          pagination: PAGINATION,
        },
      },
    });
  }, []);
  useEffect(() => {
    if (selectedMenuOption.option === "Editar") {
      navigate(`/contactos/empleados/editar`, { state: selectedMenuOption.id });
    }
    if (selectedMenuOption.option === "Archivar") {
      handleOpenDelete();
    }
  }, [selectedMenuOption]);
  useEffect(() => {
    if (data) {
      console.log(data);
      setRowsData(data.getEmployees.data);
    }
  }, [data]);

  // Data

  const rows = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: [item.firstName, item.secondName, item.firstLastName, item.secondLastName].filter(Boolean).join(" "),
      emailNotifications: item.emailNotifications === "" || item.emailNotifications === null ? "No hay email" : item.emailNotifications,
      NIT: item.documents.find((elem: any) => elem.type === "NIT")?.documentNumber ?? "No hay NIT",
      nrc_dui: item.documents.find((elem: any) => elem.type === "DUI" || elem.type === "NRC")?.documentNumber ?? "No hay NRC/DUI",
      usuario: item.hasUser ? "Activo" : "No creado",
      menu: <MenuDropdown setDeletedItem={setDeletedItem} item={item} options={options} id={String(item.idEmployeeCompany)} onSelectOption={setSelectedMenuOption} />,
    }));
  }, [rowsData]);

  const table = {
    columns: employeesDataTableData.columns,
    rows: rows || [],
  };

  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de Empleados
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          {"Aqui podras Buscar, editar y archivar todos los empleados creados"}
        </MDTypography>
      </MDBox>
      <DataTable
        table={dataDeleteEmployee.loading || loading ? { columns: employeesDataTableData.columns, rows: [] } : table}
        loading={loading || dataDeleteEmployee.loading}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => navigate("/contactos/empleados/crear")} color="info">
            Crear Nuevo Empleado
          </MDButton>
        </Grid>
      </DataTable>
      <DialogDeleteEmployee open={open} handleClose={handleCloseDelete} deleteEmployeeHandle={deleteEmployeeHandle} deletedItem={deletedItem} />
    </>
  );
}
export default EmpleadosTable;
