import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Views
import DataTable from "views/Tables/DataTable";

// Libs
import { useFormikContext } from "formik";

// Utils
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { formatNumberToMoney } from "utils/formatNumberToMoney";
import { reduceDuplicatedItems } from "utils/reduceDuplicatedItems";
import { formatNumber } from "utils/formatNumber";

// Custom Components
import ActionsDropdown from "./ActionsDropdown";
import RemoveItemModal from "./RemoveItemModal";

// Constants
import { IVA_AMOUNT } from "constants/finantial";

// Types
import { SalePrice } from "types/articles-for-selling";
import { OperationType } from "types/environment";
import { IvaDocumentTypes } from "types/iva-document";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { DataTableDTEObject } from "types/dataTableDteObject";

const columns = [
  {
    Header: "Codigo",
    accessor: "cod_prod",
    width: "15%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "Nombre",
    accessor: "name",
    width: "20%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "Tipo de Venta",
    accessor: "type",
    width: "10%",
    align: "start",
    hiddeSort: true,
  },
  { Header: "Cantidad", accessor: "quantity", width: "5%", align: "start", hiddeSort: true },
  {
    Header: "P. Unitario",
    accessor: "unitray_price",
    width: "15%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "P. Total",
    accessor: "total_price",
    width: "15%",
    align: "center",
    hiddeSort: true,
  },
  { Header: "Acciones", accessor: "actions", width: "5%", align: "end", hiddeSort: true },
];
const columnsFex = [
  {
    Header: "Codigo",
    accessor: "cod_prod",
    width: "15%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "Nombre",
    accessor: "name",
    width: "20%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "Descuento Del producto",
    accessor: "productDiscount",
    width: "10%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "Otros Montos No Afectos",
    accessor: "noGravado",
    width: "10%",
    align: "start",
    hiddeSort: true,
  },
  { Header: "Cantidad", accessor: "quantity", width: "5%", align: "start", hiddeSort: true },
  {
    Header: "P. Unitario",
    accessor: "unitray_price",
    width: "15%",
    align: "start",
    hiddeSort: true,
  },
  {
    Header: "P. Total",
    accessor: "total_price",
    width: "15%",
    align: "center",
    hiddeSort: true,
  },
  { Header: "Acciones", accessor: "actions", width: "5%", align: "end", hiddeSort: true },
];
const NewDteDataTable = ({ refs, freeSale }: { refs?: { DetailFilter?: React.MutableRefObject<HTMLInputElement>; DetailFilterSimple?: React.MutableRefObject<HTMLInputElement> }; freeSale?: Boolean }) => {
  // Context
  const { global, setGlobal } = useAddSellContext();
  const { values, setValues } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  // States
  const [rowsData, setRowsData] = useState(global.products);
  const [deletedItem, setDeletedItem] = useState<DataTableDTEObject | null>(null);
  const [deletedItemIndex, setDeletedItemIndex] = useState<number>(null);

  // Refs
  const DetailFilter = refs?.DetailFilter;
  const DetailFilterSimple = refs?.DetailFilterSimple;

  const handleSelect = (item: DataTableDTEObject) => {
    setDeletedItemIndex(rowsData.indexOf(item));
    setDeletedItem(item);
  };

  const handleEditSale = (item: DataTableDTEObject) => {
    if (freeSale) {
      setGlobal((global) => {
        return {
          ...global,
          products: global.products.filter((product) => product.id !== item.id),
        };
      });

      if (DetailFilterSimple) return DetailFilterSimple.current.scrollIntoView({ behavior: "smooth", block: "center" });

      setValues((prev) => ({
        ...prev,
        productCodes: {
          ...item.productCodes,
          name: item.productCodes?.name,
          code: item.productCodes?.code,
          type: item.type,
          measurement: {
            code: item.productCodes?.measurement?.code,
            name: item.productCodes?.measurement?.name,
            id: item.productCodes?.measurement?.id,
            symbol: item.productCodes?.measurement?.symbol,
          },
        },
        type: item.type,
        detailDocument: {
          ...prev.detailDocument,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          salePrice: item.unitPrice,
        },
        salePrice: {
          label: String(item.unitPrice),
        },
        quantity: item.quantity,
        operation_type:
          {
            label: "Gravadas",
            value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS),
          } || null,
      }));
    } else {
      setGlobal((global) => {
        return {
          ...global,
          products: global.products.filter((product) => product.id !== item.id),
        };
      });
      if (DetailFilter) return DetailFilter?.current?.scrollIntoView({ behavior: "smooth", block: "center" });

      setValues((prev) => ({
        ...prev,
        productCodes: {
          ...item.productCodes,
          measurement: {
            ...item.productCodes?.measurement,
            code: item.productCodes?.measurement?.code,
            name: item.productCodes?.measurement?.name,
            id: item.productCodes?.measurement?.id,
            symbol: item.productCodes?.measurement?.symbol,
          },
        },
        type: item.productCodes?.type,
        detailDocument: {
          ...prev.detailDocument,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          salePrice: item.unitPrice,
          description: item.description,
        },
        salePrice: {
          label: formatNumber(item.unitPrice),
          id: item.productCodes?.salesPrices?.find((salePrice: SalePrice) => salePrice.priceList.id === item.idPriceList)?.id,
        },
        quantity: item.quantity,
        operation_type:
          {
            label: "Gravadas",
            value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS),
          } || null,
      }));
    }
  };
  // global.products
  const rows = Array.from(reduceDuplicatedItems(global?.products), ([_, value]) => ({ ...value })).map((item: DataTableDTEObject) => {
    if (item) {
      return {
        cod_prod: item?.code ?? "No hay codigo",
        name: item?.name ? capitalizeFirstLetter(item?.name) : "No hay nombre",
        // dte_type: capitalizeFirstLetter(item?.IvaDocumentType?.extended_name_document),
        type: (OperationType as any)[item?.operation_type],
        quantity: item?.quantity,
        unitray_price: item?.IvaDocumentType?.name_document === "FAC" ? formatNumberToMoney(item?.unitPrice * (1 + IVA_AMOUNT)) : formatNumberToMoney(item?.unitPrice),
        total_price: item?.IvaDocumentType?.name_document === "FAC" ? formatNumberToMoney(item?.totalPrice * (1 + IVA_AMOUNT)) : formatNumberToMoney(item?.totalPrice),
        actions: (
          <>
            <ActionsDropdown selectedItem={item} handleSelect={handleSelect} handleEditSale={handleEditSale} />
          </>
        ),
      };
    }
  });

  const rowsFex = Array.from(reduceDuplicatedItems(global?.products), ([_, value]) => ({ ...value })).map((item: DataTableDTEObject) => {
    if (item) {
      return {
        cod_prod: item?.code ?? "No hay codigo",
        name: item?.name ? capitalizeFirstLetter(item?.name) : "No hay nombre",
        // dte_type: capitalizeFirstLetter(item?.IvaDocumentType?.extended_name_document),
        productDiscount: item?.productDiscount ? formatNumberToMoney(Number(item?.productDiscount)) : formatNumberToMoney(0),
        noGravado: "$0.00",
        quantity: item?.quantity,
        unitray_price: item?.IvaDocumentType?.name_document === "FAC" ? formatNumberToMoney(item?.unitPrice * (1 + IVA_AMOUNT)) : formatNumberToMoney(item?.unitPrice),
        total_price: item?.IvaDocumentType?.name_document === "FAC" ? formatNumberToMoney(item?.totalPrice * (1 + IVA_AMOUNT)) : formatNumberToMoney(item?.totalPrice),
        actions: (
          <>
            <ActionsDropdown selectedItem={item} handleSelect={handleSelect} handleEditSale={handleEditSale} />
          </>
        ),
      };
    }
  });

  const table = {
    columns: values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? columnsFex : columns,
    rows: values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? rowsFex : rows,
  };

  useEffect(() => {
    global.products && setRowsData(global.products);
  }, [global.products]);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Listado de Detalles del Documento Tributario Electronico
            </MDTypography>
            <MDTypography color="secondary" variant="subtitle2">
              Abajo veras el detalle de los productos que has seleccionado
            </MDTypography>

            <Grid container gap={5} wrap="nowrap" direction={{ xs: "column", lg: "column" }}>
              <Grid item lg={12}>
                <MDBox mb={5}>
                  <DataTable table={table} entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }} canSearch pagination={{ variant: "contained", color: "info" }}>
                    {values?.IvaDocumentType?.name_document === IvaDocumentTypes.FEX ? (
                      <Grid container alignItems="center" justifyContent="end">
                        <MDButton onClick={() => {}} variant="contained" size="medium" color="info">
                          Agregar Monto No Gravado
                        </MDButton>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </DataTable>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>

      <RemoveItemModal deletedItem={deletedItem} setDeletedItem={setDeletedItem} deletedItemIndex={deletedItemIndex} />
    </>
  );
};

export default NewDteDataTable;
