import gql from "graphql-tag";

export const GET_DTE_EMITTED_ON_TESTING_MH = gql`
  query GetDteEmittedOnTestingMh($idDteElectronicBilling: Float!) {
    getDteEmittedOnTestingMh(idDteElectronicBilling: $idDteElectronicBilling) {
      isSuccess
      data {
        quantity
      }
    }
  }
`;
