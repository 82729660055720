import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";

// @mui material components
import { Autocomplete, Card, CircularProgress, FormControl, FormHelperText, Grid, TextField } from "@mui/material";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Graph
import { gql, useMutation, useQuery } from "@apollo/client";

//Formik
import { useFormik } from "formik";

//Alert
import { toast } from "react-hot-toast";

//Types
import { OficinasOrBodegas, PtosDeVtas } from "types/PtosDeVtas";
import { PAGINATION } from "constants/pagination";

//Queries
const UPDATE_SALE_POINT = gql`
  mutation UPDATE_SALE_POINT($idSalePoint: Float!, $data: SalePointDti!) {
    updateSalePoint(idSalePoint: $idSalePoint, data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const GET_SALE_POINT = gql`
  query GET_SALE_POINT($idSalePoint: Float!) {
    getSalePoint(idSalePoint: $idSalePoint) {
      isSuccess
      data {
        id
        code
        model
        mark
        resolution
        mhCode
        serie
        name
        date
        costCenter {
          id
          name
        }
        storage {
          id
          name
        }
      }
      message {
        code
        detail
      }
    }
  }
`;
const GET_COSTS_CENTERS = gql`
  query {
    getCostsCenters {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_STORAGE = gql`
  query {
    getStorages {
      isSuccess
      data {
        id
        name
        isActive
        allowSale
        costCenter {
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

const validate = (values: PtosDeVtas) => {
  const errors: any = {};

  //General
  if (!values.name) {
    errors.nombre = "El nombre es requerido";
  } else if (values.name.length < 4) {
    errors.nombre = "El nombre debe tener al menos 4 caracteres";
  }
  if (values.code) {
    if (values.code.length < 4) {
      errors.code = "El codigo interno debe tener al menos 4 caracteres";
    }
  }
  if (!values.date) {
    errors.date = "La fecha de registro es requerida";
  }
  if (!values.resolution) {
    errors.resolution = "La resolucion es requerida";
  }
  if (!values.mark) {
    errors.mark = "La marca es requerida";
  }
  if (!values.model) {
    errors.model = "El modelo es requerida";
  }
  if (!values.serie) {
    errors.serie = "El numero de serie es requerido";
  }

  return errors;
};

function EditarPtosDeVtas(): JSX.Element {
  // React-Router-Dom
  const navigate = useNavigate();
  const { idSalePoint } = useParams();

  //Queries and Mutations
  const { data, loading } = useQuery(GET_SALE_POINT, {
    variables: {
      idSalePoint: Number(idSalePoint),
    },
  });
  const [updateSalePoint, dataUpdateSalePoint] = useMutation(UPDATE_SALE_POINT);
  const dataCostCenter = useQuery(GET_COSTS_CENTERS, { variables: { pagination: PAGINATION } });
  const dataStorage = useQuery(GET_STORAGE);

  //States
  const [oficinas, setOficinas] = useState<OficinasOrBodegas[]>([{ label: "No Options", id: 0 }]);
  const [bodegas, setBodegas] = useState<OficinasOrBodegas[]>([{ label: "No Options", id: 0 }]);

  //Effects
  useEffect(() => {
    if (dataCostCenter?.data) {
      setOficinas(
        dataCostCenter?.data?.getCostsCenters?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataCostCenter.data]);
  useEffect(() => {
    if (dataStorage?.data) {
      setBodegas(
        dataStorage?.data?.getStorages?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataStorage.data]);

  const formik = useFormik({
    initialValues: {
      code: "",
      date: "",
      tipoFacturacion: "",
      establecimiento: {
        id: 0,
        label: "",
      },
      mark: "",
      mhCode: "",
      model: "",
      name: "",
      resolution: "",
      serie: "",
    },
    validate,
    onSubmit: async (values) => {
      updateSalePoint({
        variables: {
          idSalePoint: Number(idSalePoint),
          data: {
            name: values.name,
            date: values.date,
            code: values.code,
            mhCode: values.mhCode,
            resolution: values.resolution,
            mark: values.mark,
            model: values.model,
            serie: values.serie,
            idCostCenter: values.tipoFacturacion === "Oficina" ? values?.establecimiento?.id : null,
            idStorage: values.tipoFacturacion === "Bodega" ? values?.establecimiento?.id : null,
          },
        },
      })
        .then((dataUpdateSalePoint) => {
          if (dataUpdateSalePoint.data.updateSalePoint.isSuccess) {
            toast.success("El punto de venta se actualizo correctamente");
            navigate("/configuracion/empresa", { state: 3 });
          } else {
            toast.success(`Hubo un error al actualizar el punto de venta. ${dataUpdateSalePoint.data.updateSalePoint?.message?.message}`);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Hubo un error al actualizar el punto de venta. Intente nuevamente");
        });
    },
  });

  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.name) {
        toast.error(formik.errors.name);
      }
      if (formik.errors.code) {
        toast.error(formik.errors.code);
      }
      if (formik.errors.date) {
        toast.error(formik.errors.date);
      }
      if (formik.errors.mhCode) {
        toast.error(formik.errors.mhCode);
      }
      if (formik.errors.resolution) {
        toast.error(formik.errors.resolution);
      }
      if (formik.errors.mark) {
        toast.error(formik.errors.mark);
      }
      if (formik.errors.model) {
        toast.error(formik.errors.model);
      }
      if (formik.errors.serie) {
        toast.error(formik.errors.serie);
      }
    }
  };

  //Effects
  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        name: data.getSalePoint.data?.name,
        date: data.getSalePoint.data?.date,
        code: data.getSalePoint.data?.code,
        mhCode: data.getSalePoint.data?.mhCode,
        resolution: data.getSalePoint.data?.resolution,
        mark: data.getSalePoint.data?.mark,
        model: data.getSalePoint.data?.model,
        serie: data.getSalePoint.data?.serie,
        tipoFacturacion: data.getSalePoint.data?.storage !== null ? "Bodega" : data.getSalePoint.data?.costCenter !== null ? "Oficina" : null,
        establecimiento:
          data.getSalePoint.data?.storage !== null
            ? {
                label: data.getSalePoint.data?.storage?.name,
                id: data.getSalePoint.data?.storage?.id,
              }
            : data.getSalePoint.data?.costCenter !== null
            ? {
                label: data.getSalePoint.data?.costCenter?.name,
                id: data.getSalePoint.data?.costCenter?.id,
              }
            : null,
      }));
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={2} mb={1}>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="tipoFacturacion"
                          freeSolo
                          options={["Bodega", "Oficina"]}
                          value={formik.values.tipoFacturacion}
                          onChange={(e, value) => formik.setFieldValue("tipoFacturacion", value)}
                          onBlur={() => formik.setFieldTouched("tipoFacturacion", true)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de lugar donde facturaras"
                              error={Boolean(formik.errors.tipoFacturacion) && Boolean(formik.touched.tipoFacturacion)}
                              helperText={!formik.touched.tipoFacturacion || (formik.touched.tipoFacturacion && !formik.errors.tipoFacturacion) ? " " : `${formik.errors.tipoFacturacion}`}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="establecimiento"
                          options={formik.values.tipoFacturacion === "Bodega" ? bodegas : formik.values.tipoFacturacion === "Oficina" ? oficinas : [{ label: "No options", id: 0 }]}
                          freeSolo
                          value={formik.values?.establecimiento?.label ?? ""}
                          getOptionDisabled={(option) => option.label === "No options" && option.id === 0}
                          onChange={(e, value) => formik.setFieldValue("establecimiento", value)}
                          onBlur={() => formik.setFieldTouched("establecimiento", true)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Lugar exacto desde donde facturaras"
                              error={Boolean(formik.errors.establecimiento) && Boolean(formik.touched.establecimiento)}
                              helperText={!formik.touched.establecimiento || (formik.touched.establecimiento && !formik.errors.establecimiento) ? " " : `${formik.errors.establecimiento}`}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(formik.errors.name) && formik.touched.name}
                          onBlur={() => formik.setFieldTouched("name", true)}
                          label="Nombre del Punto de Venta"
                          name="name"
                          helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(formik.errors.code) && formik.touched.code}
                          onBlur={() => formik.setFieldTouched("code", true)}
                          label="Codigo Interno"
                          name="code"
                          helperText={!formik.touched.code || (formik.touched.code && !formik.errors.code) ? " " : formik.errors.code}
                          value={formik.values.code}
                          onChange={formik.handleChange}
                          variant="standard"
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox display="flex" flexDirection="column">
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Registro
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                      Estos datos se utilizaran para enviarte los documentos tributarios electroncios.
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <FormControl variant="standard" fullWidth error={true}>
                        <MDTypography sx={{ fontSize: "0.8rem", color: "#7b809a" }}>Fecha de Registro</MDTypography>
                        <MDDatePicker
                          onChange={(_: any, value: string) => formik.setFieldValue("date", value)}
                          value={formik.values.date}
                          onBlur={() => formik.setFieldTouched("date", true)}
                          options={{ maxDate: "today" }}
                          input={{
                            placeholder: "Seleccione una fecha",
                            fullWidth: true,
                            variant: "standard",
                            error: true,
                          }}
                        />
                        <FormHelperText>{!formik.touched.date || (formik.touched.date && !formik.errors.date) ? " " : formik.errors.date}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(formik.errors.resolution) && formik.touched.resolution}
                        onBlur={() => formik.setFieldTouched("resolution", true)}
                        label="Resolucion"
                        name="resolution"
                        helperText={!formik.touched.resolution || (formik.touched.resolution && !formik.errors.resolution) ? " " : formik.errors.resolution}
                        value={formik.values.resolution}
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(formik.errors.mhCode) && formik.touched.mhCode}
                        onBlur={() => formik.setFieldTouched("mhCode", true)}
                        label="Numero Asignado"
                        name="mhCode"
                        helperText={!formik.touched.mhCode || (formik.touched.mhCode && !formik.errors.mhCode) ? " " : formik.errors.mhCode}
                        value={formik.values.mhCode}
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Caracteristicas
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(formik.errors.mark) && formik.touched.mark}
                        onBlur={() => formik.setFieldTouched("mark", true)}
                        label="Marca"
                        name="mark"
                        helperText={!formik.touched.mark || (formik.touched.mark && !formik.errors.mark) ? " " : formik.errors.mark}
                        value={formik.values.mark}
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(formik.errors.model) && formik.touched.model}
                        onBlur={() => formik.setFieldTouched("model", true)}
                        label="Nombre del Punto de Venta"
                        name="model"
                        helperText={!formik.touched.model || (formik.touched.model && !formik.errors.model) ? " " : formik.errors.model}
                        value={formik.values.model}
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(formik.errors.serie) && formik.touched.serie}
                        onBlur={() => formik.setFieldTouched("serie", true)}
                        label="Numero de Serie"
                        name="serie"
                        helperText={!formik.touched.serie || (formik.touched.serie && !formik.errors.serie) ? " " : formik.errors.serie}
                        value={formik.values.serie}
                        onChange={formik.handleChange}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/configuracion/empresa")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdateSalePoint.loading}
                    onClick={() => {
                      validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdateSalePoint.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditarPtosDeVtas;
