import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";

// Context
import { useFormikContext } from "formik";

// Views
import DataTable from "views/Tables/DataTable";

//Custom components
import AddNewDocument from "../../OtherDocument/components/modal/addNewDocument";

// @mui material components
import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

// Types
import { OtherAssociatedDocument } from "types/otherAssociatedDocument";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const columns = [
  {
    Header: "Codigo del documento",
    accessor: "code",
    width: "5%",
    align: "start",
    // Cell: ({ value }: any) => <DefaultCellSells value={value} />,
  },
  {
    Header: "Descripción",
    accessor: "description",
    width: "5%",
    align: "start",
    // Cell: ({ value }: any) => <DefaultCellSells value={value} />,
  },
  {
    Header: "	Detalle del documento",
    accessor: "documentNumber",
    width: "5%",
    align: "start",
    // Cell: ({ value }: any) => <DefaultCellSells value={value} />,
  },
  {
    accessor: "actions",
    width: "1%",
    align: "end",
    hiddeSort: true,
    // Cell: ({ value }: any) => <DefaultCellSells value={value} />,
  },
];

const OtherAssociatedDocuments = () => {
  const [openAddDocuemnt, setOpenAddDocument] = useState(false);

  // Context

  const { values, setFieldValue } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  const handleCloseAddDocument = () => {
    setOpenAddDocument(false);
    setFieldValue("infoAssociateDocument", null);
  };

  const handleOpenAddDocument = () => {
    setOpenAddDocument(true);
  };

  const rows = values?.otherAssociatedDocuments
    ? values.otherAssociatedDocuments.map((item: OtherAssociatedDocument) => {
        if (item) {
          return {
            code: item?.type,
            description: item?.description ?? item?.typeOfTransport?.descripcion,
            documentNumber: item?.document ?? item?.documentDriver,
            actions: (
              <ClearIcon
                sx={{ cursor: "pointer" }}
                id={String(item?.id)}
                fontSize="large"
                color="error"
                onClick={(e: any) => {
                  setFieldValue(
                    "otherAssociatedDocuments",
                    values.otherAssociatedDocuments.filter((item) => item.id !== Number(e.currentTarget.id))
                  );
                }}
              />
            ),
          };
        }
      })
    : [];

  const table = {
    rows,
    columns,
  };

  return (
    <>
      <Grid container gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <DataTable table={table} canSearch pagination={{ variant: "contained", color: "info" }}>
            <Grid container alignItems="center" justifyContent="end">
              <MDButton onClick={() => handleOpenAddDocument()} variant="contained" size="medium" color="info">
                Agregar Nuevo Documento
              </MDButton>
            </Grid>
          </DataTable>
        </Grid>
      </Grid>
      <AddNewDocument open={openAddDocuemnt} handleClose={handleCloseAddDocument} />
    </>
  );
};

export default OtherAssociatedDocuments;
