import gql from "graphql-tag";

export const ADD_ARTICLE = gql`
  mutation ADD_ARTICLE($data: ArticleDti!) {
    addArticle(data: $data) {
      isSuccess
      data {
        id
        type
      }
      message {
        code
        detail
        message
      }
    }
  }
`;
