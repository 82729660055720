/**
=========================================================
* Formulario de Registro de Haz Conta
=========================================================
*/

import { useCallback, useEffect, useState } from "react";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
// import bgImage from "assets/images/hazconta/register-2.png";
import bgImage from "assets/images/hazconta/imagen-register-v2.png";

import * as Yup from "yup";
// Forms
import { useFormik } from "formik";
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import HaveAcount from "./HaveAcount";

// React-Router-Dom
import { useNavigate } from "react-router-dom";

//Custom components
import CrearEmpresa from "./crearEmpresa";

//axios
import axios from "axios";

//Alert
import { toast } from "react-hot-toast";

//material ui
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { validarNIT } from "utils/validations/validarNit";
import { validarNRC } from "utils/validations/validarNrc";

export interface Values {
  nombre?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  contribuyenteType: string;
  companyName: string;
  nombreComercial: string;
  legalRepresentation: string;
  actividadPrincipal: {
    id: string;
    label: string;
  };
  nit: string;
  nrc: string;
  pais: {
    id: string;
    label: string;
  };
  departamento: {
    id: string;
    label: string;
  };
  municipio: {
    id: string;
    label: string;
  };
  direccion: string;
  telefono: string;
  companyEmail: string;
}

const validationSchema = Yup.object().shape({
  //User
  nombre: Yup.string().trim().required("El nombre es requerido").min(4, "El nombre debe tener al menos 4 caracteres"),
  email: Yup.string().trim().required("El correo es requerido").email("Correo invalido"),
  password: Yup.string().trim().required("La contraseña es requerido").min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de contraseña es requerida"),
  //Company
  contribuyenteType: Yup.string()
    .trim()
    .required("El tamaño del contribuyente es requerido")
    .matches(/(GRANDE|MEDIANO|OTRO)/, { message: "Tamaño del contribuyente invalido" })
    .ensure(),
  companyName: Yup.string().trim().required("El nombre del contribuyente es requerido").min(4, "El nombre del contribuyente debe tener al menos 4 caracteres"),
  nombreComercial: Yup.string().trim().min(4, "El nombre comercial debe tener al menos 4 caracteres"),
  actividadPrincipal: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .required("La actividad principal es requerida")
    .nullable(),
  pais: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  })
    .required("El pais es requerido")
    .nullable(),
  departamento: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  municipio: Yup.object({
    id: Yup.number(),
    label: Yup.string(),
  }).nullable(),
  nit: Yup.string()
    .trim()
    .required("El NIT es requerido")
    .test({
      name: "ValidarNit",
      message: "NITy Invalido",
      test: (value) => {
        if (validarNIT(value)) {
          return false;
        } else {
          return true;
        }
      },
    }),
  nrc: Yup.string()
    .trim()
    .required("El NRC es requerido")
    .test({
      name: "ValidarNrc",
      message: "NRC Invalido",
      test: (value) => {
        if (!validarNRC(value)) {
          return false;
        } else {
          return true;
        }
      },
    }),
  telefono: Yup.string().trim().required("El telefono es requerido").min(8, "El telefono debe tener al menos 8 caracteres"),
  direccion: Yup.string().trim().nullable(),
  companyEmail: Yup.string().trim().required("El correo es requerido").email("Correo invalido"),
});

function Illustration(): JSX.Element {
  const navigate = useNavigate();
  //states
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState({
    pass: false,
    confirm: false,
  });
  const [loadingAddCompany, setloadingAddCompany] = useState<boolean>(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleClickShowPassword = (value: boolean) =>
    setShowPassword((show) => {
      if (value) {
        return {
          ...show,
          pass: !show.pass,
        };
      } else {
        return {
          ...show,
          confirm: !show.confirm,
        };
      }
    });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik<Values>({
    initialValues: {
      nombre: "",
      email: "",
      password: "",
      confirmPassword: "",
      contribuyenteType: "",
      companyName: "",
      nombreComercial: "",
      legalRepresentation: "",
      actividadPrincipal: null,
      nit: "",
      nrc: "",
      pais: null,
      departamento: null,
      municipio: null,
      direccion: "",
      telefono: "",
      companyEmail: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let region = null;
      if (values?.municipio?.id) {
        region = Number(values.municipio.id);
      } else if (values?.departamento?.id) {
        region = Number(values?.departamento?.id);
      } else {
        region = Number(values?.pais?.id);
      }

      const data = {
        companyData: {
          name: values.companyName,
          representationLegalType: values.legalRepresentation === "JURIDICA" ? 1 : 2,
          address: values.direccion,
          email: values.companyEmail,
          idRegion: region,
          phone: values.telefono,
          tradename: values.nombreComercial === "" ? values.companyName : values.nombreComercial,
          contribuyenteType: values.contribuyenteType === "GRANDE" ? 1 : values.contribuyenteType === "MEDIANO" ? 2 : values.contribuyenteType === "OTRO" && 3,
          idLineOfBusiness: Number(values.actividadPrincipal.id),
        },
        userInformation: {
          email: values.email,
          password: values.password,
          name: values.nombre,
          surname: values.nombreComercial ?? values.nombre,
        },
        documents:
          values.nrc !== "" && values.nit === ""
            ? [{ documentNumber: values.nrc, type: 4 }]
            : values.nit !== "" && values.nrc === ""
            ? [{ documentNumber: values.nit, type: 3 }]
            : values.nit !== "" &&
              values.nrc !== "" && [
                { documentNumber: values.nrc, type: 4 },
                { documentNumber: values.nit, type: 3 },
              ],
      };

      setloadingAddCompany(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/company`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((dataAddUserCompany) => {
          if (dataAddUserCompany?.data?.isSuccess) {
            toast.success("Compañia creada exitosamente");
            navigate("/authentication/sign-in");
          } else {
            toast.error(`Hubo un error al crear la compañia - ${dataAddUserCompany.data?.error.detail}`);
            navigate("/authentication/sign-in");
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(`Hubo un error al crear la compañia - ${e.response.data.error.detail}`);
        })
        .finally(() => setloadingAddCompany(false));
    },
  });

  return (
    <>
      {isRegistered ? (
        <CrearEmpresa loading={loadingAddCompany} formik={formik} setIsRegistered={setIsRegistered} />
      ) : (
        <IllustrationLayout title="Prueba Haz Conta Gratis Por 14 Dias" description="Sin Compromisos, sin limites y sin necesidad de tarjeta de credito." illustration={bgImage}>
          <MDBox>
            <MDBox mb={2}>
              <MDInput
                name="nombre"
                label="Nombre del Usuario"
                fullWidth
                value={formik.values.nombre}
                onBlur={() => {
                  formik.setFieldTouched("nombre", true);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
              <CustomFieldError touched={formik.touched.nombre} errorName={formik.errors.nombre} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Correo del Usuario"
                name="email"
                value={formik.values.email}
                fullWidth
                onBlur={() => {
                  formik.setFieldTouched("email", true);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
              <CustomFieldError touched={formik.touched.email} errorName={formik.errors.email} />
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput
                type={showPassword.pass ? "text" : "password"}
                name="password"
                label="Contraseña"
                value={formik.values.password}
                fullWidth
                onBlur={() => {
                  formik.setFieldTouched("password", true);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
              <MDBox display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: formik.errors.password && formik.touched.password ? -6 : 0, right: 0, width: "10%", height: "100%" }}>
                <IconButton onClick={() => handleClickShowPassword(true)} onMouseDown={handleMouseDownPassword}>
                  {showPassword.pass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </MDBox>
              <CustomFieldError touched={formik.touched.password} errorName={formik.errors.password} />
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput
                type={showPassword.confirm ? "text" : "password"}
                name="confirmPassword"
                label="Confirmar Contraseña"
                value={formik.values.confirmPassword}
                fullWidth
                onBlur={() => {
                  formik.setFieldTouched("confirmPassword", true);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
              <MDBox display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: formik.errors.confirmPassword && formik.touched.confirmPassword ? -6 : 0, right: 0, width: "10%", height: "100%" }}>
                <IconButton onClick={() => handleClickShowPassword(false)} onMouseDown={handleMouseDownPassword}>
                  {showPassword.confirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </MDBox>
              <CustomFieldError touched={formik.touched.confirmPassword} errorName={formik.errors.confirmPassword} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetRememberMe} sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                &nbsp;&nbsp;Recuerdame
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={() => {
                  formik.handleSubmit();
                  if (formik.dirty) {
                    const entriesExist = Object.entries(formik.errors)
                      .flat()
                      .filter((item) => item === "nombre" || item === "email" || item === "password" || item === "confirmPassword");
                    if (entriesExist.length === 0) {
                      setIsRegistered(true);
                    } else {
                      toast.error("Complete el formulario correctamente ");
                    }
                  } else {
                    toast.error("Complete el formulario correctamente ");
                  }
                }}
                variant="gradient"
                color="primary"
                fullWidth
              >
                EMPEZAR PRUEBA GRATIS
              </MDButton>
            </MDBox>
            <HaveAcount />
          </MDBox>
        </IllustrationLayout>
      )}
    </>
  );
}

export default Illustration;
