import gql from "graphql-tag";

export const ANULAR_CCFS_CON_NOTA_CREDITO = gql`
  mutation ANULAR_CCFS_CON_NOTA_CREDITO($input: CreditInvoiceWithCreditNoteDti!) {
    anularCCFsConNotaCredito(input: $input) {
      respuestaHacienda {
        completeResponse {
          estado
          descripcionMsg
          selloRecibido
        }
      }
    }
  }
`;
