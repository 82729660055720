// Types
import { PaymentConditions } from "types/payment-conditions";
import { IvaDocumentTypes } from "types/iva-document";
import { Client } from "types/clients";

// Utils
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { convertToNit } from "./convertToNit";
import { documentTypeSelector } from "./documentTypeSelect";
import { OperationType } from "types/environment";

export const duplicateCorregirDte = ({ dataGetGeneralDocuments, dataClients, setValues, setGlobal, dataGetArticlesForSelling }: any) => {
  const document = dataGetGeneralDocuments?.data?.getGeneralDocuments?.data[0];
  // Client
  const selectedClient = dataClients?.getClients.data.find((client: Client) => client.idClientCompany == document?.idClientCompany);

  // Products
  const selectedProduct: any = dataGetArticlesForSelling?.data.getArticlesForSelling.data.filter((product: any) => {
    return document?.detailDocuments.some((item: any) => item.article?.id == product?.id);
  });
  const newProductsSimpleBilling = document?.detailDocuments
    .map((item: any) => {
      return {
        ...item,
        type: item?.articleType,
        productDiscount: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? item?.discountDetails : null,
        noGravado: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? "$0.00" : null,
        name: item?.description.split(" ").length > 1 ? item?.description.split(" ").slice(2).join(" ") : item?.description.split(" ")[0],
        code: item?.description.split(" ").length > 1 ? item?.description.split(" ")[1] : "No hay codigo",
        quantity: item?.detailQuantity,
        unitPrice: item?.unitPrice,
        totalPrice: item?.priceDetail * item?.detailQuantity,
        operation_type: item?.ivaOperationType === "gravadas" ? "GRAVADAS" : item?.ivaOperationType === "exentas" ? "EXENTAS" : item?.ivaOperationType === "no_sujetas" ? "NO_SUJETAS" : item.ivaOperationType,
      };
    })
    .flat()
    .filter((element: any) => element !== undefined);
  /* const newProductsFex =
    document?.ivaDocument?.name_document === IvaDocumentTypes.FEX
      ? document?.jsonDteFex?.cuerpoDocumento.map((item: any) => {
          return {
            ...item,
            operation_type: OperationType.GRAVADAS.toUpperCase(),
            code: item?.codigo,
            name: item?.descripcion,
            productDiscount: item?.montoDescu,
            noGravado: "$0.00",
            quantity: item?.cantidad,
            unitPrice: item?.precioUni,
            totalPrice: item?.ventaGravada,
          };
        })
      : null; */
  const newProducts = document?.detailDocuments
    .map((itemX: any) => {
      return selectedProduct.map((itemY: any) => {
        if (itemX.article?.id == itemY?.id) {
          return {
            ...itemY,
            name: itemX?.article?.name,
            type: itemX?.articleType,
            productDiscount: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? itemX?.discountDetails : null,
            idSalePrice: itemX?.idArticlePriceList,
            noGravado: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? "$0.00" : null,
            productCodes: itemY,
            quantity: itemX?.detailQuantity,
            unitPrice: itemX?.unitPrice,
            totalPrice: itemX?.priceDetail * itemX?.detailQuantity,
            operation_type:
              document?.ivaDocument?.name_document === IvaDocumentTypes.FEX
                ? OperationType.GRAVADAS.toUpperCase()
                : itemX?.ivaOperationType === "gravadas"
                ? "GRAVADAS"
                : itemX?.ivaOperationType === "exentas"
                ? "EXENTAS"
                : itemX?.ivaOperationType === "no_sujetas"
                ? "NO_SUJETAS"
                : itemX?.ivaOperationType,
            idPriceList: itemX?.priceList?.id,
          };
        }
      });
    })
    .flat()
    .filter((element: any) => element !== undefined);

  // Other Associated Documents
  const newOtherAssociatedDocuments =
    document?.ivaDocument?.name_document === IvaDocumentTypes.FEX
      ? document?.jsonDteFex?.otrosDocumentos.map((item: any) => {
          return {
            id: Math.random(),
            type: capitalizeFirstLetter(item?.codDocAsociado.toLowerCase()),
            document: item?.descDocumento,
            description: item?.detalleDocumento,
            typeOfTransport:
              item?.modoTransp?.codigo && item?.modoTransp?.descripcion
                ? {
                    ...item?.modoTransp,
                    label: (item?.modoTransp?.codigo ? item?.modoTransp?.codigo + " - " : "") + item?.modoTransp?.descripcion,
                  }
                : null,
            vehiclePlate: item?.placaTrans,
            documentDriver: item?.numConductor,
            NameDriver: item?.nombreConductor,
          };
        })
      : null;

  //Ventas Gravados
  const totalGravados = document?.detailDocuments
    .map((item: any) => {
      if (item.ivaOperationType === "gravadas") {
        return item.priceDetail;
      }
      return;
    })
    .filter((element: any) => element !== undefined)
    .reduce((acc: number, num: number) => acc + num, 0);
  //Ventas Excentas
  const totalExcentas = document?.detailDocuments
    .map((item: any) => {
      if (item.ivaOperationType === "exentas") {
        return item.priceDetail;
      }
      return;
    })
    .filter((element: any) => element !== undefined)
    .reduce((acc: number, num: number) => acc + num, 0);
  //Ventas No Sujetas
  const totalNoSujetas = document?.detailDocuments
    .map((item: any) => {
      if (item.ivaOperationType === "no_sujetas") {
        return item.priceDetail;
      }
      return;
    })
    .filter((element: any) => element !== undefined)
    .reduce((acc: number, num: number) => acc + num, 0);

  const paymentsMethods = document?.paymentMethods.map((item: any) => {
    return {
      amount: item.finalAmount,
      paymentOperationType: item.type,
      paymentMethodCode: item.paymentMethod.code,
      numeroReferencia: item.paymentMethod.hasReferenceNumber,
      selectedPaymentMethod: {
        code: item.paymentMethod.code,
        hasReferenceNumber: item.paymentMethod.hasReferenceNumber,
        id: item.paymentMethod?.id,
        name: item.paymentMethod.name,
        label: item.paymentMethod.name,
      },
    };
  });
  setValues((prev: any) => {
    return {
      ...prev,
      IvaDocumentType: {
        createdAt: document?.createdAt,
        extended_name_document: document?.ivaDocument.extended_name_document,
        id: document?.ivaDocument?.id,
        name_document: document?.ivaDocument.name_document,
        updatedAt: null,
      },
      type: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? document?.jsonDteFex?.emisor?.tipoItemExpor : null,
      incoterms:
        document?.ivaDocument?.name_document === IvaDocumentTypes.FEX && document?.jsonDteFex?.resumen?.descIncoterms
          ? {
              id: Number(document?.jsonDteFex?.resumen?.codIncoterms),
              codigo: document?.jsonDteFex?.resumen?.codIncoterms,
              descripcion: document?.jsonDteFex?.resumen?.descIncoterms,
              label: (document?.jsonDteFex?.resumen?.codIncoterms ? document?.jsonDteFex?.resumen?.codIncoterms + " - " : "") + document?.jsonDteFex?.resumen?.descIncoterms,
            }
          : null,
      recintoFiscal:
        document?.ivaDocument?.name_document === IvaDocumentTypes.FEX && document?.jsonDteFex?.emisor?.recintoFiscal
          ? {
              id: Number(99),
              codigo: document?.jsonDteFex?.emisor?.codeRecintoFiscal,
              descripcion: document?.jsonDteFex?.emisor?.recintoFiscal,
              label: (document?.jsonDteFex?.emisor?.codeRecintoFiscal ? document?.jsonDteFex?.emisor?.codeRecintoFiscal + " - " : "") + document?.jsonDteFex?.emisor?.recintoFiscal,
            }
          : null,
      regimenExportacion:
        document?.ivaDocument?.name_document === IvaDocumentTypes.FEX && document?.jsonDteFex?.emisor?.regimen
          ? {
              id: Number(99),
              codigo: document?.jsonDteFex?.emisor?.codeRegimen,
              descripcion: document?.jsonDteFex?.emisor?.regimen,
              label: (document?.jsonDteFex?.emisor?.codeRegimen ? document?.jsonDteFex?.emisor?.codeRegimen + " - " : "") + document?.jsonDteFex?.emisor?.regimen,
            }
          : null,
      otherAssociatedDocuments: document?.ivaDocument?.name_document === IvaDocumentTypes.FEX ? newOtherAssociatedDocuments : null,
      ventasCuentasTerceros:
        document?.ivaDocument?.name_document === IvaDocumentTypes.FEX && document?.jsonDteFex?.ventaTercero?.nit && document?.jsonDteFex?.ventaTercero?.nombre
          ? {
              nit: convertToNit(document?.jsonDteFex?.ventaTercero?.nit),
              name: document?.jsonDteFex?.ventaTercero?.nombre,
            }
          : null,
      clientCompany: selectedClient,
      date: document?.emisionDate,
      documentType: documentTypeSelector(document.documentReceiver),
      extension: {
        receiverName: document.receiverName,
        receiverDocument: document.documentReceiver,
        observations: document.observations,
      },
      conditionPayment:
        document?.paymentCondition === PaymentConditions.CONTADO
          ? { label: "Contado", value: document?.paymentCondition }
          : document?.paymentCondition === PaymentConditions.CREDITO
          ? { label: "Crédito", value: document?.paymentCondition }
          : { label: "Otro", value: document?.paymentCondition },
    };
  });
  setGlobal((glo: any) => {
    return {
      ...glo,
      products: document?.isSimpleBilling ? [...newProductsSimpleBilling] : [...newProducts],
      /* retention: document?.retentionAmount,
      perception: document?.perceptionAmount,
      iva: document?.ivaDocument.name_document === IvaDocumentTypes.FAC ? 0 : document?.ivaAmount, */
      seguroFex: document?.ivaDocument.name_document === IvaDocumentTypes.FEX ? String(document?.jsonDteFex?.resumen?.seguro) : "",
      fleteFex: document?.ivaDocument.name_document === IvaDocumentTypes.FEX ? String(document?.jsonDteFex?.resumen?.flete) : "",
      saleDiscount: document?.ivaDocument.name_document === IvaDocumentTypes.FEX ? String(document?.jsonDteFex?.resumen?.descuento) : "",
      /* retentionISR: document?.isr,
      subTotal: document?.subtotal, */
      operation_type: document?.operationType,
      IvaDocumentType: {
        createdAt: document?.createdAt,
        extended_name_document: document?.ivaDocument.extended_name_document,
        id: document?.ivaDocument?.id,
        name_document: document?.ivaDocument.name_document,
        updatedAt: null,
      },
      clientCompany: selectedClient,
      conditionPayment: document?.paymentCondition,
      paymentMethodsFull: paymentsMethods,
      paymentMethods: paymentsMethods,
    };
  });
};
