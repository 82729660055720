import gql from "graphql-tag";

export const ADD_CLIENTS_PRICE_LIST = gql`
  mutation ADD_CLIENTS_PRICE_LIST($data: AddClientsToPriceListDti!) {
    addClientsToPriceList(data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
