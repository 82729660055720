import React from "react";

// @mui material components
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Autocomplete, TextField, DialogActions, InputAdornment, FormControl, InputLabel, Input, FormHelperText } from "@mui/material";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Context
import { useFormikContext } from "formik";

// Assets
import tipoTransporte from "../../../../../../../../assets/data/tipoTransporte.json";
import DocuementIcon from "layouts/assets/icons/DocumentIcon";

// Types
import { OptionsOtherDocuemnts } from "types/otherDocuments";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const documentosAsociadosOptions = ["Emisor", "Receptor", "Transporte"];

interface Props {
  handleClose: () => void;
  open: boolean;
}

const AddNewDocument = ({ handleClose, open }: Props) => {
  //Context
  const { values, setFieldValue, touched, setValues, setFieldTouched, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  const tipoTransportOptions: OptionsOtherDocuemnts[] = tipoTransporte.map((item) => {
    return {
      ...item,
      label: (item?.codigo ? item?.codigo + " - " : "") + item.descripcion,
    };
  });

  const errorsInfoAssociateDocument =
    errors.infoAssociateDocument?.NameDriver ||
    errors.infoAssociateDocument?.documentDriver ||
    errors.infoAssociateDocument?.vehiclePlate ||
    errors.infoAssociateDocument?.typeOfTransport ||
    errors.infoAssociateDocument?.description ||
    errors.infoAssociateDocument?.document ||
    errors.infoAssociateDocument?.type;

  const handleSubmit = async () => {
    const { type, document, description, typeOfTransport, vehiclePlate, documentDriver, NameDriver } = values.infoAssociateDocument;

    const data = {
      id: Math.random(),
      type,
      document,
      description,
      typeOfTransport,
      vehiclePlate,
      documentDriver,
      NameDriver,
    };
    if (values?.otherAssociatedDocuments?.length > 0) {
      await setFieldValue("otherAssociatedDocuments", [...values.otherAssociatedDocuments, data]);
      handleClose();
    } else {
      await setFieldValue("otherAssociatedDocuments", [data]);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{"Información del Documento Asociado"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingBottom: 2 }}>Datos generales del documento</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Autocomplete
              disablePortal
              fullWidth
              value={values?.infoAssociateDocument?.type}
              onChange={async (_: React.SyntheticEvent, value: string, reason: string) => {
                setFieldValue("infoAssociateDocument.type", value);
                setValues((prev) => {
                  if (value === "Transporte") {
                    return {
                      ...prev,
                      infoAssociateDocument: {
                        ...prev.infoAssociateDocument,
                        type: value,
                        document: null,
                        description: null,
                      },
                    };
                  } else {
                    return {
                      ...prev,
                      infoAssociateDocument: {
                        ...prev.infoAssociateDocument,
                        type: value,
                      },
                    };
                  }
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  error={Boolean(errors?.infoAssociateDocument?.type)}
                  variant="standard"
                  label="Documento Asociado"
                />
              )}
              options={documentosAsociadosOptions}
            />
          </Grid>
          {values?.infoAssociateDocument?.type !== documentosAsociadosOptions[2] && (
            <>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth variant="standard" /* error={Boolean(errors?.infoAssociateDocument?.document)} */>
                  <InputLabel>Identificación del Documento:</InputLabel>
                  <Input
                    name="infoAssociateDocument.document"
                    endAdornment={
                      <InputAdornment position="start">
                        <DocuementIcon />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      setFieldValue("infoAssociateDocument.document", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("infoAssociateDocument.document")}
                    value={values?.infoAssociateDocument?.document}
                  />

                  {/*  <FormHelperText>{!errors?.infoAssociateDocument?.document ? " " : errors?.infoAssociateDocument?.document}</FormHelperText> */}
                </FormControl>
                <CustomFieldError touched={touched?.infoAssociateDocument?.document ? true : false} errorName={errors?.infoAssociateDocument?.document} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDInput
                  fullWidth
                  variant="standard"
                  onBlur={() => setFieldTouched("infoAssociateDocument.description", true)}
                  label="Descripción del documento"
                  value={values?.infoAssociateDocument?.description}
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue("infoAssociateDocument.description", e.target.value);
                  }}
                />
                <CustomFieldError touched={touched?.infoAssociateDocument?.description ? true : false} errorName={errors?.infoAssociateDocument && (errors?.infoAssociateDocument?.description as string)} />
              </Grid>
            </>
          )}

          {values?.infoAssociateDocument?.type === documentosAsociadosOptions[2] && (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  value={values?.infoAssociateDocument?.typeOfTransport}
                  onChange={async (_: React.SyntheticEvent, value: OptionsOtherDocuemnts) => {
                    setFieldValue("infoAssociateDocument.typeOfTransport", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      variant="standard"
                      label="Tipo de Transporte"
                    />
                  )}
                  options={tipoTransportOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  variant="standard"
                  onBlur={() => setFieldTouched("infoAssociateDocument.vehiclePlate", true)}
                  label="Placa Del Vehiculo"
                  value={values?.infoAssociateDocument?.vehiclePlate}
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue("infoAssociateDocument.vehiclePlate", e.target.value);
                  }}
                />
                <CustomFieldError touched={touched?.infoAssociateDocument?.vehiclePlate ? true : false} errorName={errors?.infoAssociateDocument && (errors?.infoAssociateDocument?.vehiclePlate as string)} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  onBlur={() => setFieldTouched("infoAssociateDocument.NameDriver", true)}
                  label="Nombre del Conductor"
                  value={values?.infoAssociateDocument?.NameDriver}
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    await setFieldValue("infoAssociateDocument.NameDriver", e.target.value);
                  }}
                />
                <CustomFieldError touched={touched?.infoAssociateDocument?.NameDriver ? true : false} errorName={errors?.infoAssociateDocument && (errors?.infoAssociateDocument?.NameDriver as string)} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth variant="standard" /* error={Boolean(errors?.infoAssociateDocument?.documentDriver)} */>
                  <InputLabel>Numero De Identificación Del Conductor</InputLabel>
                  <Input
                    name="infoAssociateDocument.documentDriver"
                    endAdornment={
                      <InputAdornment position="start">
                        <DocuementIcon />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      setFieldValue("infoAssociateDocument.documentDriver", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("infoAssociateDocument.documentDriver")}
                    value={values?.infoAssociateDocument?.documentDriver}
                  />
                </FormControl>
                <CustomFieldError touched={touched?.infoAssociateDocument?.documentDriver ? true : false} errorName={errors?.infoAssociateDocument?.documentDriver} />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="info"
        >
          Atras
        </MDButton>
        <MDButton
          onClick={() => {
            if (!errorsInfoAssociateDocument) {
              handleSubmit();
            }
          }}
          variant="gradient"
          type="submit"
          color="info"
        >
          Agregar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewDocument;
