import { useState, useEffect, SyntheticEvent } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom components
import Description from "./Description";
import History from "./History";
import Reintentos from "./Reintentos";

// Context
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";

const InfoSection = () => {
  const { loading: loadingDocument, error: errorDocument } = useGeneralDocumentContext();
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event: SyntheticEvent, newValue: number) => setTabValue(newValue);

  return (
    <>
      {!loadingDocument && !errorDocument && (
        <Grid container justifyContent="start" flexDirection="column" flexWrap="nowrap" gap={5} width={{ xs: "100%", xxl: "50%" }}>
          {/* Options */}
          <MDBox display="flex" height="auto">
            <Grid container justifyContent="center" gap={5}>
              <Grid container justifyContent="center" alignContent="center" gap={5} wrap="nowrap">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab label="Descripción" color="primary" sx={{ paddingX: "30px" }} />
                  <Tab label="Historial" sx={{ paddingX: "30px" }} />
                  <Tab label="Reintentos" sx={{ paddingX: "30px" }} />
                </Tabs>
              </Grid>
            </Grid>
          </MDBox>
          {/* Cards */}
          {tabValue === 0 && <Description />}
          {tabValue === 1 && <History />}
          {tabValue === 2 && <Reintentos />}
        </Grid>
      )}

      {loadingDocument && (
        <Grid container alignItems="center" flexDirection="column" flexWrap="nowrap" gap={5} width={{ xs: "100%", xxl: "50%" }}>
          <Skeleton variant="rounded" width="50%" height="7vh" />
          <Skeleton variant="rounded" width="100%" height="40vh" />
          <Skeleton variant="rounded" width="100%" height="20vh" />
        </Grid>
      )}
    </>
  );
};

export default InfoSection;
