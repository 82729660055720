import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Contribuyente from "./components/Contribuyente";
import Empresa from "./components/Empresa";
import Direccion from "./components/Direccion";

// React-Router-Dom
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";
import { Values } from "..";
import { toast } from "react-hot-toast";

const steps = ["Contribuyente", "Empresa", "Direccion"];

interface Props {
  setIsRegistered?: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikProps<Values>;
  reset?: boolean;
  setReset?: React.Dispatch<React.SetStateAction<boolean>>;
  result?: any;
  loading?: boolean;
}

function CrearEmpresa({ setIsRegistered, formik, reset, setReset, result, loading }: Props) {
  const navigate = useNavigate();
  //state
  const [activeStep, setActiveStep] = useState<number>(0);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <Contribuyente formik={formik} />;
      case 1:
        return <Empresa formik={formik} />;
      case 2:
        return <Direccion formik={formik} />;
    }
  }
  const handleBack = () => {
    setActiveStep((prev) => {
      if (prev < 3) return prev - 1;
      return prev;
    });
  };
  const handleNext = () => {
    setActiveStep((prev) => {
      if (prev < 2) return prev + 1;
      return prev;
    });
  };
  const validateForm = () => {
    if (formik.errors) {
      if (formik.errors.companyName) {
        toast.error(formik.errors.companyName);
      }
      if (formik.errors.nit) {
        toast.error(formik.errors.nit);
      }
      if (formik.errors.nrc) {
        toast.error(formik.errors.nrc);
      }
      if (formik.errors.contribuyenteType) {
        toast.error(formik.errors.contribuyenteType);
      }
      if (formik.errors.nombreComercial) {
        toast.error(formik.errors.nombreComercial);
      }
      if (formik.errors.actividadPrincipal) {
        toast.error("La actividad principal es requerida");
      }
      if (formik.errors.pais) {
        toast.error("El pais es requerido");
      }
      /* if (formik.errors.departamento && formik.touched.departamento) {
        toast.error(formik.errors.departamento);
      }
      if (formik.errors.municipio && formik.touched.municipio) {
        toast.error(formik.errors.municipio);
      } */
      if (formik.errors.direccion) {
        toast.error(formik.errors.direccion);
      }
      if (formik.errors.telefono) {
        toast.error(formik.errors.telefono);
      }
      if (formik.errors.companyEmail) {
        toast.error(formik.errors.companyEmail);
      }
    } else {
      toast.success("No existen errores");
    }
  };
  const buttonNext = (step: any) => {
    if (step === 0) {
      return (
        <MDButton
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
          disabled={formik.values.legalRepresentation === ""}
        >
          Siguiente
        </MDButton>
      );
    } else if (step === 1) {
      return (
        <MDButton
          onClick={() => {
            handleNext();
          }}
          color="info"
          variant="gradient"
          disabled={Boolean(formik.errors.companyName) || Boolean(formik.errors.nit) || Boolean(formik.errors.nrc) || Boolean(formik.errors.contribuyenteType) || Boolean(formik.errors.nombreComercial) || Boolean(formik.errors.actividadPrincipal)}
        >
          Siguiente
        </MDButton>
      );
    } else {
      return (
        <MDButton
          onClick={() => {
            formik.handleSubmit();
            validateForm();
          }}
          color="info"
          startIcon={result?.loading || loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
          variant="gradient"
          disabled={result?.loading || loading || Boolean(formik.errors.pais) || Boolean(formik.errors.direccion) || Boolean(formik.errors.telefono) || Boolean(formik.errors.companyEmail)}
        >
          {result?.loading === true || loading ? "Enviando..." : "Enviar"}
        </MDButton>
      );
    }
  };

  useEffect(() => {
    formik.setFieldTouched("contribuyenteType", false);
    formik.setFieldTouched("companyName", false);
    formik.setFieldTouched("nombreComercial", false);
    formik.setFieldTouched("actividadPrincipal", false);
    formik.setFieldTouched("pais", false);
    formik.setFieldTouched("departamento", false);
    formik.setFieldTouched("municipio", false);
    formik.setFieldTouched("nit", false);
    formik.setFieldTouched("nrc", false);
    formik.setFieldTouched("telefono", false);
    formik.setFieldTouched("direccion", false);
    formik.setFieldTouched("companyEmail", false);
  }, []);

  useEffect(() => {
    if (reset) {
      formik.handleReset();
      setActiveStep(0);
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <Typography color="info" variant="h3" align="center" mt={4}>
        Vamos a Crear tu Empresa
      </Typography>
      <Typography color="info" variant="body1" align="center">
        Esta informacion es necesaria por requerimiento del Ministerio de Hacienda
      </Typography>

      <MDBox mt={3} mb={3} minHeight="100vh">
        <Grid container justifyContent="center" alignItems="center" gap={4}>
          <Grid item lg={8.65}>
            <MDBox
              borderRadius="lg"
              sx={{
                mx: "1rem",
                position: "relative",
                top: "2.3rem",
                zIndex: 1,
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step}>
                    <StepLabel color="inherit">{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <Card>
              <MDBox minHeight={500} p={4} display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={{ my: 3 }}>{getStepContent(activeStep)}</MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                  <MDButton
                    onClick={() => {
                      if (activeStep === 0) {
                        if (setIsRegistered !== undefined) {
                          setIsRegistered(false);
                        } else {
                          navigate("/configuracion/empresa");
                        }
                      } else {
                        handleBack();
                      }
                    }}
                    variant="outlined"
                    color="info"
                  >
                    Atras
                  </MDButton>
                  {buttonNext(activeStep)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default CrearEmpresa;
