import { useEffect } from "react";

//@mui material components
import { Autocomplete, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import { departamentos, getMunicipios, paises } from "utils/getRegions";

//Types
import { EmployeesComponentsProps } from "types/employeesComponentsProps";

function DireccionEmployees({ formik }: EmployeesComponentsProps) {
  useEffect(() => {
    if (formik.values?.pais?.label !== "EL SALVADOR") {
      formik.setFieldValue("municipio", null);
      formik.setFieldValue("departamento", null);
    }
  }, [formik.values.pais]);

  useEffect(() => {
    if (formik.values.departamento === null) {
      formik.setFieldValue("municipio", null);
    }
  }, [formik.values.departamento]);

  return (
    <>
      <MDBox my={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Direccion
        </MDTypography>
      </MDBox>
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 9.5 }}>
              <Grid item xs={12} md={formik.values.pais?.label === "EL SALVADOR" ? 4 : 12}>
                <Autocomplete
                  id="pais"
                  openOnFocus
                  options={paises}
                  freeSolo
                  value={formik.values.pais}
                  onChange={(e, value) => formik.setFieldValue("pais", value)}
                  onBlur={() => formik.setFieldTouched("pais", true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pais"
                      error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                      helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              {formik.values.pais?.label === "EL SALVADOR" && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    freeSolo
                    openOnFocus
                    onChange={(e, value) => formik.setFieldValue("departamento", value)}
                    value={formik.values.departamento}
                    onBlur={() => formik.setFieldTouched("departamento", true)}
                    options={departamentos}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Departamento"
                        error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                        helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              )}

              {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    freeSolo
                    openOnFocus
                    value={formik.values.municipio}
                    onChange={(e, value) => formik.setFieldValue("municipio", value)}
                    onBlur={() => formik.setFieldTouched("municipio", true)}
                    options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Municipio"
                        error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                        helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Direccion"
              onBlur={() => formik.setFieldTouched("address", true)}
              error={Boolean(formik.errors.address) && formik.touched.address}
              value={formik.values.address}
              helperText={!formik.touched.address || (formik.touched.address && !formik.errors.address) ? " " : `${formik.errors.address}`}
              onChange={formik.handleChange}
              name="address"
              variant="standard"
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default DireccionEmployees;
