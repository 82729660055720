import gql from "graphql-tag";

export const GET_ARTICLE = gql`
  query GET_ARTICLE($idArticle: Float!) {
    getArticle(idArticle: $idArticle) {
      isSuccess
      data {
        id
        name
        code
        barCode
        description
        type
        operation_type
        measurement {
          id
          name
        }
        tags {
          id
          name
          value
        }
        photos {
          id
          isMain
          urlPhoto
        }
        salesPrices {
          id
          salePrice
          isDefault
          priceList {
            id
            name
          }
        }
        groups {
          idArticleGroup
          group {
            id
            name
          }
        }
        storages {
          storage {
            id
            name
            costCenter {
              id
              name
            }
          }
          id
          maxQuantity
          minQuantity
          skul
          articleStock
        }
      }
      message {
        message
        code
        detail
      }
    }
  }
`;
