import { useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import StepperCat from "components/Global/StepperCat";
import { Card } from "@mui/material";

// @mui material components
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

// Custom components
import EmpleadosTable from "./components/EmpleadosTable";
import ImportsEmployee from "./components/ImportEmployee";
import MDTypography from "components/MDTypography";
import ExportEmployee from "./components/ExportEmployee";

// Services
import { employeeService } from "services/employee-service";

function Empleados() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: <EmpleadosTable />,
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: (
        <ImportsEmployee service={employeeService}>
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Importar Empleados`}
            </MDTypography>

            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`En esta parte podrás importar empleados, pero debes de cumplir con el formato que puedes descargar en el siguiente botón:`}
            </MDTypography>
          </>
        </ImportsEmployee>
      ),
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: (
        <ExportEmployee name="Empleados">
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Exportar Empleados`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`Solamente haz clic en el botón de descargar y podrás obtener toda la información de los empleados en un archivo de excel.`}
            </MDTypography>
          </>
        </ExportEmployee>
      ),
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <MDBox display="flex" width="100%" justifyContent={{ xs: "center", sm: "flex-start" }}>
          <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
        </MDBox>
        <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Empleados;
