// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

//@mui material components
import { FormControl, FormHelperText, Grid, TextField } from "@mui/material";

//Formik
import { FormikProps } from "formik";

//Types
import { PtosDeVtas } from "types/PtosDeVtas";

interface Props {
  formik: FormikProps<PtosDeVtas>;
}

function Registro({ formik }: Props) {
  return (
    <>
      <MDBox mt={2.5} display="flex" flexDirection="column">
        <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
          Registro de Punto de Venta en Hacienda
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Estos campos son opcionales
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth error={true}>
              <MDTypography sx={{ fontSize: "0.8rem", color: "#7b809a" }}>Fecha de Registro</MDTypography>
              <MDDatePicker
                onChange={(_: any, value: string) => formik.setFieldValue("date", value)}
                value={formik.values.date}
                onBlur={() => formik.setFieldTouched("date", true)}
                options={{ maxDate: "today" }}
                input={{
                  placeholder: "Seleccione una fecha",
                  fullWidth: true,
                  variant: "standard",
                  error: true,
                }}
              />
              <FormHelperText>{!formik.touched.date || (formik.touched.date && !formik.errors.date) ? " " : formik.errors.date}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={Boolean(formik.errors.resolution) && formik.touched.resolution}
              onBlur={() => formik.setFieldTouched("resolution", true)}
              label="Resolucion"
              name="resolution"
              helperText={!formik.touched.resolution || (formik.touched.resolution && !formik.errors.resolution) ? " " : formik.errors.resolution}
              value={formik.values.resolution}
              onChange={formik.handleChange}
              variant="standard"
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default Registro;
