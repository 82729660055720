// Material Dashboard 2 PRO React TS examples components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Custom components
import CustomTimeLineItem from "./CustomTimelineItem";
import { useGeneralDocumentContext } from "context/VerGeneralDocumentContext";

const History = () => {
  const { document } = useGeneralDocumentContext();

  const lengthHistory = document?.history.length;

  return (
    <>
      <Grid container justifyContent="center" gap={5}>
        <Grid container justifyContent="center" gap={5}>
          <Card sx={{ padding: 3, width: "100%" }}>
            <MDTypography variant="h3" fontWeight="bold" color="info">
              Historial
            </MDTypography>

            <MDBox mt={2}>
              {document?.history.map((item: any) => (
                <CustomTimeLineItem
                  lastItem={lengthHistory === 1 ? true : false}
                  mb={lengthHistory === 1 ? 0 : 3}
                  color={`${
                    item.eventType === "EMISION" && item.statusReception === "APROBADO"
                      ? "info"
                      : item.eventType === "ENVIO_CORREO" && item.statusReception === "APROBADO"
                      ? "secondary"
                      : (item.eventType === "EMISION" && item.statusReception === "RECHAZADO") || item.eventType === "ANULACION"
                      ? "error"
                      : "secondary"
                  }`}
                  icon={`${
                    item.eventType === "EMISION" && item.statusReception === "APROBADO"
                      ? "done"
                      : item.eventType === "ENVIO_CORREO" && item.statusReception === "APROBADO"
                      ? "Enviado a"
                      : item.eventType === "EMISION" && item.statusReception === "RECHAZADO"
                      ? "clear"
                      : item.eventType === "ANULACION" && "clear"
                  }`}
                  title={`${
                    item.eventType === "EMISION" && item.statusReception === "APROBADO"
                      ? "Emision"
                      : item.eventType === "ENVIO_CORREO" && item.statusReception === "APROBADO"
                      ? "forward_to_inbox"
                      : item.eventType === "EMISION" && item.statusReception === "RECHAZADO"
                      ? "Rechazado"
                      : item.eventType === "ANULACION" && "Anulado"
                  }`}
                  dateTime={item?.fecha ?? "No hay fecha"}
                  identifier={`${
                    item.eventType === "EMISION" && item.statusReception === "APROBADO"
                      ? document?.clientCompany?.name
                      : item.eventType === "ENVIO_CORREO" && item.statusReception === "APROBADO"
                      ? item?.email_destinatarios ?? "No hay Email"
                      : item.eventType === "EMISION" && item.statusReception === "RECHAZADO"
                      ? ""
                      : item.eventType === "ANULACION" && ""
                  }`}
                />
              ))}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default History;
