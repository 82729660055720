// @mui material components
import { ButtonBase, Grid, SvgIcon } from "@mui/material";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormikProps } from "formik";
import { Values } from "../..";

interface Props {
  formik: FormikProps<Values>;
}
function Contribuyente({ formik }: Props) {
  return (
    <>
      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} mt={2.5} mb={{ xs: 3, md: 6 }}>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Estructura Legal
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Selecciona el tipo de contribuyente que eres:
        </MDTypography>
      </MDBox>
      <MDBox display="flex">
        <Grid container justifyContent="center" spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="PERSONA_NATURAL_IVA"
                onClick={(e) => {
                  formik.setFieldValue("legalRepresentation", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 90 103"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M45 0C59.2031 0 70.7143 11.5112 70.7143 25.7143C70.7143 39.9174 59.2031 51.4286 45 51.4286C30.7969 51.4286 19.2857 39.9174 19.2857 25.7143C19.2857 11.5112 30.7969 0 45 0ZM42.0067 72.1607L38.2701 65.933C36.9844 63.7835 38.5312 61.0714 41.0223 61.0714H45H48.9576C51.4487 61.0714 52.9955 63.8036 51.7098 65.933L47.9732 72.1607L54.683 97.0513L62.6183 64.6674C78.1272 67.0781 90 80.4978 90 96.6897C90 100.105 87.2277 102.857 83.8326 102.857H53.2567H36.7433H6.16741C2.77232 102.857 0 100.085 0 96.6897C0 80.4978 11.8728 67.0781 27.3817 64.6674L35.317 97.0513L42.0268 72.1607H42.0067Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.legalRepresentation === "PERSONA_NATURAL_IVA" ? colors.info.main : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Persona Natural (Inscrita en IVA)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <MDBox display="flex" flexDirection="column">
              <ButtonBase
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  padding: "10px 24px",
                  color: formik.values.legalRepresentation === "JURIDICA" ? colors.info.main : colors.lightBlue.main,
                  borderColor: formik.values.legalRepresentation === "JURIDICA" ? colors.info.main : colors.lightBlue.main,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  "&:hover": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                  "&:active": {
                    borderColor: "inherit",
                    borderWidth: "2px",
                  },
                }}
                name="JURIDICA"
                onClick={(e) => {
                  formik.setFieldValue("legalRepresentation", e.currentTarget.name);
                }}
              >
                <SvgIcon
                  viewBox="0 0 90 72"
                  sx={{
                    height: { xs: "5rem", md: "7rem" },
                    width: { xs: "5rem", md: "7rem" },
                    p: 2,
                  }}
                >
                  <path d="M6.75 0C3.02344 0 0 3.02344 0 6.75V65.25C0 68.9766 3.02344 72 6.75 72H20.25V60.75C20.25 57.0234 23.2734 54 27 54C30.7266 54 33.75 57.0234 33.75 60.75V72H46.3922C45.5062 70.5656 45 68.8781 45 67.0641C45 60.4688 48.6281 54.7172 54 51.7078V38.2219V6.75C54 3.02344 50.9766 0 47.25 0H6.75ZM9 33.75C9 32.5125 10.0125 31.5 11.25 31.5H15.75C16.9875 31.5 18 32.5125 18 33.75V38.25C18 39.4875 16.9875 40.5 15.75 40.5H11.25C10.0125 40.5 9 39.4875 9 38.25V33.75ZM24.75 31.5H29.25C30.4875 31.5 31.5 32.5125 31.5 33.75V38.25C31.5 39.4875 30.4875 40.5 29.25 40.5H24.75C23.5125 40.5 22.5 39.4875 22.5 38.25V33.75C22.5 32.5125 23.5125 31.5 24.75 31.5ZM36 33.75C36 32.5125 37.0125 31.5 38.25 31.5H42.75C43.9875 31.5 45 32.5125 45 33.75V38.25C45 39.4875 43.9875 40.5 42.75 40.5H38.25C37.0125 40.5 36 39.4875 36 38.25V33.75ZM11.25 13.5H15.75C16.9875 13.5 18 14.5125 18 15.75V20.25C18 21.4875 16.9875 22.5 15.75 22.5H11.25C10.0125 22.5 9 21.4875 9 20.25V15.75C9 14.5125 10.0125 13.5 11.25 13.5ZM22.5 15.75C22.5 14.5125 23.5125 13.5 24.75 13.5H29.25C30.4875 13.5 31.5 14.5125 31.5 15.75V20.25C31.5 21.4875 30.4875 22.5 29.25 22.5H24.75C23.5125 22.5 22.5 21.4875 22.5 20.25V15.75ZM38.25 13.5H42.75C43.9875 13.5 45 14.5125 45 15.75V20.25C45 21.4875 43.9875 22.5 42.75 22.5H38.25C37.0125 22.5 36 21.4875 36 20.25V15.75C36 14.5125 37.0125 13.5 38.25 13.5ZM81 38.25C81 32.0344 75.9656 27 69.75 27C63.5344 27 58.5 32.0344 58.5 38.25C58.5 44.4656 63.5344 49.5 69.75 49.5C75.9656 49.5 81 44.4656 81 38.25ZM49.5 67.0922C49.5 69.8063 51.6937 72 54.4078 72H85.0922C87.8062 72 90 69.8063 90 67.0922C90 59.8641 84.1359 54 76.9078 54H62.5922C55.3641 54 49.5 59.8641 49.5 67.0922Z" />
                </SvgIcon>
              </ButtonBase>
              <MDTypography align="center" fontWeight="bold" sx={{ color: `${formik.values.legalRepresentation === "JURIDICA" ? "info" : colors.lightBlue.main}`, p: { xs: 0, sm: 2 }, fontSize: "1rem", pt: { xs: 2 } }}>
                Persona Juridica (Empresas)
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
export default Contribuyente;
