import gql from "graphql-tag";

export const SEND_DTE_BY_EMAIL = gql`
  mutation SendDteByEmail($data: SendDteEmailDti!) {
    sendDte(data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
