import gql from "graphql-tag";

export const GET_COST_CENTERS = gql`
  query GET_COST_CENTERS($filters: CostCenterQueryFilterDti!) {
    getCostsCenters(filters: $filters) {
      isSuccess
      data {
        id
        name
        storages {
          id
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
