import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// @mui material components
import { Card } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Custom components
import StepperCat from "components/Global/StepperCat";
import DetallesLDP from "./components/DetallesLDP";
import ImportLDP from "./components/Imports/ArticulosImportLDP";
import ExportLDP from "./components/Exports/ExportLDP";
import { priceListService } from "services/priceList-service";

function ListaDePrecio() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: <DetallesLDP />,
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: (
        <ImportLDP service={priceListService}>
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              Importar Lista de Precios
            </MDTypography>

            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              En esta parte podrás importar las listas de precio, pero debes de cumplir el formato que puedes descargar en el siguiente botón:
            </MDTypography>
          </>
        </ImportLDP>
      ),
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: (
        <ExportLDP name="Lista de Precios">
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              Exportar Listas de Precios
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              Solamente haz clic en el botón de descargar y podrás obtener toda la información de las listas de precios en un archivo de excel.{" "}
            </MDTypography>
          </>
        </ExportLDP>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9} minHeight="100vh">
        <MDBox>
          <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
        </MDBox>
        <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default ListaDePrecio;
